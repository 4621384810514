import { FC, useEffect, useState } from "react";
import "./styles.scss";
import trackImage from "../../../assets/img/temporary-images/track-image.svg";
import userImage from "../../../assets/img/Profile-pics/kingroland-image.png";
import { UserLable } from "../../../common/user-labels";
import { MeatballsMenuDropdown } from "../../../common/icons";
import {
  BlueTik,
  TrackPlayBackBtn,
  YelloTik,
} from "../../../common/all-icons";
import { DropworksSmallButton, GradientActiveBtn } from "../../../common/custom-field/dropworks-button";
import projectImage from "../../../assets/img/temporary-images/project-image.svg";

import { Heading1 } from "../../../common/custom-field/dropstock-heading";
import { HeartIcon } from "../../../common/heart-icon";
import { MoreMobile } from "../../../common/moreMobile";



export const view: FC = ({projectData}:any) => {
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState([
    "Report",
  ]);
  const [songTeam, setSongTeam] = useState([
    {
      img: `${userImage}`,
      fullName: "King Roland",
      title: "Producer",
      goldTik: true,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "Maba",
      title: "Vocalist",
      goldTik: true,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "Kahino",
      title: "Beat Maker",
      goldTik: true,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "Lala Baby",
      title: "Lyricist ",
      goldTik: false,
      blueTik: true,
    },
  ]);

  const [projectId,setProjectId] = useState({
    project_id:""
  })
  useEffect(()=>{
    setProjectId({project_id:projectData?._id})
  },[projectData])

  const projectImg = projectData?.art_work_image[0]?.src

  return (
    <div className="ProjectPage container container-xxl min-height-cover">
      <div className="ProjectPage-body">
        <div>
          <img
            src={`${process.env.REACT_APP_IMGURL}/${projectImg}`||projectImage}
            className="ProjectPage-image"
            alt="user-image"
          />
        </div>
        <div className="ProjectPage-content">
          <div className="w-100">
            <div className="ProjectPage-details">
              <Heading1 >{projectData?.project_name?projectData?.project_name:projectData?.beat_name}</Heading1>
            </div>
            <div className="ProjectPage-song-title">
              King Roland, Mabapupi Ocachino,Kahino, Lala Baby
            </div>
          </div>
          <div className="d-flex gap-3 mt-2 w-100 cursor-pointer">
            <UserLable >Project</UserLable>
          </div>
        </div>
      </div>

      <div className="d-flex gap-3 uni-mt-mb-64 ProjectPage-bottons">
      <DropworksSmallButton className="width-210 text-nowrap">Ask to join</DropworksSmallButton>
      <div className="uni-py-8 uni-px-16 heart-icon-project">
        <HeartIcon id={projectId} liked={projectData?.favoriteData}/></div>
        <div className="d-flex align-items-center justify-content-center mobile-arrow-track">
              
              <span className="uni-mr-8">  More</span>
              <MoreMobile
                                  className="px-0 mobile-p-0"
                                  arr={["Block","Report"]}
                                />
              </div>
              <span className="convert-more d-flex align-items-center">
        <MeatballsMenuDropdown pagename="project"
          arr={MeatballsMenuOptions}
          height={"6px"}
        ></MeatballsMenuDropdown>
        </span>
      </div>
      <div className="d-flex justify-content-between flex-wrap  track-page-main">
        {songTeam.map((item: any, index: number) => (
          <div key={index} className="col-xxl-5 col-xl-5 col-md-5  col-12 uni-mb-16 track-page-main-sub">
            <div className="track-page-col" >
            <div>
              <h4 className="ProjectPage-subheading d-flex">
                {item.title}
                {item.goldTik ? (
                  <div className="uni-ml-8">
                    
                    <YelloTik position="top" />
                 
                  </div>
                ) : (
                  ""
                )}
              </h4>
            </div>
            <div className="ProjectPage-sub-content mb-4">
              <img
                src={item.img}
                className="ProjectPage-sub-content-img"
                alt=""
              />
              <div className="ProjectPage-sub-details">
                <h5 className="ProjectPage-sub-song-name">{item.fullName}</h5>

                {item.blueTik ? (
                  <div className="uni-ml-8">
                 
                    <BlueTik  position="top"/>
                
                  </div>
                ) : (
                  ""
                )}

                <span className="drop-project">
                <MeatballsMenuDropdown
                
                  arr={MeatballsMenuOptions}
                  height={"5px"}
                    className="track-metaballsmenudropdown"
                />
                </span>
              </div>
            </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
