import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksDisableButton, DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSelect } from "../../../../../common/custom-field/dropworks-select";
import { SelectArrowDropdown } from "../../../../../common/selection-dropdown";
import { CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";

const view = ({language,submitData,handleSelectChange,selectedLanguage,openModal}:any) => {

  const options = [
    { value: "Hindi", label: "Hindi" },
    { value: "English", label: "English" },
    { value: "Chinese", label: "Chinese" },
    { value: "Russian", label: "Russian" },
  ];
  return (
    <ModalWrapper modalId="DisplayLanguageModal" onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="DisplayLanguageModal"
      aria-hidden="true"
      aria-labelledby="DisplayLanguageModal"
      tabIndex={-1}
      data-bs-theme="dark"

    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Display Language</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh modal-second-head-font">
              Select your preferred language
            </TextfieldLG>
            <DropworksDisableButton className="custom-select-dropdown uni-mb-56">
              <SelectArrowDropdown
                options={options}
                placeHolderValue={selectedLanguage!==null?selectedLanguage:"Select"}
                selectedValue={selectedLanguage}
                onChangeValue={(value)=>handleSelectChange(value)}
              ></SelectArrowDropdown>
            </DropworksDisableButton>
          </div>
          <div className="modal-footer mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton disabled={!selectedLanguage}>
            <DropworksSmallButton disabled={!selectedLanguage} className={ !selectedLanguage ? "btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions":"btn-wid  modal-btns m-0"}  onClick={()=>submitData({ language: selectedLanguage })}>
              Save
            </DropworksSmallButton></CloseModalButton>
          </div>
        </div>
      </div>
    </div>
    </ModalWrapper>
  );
};

export default view;


