import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { AnyAction } from 'redux';
import {
  DropworksDisableButton,
  DropworksNormalButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSelect } from "../../../../../common/custom-field/dropworks-select";
import { closeModal, CloseModalButton } from "../../logo-header/view";
import uploadIcon from '../../../../../assets/steps/uploadicon.svg';
import { SelectArrowDropdown } from "../../../../../common/selection-dropdown";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import axios from "axios";
import Loader from "../../loader/view";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { uploadTrackProject } from "../../../utils/apiServices";
import { fetchTrackDetails } from "../../../../../redux/reducer/uploadSlice";

const view = () => {
  const {trackId}=useSelector((state:RootState)=>state.upload)
  const [loading, setLoading] = useState(false);
  const [audioFileAPI, setAudioFileAPI] = useState<any>(null);
  const [uploadedAudioFile, setUploadedAudioFile] = useState<any>(null);
  const dispatch=useDispatch()
  const options = [
    { value: 'Lead vocal beat ', label: 'Lead vocal beat ' },
    { value: 'Backing vocal beat', label: 'Backing vocal beat ' },
    { value: 'Ad libs beat', label: 'Ad libs beat' },
    { value: 'Instrumental track', label: 'Instrumental track' },
    { value: 'Music minus all', label: 'Music minus all' },
    { value: 'Lyric', label: 'Lyric' },
    { value: 'Guided vocal track', label: 'Guided vocal track' },
    { value: 'Music minus one', label: 'Music minus one' },
    { value: 'Main vocal stem', label: 'Main vocal stem' },
    { value: 'Double recording stem', label: 'Double recording stem' },
    { value: 'Backing vocal stem', label: 'Backing vocal stem' },
    { value: 'Ad libs vocal stem', label: 'Ad libs vocal stem' },
    { value: 'Vocal track', label: 'Vocal track' },
    { value: 'Final mix and master', label: 'Final mix and master' },
    { value: 'Mix master minus one', label: 'Mix master minus one' },
  ] 
  const schema6 = yup.object({
    file_type: yup.string().required("File type is  required"),
    short_description: yup.string().required("Short description is required"),
    upload_file: yup
    .mixed()
    .required("Audio file is required"),
});
    const onChange=(data:any)=>{
      setValue('file_type',data,{shouldValidate:true})
    }

  const {register,handleSubmit,formState: { errors ,isSubmitted,isValid},setError,setValue} = useForm<any>({resolver: yupResolver(schema6),mode: "onSubmit"});
 
  const handleAudioUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setLoading(true);
    if (file) {
        setUploadedAudioFile(file);
        const formData = new FormData();
        formData.append('uploadingFile', file);

        try {
            const uploadResponse = await axios.post(`${process.env.REACT_APP_URL}/upload-file`, formData);
            if (uploadResponse.data.success) {
              setLoading(false);
                setAudioFileAPI(uploadResponse?.data.file_data)
                setValue('upload_file',uploadResponse?.data.file_data,{shouldValidate:true})
            }
            else {
              setLoading(false);
                console.error("Else Error in response of uploading image", uploadResponse.data.message);
            }
        }
        catch (error) {
            console.error("Catch Error in response of uploading image", error);
        }
    }
};
const handleRemoveAudio = () => {
  setUploadedAudioFile(null);
  setAudioFileAPI(null);
};

const onSubmit = async (data: any) => {

  data={...data,trackId:trackId};
const response: any = await uploadTrackProject(data);

if (response.success) {
 
  dispatch(fetchTrackDetails(response.data.slug_name) as unknown as AnyAction);

  
  closeModal();
} else {
  setError(response.type || "file_type" , { type: "custom", message: response.message });
}
};
 
 
  return (
    <div
      className="modal fade"
      id="UploadModal"
      aria-hidden="true"
      aria-labelledby="UploadModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered    mx-auto   custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header uni-mb-32 p-0">
            <Heading3 className="modal-title">Upload a File</Heading3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="text-content mt-0 uni-mb-16">
              File Type
            </TextfieldLG>
            {/* <DropworksSelect className="uni-mb-32">
              <option value="">Select</option>
            </DropworksSelect> */}
            <DropworksDisableButton className="custom-select-dropdown ">
              <SelectArrowDropdown
                options={options}
                placeHolderValue="Select"
                onChangeValue={onChange}
              ></SelectArrowDropdown>
            </DropworksDisableButton>
            {errors.file_type && typeof errors.file_type.message === "string" && <p className="invalid-feedback error-red">{errors.file_type.message}</p>}

            <TextfieldLG className="text-content uni-mb-16 uni-mt-16">
              Short Description
            </TextfieldLG>
            <DropworksInput placeholder="Type" onInput={(e: any) => {
                 
                    setValue('short_description',e.target.value,{shouldValidate:true})
            }} />
            {errors.short_description && typeof errors.short_description.message === "string" && <p className="invalid-feedback error-red">{errors.short_description.message}</p>}

            <TextfieldLG className="text-content uni-mb-16 uni-mt-32">
              File
            </TextfieldLG>
            <div className="audio-upload-section">
          {audioFileAPI ? (
            <div className="audio-file-info d-flex align-items-center justify-content-between">
              <TextfieldSM className="file-name mx-0 my-0">
                {(() => {
                  const fileName = uploadedAudioFile.name;
                  const extension = fileName.slice(fileName.lastIndexOf('.'));
                  const nameWithoutExtension = fileName.slice(0, fileName.lastIndexOf('.'));

                  return nameWithoutExtension.length > 10
                    ? `${nameWithoutExtension.slice(0, 10)}...${extension}`
                    : fileName;
                })()}
              </TextfieldSM>

              <span className="remove-file-icon ml-2" onClick={handleRemoveAudio}>X</span>
            </div>
          ) : loading ? <Loader className="uni-mt-24"/>: (
            <label htmlFor="audio-uploads" className="upload-label">
              <div className="beatEmpty_uploadbox flex-column">
                <img src={uploadIcon} className="audio-img" />
                <label className="uni-pt-16 uni-pb-16 beat-text">Upload</label>
              </div>
            </label>
          )}
          <input
            id="audio-uploads"
            type="file"
            accept=".mp3, .wav, .m4a"
            onChange={handleAudioUpload}
            className="d-none"
          />
        </div>
        {errors.upload_file && typeof errors.upload_file.message === "string" && (
  <p className="invalid-feedback error-red">{errors.upload_file.message}</p>
)}
          
          </div>
          <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72 uni-mt-32">
            <CloseModalButton className="buttoncomponent-mobile-modle">
            <DropworksDisableButton className="btn-wid social-link-modal-btns btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
              Cancel
            </DropworksDisableButton>
            </CloseModalButton>
            <DropworksNormalButton className={isValid && !loading ?"btn-wid social-link-modal-btns m-0":"btn-wid m-0  social-link-modal-btns custom-inactive-btn text-secondary disable-interactions"} type="submit">
              Upload 
           </DropworksNormalButton>
          </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default view;
