import { useNavigate } from "react-router-dom"
import view from "./view";
import { useEffect } from "react";

export const HomePage=()=>{

    const navigate = useNavigate();
    
    const navigateTo = (value: string) => {
        navigate(value);
    };
    
  useEffect(() => {
    const url = new URL(window.location.href);
    const tokenParam = url.searchParams.get("token");
    if(tokenParam)
    {
      localStorage.setItem('dw-register-token',tokenParam)
      // setToken(tokenParam);
      console.log(tokenParam,"token is  there ++++++++++++++++++");

      const modalTrigger = document.querySelector('a[data-bs-toggle="modal"][href="#Modal1"]') as HTMLAnchorElement;
      if (modalTrigger) {
        modalTrigger.click();
      }
    }
    else {
      console.log("token is not ther")
    }
  }, []);

 
    return view({ navigateTo });
}