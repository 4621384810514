import React, { FC, useState, useRef, useEffect } from "react";
import "./styles.scss";
import meatballsIcon from "../../assets/img/icons/meatballs-menu-icon.svg"
import { ButtonComponent } from "../../app/shared/components/logo-header/view";
import { Link } from "react-router-dom";

interface DropdownProps extends React.InputHTMLAttributes<HTMLInputElement> {
  arr: string[];
  height?: string;
  icon?: any;
  className?: string;
  pagename?: string;
}
export const view: FC<DropdownProps> = ({ arr, icon = meatballsIcon, className, height, pagename }) => {
  const[open,setOpen]=useState(false)
  const moreopen=(e: React.MouseEvent)=>{
    e.stopPropagation();
    setOpen(!open)
  }
  return (
    <div className='' onClick={(e: any) => e.stopPropagation()} >
      <div className="" onClick={moreopen}>
        <img src={icon} className={`more-mobile-view-image `} style={{ height: `${height}` }} alt="img" />
      </div>
      {open ?  <ul className="more-mobile-view-ul">
        {arr.map((data,i) => {
          return (
            <>
             <ButtonComponent id={data ==="Block"?"BlockedModal":"ReportModal1"}>
            <li>{data}</li>
            </ButtonComponent>
            </>
          )
        })}
      </ul>:""}
    
    </div>
  );
};

