import view from "./view";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CheckAuthentication } from "../../utils/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { setAuthentication } from "../../../../redux/reducer/auth";
import { RootState } from "../../../../redux/store";
import { getUserData } from "../../utils/apiServices";
import { setUserData } from "../../../../redux/reducer/userSlice";
const LogoHeader=()=>{
  const dropAnimationRef:any = useRef(null);
  const userData = useSelector((state: any) => state.user);
  const {displayName} =userData;
 
  
    const dispatch =useDispatch();
    const {authentication :isLoggedin} = useSelector((state:RootState)=>state.auth) || false ;
    const logoutUser=()=>{
      localStorage.removeItem("dropworksToken");
      dispatch(setAuthentication(false))
      }
    useEffect(() => {
     let value= CheckAuthentication();
     dispatch(setAuthentication(value))
    },[]);
    const navigate=useNavigate();
    
    const navigateTo = (value: string) => () => {
    navigate(value); 

  };
 
  useEffect(() => {
    const fetchUserData = async () => {
        const result = await getUserData(); // Call your API function
        if (result.success && result.userData) { // Adjust based on your API response
            dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
        }
    };

    fetchUserData();
}, []);

      return view({ dropAnimationRef, navigateTo ,isLoggedin,logoutUser,displayName ,profileImg:userData?.profileImg});

}

export default LogoHeader;

