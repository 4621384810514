import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";
import {getAllUserStatus, getUnreadMessageList, getUserGroupList, oneToOneChatHistroy } from "../../shared/utils/apiServices";



export const MessageChat=()=>{
    const [allUserData,setAllUserData] = useState<string[]>([]);
    const [allGroupData,setAllGroupData] = useState<string[]>([]);
    const[userchatHistroy, setUserChatHistroy] = useState<string[]>([]);
    const[allUnread, setAllUnread] = useState<string[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');


    const navigate=useNavigate();


    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const result = await getAllUserStatus();
                if (result) { 
                    setAllUserData(result.allUserStatus.data); 
                
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        const fetchGroupUser = async () => {
            try {
                const result = await getUserGroupList();
                if (result) { 
                    setAllGroupData(result.groupsStatus.data);
                  
                }
            } catch (error) {
                console.error("Error fetching groupUser data:", error);
            }
        };
        const fetchUnreadMessage = async () =>{
             try{
                const result = await getUnreadMessageList();
                if(result){
                    setAllUnread(result.unreadMessage.data);
                }
             }
             catch(error){
                console.error('Error fetching unread message', error);
             }
        }
        fetchAllUsers(); 
        fetchGroupUser();
        fetchUnreadMessage();
      
    }, []);
    console.log(allUnread,'all unread message in index');
    useEffect(()=>{
        const fetchChatHistory = async () => {
            try {
                const result = await oneToOneChatHistroy(selectedUserId);
                if (result) { 
                    setUserChatHistroy(result.onChatHistroy.data); 
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        if (selectedUserId) { 
            fetchChatHistory();
        }
    },[selectedUserId])

    // console.log(allGroupData,'thiss++++++++++++++++++++++++');
    // console.log(userchatHistroy,'all chat histroy by id');
//    console.log(type,'...................................');
    
   console.log(selectedUserId,'...................................');

   
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({navigateTo,allUserData,allGroupData,userchatHistroy,selectedUserId,setSelectedUserId,setSelectedGroupId,selectedGroupId,allUnread}))
}