import { FC } from "react";
import { view } from "./view";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const DropworksButton:FC<ButtonProps>=({...props})=>{
    return view({...props,className:`${props.className} ${``} custom_btn`});
}
export const DropworksPrimaryButton:FC<ButtonProps>=({...props})=>{
    return view({...props ,className:`${props.className} ${``} custom_btn_primary`});
}
export const DropworksSecondaryButton:FC<ButtonProps>=({...props})=>{
    return view({...props ,className:`${props.className} ${``} custom_btn_secondary`});
}
export const DropworksSmallButton:FC<ButtonProps>=({...props})=>{
    return view({...props ,className:`${props.className} ${``} custom_btn_small`});
}
export const DropworksNormalButton:FC<ButtonProps>=({...props})=>{
    return view({...props ,className:`${props.className} ${``} custom_btn_normal`});
}
export const DropworksMediumButton:FC<ButtonProps>=({...props})=>{
    return view({...props ,className:`${props.className} ${``} custom_btn_medium`});
}
export const DropworksLargeButton:FC<ButtonProps>=({...props})=>{
    return view({...props ,className:`${props.className} ${``} custom_btn_large`});
}
export const DropworksDefaultButton:FC<ButtonProps>=({...props})=>{
    return view({...props ,className:`${props.className} ${``} custom_btn`});
}
export const DropworksHoverButton:FC<ButtonProps>=({...props})=>{
    return view({...props ,className:`${props.className} ${``} custom_btn`});
}
export const DropworksActiveButton:FC<ButtonProps>=({...props})=>{
    return view({...props ,className:`${props.className} ${``} custom_btn`});
}
export const DropworksDisableButton:FC<ButtonProps>=({...props})=>{
    return view({...props ,className:`${props.className} ${``} custom-inactive-btn`});
}
export const DropworkGhostButton:FC<ButtonProps>=({...props})=>{
    return view({...props ,className:`${props.className} ${``} custom-ghost-btn`});
}

export const GradientActiveBtn:FC<ButtonProps>=({...props})=>{
    return view({...props,className:`${props.className} ${``} gradient-active-btn`});
}

