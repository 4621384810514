import React from "react";
import "./styles.scss";
interface CustomRadioButtonProps
  extends React.HTMLAttributes<HTMLSpanElement> {}
const view: React.FC<CustomRadioButtonProps> = ({ ...props }) => {
  return (
    <div>
      <input
        type="radio"
        id="contactChoice1"
        name="contact"
        value="email"
        className="radio-button"
      />
    </div>
  );
};

export default view;
