import { FC } from "react";
import Select from "react-select";
import "./styles.scss";

interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  options: Option[];
  className?: string;
  placeHolderValue?: any;
  maxheight?: number;
  onChange?: (value: Option | null) => void;
}

export const view: FC<DropdownProps> = ({
  options,
  className,
  placeHolderValue,
  maxheight,
  onChange, 
}) => {
  const singleSelectStylesMarkeplace = {
    placeholder: (styles: any) => ({
      ...styles,
      color: "$theme-color-gray4",
      fontSize: "14",
      marginLeft: "0px",
      backGroundColor: "rgba(255, 255, 255, 0.05)",
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      color: "white",
      backgroundColor: "#212121",
      textAlign: "left",
      maxHeight: maxheight || 195,
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      borderRadius: "10px !important",
      backgroundColor: "rgb(0, 0, 0, 0.5)",
    }),  
  };
  return (
    <Select
      options={options}
      className={className}
      classNamePrefix="custom-select-marketplace"
      styles={singleSelectStylesMarkeplace}
      isClearable={false}
      placeholder={placeHolderValue || "Socials"}
      isSearchable={false}
      onChange={(selectedOption) => onChange && onChange(selectedOption)}
      // Pass custom styles or theme if needed
    />
  );
};
