import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InviteSlice {
   Data:any,
   sentINvite:any
   selectInde:number
  }

const initialState : InviteSlice = {
  Data:"", 
  sentINvite:"",
  selectInde:0
};

const inviteSlice = createSlice({
  name: "invite",
  initialState,
  reducers: {
    setInvite: (state, action: PayloadAction<any>) => {
      state.Data = action.payload; 
    },
    setSelectInde: (state, action: PayloadAction<any>) => {
      state.selectInde = action.payload; 
    },
    setSentInvite: (state, action: PayloadAction<any>) => {
      state.sentINvite = action.payload; 
    },
  },
});


export const { setInvite,setSelectInde,setSentInvite } = inviteSlice.actions;


export const inviteReducer = inviteSlice.reducer;
