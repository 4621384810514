import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishdict from './english';
import spanishdict from './spanish';

i18next.use(initReactI18next).init({
  resources: {
    en: { translation: englishdict },
    es: { translation: spanishdict },
    
  },
  lng: localStorage.getItem('lng') || 'en', 
  fallbackLng: 'en', 
  interpolation: {
    escapeValue: false,
  },
});
