import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { FC } from "react";
import { DropworksTextArea } from '../../../../../common/custom-field/dropworks-textArea';
import king from '../../../../../assets/img/king.png';
import verfiedIcon from '../../../../../assets/steps/verified-badge.svg';
import { MdOutlineFileDownload } from "react-icons/md";
import download from '../../../../../assets/steps/download.svg';
import { CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { BlueTik } from '../../../../../common/all-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

interface viewType {
  navigateTo:(path:string)=>()=>void
} 

const view  :FC<viewType> =({ navigateTo }) => {
  const invite = useSelector((state: RootState) => state.invite)
  return (
    <div
      className="modal fade"
      id="DeclinedInviteReceived"
      aria-hidden="true"
      aria-labelledby="DeclinedInviteReceived"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Invite <span className='declined-received-accepted'>Declined</span> </Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh">You have declined @kingroland’s invite to participate in his project</TextfieldLG>
            <div className="d-flex align-items-center uni-mb-32 invite_firstcolumn">
              <img src={king} className="invite_firstcolumn_imagebox" alt="" />
              <CloseModalButton>
              <div onClick={navigateTo("/user-profile")} className='invite-modal-pointer'>
                <h2 className="fw-700 text-light uni-mr-8">{invite.Data[invite.selectInde]?.sent_user_id.firstname+" "+ invite.Data[invite.selectInde]?.sent_user_id.lastname}
                <span> <BlueTik/></span> </h2>
                  <TextfieldSM className='text-light'>@kingroland</TextfieldSM>
              </div>
              </CloseModalButton>
             
            </div>

            <TextfieldSM className=' received-label uni-mb-16'>Project</TextfieldSM>
            <DropworksInput placeholder={invite.Data[invite.selectInde]?.track_id.project_name} className='uni-mb-32 cstm-inp-invite' readOnly></DropworksInput>

            <TextfieldSM className='received-label uni-mb-16 '>Role</TextfieldSM>
            <DropworksInput placeholder={invite.Data[invite.selectInde]?.role} readOnly className='uni-mb-32 cstm-inp-invite'></DropworksInput>

            <TextfieldSM className=' received-label uni-mb-16 ' >Percentage Offered</TextfieldSM>
            <DropworksInput  placeholder={invite.Data[invite.selectInde]?.percentage_offered} readOnly className='uni-mb-32 cstm-inp-invite'></DropworksInput>
            <TextfieldLG className=' received-label uni-mb-16'>Message</TextfieldLG>
            <DropworksTextArea className="uni-mb-32 cstm-txt-invite uni-p-16 uni-fw-400" hideIcon={true} readOnly placeholder= {invite.Data[invite.selectInde]?.message} >
           
            </DropworksTextArea>
            <TextfieldLG className='text-light uni-mb-16'>Preview</TextfieldLG>
            <div className='d-flex justify-content-between uni-p-16 align-items-center uni-mb-32'>
               <TextfieldSM className='file-name'>{invite.Data[invite.selectInde]?.upload_audio?.[0]?.fileName || 'No file available'}</TextfieldSM>
               <TextfieldSM className='file-name'> 
               <a
    href={invite.Data[invite.selectInde]?.upload_audio?.[0]?.src || '#'}
    // download={invite.Data[invite.selectInde]?.upload_audio[0]?.fileName || 'default_filename.mp3'}
  >
    <img src={download} alt="Download" className="uni-ml-8" />
  </a>
                {/* <img src={download} alt="" className="uni-ml-8" /> */}
                </TextfieldSM>
            </div>
            <div className=' uni-mb-32'>
              <TextfieldSM className='received-date'>Received on {invite.Data[invite.selectInde]?.created_at}</TextfieldSM>
            </div>

          </div>
        
        </div>
      </div>
    </div>
  )
}

export default view;


export const openButton = () => {
  console.log("ppppppppppppp")
  document.getElementById('okk')?.click();
}