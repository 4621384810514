import { FC, useState } from "react";
import { filldetailsviewType, LoadingInterface, viewType } from "../login/modal";
import "./styles.scss";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import 'react-datepicker/dist/react-datepicker.css';
import {
  DropworksButton,
  DropworksDisableButton,
} from "../../../../common/custom-field/dropworks-button";
import { DropworksSelect } from "../../../../common/custom-field/dropworks-select";
import { TextfieldSM } from "../../../../common/custom-field/dropstock-text";
import { Heading2, Heading3 } from "../../../../common/custom-field/dropstock-heading";
import { SelectArrowDropdown } from "../../../../common/selection-dropdown";
import Loader from "../../../shared/components/loader/view";
import { getValue } from "@testing-library/user-event/dist/utils";
import Countries from "../../../../i18n/country";
import DatePicker from "react-datepicker";

const view: FC<filldetailsviewType & LoadingInterface > = ({ navigateTo , handleSubmit, onSubmit, errors,setError, register,setValue,loading=false,isSubmitted,dateInputRef,trigger}) => {
  const options = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
    { value: "I prefer not to say", label: "I prefer not to say" },
  ];

  const CountryList = Countries.map((ele:any)=>{return{value:ele.name,label:ele.name}})
  
  const onChangeCountry=(data:any)=>{
    console.log(data,"");
    setValue('country',data,{shouldValidate:true})
      }
  
      const onChangeGender=(data:any)=>{
        console.log(data,"");
        setValue('gender',data,{shouldValidate:true})
          }
          // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          //   const selectedDate = event.target.value; // Format: yyyy-mm-dd
          //   const date = new Date(selectedDate);
            
          //   // Set the allowed date range
          //   const minDate = new Date(1950, 0, 1); // January 1st, 1950
          //   const maxDate = new Date(); // Current date
            
          //   // Validate date range
          //   if (date >= minDate && date <= maxDate) {
          //     // Format to dd/mm/yyyy
          //     const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(
          //       date.getMonth() + 1
          //     ).padStart(2, '0')}/${date.getFullYear()}`;
          //     setValue("DOB", formattedDate,{shouldValidate:true});
          //   }
          //   else{
          //      setError("DOB", { type: "custom", message:'Date of birth must be after January 1st, 1950 and not in the future.'});
          //   }
           
          //   setTimeout(() => {
          //     dateInputRef.current?.blur();
          //   }, 0);
          // };

          const [isFocused, setIsFocused] = useState(false);
          const handleFocus = () => {
            setIsFocused(true);
          };
        
          const handleBlur = () => {
            setIsFocused(false);
          };
          
          const [startDate, setStartDate] = useState<Date | null>(null);
          const handleChange = (date: Date | null) => {
            if (date) {

              const minDate = new Date(1950, 0, 1); // January 1st, 1950
              const maxDate = new Date(); // Current date
         setStartDate(date);
              if (date >= minDate && date <= maxDate) {
                const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(
                  date.getMonth() + 1
                ).padStart(2, '0')}/${date.getFullYear()}`;
                setValue("DOB", formattedDate, { shouldValidate: true });
               
              } else {
                setError("DOB", {
                  type: "custom",
                  message: 'Date of birth must be between January 1st, 1950, and today.',
                });
              }
            }
          };
        

  return (
    <div className=" page-content row align-items-pages container px-0 m-auto h-100  min-h-800">
     
      <div className="col-12 col-sm-12 col-md-6 col-lg-6  col-xl-6 col-xxl-5 px-0">
        <Heading3 className="signUp_heading my-0">
          Welcome to DropWorks
        </Heading3>
        <TextfieldSM className="uni-my-32 signUp-fill-normal">
          Please fill out all the fields
        </TextfieldSM>
 
        <div className="row fill-details-mobile-ui">
          <div className="col-lg-6 col-12  pe-1 ps-0 mobile-p-0-filldetails" >
            <DropworksInput {...register('firstname')} placeholder="Name" className={`form-control ${isSubmitted && (errors.firstname ? 'is-invalid b-red-2' : !errors.firstname ? 'is-valid  b-green-2' : '')}`}  disabled={loading}  />
          {errors.firstname && <p className="invalid-feedback">{errors.firstname.message}</p>}
          </div>
          <div className="col-lg-6 col-12 pe-0 ps-1 mobile-p-0-filldetails ps-fill-details">
            <DropworksInput placeholder="Surname"  {...register('lastname')}  className={`form-control ${isSubmitted && (errors.lastname ? 'is-invalid b-red-2' : !errors.lastname ? 'is-valid  b-green-2' : '')}`}   disabled={loading} />
            {errors.lastname && <p className="invalid-feedback">{errors.lastname.message}</p>}
          </div>
        </div>
        <div className="row  ">
          <div className="col-lg-6 col-12 fill-details-space uni-mt-16 pe-1 ps-0 mobile-p-0-filldetails">
          {/* <DropworksInput
              placeholder="Birth Date"
              {...register('DOB')}
              type="text"
              ref={dateInputRef}
              disabled={loading}
              className={`form-control ${isSubmitted && (errors.DOB ? 'is-invalid b-red-2 ' : !errors.DOB ? 'is-valid  b-green-2' : '')}`} 
              // onChange={(e)=>console.log(e.target.value,"value in date")}
              onFocus={(e) =>{ (e.target.type = "date") ; dateInputRef.current?.showPicker();}}
              onBlur={(e) => (e.target.type = e.target.value? "date":"text")}
              onChange={handleChange}
              id="date"
            /> */}
             <DatePicker
              selected={startDate}
              onChange={handleChange}
              placeholderText={isFocused ? "dd/mm/yyyy" : "Birth Date"}
              dateFormat="dd/MM/yyyy"
              className={`custom_inp form-control ${isSubmitted && (errors.DOB ? 'is-invalid b-red-2 ' : !errors.DOB ? 'is-valid  b-green-2' : '')}`} 
              id="date"
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={(e) => {
                const allowedKeys = [
                  "Backspace", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Tab", // Allow navigation keys
                  "Delete", // Allow delete
                ];
                // Prevent all other non-numeric keys
                if (!allowedKeys.includes(e.key) && !/^[0-9]$/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            
               {errors.DOB && <p className="invalid-feedback">{errors.DOB.message}</p>}
          </div>
          <div className="col-lg-6 ps-fill-details col-12 pe-0 ps-1 uni-mt-16 mobile-p-0-filldetails mobile-p-0-filldetails mobile-m-0-filldetails">
            <DropworksDisableButton  disabled={loading}  className={`custom-select-dropdown track-overview-dropdown cursor-pointer bg-trans mobile-mb-0-filldetails form-control cursor-pointer ${isSubmitted && (errors.gender ? 'is-invalid b-red-2' : !errors.gender ? 'is-valid  b-green-2' : '')}`} >
              <SelectArrowDropdown
                options={options}
                placeHolderValue="Gender"
                onChangeValue={onChangeGender}
                ></SelectArrowDropdown>
            </DropworksDisableButton>
            {errors.gender && <p className="invalid-feedback">{errors.gender.message}</p>}
          </div>
        </div>
       
        <div className="row uni-mb-32  uni-mt-16  mobile-m-0-filldetails">
             
            <DropworksDisableButton   disabled={loading}   className={`form-control custom-select-dropdown   track-overview-dropdown bg-trans cursor-pointer ${isSubmitted && (errors.country ? 'is-invalid b-red-2' : !errors.country ? 'is-valid  b-green-2' : '')}`}  >
            <SelectArrowDropdown
            options={CountryList}
            placeHolderValue="Country"
            onChangeValue={onChangeCountry}
            ></SelectArrowDropdown>
          </DropworksDisableButton>
          {errors.country && <p className="invalid-feedback ps-0">{errors.country.message}</p>}
        </div>  
       {loading ?<Loader className="uni-mt-40"/>:<DropworksButton type="submit" className="btn-submit-filldetails font-weight-600" onClick={handleSubmit(onSubmit)}
        //  onClick={navigateTo("/setting-up")}
                >
          Next

        </DropworksButton>}
   
      </div>
    </div>
  );
};
export default view;
