import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";

export const Footer=()=>{

    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }
const [hide,setHide]=useState(false);
const [allHide,setAllHide]=useState(false);
// Tracks Portfolio

// Personal Portfolio

// Invites

// Create

// Wallet

// Notification center
useEffect(()=>{
    if ( window.location.pathname === "/beat-empty" || window.location.pathname === "/track-project-overview2" || window.location.pathname === "/track-project-overview" || window.location.pathname === "/message-chat" || window.location.pathname === "/profile-setting"|| window.location.pathname === "/dropworks-select" || window.location.pathname === "/dropworks-composite")
     {
      setAllHide(true); // hide all footer
     }
   else if( window.location.pathname === "/personal-portfolio" || window.location.pathname === "/search" || window.location.pathname === "/library"|| window.location.pathname === "/invite" ||  window.location.pathname === "/create" || window.location.pathname === "/wallet" ||  window.location.pathname === "/notification"  ||  window.location.pathname === '/select-portfolio'  ||  window.location.pathname === '/composite-portfolio'       )
    {
        // hide small footer 
            setHide(true);
            setAllHide(false);
    }

    else{
        setHide(false);
        setAllHide(false);
        }
        },[window.location.pathname])
    return(view({navigateTo,hide,allHide}))
}