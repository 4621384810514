import { useDispatch, useSelector } from "react-redux";
import { NavigationOnLogin } from "../login";
import view from "./view";
import { useEffect } from "react";
import { setUserData } from "../../../../redux/reducer/userSlice";
import { getUserData, updateUser } from "../../../shared/utils/apiServices";


export const ProfilSetting=()=>{
    const dispatch = useDispatch();
    const submitData = async (data:any)=>{
        let response: any = await updateUser(data);
        if (response) {
            console.log("response",response)
            const result = await getUserData(); // Call your API function
            if (result.success && result.userData) { // Adjust based on your API response
                dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
            }
          } else {
            console.log("fail");
          }
    }
    const displayReleases = useSelector((state: any) => state.user.displayReleases)
    const displayBeats = useSelector((state: any) => state.user.displayBeats)
    const receiveMessagesFromAnyone = useSelector((state: any) => state.user.receiveMessagesFromAnyone)
    const publicProfile = useSelector((state: any) => state.user.publicProfile)
    const receiveOfferFromAnyone = useSelector((state: any) => state.user.receiveOfferFromAnyone)
    return view(
        {displayReleases,displayBeats,submitData,receiveMessagesFromAnyone,publicProfile,receiveOfferFromAnyone}
    );
}