import { FC, useState,forwardRef } from "react";
import { view } from "./view";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    tooltipTitle: string; 
    className2?:string | undefined,
}

// export const DropworksInputIcon:forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    export const DropworksInputIcon= forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const [show,setShow]=useState<boolean>(true);
    const showHide=()=>{
        setShow(!show)
    }
    return view({ showHide,ref,show,...props});
})