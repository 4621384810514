import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserSlice {
  securityQuestions:string[]
}

const initialState: UserSlice = {
  securityQuestions:[]
};


const settingsSlice = createSlice({
  name: "securityQuestions",
  initialState,
  reducers: {
    setSecurityQuestions: (state, action: any) => {
    //   const { _id} = action.payload;
        state.securityQuestions = action.payload
    },
  },
});


export const { setSecurityQuestions } = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
