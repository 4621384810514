import React, { useEffect, useState } from "react";
import "./styles.scss";
interface CustomRadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
    checked?:boolean
    onChange?:(value:any)=>void
    disabled?:boolean
  }
const view: React.FC<CustomRadioButtonProps> = ({disabled=false, ...props }) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const handleToggle = (value:any) => {
           props.onChange &&  props.onChange(value.target.checked)
      setIsChecked(!isChecked);
    };

    useEffect(()=>{
      setIsChecked(props.checked?props.checked:false);
    },[props.checked])

  return (
     <div>
      <label className="switch-main">
        <input
          disabled={disabled}
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default view;
