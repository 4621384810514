import { FC, useEffect, useState } from "react";
import view from "./view";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, updateUser } from "../../../utils/apiServices";
import { setUserData } from "../../../../../redux/reducer/userSlice";



export const DawsModal: FC<any> = () => {

    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const DWPreferences: any = useSelector((state: any) => state.user.DWPreferences)
    const dispatch = useDispatch()

    useEffect(()=>{
        setSelectedItems(DWPreferences)
    },[DWPreferences])
    const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (e.target.checked) {
            if (selectedItems.length < 2) {
                setSelectedItems([...selectedItems, value]);
            }
        } else {
            setSelectedItems(selectedItems.filter((item) => item !== value));
        }
    };

    const submitData = async(data: { selectedItems: any })=>{
        let response: any = await updateUser(data);
        if (response) {
            console.log("success")
            const result = await getUserData(); // Call your API function
            if (result.success && result.userData) { // Adjust based on your API response
                dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
            }
          } else {
            console.log("fail");
          }
    }


    return view(
        { DWPreferences ,handleSelectChange,selectedItems,submitData}
    );
}
export default DawsModal