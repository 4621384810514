import { useNavigate } from "react-router-dom"
import view from "./view";
import { LoginFormInputs } from "../login/modal";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginApi, signUpApi } from "../../../shared/utils/apiServices";
import { useState } from "react";
import { NavigationOnLogin } from "../login";
import { useDispatch } from "react-redux";
import { setMail } from "../../../../redux/reducer/mailSlice";


export const Sign_Up=()=>{
  NavigationOnLogin();
  const [loading,setLoading] =useState(false)
    const schema1 = yup
    .object({
      email: yup
      .string()
      .trim()
      .required("Email is required") 
      .matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, "Invalid email address"),
      password: yup
        .string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters long"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors ,isSubmitted},
    setError,
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(schema1),
    mode: "onSubmit",
  });
  const dispatch=useDispatch()
  const onSubmit = async (data: LoginFormInputs) => {
    setLoading(true);
    let response: any = await signUpApi(data);
    if (response?.success) {
      dispatch(setMail(response.email))
      navigate("/fill-details");
      setLoading(false)
    } else {
      setError(response?.type, { type: "custom", message: response?.message });
      setLoading(false)
    }
  };
    const navigate=useNavigate();
        const navigateTo=(value:string)=>()=>{
        console.log(value,"all value")
        navigate(value)
    }

    return(view({navigateTo, handleSubmit, onSubmit, errors, register,loading,isSubmitted}))
}
