import React, { FC } from "react";
import { view } from "./view";
import ArrowDown from "../../assets/steps/arrow-down.svg";

interface Option {
  value: string;
  label: string;
}
interface DropdownProps {
  options: Option[];
  className?: string;
  placeHolderValue?: any;
  maxheight?: number;
  onChange?: (value: Option | null) => void; 
}

export const SelectArrowDropdownMarketplace: FC<DropdownProps> = ({ maxheight ,options, onChange, ...props }) => {
  return view({ options,...props, onChange,});
  
};
