import React, { FC, InputHTMLAttributes } from "react";
import "./styles.scss";

interface RadioCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  disabled?: boolean;
}

export const view: FC<RadioCheckboxProps> = ({ className = "", checked, disabled, ...props }) => {
  return (
    <input
      type="checkbox"
      className={`custom_radio_chekbox cursor-pointer ${className}`}
      checked={checked}
      disabled={disabled}
      {...props}
    />
  );
};


