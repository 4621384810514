import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import UserIcon from "../../../../../assets/steps/Group 18414.svg";
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { DropworksSearchInput } from '../../../../../common/custom-field/dropworks-search-input';
import king from '../../../../../assets/img/king.png';
import { BlueTik } from '../../../../../common/all-icons';
import { SelectArrowDropdown } from '../../../../../common/selection-dropdown';
import { ButtonComponent, CloseModalButton } from '../../logo-header/view';
import { useEffect, useState } from 'react';
import { getAllUsers } from '../../../utils/apiServices';
import Loader from '../../loader/view';
import { DropworksRadioCheckbox } from '../../../../../common/custom-field/dropworks-radio-checkbox';
import { setInviteModalUserID } from '../../../../../redux/reducer/uploadSlice';
import { useDispatch } from 'react-redux';
const view = () => {

  const [searchUser,setSearchUser]=useState<string>("");
  const [loading,setLoading]=useState<boolean>(false);
  const [userList,setUserList]=useState<any>([]);
  const [selectedValue,setSelectedValue]=useState<any>();
  const dispatch=useDispatch();
  const userListAPI=async()=>{

    if(searchUser)
    {
      const response = await getAllUsers('search',searchUser);
      if(response.success)
        {
        setSelectedValue("")
        response.userData.data ? setUserList(response.userData.data):setUserList([]);
        setLoading(false);

      }
      else{
        setLoading(false);
        setUserList([]);
        setSelectedValue("")
      }
      }

      else{
        setLoading(false);
        setUserList([]);
        setSelectedValue("")
      }
  }

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      userListAPI();
    }, 500);
  
    return () => clearTimeout(delayDebounceFn);
  }, [searchUser]);

  const options = [
    { value: 'Spotify', label: 'Spotify' },
    { value: 'Apple Music', label: 'Apple Music' },
    { value: 'Deezer', label: 'Deezer' },
    { value: 'Tidal', label: 'Tidal' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Youtube', label: 'Youtube' },
  ]

  return (
    <div
      className="modal fade"
      id="InviteModal8"
      aria-hidden="true"
      aria-labelledby="InviteModal8"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content modal-width-inviteModal">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <h1 className="modal-title">Invitation</h1>
          </div>
 
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className=" uni-mt-16 ctm-txt">Please use the search to find the user you want to invite</TextfieldLG>
            <TextfieldSM className='uni-mb-56 modal-8-subcontent'>You may search for his display name or by his username</TextfieldSM>
           <DropworksSearchInput placeholder='Search' className='uni-mb-32' getSearchValue={(e:string)=>setSearchUser(e)}/>
          {loading?<Loader className="uni-my-16"/>:
            <div className='content-height content-height-invite uni-p-16'>
              { !searchUser ? "" : searchUser && !loading && userList?.length ===0 ? <TextfieldMD>No User Found</TextfieldMD>: userList.map((ele:any)=>{
                return<p className='d-flex uni-p-16 content align-items-center cursor-pointer' onClick={()=>setSelectedValue(ele._id)}>
                  <img className='modal-8-box' src={`${process.env.REACT_APP_IMGURL}/${ele.profile_image?.[0]?.src}`} onError={(e) => {e.currentTarget.src = UserIcon}}/>
                  <TextfieldSM className='uni-mx-16 my-0 text-field'> <span className='d-block invite-8-head'>{ele.display_name}</span> <TextfieldXSM>@{ele.username}</TextfieldXSM></TextfieldSM>
                  <DropworksRadioCheckbox checked={selectedValue===ele._id}  />
                </p>})
              }
            </div>}
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72 uni-mt-32">
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">Cancel</DropworksSmallButton>
            </CloseModalButton>
            {selectedValue && searchUser  ? <ButtonComponent id="InviteModal2">
            <DropworksSmallButton className="btn-wid m-0 my-0"  onClick={()=>dispatch(setInviteModalUserID(selectedValue))}>Next</DropworksSmallButton>
            </ButtonComponent>:
            <DropworksDisableButton className="btn-wid m-0 social-link-modal-btns  search-invite-upload custom-btn-upload">Next</DropworksDisableButton>}
          </div>
        </div>
      </div>
    </div>
  )
}
 
export default view;
 
 