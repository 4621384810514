import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import { CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import { getUserData, updateUser } from '../../../utils/apiServices';
import { setUserData } from '../../../../../redux/reducer/userSlice';

const view = () => {

  const {notifications} =useSelector((state:RootState)=>state.user);
  const [check,setCheck]=useState({
    "announcements": false,
    "wallet": false,
    "invites": false,
    "marketplace": false,
    "favorites": false,
    "_id":""
})

const onChange=(name:string,value:boolean)=>{
  setCheck({...check,[name]:value})
}
  useEffect(()=>{
setCheck(notifications || {
  "announcements": false,
  "wallet": false,
  "invites": false,
  "marketplace": false,
  "favorites": false,
  "_id": ""
})
  },[notifications]);
const dispatch =useDispatch();

const save=async()=>{
  const result =  Object.entries(check).filter(([key]) => key !== "_id") .map(([key, value]) => ({ [key]: value }));
  console.log(result,"----------------")
  let response: any = await updateUser({notifications:result});
  if (response) {
      const result = await getUserData(); // Call your API function
      if (result.success && result.userData) { // Adjust based on your API response
          dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
      }
  } else {
      console.log("failLLLLLLLLL");
  }
}
  return (
    <div
    className="modal fade"
    id="SocialLinksNotification"
    aria-hidden="true"
    aria-labelledby="SocialLinksNotification"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width ">
      <div className="modal-content modal-width-notification">
      <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">Notifications</Heading3>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
          <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh modal-second-head-font">Select what you want to be notified about</TextfieldLG>
          <TextfieldXSM className='uni-mb-56 sn-ctm-txt modal-second-head-font'>You will still receive all notifications, but won’t be alerted on the ones turned off</TextfieldXSM>

          <p className='d-flex justify-content-between uni-p-16 notification-body-content popup-space-mobile'>
          <TextfieldSM className='mx-0 my-0 text-field-notification'>Announcements</TextfieldSM>
          <DropworksSwitch checked={check.announcements} onChange={(e:boolean)=>onChange("announcements",e)}/>
          </p>
          <p className='d-flex justify-content-between uni-p-16 notification-body-content popup-space-mobile'>
          <TextfieldSM className='mx-0 my-0 text-field-notification'>Wallet</TextfieldSM>
          <DropworksSwitch  checked={check.wallet}  onChange={(e:boolean)=>onChange("wallet",e)}/>
          </p>
          <p className='d-flex justify-content-between uni-p-16 notification-body-content popup-space-mobile'>
          <TextfieldSM className='mx-0 my-0 text-field-notification'>Invites</TextfieldSM>
          <DropworksSwitch  checked={check.invites}  onChange={(e:boolean)=>onChange("invites",e)}/>
          </p>
          <p className='d-flex justify-content-between uni-p-16 notification-body-content popup-space-mobile'>
          <TextfieldSM className='mx-0 my-0 text-field-notification'>Marketplace</TextfieldSM>
          <DropworksSwitch  checked={check.marketplace}  onChange={(e:boolean)=>onChange("marketplace",e)}/>
          </p>
          <p className='d-flex justify-content-between uni-p-16 notification-body-content popup-space-mobile'>
          <TextfieldSM className='mx-0 my-0 text-field-notification'>Favorites</TextfieldSM>
          <DropworksSwitch  checked={check.favorites}  onChange={(e:boolean)=>onChange("favorites",e)}/>
          </p>
        </div>  
        <div className="modal-footer  mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
          <CloseModalButton>
          <DropworksSmallButton  className="btn-wid m-0 modal-btns" onClick={save}>Save</DropworksSmallButton>
          </CloseModalButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

