import Api from "./AxiosService"; // Importing the Axios service for making API requests

/*
 * Name: apiClient.ts
 * Description: A client for making API requests using Axios.
 * Version: 1.0.0
 * Author: SWAPAC INFOTECH
 */

const apiClient = {
  // Method for making GET requests
  async get(url: string) {
    return Api.get(url); // Delegating the request to the Axios service
  },

  // Method for making POST requests
  async post(url: string, body: any, contentType: string = "application/json") {
    return Api.post(url, body, {
      headers: {
        "Content-Type": "application/json", // Setting the content type for the request
      },
    });
  },

  // Method for making PUT requests
  async put(url: string, body: any, contentType: string = "application/json") {
    return Api.put(url, body, {
      headers: {
        "Content-Type": contentType, // Allowing custom content type for the request
      },
    });
  },

  // Method for making DELETE requests
  async delete(url: string) {
    return Api.delete(url); // Delegating the request to the Axios service
  },
};

export default apiClient; // Exporting the apiClient for use in other modules
