import { FC, useEffect, useState } from "react";
import "./styles.scss";
import kingRoland from "../../../assets/img/roland.png";
import { InviteFriends } from "../../../common/invite-friends";
import Tabs from "../../../common/tabs";
import { Heading2 } from "../../../common/custom-field/dropstock-heading";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export const view: FC = ({}) => {

  const invite = useSelector((state: RootState) => state.invite)
  const sentInvite = useSelector((state: RootState) => state.invite.sentINvite)
  const [recivedearr, setRecivedarr]=useState([])
  const [sentarr, setSentarr] = useState([])
  // const [recivedearr, setRecivedarr] = useState([
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //     id: "ActiveInviteReceived"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //     id: "ActiveInviteReceived"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //     id: "ActiveInviteReceived"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //     id: "ActiveInviteReceived"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //     id: "ActiveInviteReceived"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //     id: "ActiveInviteReceived"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Declined",
  //     id: "DeclinedInviteReceived"

  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Accepted",
  //     id: "AcceptedInviteReceived"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Accepted",
  //     id: "AcceptedInviteReceived"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Expired",
  //     id: "ExpiredInviteReceived"
  //   },
  // ]);

  // const [sentarr, setSentarr] = useState([
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //      id: "ActiveInviteSent"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //      id: "ActiveInviteSent"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //      id: "ActiveInviteSent"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //      id: "ActiveInviteSent"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //      id: "ActiveInviteSent"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Active",
  //      id: "ActiveInviteSent"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Declined",
  //      id: "DeclinedInviteSent"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Accepted",
  //      id: "AcceptedInviteSent"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Accepted",
  //      id: "AcceptedInviteSent"
  //   },
  //   {
  //     img: kingRoland,
  //     name: "King Roland",
  //     subname: "@kingroland",
  //     vocalist: "Vocalist",
  //     percentage: "15%",
  //     active: "Expired",
  //      id: "ExpiredInviteSent"
  //   },
  // ]);

  
  useEffect(() => {
    if (invite?.Data?.length > 0) {
      const updatedArray = invite.Data.map((data: any, index: number) => ({
        img: kingRoland,
        name: data.sent_user_id.firstname+" "+ data.sent_user_id.lastname,
        subname: "@kingroland",
        vocalist: data.role,
        percentage: data.percentage_offered,
        active:  data.acceept_status === "rejected" ? "Declined" : data.acceept_status === "pending"? "Active": data.acceept_status === "accepted"?"Accepted":"Expired",
        id: data.acceept_status === "rejected" ? "DeclinedInviteReceived" : data.acceept_status === "pending"? "ActiveInviteReceived": data.acceept_status === "accepted"?"AcceptedInviteReceived":"ExpiredInviteReceived"
      }));
      setSelectedArr(updatedArray);
      setRecivedarr(updatedArray);
    }
  }, [invite]);



  useEffect(() => {
    if (sentInvite?.length > 0) {
      const updatedArray = sentInvite.map((data: any, index: number) => ({
        img: kingRoland,
        name: data.receiver_name,
        subname: "@kingroland",
        vocalist: data.role,
        percentage: data.percentage_offered,
        active: data.acceept_status === "rejected" ? "Declined" : data.acceept_status === "pending"? "Active": data.acceept_status === "accepted"?"Accepted":"Expired",
        id: data.acceept_status === "rejected" ? "DeclinedInviteSent" : data.acceept_status === "pending"? "ActiveInviteSent": data.acceept_status === "accepted"?"AcceptedInviteSent":"ExpiredInviteSent",
      }));
      setSentarr(updatedArray);
    }
  }, [sentInvite]);




  const [selectedArr, setSelectedArr] = useState<any>(recivedearr);
  const [currentSection, setCurrentSection] = useState("received");
  const [status, setStatus] = useState("");

  const handleSectionClick = (section: string) => {
    setCurrentSection(section);
    setSelectedArr(section === "received" ? recivedearr : sentarr );
    setStatus("");
  };

  const handleStatusClick = (statusType: string) => {
    if (status === statusType) {
    
      setSelectedArr(!tabsValue  ? recivedearr : sentarr);
      setStatus("");
    } else {
      setStatus(statusType);
      let filteredArr: any;

      if (statusType === "Active") {
        filteredArr = (!tabsValue  ? recivedearr : sentarr).filter(
          (item:any) => item.active === "Active"
        );
      } else if (statusType === "Expired") {
        filteredArr = (!tabsValue   ? recivedearr : sentarr).filter(
          (item:any) => ["Expired", "Accepted", "Declined"].includes(item.active)
        );
      }
      setSelectedArr(filteredArr);
    }
  };
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
    setStatus("");
    setSelectedArr(value?sentarr:recivedearr);
  }



  return (
    <div className="invite  container container-xl container-xxl min-height-cover">
      <Heading2 className="invite-heading uni-mb-32">Invites</Heading2>


      <Tabs tabsTitle={["Received", "Sent"]}  id="2"  className="uni-mb-40"   checkTabState={checkTabState} />
    
      <div className="d-flex invite-acexp flex-wrap uni-mb-32">
        <button
          className={`invite-acexp-btn ${status === "Active" ? "active" : ""}`}
          onClick={() => handleStatusClick("Active")}
        >
          Active
        </button>
        <button
          className={`invite-acexp-btn ${status === "Expired" ? "active" : ""}`}
          onClick={() => handleStatusClick("Expired")}
        >
          Expired
        </button>
      </div>
      <div className="invite-usericon">
        <InviteFriends arr={selectedArr} />
      </div>
    </div>
  );
};
