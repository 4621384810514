import { FC } from "react";
import Select from "react-select";
import "./styles.scss";

interface Option {
  value: string;
  label: string;
}

interface DropdownProps {
  options: Option[];
  className?: string;
  placeHolderValue?: string;
  maxheight?: number;
  value:string;
  updateValue:(value: string) => void; 
  onChangeValue?: (value: string | null) => void; 
}

export const view: FC<DropdownProps> = ({
  options,
  className,
  placeHolderValue,
  maxheight,
  value,
  updateValue,
  onChangeValue
}) => {
  const singleSelectStyles = {
    placeholder: (styles: any) => ({
      ...styles,
      color: "$theme-color-gray4",
      fontSize: "14",
      marginLeft: "10px",
      backGroundColor: "rgba(255, 255, 255, 0.05)",
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      color: "white",
      maxWidth:"100%",
      minWidth:"max-content",
      backgroundColor: "#212121",
      textAlign: "left",
      maxHeight: maxheight || 256,
      
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      borderRadius: "10px !important",
      backgroundColor: "rgb(0, 0, 0, 0.5)",
      height:"60px",
      fontWeight:"400"
    }),  
  };
  const selectedOption = options.find(option => option.value === value) || null;
  return (
    <Select
      options={options}
      className={`${className}`}
      classNamePrefix="custom-select"
      styles={singleSelectStyles}
      isClearable={false}
      value={selectedOption}
      placeholder={placeHolderValue || "Socials"}
      isSearchable={false}
      onChange={(selectedOption)=>{updateValue(selectedOption?.value || "" ) ;onChangeValue && onChangeValue(selectedOption?.value || null)}}
    />
  );
};
