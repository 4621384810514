import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksLargeButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { BlueTik } from "../../../../../common/all-icons";
import hankeymen from "../../../../../assets/img/hankeyman.png";
import tree from "../../../../../assets/img/tree.png";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";

const view = () => {
  return (
    <div
      className="modal fade"
      id="DropStocksSelectsubmit3"
      aria-hidden="true"
      aria-labelledby="DropStocksSelectsubmit3"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width select-submit3-modal">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-32">
            <Heading3 className="approve-modal-title mx-0 my-0">DropWorks Select <br /> Track Submission</Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <TextfieldSM className="approve-modal-subtitle uni-mb-32">
            You are about to apply your Dropstocks into DropWorks Select.
            </TextfieldSM>
            <div className="d-flex align-items-center uni-mb-32">
              <img className="img-fluid custom-tree-img uni-mr-16 mobile-tree-img-select-submit" src={tree} />
              <h4 className="">
                {" "}
                <span className="sellDropstock-modal-text">Hot Summer</span>
                <TextfieldMD className="d-block m-0 sell-dropstocks-subpara">
                  Unlost, Uncovered, Meyna, Maluma
                </TextfieldMD>
              </h4>
            </div>
            <TextfieldSM className="sell-note-3 uni-mb-32">Our team will carefully review your submission to ensure it meets the standards of our curated collection.</TextfieldSM>
            <TextfieldSM className="sell-note-3">If your track is selected, we’ll make an offer to purchase the Dropstocks associated with this track. All holders of these Dropstocks will have the opportunity to sell based on our offer through their portfolio. If any holder accepts our offer, this track will be featured in DropWorks Select.</TextfieldSM>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72 social-mobile-modal-footer ">
            <CloseModalButton className="buttoncomponent-mobile-modle">
            <DropworksSmallButton className="btn-wid btn-bg-gray3-approve my-0 ml-0  social-link-modal-btns">Cancel</DropworksSmallButton></CloseModalButton>
            <ButtonComponent id="SubMissionThankyou" className="buttoncomponent-mobile-modle">
            <DropworksSmallButton className="btn-wid social-link-modal-btns m-0">Submit</DropworksSmallButton>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
