import { useNavigate } from "react-router-dom";
import { view } from "./view";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoginFormInputs } from "./modal";
import { loginApi ,getUserData } from "../../../shared/utils/apiServices";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthentication } from "../../../../redux/reducer/auth";
import { RootState } from "../../../../redux/store";
import { setUserData } from "../../../../redux/reducer/userSlice";
import { userLogin } from "../../../../redux-toolkit/features/authSlice";
// import { getUserData } from "../../../../redux-toolkit/features/userSlice";

export const Login = () => {
  NavigationOnLogin()
  const navigate = useNavigate();
const [loading,setLoading] =useState(false);
const dispatch = useDispatch();
  const navigateTo = (value: string) => () => {
    navigate(value);
  };
  const schema = yup
    .object({
      email: yup
        .string()
        .trim()
        .required("Email is required") 
        .matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, "Invalid email address")
        ,
      password: yup
        .string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters long"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid , dirtyFields, isDirty,isSubmitted},
    setError,
    getFieldState,
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = async (data: LoginFormInputs) => {
    setLoading(true);
    let response: any = await loginApi(data);
    if (response?.success) {
      setLoading(false);
      dispatch(setAuthentication(true));
        const result = await getUserData(); // Call your API function
     if (result.success && result.userData) { // Adjust based on your API response
      dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
            }
      navigate("/");
    } else {
      setError(response?.type || "password", { type: "custom", message: response?.message });
      setLoading(false)
    }
  };


  // const onSubmit = async (data: LoginFormInputs) => {
  //   setLoading(true);
  //   dispatch(userLogin(data) as any).then((response: any) => {
  //     console.log(response,"login res")
  //     if (response?.success) {
  //       setLoading(false);
  //       dispatch(setAuthentication(true));
  //       dispatch(getUserData() as any).then((response: any) => {
  //         if (response.success && response.userData) { // Adjust based on your API response
  //           dispatch(setUserData(response.userData.data.userdata)); // Dispatch the action to set user data
  //         }
  //       })
  //       navigate("/");
  //     } else {
  //       setError(response?.type, { type: "custom", message: response?.message });
  //       setLoading(false)
  //     }
  //   })
 
  // };

  return view({ navigateTo, handleSubmit, onSubmit, errors, register,loading , isValid, dirtyFields,getFieldState,isDirty,isSubmitted});
};



export const NavigationOnLogin=()=>{
  const {authentication} =useSelector((state:RootState)=>state.auth);
  const navigate =useNavigate()
  if(authentication)
  {
    navigate('/')
  }
}