import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProjectnameSlice {
   Data:any,
   click:boolean
  }

const initialState : ProjectnameSlice = {
  Data:"", 
  click:false
};

const projectnameSlice = createSlice({
  name: "projectname",
  initialState,
  reducers: {
    setProjectname: (state, action: PayloadAction<any>) => {
      state.Data = action.payload; 
    },
    setClick: (state, action: PayloadAction<any>) => {
      state.click = action.payload; 
    }
  },
});


export const { setProjectname,setClick } = projectnameSlice.actions;


export const projectReducer = projectnameSlice.reducer;
