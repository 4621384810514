import "./styles.scss";
import { FC, useEffect, useState, useRef } from "react";
import { CiPause1 } from "react-icons/ci";
import Plyr from "plyr"; // Ensure you have installed plyr library
import "plyr/dist/plyr.css"; // Import Plyr's CSS
import {
  AudioPauseBtn,
  CrossCircle,
  LeftNextBtn,
  MuteIcon,
  PlayerPauseBtn,
  RightNextBtn,
  VolumeUpIcon,
} from "../../../../common/all-icons";
import { MeatballsMenuDropdown } from "../../../../common/icons";
import trackImage from "../../../../assets/img/temporary-images/track-image.svg";
import trackImage2 from "../../../../assets/img/temporary-images/beat-image.svg"
import { useDispatch } from "react-redux";
import { setData } from "../../../../redux/reducer/musicSlice";
import { HeartIcon } from "../../../../common/heart-icon";
import Playercross from "../../../../assets/steps/audioplayercross.svg";
import { TextfieldXSM } from "../../../../common/custom-field/dropstock-text";
import crossIcon from '../../../../assets/steps/modal-cross-icon.svg';
// Define Track type
interface Track {
  track: number;
  name: string;
  duration: string;
  file: string;
  img:any
}

// Track list
const tracks: Track[] = [
  {
    track: 1,
    name: "King Roland, Mabapupi Ocachino,Kahino, Lala Baby",
    duration: "3:20",
    file: "/new.mp3",
    img:trackImage
  },
  {
    track: 1,
    name: "Beat Musics, Lala Baby",
    duration: "2:59",
    file: "/new2.mp3",
    img:trackImage2
  },

  // Add all other tracks here
  //...
];

const mediaPath = "https://archive.org/download/mythium/";
const extension = ".mp3"; // Adjust if needed
export const view: FC = () => {
  const [playing, setPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [npAction, setNpAction] = useState("Paused...");
  const audioRef = useRef<HTMLAudioElement>(null);
  const plyrRef = useRef<Plyr | null>(null);
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState(["Report"]);

  const [volume, setVolume] = useState<any>('75');
  const [mute, setMute] = useState(false);


  const [currentTime, setCurrentTime] = useState(0); // Track current time
  const [duration, setDuration] = useState(0); // Track total duration

  // const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const newVolume = e.target.value;
  //   setVolume(newVolume);
  //   setMute(newVolume === '0');
  //   if (audioRef.current) {
  //     audioRef.current.volume = parseInt(newVolume) / 100; // Update audio element volume
  //   }
  // };
  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume:any = Number(e.target.value); // Convert to number
    setVolume(newVolume);
    setMute(newVolume === 0); // If volume is 0, mute is true
    if (audioRef.current) {
      audioRef.current.volume = newVolume / 100; // Update audio volume (normalize to 0-1 scale)
    }
  };

  

  const handleMute = () => {
    if (mute) {
      // Unmute: set volume to previous value (75 in this case)
      const previousVolume = 75; // You can store the previous volume in a state if needed
      setVolume(previousVolume);
      if (audioRef.current) {
        audioRef.current.volume = previousVolume / 100;
      }
    } else {
      // Mute: set volume to 0
      setVolume(0);
      if (audioRef.current) {
        audioRef.current.volume = 0;
      }
    }
    setMute(!mute); // Toggle mute state
  };
  console.log(mute, "this is my mute buton");

  useEffect(() => {
    if (audioRef.current) {
      plyrRef.current = new Plyr(audioRef.current, {
        controls: [
          "restart",
          "play",
          "progress",
          "current-time",
          "duration",
          "mute",
          "volume",
          "download",
        ],
      });

      const audioElement = audioRef.current;

      audioElement.addEventListener("play", () => {
        setPlaying(true);
        setNpAction("Now Playing...");
      });

      audioElement.addEventListener("pause", () => {
        setPlaying(false);
        setNpAction("Paused...");
      });

      audioElement.addEventListener("ended", () => {
        setNpAction("Paused...");
        if (currentTrackIndex + 1 < tracks.length) {
          setCurrentTrackIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            loadTrack(newIndex);
            audioElement.play();
            return newIndex;
          });
        } else {
          setCurrentTrackIndex(0);
          loadTrack(0);
          audioElement.pause();
        }
      });

      audioElement.addEventListener("timeupdate", () => {
        if (audioRef.current) {
          setCurrentTime(audioRef.current.currentTime);
          setDuration(audioRef.current.duration);
        }
      });
    
    }
  }, [currentTrackIndex]);

  

  // const loadTrack = (index: number) => {
  //   if (audioRef.current) {
  //     const audioElement = audioRef.current;
  //     const track = tracks[index];
  //     audioElement.src = `${track.file}`;
  //     setNpAction("Now Playing...");
  //     // Update the download link
  //     plyrRef.current?.on("loadedmetadata", () => {
  //       const downloadLink = document.querySelector('a[data-plyr="download"]');
  //       if (downloadLink) {
  //         (downloadLink as HTMLAnchorElement).href = audioElement.src;
  //       }
  //     });
  //   }
  // };
  const loadTrack = (index: number) => {
    if (audioRef.current) {
      const audioElement = audioRef.current;
      const track = tracks[index];
      audioElement.src = `${track.file}`;
      setNpAction("Now Playing...");
      
      // Play the audio only after it has loaded
      audioElement.addEventListener("loadeddata", () => {
        if (playing) audioElement.play();
      }, { once: true }); // Use { once: true } to remove listener after it triggers
    }
  };

  // const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
  //   const progressBar = e.currentTarget;
  //   const clickPosition = e.clientX - progressBar.getBoundingClientRect().left;
  //   const newTime = (clickPosition / progressBar.clientWidth) * duration;
  //   if (audioRef.current) {
  //     audioRef.current.currentTime = newTime; // Update audio current time
  //     setCurrentTime(newTime); // Update state for current time
  //   }
  // };

  const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (duration && !isNaN(duration)) { // Check if duration is valid
      const progressBar = e.currentTarget;
      const clickPosition = e.clientX - progressBar.getBoundingClientRect().left;
      const newTime = (clickPosition / progressBar.clientWidth) * duration;
  
      if (!isNaN(newTime) && audioRef.current) { // Ensure newTime is a valid number
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
      }
    }
  };
  

  // const handlePrev = () => {
  //   if (currentTrackIndex > 0) {
  //     setCurrentTrackIndex((prevIndex) => {
  //       const newIndex = prevIndex - 1;
  //       loadTrack(newIndex);
  //       if (playing) audioRef.current?.play();
  //       return newIndex;
  //     });
  //   } else {
  //     setCurrentTrackIndex(0);
  //     loadTrack(0);
  //   }
  // };

  // const handleNext = () => {
  //   if (currentTrackIndex + 1 < tracks.length) {
  //     setCurrentTrackIndex((prevIndex) => {
  //       const newIndex = prevIndex + 1;
  //       loadTrack(newIndex);
  //       if (playing) audioRef.current?.play();
  //       return newIndex;
  //     });
  //   } else {
  //     setCurrentTrackIndex(0);
  //     loadTrack(0);
  //   }
  // };

  // const handlePrev = () => {
  //   setCurrentTrackIndex((prevIndex) => {
  //     const newIndex = (prevIndex - 1 + tracks.length) % tracks.length;
  //     loadTrack(newIndex);
  //     if (playing) audioRef.current?.play();
  //     return newIndex;
  //   });
  // };
  
  // const handleNext = () => {
  //   setCurrentTrackIndex((prevIndex) => {
  //     const newIndex = (prevIndex + 1) % tracks.length;
  //     loadTrack(newIndex);
  //     if (playing) audioRef.current?.play();
  //     return newIndex;
  //   });
  // };

  const handlePrev = () => {
    setCurrentTrackIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + tracks.length) % tracks.length;
      loadTrack(newIndex);
      return newIndex;
    });
  };
  
  const handleNext = () => {
    setCurrentTrackIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % tracks.length;
      loadTrack(newIndex);
      return newIndex;
    });
  };

  const formatTime = (time: number) => {
    if (!time || isNaN(time)) return "0:00"; // Return "--:--" if time is 0 or not a valid number
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  useEffect(() => {
    loadTrack(0); // Load the first track on component mount
  }, []);
  const dispatch = useDispatch();
  return (
    <div className="palyer container-fluid">
      <div className="d-none">
       <audio ref={audioRef} />
       </div>
      <div className="palyer-nowPlay col-lg-4 col-8">
        <div className="d-flex align-items-center gap-3">
          <div>
            <img
              src={tracks[currentTrackIndex].img}
              className="palyer-song-image"
              alt="user-image"
            />
          </div>
          <div className="player-content">
            <div className="w-100">
              <div className="player-details">
                <h6 className="player-name">Hot Summer</h6>
              </div>
              <div className="player-song-title">
               {tracks[currentTrackIndex].name}
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 track-page-bottons heart-image">
            <HeartIcon className="custom-audio-heart-icon" />
            <MeatballsMenuDropdown
              arr={MeatballsMenuOptions}
              height={"6px"}
              className="meatballsplayer"
            ></MeatballsMenuDropdown>
          </div>
        </div>

        {/* <span id="npAction">{npAction}</span>
          <span id="npTitle">{tracks[currentTrackIndex]?.name || ""}</span> */}
      </div>
      <div className="col-lg-4 col-4">
        <div className="d-flex justify-content-center align-items-center gap-2 all-music-btn">
          <div className="cursor-pointer" onClick={handlePrev}>
            <LeftNextBtn />
          </div>
          {/* <div className="cursor-pointer">
            <PlayerPauseBtn />
          </div> */}
          <div className="cursor-pointer play-pause" onClick={() => {
            if (playing) {
              audioRef.current?.pause();
            } else {
              audioRef.current?.play();
            }
          }}>
            {playing ?  <AudioPauseBtn />:<PlayerPauseBtn /> }
          </div>
          <div className="cursor-pointer" onClick={handleNext}>
            <RightNextBtn />
          </div>
        </div>
        {/* <div className="player-controls gap-3">
          <TextfieldXSM className="m-0 play-time">2:46</TextfieldXSM>
          <div
            className="progress cursor-pointer music-bar"
            style={{ height: "4px", width: "100%" }}
          >
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "75%" }}
              // aria-valuenow="25"
              // aria-valuemin="0"
              // aria-valuemax="100"
            ></div>
          </div>
          <TextfieldXSM className="m-0 play-time">3:16</TextfieldXSM>
        </div> */}
        <div className="player-controls gap-3">
          <TextfieldXSM className="m-0 play-time">
            {formatTime(currentTime)}
          </TextfieldXSM>

          <div className="progress cursor-pointer music-bar" style={{ height: "4px", width: "100%" }} onClick={handleProgressBarClick}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${(currentTime / duration) * 100}%` }}
            ></div>
          </div>

          <TextfieldXSM className="m-0 play-time">
            {/* {formatTime(duration)} */}
            {tracks[currentTrackIndex].duration}
          </TextfieldXSM>
        </div>
      </div>

      <div className="col-lg-4 col-4 d-flex justify-content-end h-100 cross-icon">
        <div className="d-flex align-items-center gap-3">
          <div className="cursor-pointer sound-image">
            {mute && !(volume==='0') ?(
                   <div onClick={handleMute}>
                   <MuteIcon />
                 </div>
            ) : (
         
              <div onClick={handleMute}>
              <VolumeUpIcon />
            </div>
            )}
          </div>
          <input
            type="range"
            color="red"
            min="0"
            max="100"
            value={mute ?0: volume }
            onChange={handleVolumeChange}
            className="cursor-pointer uni-mr-32"
            style={{ height: "3px", width: "81px" }}
          />
          <div onClick={() => dispatch(setData(false))}>
            <img src={crossIcon} className="cursor-pointer cross-icon-audio" />
          </div>
        </div>
      </div>
    </div>
  );
};
