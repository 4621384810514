import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import "./styles.scss";
import { CloseModalButton } from '../../logo-header/view';
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useEffect } from 'react';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';


const view = ({userDisplayName, onChange ,submitData,openModal}: any) => {
  
  return (
    <ModalWrapper modalId="DisplayName" onBackdropClick={openModal}>
    <div
    className="modal fade"
    id="DisplayName"
    aria-hidden="true"
    aria-labelledby="DisplayName"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">Display Name</Heading3>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh  modal-second-head-font">Name that will be displayed across DropWorks</TextfieldLG>
          <TextfieldXSM className='uni-mb-56 ds-ctm-txt  modal-second-head-font'>Your username won’t be affected</TextfieldXSM>
          <DropworksInput maxLength={30} className="" placeholder="Display Name" value={userDisplayName}  onChange={onChange}/>
        </div>
        <div className="modal-footer mobile-modal-footer uni-mt-56 ps-0 pt-0 pb-0 uni-pr-72">
          <CloseModalButton disabled={!userDisplayName}>
          <DropworksSmallButton disabled={!userDisplayName} className={ !userDisplayName ? "btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions":"btn-wid  modal-btns m-0"} onClick={()=>submitData({ display_name: userDisplayName })}>Save</DropworksSmallButton>
          </CloseModalButton>
        </div>
      </div>
    </div>
  </div>
  </ModalWrapper>
  )
}

export default view;

