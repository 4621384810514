import { FC } from "react";

import "./styles.scss";
import { FcGoogle } from "react-icons/fc";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksButton, DropworksLargeButton, DropworksMediumButton, DropworksNormalButton, DropworksPrimaryButton, DropworksSecondaryButton, DropworksSmallButton } from "../../../../common/custom-field/dropworks-button";
import { DropworksPassword } from "../../../../common/custom-field/dropworks-password";
import { TextfieldSM, TextfieldXSM } from "../../../../common/custom-field/dropstock-text";
import { Heading2, Heading3, Heading5 } from "../../../../common/custom-field/dropstock-heading";
import { ButtonComponent } from "../../../shared/components/logo-header/view";
import { viewType } from "../login/modal";
import Loader from "../../../shared/components/loader/view";

const view: FC<viewType> = ({ navigateTo, handleSubmit, onSubmit, errors, register,loading=false,isSubmitted }) => {
  return (
    <div className="signUp container-xl container-xxl row align-items-pages container pb-3 m-auto h-100 px-0  min-h-800">
      <div className="signUp_content col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
      <Heading3 className="signUp_heading my-0">Welcome to DropWorks</Heading3>
        <TextfieldSM className="signUp_normal uni-my-32 front-pages-second-heading">
          Use one of the options below to register
        </TextfieldSM> 
        <form className="singup-form-mobile" onSubmit={onSubmit && handleSubmit(onSubmit)}>
        <div>
          <DropworksInput disabled={loading}  className={`form-control login-specing login-input-first ${isSubmitted && (errors.email ? 'is-invalid b-red-2' : !errors.email ? 'is-valid  b-green-2' : '')}`} {...register('email')}  placeholder="Email" /></div>
          {errors.email && <p className="invalid-feedback">{errors.email.message}</p>}
          <DropworksPassword className="mb-0 relative login-specing" className3={isSubmitted} className2={`uni-mt-16 p-0 signup-cstm-pass  form-control ${isSubmitted && (errors.password ? 'is-invalid b-red-2' : !errors.password ? 'is-valid   b-green-2' : '')}`}  disabled={loading}  {...register('password')} />
          {errors.password && <p  className="invalid-feedback text-danger">{errors.password.message}</p>}
         {!loading && <DropworksButton className="mb-0 signUp_register uni-mt-32 font-weight-600" type="submit" >Register</DropworksButton>}
        </form>
        {loading ?  <Loader className="uni-mt-40"/> :
        <><TextfieldSM className="signUp_or text-center uni-my-16">OR</TextfieldSM>
          <DropworksButton className="signUp_register_google uni-mb-32 font-weight-600"> <FcGoogle className="signUp_icon" /> Sign up with Google</DropworksButton>
          <p className="signUp_already_registered  uni-mb-32 fs-6">Already registered? <span className="signUp_logins"  onClick={navigateTo("/login")}>Login</span></p>
          <TextfieldXSM className="signUp_privacy pb-0">By creating an account with DropWorks you agree with our <a className="signUp_privacy_link">Terms of Use</a>,<a className="signUp_privacy_link"> Privacy Policy </a> and <a className="signUp_privacy_link">Community Guidelines</a>.</TextfieldXSM>
          </>}</div>
    </div>
  );
};
export default view;
