import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { closeModal, CloseModalButton } from '../../logo-header/view';
import './styles.scss';
import { ButtonComponent } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import { getUserData, updateUser } from '../../../utils/apiServices';
import { setUserData } from '../../../../../redux/reducer/userSlice';

const view = () => {
  const {socialModal} =useSelector((state:RootState)=>state.modal);
  const {social_links}=useSelector((state:RootState)=>state.user);
  const trimmedString = socialModal.replace(/\s+/g, '').toLocaleLowerCase();
  const [socialLink,setSocialLink]= useState<string>("") ;
  const [message,setMessage] =useState("");

  const validateCustomUrl = (check:string) => {
    const urlRegex = new RegExp(`^https:\/\/www\\.${trimmedString}\\.com\/[a-zA-Z0-9]+`);
    return urlRegex.test(check) ? setMessage("The URL is valid.") : setMessage("The URL is not valid.");

  };

  useEffect(()=>{
    setSocialLink(`https://www.${trimmedString}.com/`);
    setMessage("");
    let socialLink=social_links.filter((ele:any)=>ele.name===socialModal || ele.name===socialModal.toLocaleLowerCase() );
    if(socialLink?.[0])
    {
      setSocialLink(socialLink?.[0].link)
    }
  },[trimmedString])

  const handleChange = (event:any) => {
    const trimmedString = event.target.value.trim();
    setSocialLink(trimmedString); // Update the state with trimmed value
    validateCustomUrl(trimmedString); // Validate the trimmed string
  };
const dispatch =useDispatch();

  const saveLink =async()=>{
if(message==="The URL is valid."){
  closeModal();
const response =await updateUser({name:socialModal,link:socialLink});
if (response) {
    console.log("response",response);
    const result = await getUserData(); // Call your API function
    if (result.success && result.userData) { // Adjust based on your API response
        dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
    }
  }
}

  }
  
  return (

    <div
    className="modal fade"
    id="SocialLink8" 
    aria-hidden="true"
    aria-labelledby="SocialLink8"
    tabIndex={-1}
    data-bs-theme="dark"
    >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">Social Links</Heading3>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72  ">
          <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt social-link-mobile-txt modal-second-head-font mb-16-mbl ">Please input your {socialModal} public link</TextfieldLG>
          <DropworksInput className="mb-0 dropinput chg-plchold-col" value={socialLink} onChange={handleChange} placeholder={`https/${trimmedString}.com/`} />
        </div>  
        {message==="The URL is not valid." &&  <p className='text-danger'>{message}</p>}
        <div className="modal-footer ps-0 pt-0 pb-0  social-mobile-modal-footer uni-mt-56  uni-pr-72">
        <ButtonComponent  id="SocialLinksMain" className='buttoncomponent-mobile-modle'>
        <DropworksSmallButton  className="btn-wid btn-bg-gray3 social-link-modal-btns my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> 
        </ButtonComponent>
        {/* <ButtonComponent id="SocialLinksMain" className='buttoncomponent-mobile-modle'>
          
        <DropworksSmallButton  className="btn-wid m-0 social-link-modal-btns">Save</DropworksSmallButton>
        </ButtonComponent> */}

        <DropworksSmallButton  disabled={message!=="The URL is valid." } className={message==="The URL is valid."?"btn-wid  modal-btns m-0":"btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions"}  onClick={saveLink}>Save</DropworksSmallButton>

        </div>
      </div>
    </div>
  </div>
  )
}

export default view;


