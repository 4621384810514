import React, { FC } from "react";
import "./styles.scss";
import playmusic from "../assets/img/play-music-icon.png";
import playmusic2 from "../../assets/img/play-music-icon-2.png";
import { useNavigate } from "react-router-dom";
export const view: FC<any> = ({ navigateTo, image, arr,className,className2 ,page}) => {
  const navigate=useNavigate()
  const submitData = async (data:any) => {
      navigate(`/track-page/${data}`)
}
  return (
    <div className={`row ${className}`}>
      {arr.length?<>{arr.map((data: any, index: any) => (
      <div className={`col-6 col-sm-6 col-md-3 col-lg-2 track-icon ${className2}`}> 
            <div className="track-icon-box" onClick={()=>submitData(data.slugName)}>
            {data.image?<img className="track-icon-box" src={`${process.env.REACT_APP_IMGURL}/${data.image}`}/>:""}
              <img className="track-icon-box-icon" src={playmusic2} />
            </div> 
            <div className="track-icon-heading">{data?.heading?.length > 12 ? data.heading.substring(0, 12) + "..." : data.heading}</div>
            <div className="track-icon-subheading">{data.subheading}</div>
      </div>
        ))}</>:"No Tracks Data"}
        
    </div>
  );
};
