import { FC, useEffect, useState } from "react";
import { trackFormData, viewType } from "./modal";
import "./styles.scss";
import UserIcon from "../../../assets/steps/Group 18414.svg";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import {
  DropworksButton,
  DropworksDisableButton,
  DropworksNormalButton,
} from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../common/custom-field/dropstock-text";
import Tabs from "../../../common/tabs";
import { DropworksSelect } from "../../../common/custom-field/dropworks-select";
import { DropworksSwitch } from "../../../common/custom-field/dropworks-switch";
import kingRoland from "../../../assets/img/roland.png";
import { BlueTik, YelloTik } from "../../../common/all-icons";
import { MeatballsMenuDropdown } from "../../../common/icons";
import king from "../../../assets/img/king.png";
import downloadIcon from "../../../assets/steps/download.svg";
import Producer from "../../../assets/img/Producer.png";
import { UserLable } from "../../../common/user-labels";
import treeImage from "../../../assets/img/tree.png";
import rightArrow from "../../../assets/steps/arrow-small-right.svg";
import exclamationTriangle from "../../../assets/steps/exclamation-triangle.svg";
import {
  ButtonComponent,
  openPopup,
} from "../../shared/components/logo-header/view";
import TabsComponent from "../../../common/tabs-component/view";
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
} from "../../../common/custom-field/dropstock-heading";
import { SelectArrowDropdown } from "../../../common/selection-dropdown";
import CustomTooltip from "../../../common/custom-tooltip/view";
import { preProcessFile } from "typescript";
import {
  createTrackProject,
  getFile,
  getMissingStep,
  updateInvitedCollaboratorsTrackProject,
} from "../../shared/utils/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  fetchTrackDetails,
  getALlInvitedUserbyTrackProject,
  setSelectedFile,
  setSetelectedCollaborator,
  setTrackID,
  setTrackSlugName,
  setUploadNewBeatImg,
  setUploadNewBeatImgAPI,
} from "../../../redux/reducer/uploadSlice";
import { AppDispatch } from "../../../redux-toolkit/store";
import { useNavigate, useParams } from "react-router-dom";
import { setProjectname } from "../../../redux/reducer/projectnameSlice";

const view: FC<viewType> = ({ navigateTo }) => {
  const checkTabState = (value: number) => {
    setTabValue(value);
  };
  const dispatch = useDispatch<AppDispatch>();
  const parmsSlug = useParams();
  const { selectedCollaborator } = useSelector(
    (state: RootState) => state.upload
  );
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState([
    "Change Alias",
  ]);
  const [MeatballsMenuOptions2, setMeatballsMenuOptions2] = useState([
    "Change Alias",
    "Message",
    "Remove",
  ]);

  const [MeatballsMenuOptions3, setMeatballsMenuOptions3] = useState([
    "Delete",
    "Approve",
    "Decline",
  ]);
  const [MeatballsMenuOptions31, setMeatballsMenuOptions31] = useState([
    "Delete",
  ]);
  const { uploadnewbeatImg, uploadnewbeatImgAPI, invitedUserTrackProject ,trackDetails,trackId,trackSlugname} = useSelector((state: RootState) => state.upload);
    const pname = useSelector((state: RootState) => state.projectName);
 
  const [slugName, setslugName] = useState<any>();
  const [trackformData, setTrackFormData] = useState<trackFormData>({
    project_name: "Project Name",
    track_name: "",
    description: "",
    genre: "",
    language: "",
    contains_explicit_language: false,
    is_project_public: false,
    art_work_image: "",
    file_type: "",
    short_description: "",
  });

  const[missingStep,setMissingStep]=useState<any>([])
  const [previousTrackformData, setPreviousTrackformData] = useState<any>({
    project_name: "Project Name",
    track_name: "",
    description: "",
    genre: "",
    language: "",
    contains_explicit_language: false,
    is_project_public: false,
    art_work_image: "",
    file_type: "",
    short_description: "",
  });
  const [tabsValue, setTabValue] = useState(0);
  const [showComponent, setShowComponent] = useState(tabsValue);

  // const [id, setId] = useState<string>("");
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(tabsValue);
    }, 300); // Matches the CSS transition duration

    return () => clearTimeout(timeout);
  }, [tabsValue]);
  const options = [
    { value: "Primary Artist", label: "Primary Artist" },
    { value: "Featured Artist", label: "Featured Artist" },
    { value: "Hidden", label: "Hidden" },
  ];

  const GenreOptions = [
    { value: "Pop music", label: "Pop music" },
    { value: "Rock", label: "Rock" },
    { value: "Hip hop music", label: "Hip hop music" },
    { value: "Electronic dance music", label: "Electronic dance music" },
    { value: "Electronic music", label: "Electronic music" },
    { value: "Pop rock", label: "Pop rock" },
    { value: "Alternative rock", label: "Alternative rock" },
    { value: "Popular music", label: "Popular music" },
    { value: "Heavy metal", label: "Heavy metal" },
  ];
  const LanguageOption = [
    { value: "Afrikaans", label: "Afrikaans" },
    { value: "Amharic", label: "Amharic" },
    { value: "Bulgarian", label: "Bulgarian" },
    { value: "Catalan", label: "Catalan" },
    { value: "Chinese (Hong Kong)1", label: "Chinese (Hong Kong)1" },
    { value: "Chinese (PRC)", label: "Chinese (PRC)" },
    { value: "Chinese (Taiwan)", label: "Chinese (Taiwan)" },
    { value: "Czech", label: "Czech" },
    { value: "Danish", label: "Danish" },
    { value: "Dutch", label: "Dutch" },
    { value: "English (UK)", label: "English (UK)" },
    { value: "English (US)", label: "English (US)" },
    { value: "Estonian", label: "Estonian" },
    { value: "Filipino", label: "Filipino" },
    { value: "Finnish", label: "Finnish" },
    { value: "French (Canada)", label: "French (Canada)" },
    { value: "French (France)", label: "French (France)" },
    { value: "German", label: "German" },
    { value: "Greek", label: "Greek" },
    { value: "Hebrew", label: "Hebrew" },
    { value: "Hindi", label: "Hindi" },
    { value: "Hungarian", label: "Hungarian" },
    { value: "Icelandic", label: "Icelandic" },
    { value: "Indonesian", label: "Indonesian" },
    { value: "Italian", label: "Italian" },
    { value: "Japanese", label: "Japanese" },
    { value: "Korean", label: "Korean" },
    { value: "Latvian", label: "Latvian" },
    { value: "Lithuanian", label: "Lithuanian" },
    { value: "Malay", label: "Malay" },
    { value: "Polish", label: "Polish" },
    { value: "Norwegian", label: "Norwegian" },
    { value: "Portuguese (Brazil)", label: "Portuguese (Brazil)" },
    { value: "Portuguese (Portugal)", label: "Portuguese (Portugal)" },
    { value: "Romanian", label: "Romanian" },
    { value: "Serbian", label: "Serbian" },
    { value: "Slovak", label: "Slovak" },
    { value: "Slovenian", label: "Slovenian" },
    { value: "Spanish (Latin America)", label: "Spanish (Latin America)" },
    { value: "Spanish (Spain)", label: "Spanish (Spain)" },
    { value: "Swahili", label: "Swahili" },
    { value: "Swedish", label: "Swedish" },
    { value: "Thai", label: "Thai" },
    { value: "Turkish", label: "Turkish" },
    { value: "Ukrainian", label: "Ukrainian" },
    { value: "Vietnamese", label: "Vietnamese" },
    { value: "Zulu", label: "Zulu" },
  ];

  useEffect(() => {
    onBlurFunction("art_work_image", uploadnewbeatImgAPI);
  }, [uploadnewbeatImgAPI]);




  useEffect(() => {
    if (trackId) {
      dispatch(getALlInvitedUserbyTrackProject(trackId));
      dispatch(fetchTrackDetails(trackSlugname))
    }
  }, [trackId,tabsValue]);

  useEffect(() => {
    if (!parmsSlug.slugName){
    openPopup("ProjectName")}
  }, []);

  // useEffect(() => {
  //   if(pname){
  //     onBlurFunction("project_name", pname.Data)
  //     setTrackFormData((pre: any) => ({
  //       ...pre,
  //       project_name:pname.Data,
  //     }))
  //   }
  // }, []);


  const navigate = useNavigate();
  const onBlurFunction = async (
    name: string,
    value: string | null | boolean
  ) => {
    
    if (value && previousTrackformData?.[name] !== value) {
      const response = await createTrackProject({ [name]: value, id: trackId });
      if (response.success) {
        // setId(response.data._id);
        dispatch(setTrackID(response.data._id));
        
        setPreviousTrackformData(response.data);
        // setslugName(response.data.slug_name);
        dispatch(setTrackSlugName(response.data.slug_name))
        dispatch(setProjectname(response.data.project_name))
      } else {
      }
    }
  };
  const [message, setMessage] = useState<string>("");

  const OnClickMeatBalls = async (value: any, i: number,secondi?:any) => {
   
    if (value === "Change Alias") {
      dispatch(setSetelectedCollaborator(invitedUserTrackProject?.[i]));
      openPopup("ChangeAlias");
    } else if (value === "Remove") {
      const data = {
        track_id: invitedUserTrackProject?.[i].track_id,
        remove: "remove",
        invited_id: invitedUserTrackProject?.[i]._id,
      };
      const response = await updateInvitedCollaboratorsTrackProject(data);
      if (response.success) {
        dispatch(
          getALlInvitedUserbyTrackProject(trackId)
        );
      } else {
        setMessage(response.message);
      }
    } 
    else if(value==="Delete"){
      dispatch(setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i]))
      dispatch(setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i]))
      dispatch(setSetelectedCollaborator(trackDetails?.collaborators?.[secondi]))
      openPopup("DeleteFile");
    }
    else if(value==="Approve"){
      dispatch(setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i]))
      dispatch(setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i]))
      dispatch(setSetelectedCollaborator(trackDetails?.collaborators?.[secondi]))
      openPopup("ApproveFile");
    }
    else {
      navigate("/message-chat");
    }
  };

  useEffect(() => {
    const getMissing = async () => {
      if (trackId) {
        const response: any = await getMissingStep(trackId);
        if (response) {
          setMissingStep(response.data)
         
        } else {
          console.log("Failed to fetch data");
        }
      }
    };
    getMissing();
  }, [tabsValue]);


  useEffect(() => {
    const fetchFile = async () => {
      if (parmsSlug.slugName) {
        const response: any = await getFile(parmsSlug.slugName);
        if (response) {
          // setId(response.data.trackData._id);
          setTrackFormData({
            project_name: response.data.trackData.project_name,
            track_name: response.data.trackData.track_name,
            description: response.data.trackData.description,
            genre: response.data.trackData.genre,
            language: response.data.trackData.language,
            contains_explicit_language:
              response.data.trackData.contains_explicit_language,
            is_project_public: response.data.trackData.is_project_public,
            art_work_image: "",
            file_type: "",
            short_description: "",
          });
          
          dispatch(fetchTrackDetails(response.data.trackData.slug_name));
          // setslugName(response.data.trackData.slug_name);
          dispatch(setTrackSlugName(response.data.trackData.slug_name))
          dispatch(setTrackID(response.data.trackData._id));

           dispatch(setUploadNewBeatImg(response?.data?.trackData?.art_work_image[0]?.src))
        } else {
          console.log("Failed to fetch data");
        }
      }
    };
    fetchFile();
  }, [parmsSlug]);

  useEffect(() => {
    return () => {
      
        dispatch(setTrackID(""));
    };
}, [dispatch]);


  const imageUrl = uploadnewbeatImg?.includes("http")? uploadnewbeatImg : `${process.env.REACT_APP_IMGURL}/${uploadnewbeatImg}`;

  return (
    <div className="container container-xxl min-height-cover">
      {/* <Heading3 className="uni-mb-24">Project Name</Heading3> */}
      <DropworksInput
        placeholder="Project Name"
        // disabled={!id}
        className="uni-mb-24 bg-transparent project-font  ps-0"
        value={!trackSlugname? trackformData.project_name:pname.Data}
        onBlur={() =>
          onBlurFunction("project_name", trackformData.project_name)
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTrackFormData((pre: any) => ({
            ...pre,
            project_name: e.target.value,
          }))
        }
        maxLength={30}
      />

      <Tabs
        id="14"
        tabsTitle={["Overview", "Collaborators", "File Center", "Release"]}
        className="uni-mb-40"
        // disabled={!id}
        checkTabState={checkTabState}
      />
      <TabsComponent
        tabsValue={tabsValue}
        components={[
          <>
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 p-0 track-overview-col">
                <Heading4 className="uni-mb-16">Overview</Heading4>
                <TextfieldLG className="mt-0">
                  Fill out the general information about this track.{" "}
                </TextfieldLG>
                <TextfieldSM className="fg-color uni-mb-40 mt-0">
                  Note that you can navigate freely between sections at any
                  given time.
                </TextfieldSM>

                <TextfieldLG className="uni-mb-16 mt-0 content-sunheading">
                  Track Name
                </TextfieldLG>
                <DropworksInput
                  placeholder="Name"
                  className="uni-mb-32"
                  value={trackformData.track_name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setTrackFormData((pre: any) => ({
                      ...pre,
                      track_name: e.target.value,
                    }))
                  }
                  maxLength={30}
                  onBlur={() =>
                    onBlurFunction("track_name", trackformData.track_name)
                  }
                />
                <TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Description
                </TextfieldLG>
                <DropworksTextArea
                  className="uni-mb-32 track-textarea"
                  placeholder="Description (Optional)"
                  // disabled={!id}
                  rows={2}
                  hideIcon={true}
                  value={trackformData.description}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setTrackFormData((pre: any) => ({
                      ...pre,
                      description: e.target.value,
                    }))
                  }
                  onBlur={() =>
                    onBlurFunction("description", trackformData.description)
                  }
                />
                <TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Genre
                </TextfieldLG>
                {/* <DropworksSelect className="uni-mb-32"></DropworksSelect> */}

                <DropworksDisableButton
                  className="custom-select-dropdown track-overview-dropdown uni-mb-56"
                  // disabled={!id}
                >
                  <SelectArrowDropdown
                    options={GenreOptions}
                    placeHolderValue="Select"
                    value={trackformData.genre}
                    onChangeValue={(value: null | string) => {
                      onBlurFunction("genre", value);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        genre: value,
                      }));
                    }}
                  ></SelectArrowDropdown>
                </DropworksDisableButton>

                <TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Language
                </TextfieldLG>
                <DropworksDisableButton
                  className="custom-select-dropdown track-overview-dropdown uni-mb-56"
                  // disabled={!id}
                >
                  <SelectArrowDropdown
                    options={LanguageOption}
                    placeHolderValue="Select"
                    value={trackformData.language}
                    onChangeValue={(value: null | string) => {
                      onBlurFunction("language", value);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        language: value,
                      }));
                    }}
                  ></SelectArrowDropdown>
                </DropworksDisableButton>
                <div className="d-flex justify-content-between uni-py-16 uni-mb-40">
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">
                    Contains Explicit Language
                  </TextfieldSM>

                  <DropworksSwitch
                    checked={trackformData.contains_explicit_language}
                    
                    // disabled={!id}
                    onChange={(e: boolean) => {
                      console.log("Switch toggled:", e);
                      onBlurFunction("contains_explicit_language", e);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        contains_explicit_language: e,
                      }));
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between uni-py-16 uni-mb-40">
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">
                    Make this project public
                    <CustomTooltip
                      title="Activate this setting if you want your project to be visible by other users. Only the artwork and current collaborators will be displayed."
                      position="right"
                    >
                      <RiQuestionFill className="uni-ml-16 track-tooltip" />
                    </CustomTooltip>
                  </TextfieldSM>
                  <DropworksSwitch
                    checked={trackformData.is_project_public}
                    // disabled={!id}
                    onChange={(e: boolean) => {
                      onBlurFunction("is_project_public", e);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        is_project_public: e,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 d-flex justify-content-end p-0">
                <div>
                  <TextfieldLG className="me-0 ml-auto beat-empty-artwork ">
                    Artwork
                  </TextfieldLG>
                  <div className="beatEmpty_uploadbox2 flex-column mb-3">
                    {uploadnewbeatImg ? (
                      <ButtonComponent id="trackartwork">
                        <img
                          src={imageUrl}
                          className="upload-img-2 w-100 h-100"
                        />
                      </ButtonComponent>
                    ) : (
                      <>
                        {/* <ButtonComponent id="trackartwork" disabled={!id}> */}
                        <ButtonComponent id="trackartwork" >
                          <img src={iconImg} alt="Upload Icon" />
                        </ButtonComponent>
                        <label className="mt-4"> Track Artwork</label>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>,
          <>
            <div className="">
              <Heading4 className="uni-mb-16">Collaborators</Heading4>
              <TextfieldLG>Manage your team of collaborators here.</TextfieldLG>
              <TextfieldSM className="fg-color uni-mb-40">
                Note that you can navigate freely between sections at any given
                time.
              </TextfieldSM>

              <div className="d-flex justify-content-between align-items-center collaborators-content uni-mb-32 flex-wrap">
                {invitedUserTrackProject.length > 0 ? (
                  invitedUserTrackProject.map((ele: any, i: number) => {
                    return (
                      <div className="collaborators-content-under pe-0 uni-mb-32">
                        <h2>
                          {ele.role}
                          <YelloTik />
                        </h2>
                        <div className="d-flex align-items-center uni-py-16">
                          <img
                            src={`${process.env.REACT_APP_IMGURL}/${ele.profile_image?.[0]?.src}`}
                            onError={(e) => {
                              e.currentTarget.src = UserIcon;
                            }}
                            className="img-king uni-mr-8"
                            alt=""
                          />
                          <div className="uni-mr-16">
                            <Heading5 className="king-roland-text">
                              {ele.receiver_name} 
                              {/* <BlueTik position="top" /> */}
                            </Heading5>
                          </div>
                          <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                            {ele.percentage_offered}%
                          </DropworksNormalButton>
                          <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                            <SelectArrowDropdown
                              options={options}
                              placeHolderValue="Credit"
                              className="credit-selectdropdown"
                            ></SelectArrowDropdown>
                          </DropworksDisableButton>
                          <MeatballsMenuDropdown
                            arr={MeatballsMenuOptions2}
                            pagename="call-funtion"
                            onClickOptions={(stelected: any) =>
                              OnClickMeatBalls(stelected, i)
                            }
                            className="custom-metaballs"
                          ></MeatballsMenuDropdown>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <TextfieldLG>No Collaborators invited.</TextfieldLG>
                )}

                {/* <div className="collaborators-content-under pe-0 uni-mb-32">
                  <h2>
                    Vocalist <YelloTik />
                  </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <Heading5 className="king-roland-text">
                        King Roland <BlueTik position="top"/>
                      </Heading5>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                      <SelectArrowDropdown options={options} placeHolderValue="Credit" ></SelectArrowDropdown></DropworksDisableButton>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                      className="custom-metaballs"
                    ></MeatballsMenuDropdown>
                  </div>
                </div> */}
              </div>

              {/* <div className=" d-flex justify-content-between align-items-center collaborators-content uni-mb-32">
                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <h2 className="king-roland-text">
                    Beat Maker <YelloTik />
                  </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <Heading5>
                        King Roland <BlueTik position="top"/>
                      </Heading5>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                      <SelectArrowDropdown options={options} placeHolderValue="Credit" ></SelectArrowDropdown></DropworksDisableButton>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                      className="custom-metaballs"
                    ></MeatballsMenuDropdown>
                  </div>
                </div>

                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <h2 className="king-roland-text">Lyricist </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <Heading5>
                        King Roland <BlueTik position="top"/>
                      </Heading5>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                      <SelectArrowDropdown options={options} placeHolderValue="Credit" ></SelectArrowDropdown></DropworksDisableButton>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                      className="custom-metaballs"
                    ></MeatballsMenuDropdown>
                  </div>
                </div>
              </div>

              <div className=" d-flex justify-content-between align-items-center collaborators-content uni-mb-32">
                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <h2>Artwork Designer </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <Heading5 className="king-roland-text">
                        King Roland <BlueTik position="top"/>
                      </Heading5>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                      <SelectArrowDropdown options={options} placeHolderValue="Credit" ></SelectArrowDropdown></DropworksDisableButton>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                      className="custom-metaballs"
                    ></MeatballsMenuDropdown>
                  </div>
                </div>

                <div className="collaborators-content-under pe-0 uni-mb-32">
                  <h2>Curator </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <Heading5 className="king-roland-text">
                        King Roland <BlueTik position="top"/>
                      </Heading5>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                      <SelectArrowDropdown options={options} placeHolderValue="Credit" ></SelectArrowDropdown></DropworksDisableButton>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                      className="custom-metaballs"
                    ></MeatballsMenuDropdown>
                  </div>
                </div>
              </div> */}

              <div className=" d-flex justify-content-between">
                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <ButtonComponent id="InviteModal8">
                    <div className="track-plus-icon">
                      <span className="under-plus-icon">+</span>
                    </div>
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </>,
          <>
            <Heading4 className="uni-mb-16">File Center</Heading4>
            <div className="d-flex">
              <div className="uni-mr-56">
                <TextfieldLG className="mt-0">
                  Manage all files uploaded here.
                </TextfieldLG>
                <TextfieldSM className="fg-color uni-mb-40">
                  Note that you can navigate freely between sections at any
                  given time.
                </TextfieldSM>
              </div>
              <ButtonComponent id="UploadModal">
                <DropworksNormalButton>Upload</DropworksNormalButton>
              </ButtonComponent>
            </div>

            <div className="d-flex justify-content-between file-center-main align-items-center uni-mb-32">

        
             
              {  trackDetails?.collaborators?.map((data: any,i:any) => {
                  return (
                    <div className="producer-main">
                    <h2 className="uni-mb-16">
                      {data?.role} <YelloTik />{" "}
                    </h2>
    
                    <div className="d-flex align-items-center uni-mb-16 invite_firstcolumn">
                      <img src={`${process.env.REACT_APP_IMGURL}/${data.profile_image[0]?.src}`} className="img-king" alt="" />
                      <div>
                        <Heading5 className="fw-700 d-flex align-items-center">
                          {data?.receiver_name}
                           {/* <BlueTik className="uni-ml-8" />{" "} */}
                        </Heading5>
                      </div>
                    </div>
                    <div className="producer-border">
                    {data.upload_file?.map((data:any,index:any)=>{
                          return (
                            <>
                      <div className="d-flex align-items-center justify-content-between uni-mt-16 border-style">
                       
                        
                        <div>
                          <TextfieldSM className="fs-color-g3 m-0">
                            Type:<span className="fw-400-wh">{data?.file_type}</span>
                          </TextfieldSM>
                          <TextfieldSM className="fs-color-g3 m-0">
                            Description:
                            <span className="fw-400-wh">
                              {" "}
                              {data?.short_description}
                            </span>
                          </TextfieldSM>
                          <TextfieldSM className="fs-color-g3 m-0">
                            Date:{" "}
                            <span className="fw-400-wh"> {data?.upload_date}</span>
                          </TextfieldSM>
                        </div>
                        <div className="d-flex downloadicon-metaballs-file-center">
                          <h5>
                            <img src={downloadIcon} alt="" />
                          </h5>
                          <h5>
                            <MeatballsMenuDropdown
                              className="custom-metaballs2"
                              pagename="call-funtion"
                            onClickOptions={(stelected: any) =>
                              OnClickMeatBalls(stelected, index,i)
                            }
                              arr={i==0?MeatballsMenuOptions31: MeatballsMenuOptions3}
                            ></MeatballsMenuDropdown>
                          </h5>
                     
                      </div>
                      </div>
                            
                            </>
                          )
                        })}
                       
                     
                    </div>
                  </div>
                  );
                })}
             
          

             
            </div>

            
          </>,
          <>
            <Heading4 className="uni-mb-16">Release</Heading4>

            <TextfieldLG>
              Remember to review everything before releasing the track.{" "}
            </TextfieldLG>
            <TextfieldSM className="fg-color uni-mb-40">
              {" "}
              Note that you can navigate freely between sections at any given
              time.
            </TextfieldSM>

            <div className="d-flex uni-mb-40 justify-content-between align-items-end release-content-border">
              <div className="d-flex align-items-end">
                <img
                  src={uploadnewbeatImg ? imageUrl : treeImage}
                  className="uni-mr-24 treeImage-track"
                />
                <div>
                  <Heading1>
                    {trackformData?.track_name || "Hot Summer"}{" "}
                  </Heading1>
                  <TextfieldMD className="fc-gray4">
                    Unlost, Uncovered, Meyna, Maluma
                  </TextfieldMD>
                  <UserLable>EDM</UserLable>
                </div>
              </div>
              <ButtonComponent id="ReleaseModal">
                <DropworksNormalButton className="release-btn mb-0" onClick={()=>onBlurFunction("is_released",true)}>
                  Release
                </DropworksNormalButton>
              </ButtonComponent>
            </div>

            <div className="uni-p-40 missing-steps">
              
                  <h2 className="missing-text uni-mb-24">
                    <img src={exclamationTriangle} className="uni-mr-16" />
                    Missing Steps
                  </h2>
                  {/* <TextfieldLG className="fc-gray4 uni-mr-8 uni-mb-16 mt-0">
                Credit all collaborators <img src={rightArrow} />
              </TextfieldLG>
              <TextfieldLG className="fc-gray4 uni-mr-8 uni-mb-16 mt-0">
                Upload the final track file <img src={rightArrow} />
              </TextfieldLG> */}

              {missingStep && missingStep?.[0]?.pageOne==false?<>
                  <TextfieldLG className="fc-gray4 uni-mr-8 mt-0 mb-0">
                    Fill all the base information of the track{" "}
                    <img
                      src={rightArrow}
                      onClick={() => {
                        {
                          checkTabState(0);
                        }
                      }}
                      className="cursor-pointer"
                    />
                  </TextfieldLG>
                  </>:<></>}
                  {missingStep && missingStep?.[0]?.pageTwo==false?<>
                    <TextfieldLG className="fc-gray4 uni-mr-8 uni-mb-16 mt-0">
                Credit all collaborators <img src={rightArrow}  onClick={() => {
                        {
                          checkTabState(1);
                        }
                      }}
                      className="cursor-pointer" />
              </TextfieldLG>
                  </>:<></>}
                  {missingStep && missingStep?.[0]?.pageThree==false?<>
                    <TextfieldLG className="fc-gray4 uni-mr-8 uni-mb-16 mt-0">
                Upload the final track file <img src={rightArrow}  onClick={() => {
                        {
                          checkTabState(2);
                        }
                      }}
                      className="cursor-pointer"/>
              </TextfieldLG>
                  </>:<></>}
              
            </div>
          </>,
        ]}
      />
    </div>
  );
};
export default view;
