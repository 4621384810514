import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { AiOutlineRight } from "react-icons/ai";
import { CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClick, setProjectname } from '../../../../../redux/reducer/projectnameSlice';
import { createTrackProject } from '../../../utils/apiServices';
import { setTrackID, setTrackSlugName } from '../../../../../redux/reducer/uploadSlice';

const view = () => {
  const [projectName, setProjectName] = useState("");
  const userData = useSelector((state: any) => state.user);
  const {displayName} =userData;
  const dispatch=useDispatch()


  const click = async (
    name: string,
    value: string | null | boolean
  ) => {
    
    if (value) {
      const response = await createTrackProject({ [name]: value});
      if (response.success) {
        dispatch(setTrackID(response.data._id));
      
        dispatch(setTrackSlugName(response.data.slug_name))
        dispatch(setProjectname(response.data.project_name))
      } else {
      }
    }
  };

  useEffect(() => {
    const handleBackdropClick = async (event: MouseEvent) => {
      const modalElement = document.getElementById("ProjectName");
  

      if (event.target === modalElement) {
        const name = "project_name"; 
        const value = displayName;    
        
        await click(name, value);
      }
    };
  
    const modalElement = document.getElementById("ProjectName");
    modalElement?.addEventListener("click", handleBackdropClick);
  

    return () => {
      modalElement?.removeEventListener("click", handleBackdropClick);
    };
  }, [displayName]);

  

  return (
    <div
      className="modal fade"
      id="ProjectName"
      aria-hidden="true"
      aria-labelledby="ProjectName"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon' onClick={()=>click("project_name",displayName)}><CloseModalButton ><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-40">
            <Heading3 className="modal-title-fs-32">Project Name</Heading3>
          </div>
          <form >
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 ">
            {/* <p className='d-flex justify-content-between uni-p-16 popup-space-mobile uni-mb-56'>
                <TextfieldSM className="ctm-txt2 font-col-wh mx-0 my-0">Learn more about our <span className="change-color">community guidelines</span></TextfieldSM>
            
            </p> */}
            <DropworksInput
                  placeholder=" name"
                  className="uni-mb-32"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
  
                />

          </div>
          <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton className='buttoncomponent-mobile-modle'>
         <DropworksSmallButton className="btn-wid-success social-link-modal-btns m-0" onClick={()=>click("project_name",projectName)}>Confirm</DropworksSmallButton>
         </CloseModalButton>
        </div>
        </form>
        </div>
      </div>
    </div>
  )
}

export default view;


