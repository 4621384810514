import { useNavigate, useParams } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";
import { getBeat } from "../../shared/utils/apiServices";
import { useDispatch } from "react-redux";
import { setBeat } from "../../../redux/reducer/beatSlice";

export const BeatesPage=()=>{

    
    const navigate=useNavigate();
    const slugName = useParams()
    const dispatch=useDispatch()
    useEffect(() => {
        const fetchBeatData = async () => {
          if (slugName) {
            const response: any = await getBeat(slugName.slugName);
            if (response) {
              console.log("Fetched data:", response);
              dispatch(setBeat(response.data));
            } else {
              console.log("Failed to fetch data");
            }
          }
        };
        fetchBeatData();
      }, [slugName]);
  
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({navigateTo}))
}