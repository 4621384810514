import React, { FC } from "react";
import "./styles.scss";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const view: FC<ButtonProps> = ({
  className = "",
  children,
  ...rest
}) => {
  return (
    <button className={`${className}`} {...rest}>
      {children}
    </button>
  );
};
