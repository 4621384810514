import { useLocation, useNavigate } from "react-router-dom"
import view from "./view";
import { useEffect, useState } from "react";
import { viewType } from "./modal";
import { useSelector } from "react-redux";
import axios from "axios";
import { addBeat } from "../../shared/utils/apiServices";

export const BeatEmpty = () => {
    const [beatName, setBeatName] = useState<string>()
    const [beatDescription, setBeatDescription] = useState<string>()
    const [uploadedAudioFile, setUploadedAudioFile] = useState<any>(null);
    const [audioFileAPI, setAudioFileAPI] = useState<any>(null);
    const [audioImage, setAudioImage] = useState<any>(null);
    const [error, setError] = useState<any>("");

    const uploadedImage = useSelector((state: any) => state.upload.beatImg);
    const setBeatImgAPI = useSelector((state: any) => state.upload.beatImgAPI);

    useEffect(()=>{
        setAudioImage(uploadedImage)
    },[uploadedImage])

    const navigate = useNavigate();

    const location = useLocation();
 
    useEffect(() => {
        setAudioImage(null);
    }, [location]);


    const handleAudioUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setUploadedAudioFile(file);
            const formData = new FormData();
            formData.append('uploadingFile', file);

            try {
                const uploadResponse = await axios.post(`${process.env.REACT_APP_URL}/upload-file`, formData);
                if (uploadResponse.data.success) {
                    setAudioFileAPI(uploadResponse?.data.file_data)
                }
                else {
                    console.error("Else Error in response of uploading image", uploadResponse.data.message);
                }
            }
            catch (error) {
                console.error("Catch Error in response of uploading image", error);
            }
        }
    };


    const handleRemoveAudio = () => {
        setUploadedAudioFile(null);
    };


    const navigateTo = (value: string) => () => {
        navigate(value)
    }

    const payload = {
        beat_name: beatName,
        description: beatDescription,
        art_work_image: setBeatImgAPI,
        audio_file: audioFileAPI
    };

    const handleSubmit = async () => {

        let response: any = await addBeat(payload);
        if (response.data.success && response.data.beat) {
            setBeatName("")
            setBeatDescription("")
            setUploadedAudioFile(null)
            setAudioFileAPI(null)
            setAudioImage(null)
        } else {
            console.log(response.data.message,"sahdjkfjkasdfdsjh")
            setError(response.data.message)
        }
    }


    console.log("error",error)


    return (view({ navigateTo }, {beatName, setBeatName,beatDescription, setBeatDescription, uploadedImage, handleAudioUpload, handleRemoveAudio, uploadedAudioFile ,handleSubmit,audioImage,error}))
}