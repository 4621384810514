import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
  targetDate: Date; // The date and time we are counting down to
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer); // Cleanup the timer
  });

  return (
    <div className='timer  px-0'>
      <ul>
                        <li>
                            <h3 className="days">{timeLeft.days}</h3>
                            <span>Days</span>
                        </li>
                        <li>
                            <h3 className="hours">{timeLeft.hours}</h3>
                            <span>Hours</span>
                        </li>
                        <li>
                            <h3 className="minutes">{timeLeft.minutes}</h3>
                            <span>Minutes</span>
                        </li>
                        <li>
                            <h3 className="seconds">{timeLeft.seconds}</h3>
                            <span>Seconds</span>
                        </li>
                    </ul>
    </div>
  );
};

export default CountdownTimer;
