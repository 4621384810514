import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"; // Importing functions for creating async actions and slices
 // Importing API client for making requests
import { RootState } from "../../redux/store"; // Importing RootState type for TypeScript
import apiClient from "../../services/FetchClient";

/*
 * Name: authSlice.ts
 * Description: Slice for managing authentication state, including login actions and user information.
 * Version: 1.0.0
 * Author: SWAPAC INFOTECH
 */

// Interface defining the structure of the login state
interface LoginState {
  email: string; // User's email
  password: string; // User's password
  status: boolean; // Status of the login process (pending, successful, failed)
  success: string; // Indicates success of the login operation
  error: string; // Error message if login fails
  type: string; // Type of error if login fails
  authentication: boolean | undefined; // Authentication status
  isFirstTime: boolean; // Indicates if it's the user's first login
  userdata: any | null; // User data
  loginMessage:string
}

// Initial state of the login slice
const initialState: LoginState = {
  email: "",
  password: "",
  status: false,
  success: "",
  error: "",
  type: "",
  authentication: false,
  isFirstTime: false,
  userdata: null,
  loginMessage:""
};

// Interface defining the structure of the login request body
interface LoginBody {
  email: string; // User's email
  password: string; // User's password
}

// ------- Async action for user login -------
export const userLogin = createAsyncThunk(
  "userLogin/login", // Action type
  async (body: LoginBody, thunkAPI) => { // Async function to handle login
    const { email, password } = body;

    try {
      // Sending a POST request to the API for user authentication
      const response = await apiClient.post(
        `${process.env.REACT_APP_URL}/login`,
        {
          email,
          password,
        }
      );
      if (response.data.success) {
        localStorage.setItem("dropworksToken", response.data.token);
        console.log(response.data,"response.dataresponse.data")
        return response.data; // Return success status
      } else {
        return {
          success: false,
          type: response.data?.type || "",
          message: response.data.message,
        };
      }
    } catch (error: any) {
      // Catching and returning error message if API request fails
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);


// ------- createSlice for authentication -------
export const authSlice1 = createSlice({
  name: "userLogin", // Name of the slice
  initialState, // Initial state defined above
  reducers: {
    setAuthentication: (state, action: PayloadAction<boolean>) => {
      state.authentication = action.payload; // Set authentication status
    }
  },
  extraReducers: (builder) => {
    // Handling userLogin actions
    builder
      .addCase(userLogin.pending, (state) => {
        state.status = false; // Set status to false while login is pending
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = true; // Set status to true on successful login
        state.loginMessage = action.payload.message; // Clear error message
        console.log(action.payload.message,"state.loginMessage state.loginMessage")
        // state.data= action.payload.data
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.status = false; // Set status to false on login failure
      });

    // Add similar handling for remaining actions...


  },
});

// Selector to access the authentication state
export const auth = (state: RootState) => state.auth; // Selector to get authentication state from the store

// Exporting the reducer to be used in the store
export default authSlice1.reducer; // Export the reducer for use in the Redux store
