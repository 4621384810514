import React, { useEffect, useState } from "react";
import gsap from "gsap";
import { viewType } from "./modal";
import * as THREE from "three";
import "./styles.scss";

import {
  Heading0,
  Heading1,
  Heading3,
  Heading4,
} from "../../../../common/custom-field/dropstock-heading";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
} from "../../../../common/custom-field/dropstock-text";
import {
  DropworksNormalButton,
  DropworksSecondaryButton,
  DropworksSmallButton,
} from "../../../../common/custom-field/dropworks-button";
import Tabs from "../../../../common/tabs";
import { HomeCheckIcon } from "../../../../common/all-icons";
import TabsComponent from "../../../../common/tabs-component/view";
import { FC } from "react";
import HomeTabs from "../../../../common/home-tabs";
import { ButtonComponent } from "../../../shared/components/logo-header/view";
import { useTranslation } from "react-i18next";


// CSS for your component


const view: FC<viewType> = ({ navigateTo }) => {
 
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };
  const { t } = useTranslation();
 
  return (
    <div className="home ">
      <ButtonComponent  id="Modal1" className="d-none" ></ButtonComponent>
      {/* <div className="row no-gutters">
            <div className="col-12">
                <img src="homeImgGirl.png" className="custom-img img-fluid" alt="Custom Image" />
                <div id="effect-container"></div>
            </div>
        </div> */}
      <div className="home-container full-width-child">
        <div className="bubbles">
          <video className="dw-maintenance-videod" autoPlay muted loop>
            <source src="/HomeBackVideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* {bubbleValues.map((value:number, index:number) => (
        <Bubble key={index} i={value} />
      ))} */}
        </div>
        <div className="girlImg"></div>
      </div>

      <div className="container container-xxl" data-aos="fade-up">
        <Heading1 className="uni-mb-40  text-center">
         {t('TRANSACTIONS')}
        </Heading1>
        <TextfieldLG className="uni-mb-40 home-w-782 txt-col-204 text-center">
          Empowering artists and fans to connect, collaborate, and thrive in a
          music ecosystem designed for the digital age.
        </TextfieldLG>
        <DropworksSmallButton
          className="w-auto mx-auto home-mobileview-btn"
          onClick={() => navigateTo("/featured")}
        >
          Start Your Journey
        </DropworksSmallButton>
      </div>
      <div className=" w-100 d-flex full-width-child uni-my-208 mx-auto home-box-slide" >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((ele,i) => {
          return <div className="home-box"></div>;
        })}
      </div>
      <div className="container container-xxl overflow-hidden" data-aos="fade-up">
        <div className="uni-mb-120 home-page-mobilespace-bottom">
          <Heading1 className="text-center uni-mb-40"  data-aos="fade-right">
            Invest in the Sound of Tomorrow
          </Heading1>
          <TextfieldLG className="text-center txt-col-204"  data-aos="fade-left">
            Here at DropWorks we give everyone the opportunity to diversify
            their investments!.
            <br />
            That’s why we created two very diversified portfolios of tracks,
            ensuring that our investors are able to trade its shares as well as
            receive its royalties.
            <br />
            This way we make sure they never miss out on the latest trends.
          </TextfieldLG>
          <HomeTabs
            id="17"
            className="mx-auto home-tabs uni-mb-96 home-page-mobilespace-bottom"
            tabsTitle={["DropWorks Select", "DropWorks Composite"]}
            checkTabState={checkTabState}
            home={true}
          />
          <div className="home-tabs-content">
            <TabsComponent
              components={[
                <div className="d-flex home-tab-mobile-view">
                  <div>
                    <div className="home-tabs-content-box" data-aos="fade-left"></div>
                  </div>
                  <div className="uni-p-72 home-page-mobile-pd" data-aos="fade-right">
                    <Heading4 className="uni-mb-56 home-heading4 home-font-weight">
                      Your favorite tracks, reimagined
                    </Heading4>
                    <TextfieldMD className="uni-mb-56 home-textMd">
                      Dropworks Select features popular songs from established
                      artists, now available on Dropworks. Own and enjoy music
                      that’s already making waves.
                    </TextfieldMD>
                    <p className="d-flex justify-content-start learn-trade-mobileview">
                      <DropworksNormalButton
                        className="w-auto f-18 home-mobileview-btn"
                        onClick={() =>
                          navigateTo("/marketplace-dropworks-select")
                        }
                      >
                        Learn more!
                      </DropworksNormalButton>
                      <DropworksNormalButton
                        className="w-auto f-18  home-tabs-content-btn-bg uni-ml-56  home-mobileview-btn trade-btn"
                        onClick={() => { 
                       
                          navigateTo("/dropworks-select");
                        }}
                      >
                        Trade now!
                      </DropworksNormalButton>
                    </p>
                  </div>
                </div>,
                <div className="d-flex home-tab-mobile-view">
                  <div>
                    <div className="home-tabs-content-box" data-aos="fade-left"></div>
                  </div>
                  <div className="uni-p-72 home-second-tab" data-aos="fade-right">
                    <Heading4 className="uni-mb-56 home-heading4">
                      Dropworks exclusive music from our community
                    </Heading4>
                    <TextfieldMD className="uni-mb-56 home-textMd">
                      Dropworks Composite Offers a curated selection songs from
                      established artists, now available on Dropworks. Own and
                      enjoy music that’s already making waves.
                    </TextfieldMD>
                    <p className=" learn-trade-mobileview d-flex justify-content-start ">
                      <DropworksNormalButton
                        className="w-auto f-18  home-mobileview-btn"
                        onClick={() =>
                          navigateTo("/marketplace-dropworks-composite")
                        }
                      >
                        Learn more!
                      </DropworksNormalButton>
                      <DropworksNormalButton
                        className="w-auto  home-tabs-content-btn-b f-18 gradient-btn-2 uni-ml-56  home-mobileview-btn trade-btn"
                        onClick={() => {
                          navigateTo("/dropworks-composite");
                        }}
                      >
                        Trade now!
                      </DropworksNormalButton>
                    </p>
                  </div>
                </div>,
              ]}
              tabsValue={tabsValue}
            />
          </div>
        </div>
        <div className="uni-mt-120 home-page-mobilespace-top">
          <Heading1 className="text-center uni-mb-40"  data-aos="fade-left">
            Limitless Possibilities with DropWorks
          </Heading1>
          <TextfieldLG className="home-never-bored uni-mb-120">Never be bored.<span className='unique-features'>Explore our wide range of unique features.</span></TextfieldLG>
        </div>
        <div className="home-tabs-content home-tab-mobile-view home-box-section-spacing uni-mb-120">
          <div className="home-tabs-content-box2 uni-mr-8"  data-aos="fade-left"></div>
          <div className="w-670 d-flex align-items-center justify-content-center uni-ml-56 content-boxes">
            <div className="max-w-403 home-page-max-width"  data-aos="fade-right">
              <Heading3 className="home-tabs-content-box2-heading uni-mb-24">
                We launch your new tracks
              </Heading3>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" /> All major streaming
                platforms
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" /> Keep full royalty
                ownership
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" /> Access to DropWorks
                Composite
              </TextfieldSM>
              <DropworksSmallButton
                className="w-auto  uni-mt-32 home-mobileview-btn home-btn-mobileui-mt"
                onClick={() => navigateTo("/create")}
              >
                Create a Track
              </DropworksSmallButton>
            </div>
          </div>
        </div>

        <div className="home-tabs-content home-tab-mobile-view reverse-col uni-mb-120">
          <div className="w-670 d-flex align-items-center justify-content-center uni-mr-56 content-boxes"   data-aos="fade-left">
            <div className="max-w-403 home-page-max-width">
              <Heading3 className="home-tabs-content-box2-heading uni-mb-24">
                We also launch your old tracks
              </Heading3>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />
                Keep historical data
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />
                All major streaming platforms
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />
                Keep full royalty ownership
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />
                Access to DropWorks Composite
              </TextfieldSM>
              <DropworksSmallButton
                className="w-auto  uni-mt-32 home-mobileview-btn home-btn-mobileui-mt"
                onClick={() => navigateTo("/create")}
              >
                Re-release a Track
              </DropworksSmallButton>
            </div>
          </div>
          <div className="home-tabs-content-box2 uni-ml-8 home-box-right" data-aos="fade-right"></div>
        </div>

        <div className="home-tabs-content  home-box-section-spacing home-tab-mobile-view uni-mb-120">
          <div className="home-tabs-content-box2 uni-mr-8"  data-aos="fade-left"></div>
          <div className="w-670 d-flex align-items-center justify-content-center uni-ml-56 content-boxes">
            <div className="max-w-403 home-page-max-width"  data-aos="fade-right" >
              <Heading3 className="home-tabs-content-box2-heading uni-mb-24">
                Explore new talents
              </Heading3>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile"/>
                Discover new music
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />
                Stay connected
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />
                Interact with the community
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />
                Collaborate with new artists
              </TextfieldSM>

              <DropworksSmallButton
                className="w-auto  uni-mt-32 home-mobileview-btn home-btn-mobileui-mt"
                onClick={() => navigateTo("/featured")}
              >
                Discover
              </DropworksSmallButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
 // useEffect(() => {
  //   createSparkleEffect(); // Sparkle effect

  //   const smoke = new Smoke();
  //    // Initialize smoke effect

  //   return () => {
  //     window.removeEventListener("resize", smoke.onResize);
  //   };
  // }, []);


  // function createSparkleEffect() {
  //   const container = document.getElementById(
  //     "effect-container"
  //   ) as HTMLElement;
  //   for (let i = 0; i < 100; i++) {
  //     const sparkle = document.createElement("div");
  //     sparkle.className = "sparkle";
  //     const size = Math.random() * 8 + 2;
  //     sparkle.style.width = `${size}px`;
  //     sparkle.style.height = `${size}px`;
  //     sparkle.style.left = `${Math.random() * window.innerWidth}px`;
  //     sparkle.style.top = `${Math.random() * window.innerHeight}px`;
  //     container.appendChild(sparkle);

  //     gsap.to(sparkle, {
  //       duration: Math.random() * 4 + 3,
  //       y: -window.innerHeight - 85, // move upwards off the screen
  //       opacity: 0,
  //       scaleX: 0,
  //       scaleY: 0,
  //       repeat: -1,
  //       ease: "power1.inOut",
  //     });
  //   }
  // }

  // Smoke class for the Three.js smoke effect
  // class Smoke {
  //   width: number;
  //   height: number;
  //   clock: THREE.Clock;
  //   renderer: THREE.WebGLRenderer;
  //   scene: THREE.Scene;
  //   camera!: THREE.PerspectiveCamera;
  //   smokeParticles: THREE.Mesh[] = [];

  //   constructor() {
  //     this.width = window.innerWidth;
  //     this.height = window.innerHeight;
  //     this.clock = new THREE.Clock();
  //     this.renderer = new THREE.WebGLRenderer();
  //     this.renderer.setSize(this.width, this.height);
  //     document
  //       .getElementById("effect-container")
  //       ?.appendChild(this.renderer.domElement);

  //     this.scene = new THREE.Scene();
  //     const textureLoader = new THREE.TextureLoader();
  //     textureLoader.load("homeImage_background.png", (texture: any) => {
  //       this.scene.background = texture;
  //     });

  //     this.addCamera();
  //     this.addLights();
  //     this.addParticles();

  //     this.update();

  //     this.addEventListeners();
  //   }

  //   evolveSmoke(delta: number) {
  //     const oscillationSpeed = 0.5; // Speed of oscillation
  //     const amplitude = 50; // Amplitude of oscillation

  //     this.smokeParticles.forEach((particle) => {
  //       particle.rotation.z += delta * 0.2;

  //       // Horizontal oscillation
  //       particle.position.x +=
  //         Math.sin(Date.now() * 0.001 * oscillationSpeed) * amplitude * delta;
  //       // Keep particles within the viewport bounds
  //       if (particle.position.x > window.innerWidth / 2) {
  //         particle.position.x = window.innerWidth / 2;
  //       } else if (particle.position.x < -window.innerWidth / 2) {
  //         particle.position.x = -window.innerWidth / 2;
  //       }
  //     });
  //   }

  //   addCamera() {
  //     this.camera = new THREE.PerspectiveCamera(
  //       78,
  //       this.width / this.height,
  //       1,
  //       650
  //     );
  //     this.camera.position.z = 50;
  //     this.scene.add(this.camera);
  //   }

  //   addLights() {
  //     const light = new THREE.DirectionalLight(0xffffff, 2);
  //     light.position.set(-1, 0, 1);
  //     this.scene.add(light);
  //   }

  //   addParticles() {
  //     const textureLoader = new THREE.TextureLoader();
  //     textureLoader.load(
  //       "https://rawgit.com/marcobiedermann/playground/master/three.js/smoke-particles/dist/assets/images/clouds.png",
  //       (texture: any) => {
  //         const smokeMaterial = new THREE.MeshLambertMaterial({
  //           color: 0xffffff,
  //           map: texture,
  //           transparent: true,
  //         });
  //         if (smokeMaterial.map) {
  //           smokeMaterial.map.minFilter = THREE.LinearFilter;
  //         }
  //         const smokeGeometry = new THREE.PlaneGeometry(250, 250);

  //         for (let i = 0; i < 100; i++) {
  //           const smokeMesh = new THREE.Mesh(smokeGeometry, smokeMaterial);
  //           smokeMesh.position.set(
  //             Math.random() * 500 - 250,
  //             Math.random() * 500 - 250,
  //             Math.random() * 1000 - 100
  //           );
  //           smokeMesh.rotation.z = Math.random() * 360;
  //           this.smokeParticles.push(smokeMesh);
  //           this.scene.add(smokeMesh);
  //         }
  //       }
  //     );
  //   }

  //   render() {
  //     this.renderer.render(this.scene, this.camera);
  //   }

  //   update() {
  //     const delta = this.clock.getDelta();
  //     this.evolveSmoke(delta);
  //     this.render();
  //     requestAnimationFrame(this.update.bind(this));
  //   }

  //   onResize() {
  //     this.camera.aspect = window.innerWidth / window.innerHeight;
  //     this.camera.updateProjectionMatrix();
  //     this.renderer.setSize(window.innerWidth, window.innerHeight);
  //   }

  //   addEventListeners() {
  //     window.addEventListener("resize", this.onResize.bind(this));
  //   }
  // }

  // const getRandomArray = (length: number, min: number, max: number) => {
  //   return Array.from(
  //     { length },
  //     () => Math.floor(Math.random() * (max - min + 1)) + min
  //   );
  // };
  // const Bubble = ({ i, key }: { i: number; key: number }) => {
  //   return <span key={key} style={{ "--i": i } as React.CSSProperties}></span>;
  // };
  // const bubbleValues = getRandomArray(250, 1, 250);
  // const dispatch = useDispatch();
  //const styles = {
//   body: {
//     margin: 0,
//     overflow: "hidden",
//     position: "absolute" as "absolute",
//     width: "100%",
//     height: "100%",
//     left: 0,
//   },
//   customImg: {
//     position: "absolute" as "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     objectFit: "cover" as "cover",
//     zIndex: 1,
//   },
//   effectContainer: {
//     position: "absolute" as "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     pointerEvents: "none" as "none",
//     zIndex: 0,
//   },
//   sparkle: {
//     position: "absolute" as "absolute",
//     borderRadius: "50%",
//     pointerEvents: "none" as "none",
//     background: "rgb(250, 202, 45)",
//     opacity: 0.7,
//     border: "1px solid black",
//   },
// };