import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { ButtonComponent } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';

const view = () => {
  return (
    <div
      className="modal fade"
      id="UserReportReasonModal"
      aria-hidden="true"
      aria-labelledby="UserReportReasonModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-40">
            <Heading3 className="modal-title-fs-32">Report something about this user</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <p className='d-flex justify-content-between  uni-p-16 p-lh uni-mb-8  popup-space-mobile'>

              <TextfieldSM className=" font-col-wh my-0 mx-0">They are pretending to be someone else</TextfieldSM>
              <DropworksRadioButton />
            </p>
            <p className='d-flex justify-content-between uni-p-16  popup-space-mobile p-lh uni-mb-8'>

              <TextfieldSM className=" font-col-wh my-0 mx-0">Their profile contains inappropriate content</TextfieldSM>
              <DropworksRadioButton />
            </p>
            <p className='d-flex justify-content-between popup-space-mobile uni-p-16 p-lh uni-mb-8'>

              <TextfieldSM className=" font-col-wh my-0 mx-0">Something else</TextfieldSM>
              <DropworksRadioButton />
            </p>

          </div>
          <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
          <ButtonComponent id="ReportModal1">
            <DropworksSmallButton className="btn-wid btn-bg-gray3 social-link-modal-btns my-0 ml-0 uni-mr-16 uni-mr-16">Back</DropworksSmallButton></ButtonComponent>
            <ButtonComponent id="ImpersonatingReportModal">
            <DropworksSmallButton className="btn-wid m-0 social-link-modal-btns">Next</DropworksSmallButton>
            </ButtonComponent>
         
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;


