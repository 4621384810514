import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModalSlice {
   modal1:boolean
   modal2:boolean
   modal3:boolean
   mobile:number
   startTimer:boolean
   newEmail:string
   socialModal:string,
   phoneNumberAdded:string,
   mobileCountryCode:string,
   mobileCountryCodeAdded:string,
  }

const initialState : ModalSlice = {
    modal1: true, 
    modal2:true,
    modal3:true,
    mobile:0,
    mobileCountryCode:"+93",
    startTimer:false,
    newEmail:"",
    socialModal:"",
    phoneNumberAdded:"",
    mobileCountryCodeAdded:"",

};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setMobile: (state, action: PayloadAction<number>) => {
      state.mobile = action.payload; 
    },
    setMobileCountryCode: (state, action: PayloadAction<string>) => {
      state.mobileCountryCode = action.payload; 
    },
    setModal1: (state, action: PayloadAction<boolean>) => {
      state.modal1 = action.payload; 
    },
    setModal2: (state, action: PayloadAction<boolean>) => {
      state.modal2 = action.payload; 
    },
    setModal3: (state, action: PayloadAction<boolean>) => {
      state.modal3 = action.payload; 
    },
    setStartTimer: (state, action: PayloadAction<boolean>) => {
      state.startTimer = action.payload; 
    },
    setNewEmail: (state, action: PayloadAction<string>) => {
      state.newEmail = action.payload; 
    },
    setsocialModal: (state, action: PayloadAction<string>) => {
      state.socialModal = action.payload; 
    },
    setPhoneNumberAdded:(state, action: PayloadAction<string>) => {
      state.phoneNumberAdded = action.payload; 
    },
    setPhoneNumberAddedCountryCode:(state, action: PayloadAction<string>) => {
      state.mobileCountryCodeAdded = action.payload; 
    },
  },
});


export const { setModal1,setModal2,setModal3,setMobile,setStartTimer,setNewEmail,setsocialModal,setPhoneNumberAdded,setMobileCountryCode,setPhoneNumberAddedCountryCode } = modalSlice.actions;


export const modalReducer = modalSlice.reducer;
