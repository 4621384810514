import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';

import { ButtonComponent, closeModal, CloseModalButton } from '../../logo-header/view';
import { useEffect, useState } from 'react';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { changePhoneNumber, getUserData, verifyPhoneNumber } from '../../../utils/apiServices';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { setUserData } from '../../../../../redux/reducer/userSlice';
import Loader from '../../loader/view';

const view = () => {
  const [cooldown, setCooldown] = useState(false);
  const [timer, setTimer] = useState(0);
  const {phoneNumberAdded,mobileCountryCodeAdded} =useSelector((state:RootState)=>state.modal);
  const [loading,setLoading]=useState<boolean>(false);
  const {phone} =useSelector((state:RootState)=>state.user);
  const [message,setMessage]=useState("");
  const dispatch =useDispatch();
const [otp,setOtp]=useState("")
  const handleResendCode = () => {
    if (!cooldown) {
      setCooldown(true);
      setTimer(10); 
      changePhoneNumber({phone:phoneNumberAdded,countryCode:mobileCountryCodeAdded});
    }
  };

  const submit=async()=>{
    setLoading(true)
    const response =await verifyPhoneNumber({phone:phoneNumberAdded,otp:otp,countryCode:mobileCountryCodeAdded});
    if(response.success)
    {
      setLoading(false)
      closeModal();
      const result = await getUserData(); 
      if (result.success && result.userData) { 
          dispatch(setUserData(result.userData.data.userdata));
      }
    }
    else{
      setLoading(false)
setMessage(response.message);
setTimeout(()=>{
setMessage("")
},2000)
    }

  }
  useEffect(() => {
    if (cooldown && timer > 0) {
      const countdown = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);

      if (timer === 1) {
        setCooldown(false);
      }

      return () => clearInterval(countdown);
    }
  }, [cooldown, timer]);
  return (
    <div
    className="modal fade"
    id="MobilePhoneModal3"
    aria-hidden="true"
    aria-labelledby="MobilePhoneModal3"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">Confirm Phone Number</Heading3>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt">Please confirm the code we’ve sent to your phones</TextfieldLG>
          <DropworksInput className="mb-0" placeholder="Code" maxLength={6}
                value={otp}
                onInput={(e: any) => {
                  let check = e.target.value.replace(/[^0-9]/g, "");
                  setOtp(check)}}
                   />
                   {message && <p className='text-danger'>{message}</p>}
          <TextfieldXSM
              className={`ctm-txt2 uni-mb-56 ${cooldown ? 'disabled' : ''}`}
            >
              <button onClick={handleResendCode} className='resend-btn '
                disabled={cooldown}>  {cooldown ? `Resend in ${timer}s` : 'Resend Code'}</button>
            </TextfieldXSM>
        </div>  
        <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
        {loading?<Loader/>:<><ButtonComponent id={phone?"MobilePhoneModalB":"MobilePhoneModalA"}>
        <DropworksSmallButton className="btn-wid btn-bg-gray3 social-link-modal-btns  my-0 ml-0 uni-mr-16">Back</DropworksSmallButton>
        </ButtonComponent> 
        <DropworksSmallButton   className={!otp ||  otp?.length<4?"btn-wid social-link-modal-btns m-0 custom-inactive-btn text-secondary disable-interactions":"btn-wid social-link-modal-btns m-0"} onClick={submit}>Finish</DropworksSmallButton></>}
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;


