import { FC, useEffect, useState } from "react";
import "./styles.scss";
import userImage from "../../../assets/img/Profile-pics/kingroland-image.png";
import beatsImage from "../../../assets/img/temporary-images/beat-image.svg";
import blueTik from "../../../assets/img/Profile-pics/Subtract.svg";
import PlaybackBtn from "../../../assets/img/play-btn/Beat-Playback-Btn.png";
import { UserLable } from "../../../common/user-labels";
import {
  DropworksDisableButton,
  DropworksSmallButton,
  GradientActiveBtn,
} from "../../../common/custom-field/dropworks-button";
import { MeatballsMenuDropdown } from "../../../common/icons";
import { viewType } from "./modal";
import { BlueTik} from "../../../common/all-icons";
import { Heading1 } from "../../../common/custom-field/dropstock-heading";
import { ButtonComponent } from "../../shared/components/logo-header/view";
import { HeartIcon } from "../../../common/heart-icon";
import { MoreMobile } from "../../../common/moreMobile";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";


export const view: FC<viewType> = ({ navigateTo }) => {
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState([
    "Report",
  ]);

  const beat = useSelector((state: RootState) => state.beat)

  const [beatId,setBeatId] = useState({
    beat_id:""
  })
  useEffect(()=>{
    setBeatId({beat_id:beat?.Data[0]?._id})
  },[beat])

  console.log(beat,"beat id at beat page")

  return (
    <div className="beats-page container container-xxl min-height-cover beats-page-minheight">
      <div className="beats-page-body">
        <div>
          <img src={`${process.env.REACT_APP_IMGURL}/${beat.Data[0]?.art_work_image[0]?.src}`} className="beats-page-image" alt="user-image" />
        </div>
        <div className="beats-page-content">
          <div className="w-100">
            <div className="beats-page-details">
              <Heading1 className="beats-page-name font-weight-600 ">{beat.Data[0]?.beat_name}</Heading1>
              <img
                src={PlaybackBtn}
                className="beats-page-playback-Btn uni-ml-24 cursor-pointer"
                alt="PlaybackBtn"
              />
            </div>
            <div className="beats-page-song-title">Unlost</div>
          </div>
          <div className="d-flex gap-3 mt-2 w-100">
            <UserLable>Beat</UserLable>
          </div>
        </div>
      </div>

      <div className="d-flex gap-3 uni-mt-mb-64 beats-page-bottons">
        <DropworksDisableButton onClick={navigateTo("/message-chat")} className="message-btn font-weight-600">Message</DropworksDisableButton>
        <div className="d-flex align-items-center justify-content-center mobile-arrow-track">
              
              <span className="uni-mr-8 font-weight-600">  More</span>
              <MoreMobile
                                  className="px-0 mobile-p-0"
                                  arr={["Block","Report"]}
                                />
              </div>
        <ButtonComponent id="InviteModal" className="invite-disabled-btn-mbl"><DropworksSmallButton className="width-178 message-btn invite-btn-disable-mbl">Invite</DropworksSmallButton></ButtonComponent>
        <span className="beats-icon-track">
        <HeartIcon id={beatId} liked={beat.Data[0]?.favoriteData}/>
        </span>
      
        <span className="convert-more d-flex align-items-center">
        <MeatballsMenuDropdown pagename="beat"
          arr={MeatballsMenuOptions} 
          height={"9px"}
        ></MeatballsMenuDropdown>
       </span>
      </div>
      <h4 className="beats-page-subheading uni-mb-16">Creator</h4>
      <div className="beats-page-sub-content mb-4 cursor-pointer" onClick={navigateTo(`/user-profile/${beat.Data[0]?.user_id.slug_name}`)} >
        <img src={`${process.env.REACT_APP_IMGURL}/${beat.Data[0]?.user_id.profile_image[0]?.src}`} className="beats-page-sub-content-img" alt="" />
        <div className="beats-page-sub-details">
          <h5 className="beats-page-sub-song-name">{beat.Data[0]?.user_id.firstname+" "+beat.Data[0]?.user_id.lastname}</h5>
          <div className="uni-ml-8"><BlueTik position="top"/></div>
          <span className="drop-beats">
          <MeatballsMenuDropdown
          arr={MeatballsMenuOptions}
          height={"5px"}
            className="track-metaballsmenudropdown"
        ></MeatballsMenuDropdown>
        </span>
        </div>
      </div>
    </div>
  );
};
