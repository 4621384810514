import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import apiClient from "../../services/FetchClient";
import axios from "axios";

interface UploadSlice {
   imgData:string;
   settingUpImg:string;
   settingUpImgAPI:any;
   imgDataAPI:any;
   beatImg:any;
   beatImgAPI:any;
   uploadnewbeatImg:any;
   uploadnewbeatImgAPI:any;
   inviteModalUserId:string;
   trackId:string;
   invitedUserTrackProject:any;
   trackDetails:any,
   selectedCollaborator:any,
   selectedFile:any,
   selectedFileCollaborator:any,
   trackSlugname:any
  }

const initialState : UploadSlice = {
  imgData: "", 
  settingUpImg:"",
  settingUpImgAPI:"",
  imgDataAPI:"",
  beatImg:"",
  beatImgAPI:"",
  uploadnewbeatImg:"",
  uploadnewbeatImgAPI:"",
  inviteModalUserId:"",
  trackId:"",
  selectedCollaborator:"",
  trackDetails:"",
  selectedFile:"",
  selectedFileCollaborator:"",
  trackSlugname:"",
  invitedUserTrackProject:[
    {
        "_id": "6730792187d49fdc23732510",
        "sent_user_id": "671758625da7cdc12459e85b",
        "receiver_user_id": "672b3bec76d584506bbbc173",
        "receiver_name": "Subodh Patil",
        "track_id": "6730785187d49fdc237324ed",
        "beat_id": null,
        "invite_token": null,
        "role": "Producer",
        "percentage_offered": "33",
        "message": "message",
        "upload_audio": [
            {
                "fileType": "audio/mpeg",
                "src": "uploads/1731229981593.mpeg",
                "fileName": "ringtone_sino_natal.mp3",
                "_id": "6730792187d49fdc23732511"
            }
        ],
        "status": "active",
        "acceept_status": "pending",
        "expired_invite_at": "2024-11-10T09:18:05.024Z",
        "updated_at": null,
        "created_by": null,
        "updated_by": null,
        "created_at": "2024-11-10T09:13:05.025Z",
        "__v": 0
    },
    {
      "_id": "6730792187d49fdc23732510",
      "sent_user_id": "671758625da7cdc12459e85b",
      "receiver_user_id": "672b3bec76d584506bbbc173",
      "receiver_name": "Sunil Dhangar",
      "track_id": "6730785187d49fdc237324ed",
      "beat_id": null,
      "invite_token": null,
      "role": "Producer",
      "percentage_offered": "33",
      "message": "message",
      "upload_audio": [
          {
              "fileType": "audio/mpeg",
              "src": "uploads/1731229981593.mpeg",
              "fileName": "ringtone_sino_natal.mp3",
              "_id": "6730792187d49fdc23732511"
          }
      ],
      "status": "active",
      "acceept_status": "pending",
      "expired_invite_at": "2024-11-10T09:18:05.024Z",
      "updated_at": null,
      "created_by": null,
      "updated_by": null,
      "created_at": "2024-11-10T09:13:05.025Z",
      "__v": 0
  },
  {
    "_id": "6730792187d49fdc23732510",
    "sent_user_id": "671758625da7cdc12459e85b",
    "receiver_user_id": "672b3bec76d584506bbbc173",
    "receiver_name": "Sagar Rana",
    "track_id": "6730785187d49fdc237324ed",
    "beat_id": null,
    "invite_token": null,
    "role": "Producer",
    "percentage_offered": "33",
    "message": "message",
    "upload_audio": [
        {
            "fileType": "audio/mpeg",
            "src": "uploads/1731229981593.mpeg",
            "fileName": "ringtone_sino_natal.mp3",
            "_id": "6730792187d49fdc23732511"
        }
    ],
    "status": "active",
    "acceept_status": "pending",
    "expired_invite_at": "2024-11-10T09:18:05.024Z",
    "updated_at": null,
    "created_by": null,
    "updated_by": null,
    "created_at": "2024-11-10T09:13:05.025Z",
    "__v": 0
}
]
};

export const getALlInvitedUserbyTrackProject = createAsyncThunk(
  "track/get-all-invited-collabotor-by", // Action type
  async(data: string, { rejectWithValue })=>{
    try {
      const response: any = await axios.get(`https://dropworksapi.swapinfotech.com/api/track/get-all-invited-collabotor-by/${data}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return response.data.data || [];
    } else {
   
        return []

    }
    } catch (err: any) {
      return []
    }
  }
);

export const fetchTrackDetails = createAsyncThunk(
  "track/get-all-track", // Action type
  async(data: string, { rejectWithValue })=>{
    
    try {
      const response: any = await axios.get(`https://dropworksapi.swapinfotech.com/api/track/get-track-by/${data}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return response.data.data || [];
    } else {
   
        return []

    }
    } catch (err: any) {
      return []
    }
  }
);



const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setUploadImg: (state, action: PayloadAction<any>) => {
      state.imgData = action.payload; 
    },
    setUploadImgAPI: (state, action: PayloadAction<any>) => {
      state.imgDataAPI = action.payload; 
    },
    setUploadSettingImg: (state, action: PayloadAction<any>) => {
      state.settingUpImg = action.payload; 
    },
    setUploadSettingImgAPI:(state, action: PayloadAction<any>) => {
      state.settingUpImgAPI = action.payload; 
    },
    setUploadSettingImgBanner: (state, action: PayloadAction<any>) => {
      state.settingUpImg = action.payload; 
    },
    setUploadSettingImgBannerAPI:(state, action: PayloadAction<any>) => {
      state.settingUpImgAPI = action.payload; 
    },
    setUploadSettingImgProfile: (state, action: PayloadAction<any>) => {
      state.settingUpImg = action.payload; 
    },
    setUploadSettingImgProfileAPI:(state, action: PayloadAction<any>) => {
      state.settingUpImgAPI = action.payload; 
    },
    setUploadBeatImg: (state, action: PayloadAction<any>) => {
      state.beatImg = action.payload; 
    },
    setUploadBeatImgAPI: (state, action: PayloadAction<any>) => {
      state.beatImgAPI = action.payload; 
    },
    setUploadNewBeatImg: (state, action: PayloadAction<any>) => {
      state.uploadnewbeatImg = action.payload; 
    },
    setUploadNewBeatImgAPI: (state, action: PayloadAction<any>) => { 
      state.uploadnewbeatImgAPI = action.payload; 
    },
    setInviteModalUserID: (state, action: PayloadAction<any>) => {
      state.inviteModalUserId = action.payload; 
    },
    setTrackID:(state,action:PayloadAction<string>)=>{
      state.trackId=action.payload;
    },
    setSetelectedCollaborator:(state,action:PayloadAction<any>)=>{
      state.selectedCollaborator=action.payload
    },
    setTrackdetails:(state,action:PayloadAction<any>)=>{
      state.trackDetails=action.payload
    },
    setSelectedFile:(state,action:PayloadAction<any>)=>{
      
      state.selectedFile=action.payload
    },
    setSelectedFileCollaborator:(state,action:PayloadAction<any>)=>{
      
      state.selectedCollaborator=action.payload
    },
    setTrackSlugName:(state,action:PayloadAction<any>)=>{
      
      state.trackSlugname=action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getALlInvitedUserbyTrackProject.pending, (state) => {
        state.invitedUserTrackProject = [];
      })
      .addCase(getALlInvitedUserbyTrackProject.fulfilled, (state, action) => {

        state.invitedUserTrackProject = action.payload;
      })
      .addCase(getALlInvitedUserbyTrackProject.rejected, (state, action) => {
        state.invitedUserTrackProject = []; 
      });


      builder
      .addCase(fetchTrackDetails.pending, (state) => {
        state.trackDetails = [];
      })
      .addCase(fetchTrackDetails.fulfilled, (state, action) => {
     
        state.trackDetails = action.payload;
      })
      .addCase(fetchTrackDetails.rejected, (state, action) => {
        state.trackDetails = []; 
      });

    // Add similar handling for remaining actions...


  },
});


export const {setTrackSlugName,setSelectedFileCollaborator,setTrackID,setSelectedFile,setSetelectedCollaborator, setUploadImg,setUploadSettingImg,setUploadSettingImgAPI,setUploadSettingImgBanner,setUploadSettingImgBannerAPI,setUploadSettingImgProfileAPI,setUploadSettingImgProfile,setUploadImgAPI,setUploadBeatImg,setUploadBeatImgAPI,setUploadNewBeatImg ,setInviteModalUserID, setUploadNewBeatImgAPI,setTrackdetails} = uploadSlice.actions;


export const uploadReducer = uploadSlice.reducer;