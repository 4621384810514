import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DropWorksSelectSlice {
   Data:string
  }

const initialState : DropWorksSelectSlice = {
  Data: "DropWorks Select", 
};

const dropWorksSelectSlice = createSlice({
  name: "music",
  initialState,
  reducers: {
    setDropworksSelectoptin: (state, action: PayloadAction<string>) => {
      state.Data = action.payload; 
    }
  },
});


export const { setDropworksSelectoptin } = dropWorksSelectSlice.actions;


export const dropWorksSelectReducer = dropWorksSelectSlice.reducer;
