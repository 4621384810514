import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MailSlice {
   Data:any
  }

const initialState : MailSlice = {
  Data:"", 
};

const mailSlice = createSlice({
  name: "mail",
  initialState,
  reducers: {
    setMail: (state, action: PayloadAction<any>) => {
      state.Data = action.payload; 
    }
  },
});


export const { setMail } = mailSlice.actions;


export const mailReducer = mailSlice.reducer;
