import { FC, forwardRef, useState } from "react";
import { view } from "./view";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    className2?:string;
    className3?:boolean;
}

export const DropworksPassword = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const [show,setShow]=useState<boolean>(true);
    const showHide=()=>{
        setShow(!show)
    }
    return view({showHide,show,ref,...props});
})