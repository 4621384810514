const spanishdict = {
    ETH: 'ETH',
    TRANSACTIONS: "Libera el potencial de la música",
    APPROVE: 'Aprobar',
    REJECT: 'Rechazar',
    DISABLED: 'Deshabilitado',
    DISABLE: 'Deshabilitar',
    USERS: 'Usuarios',
    TEXTINPUT: 'Texto Input',
    SWITCH: 'Interruptor',
  };
  
  export default spanishdict;