import axios from "axios";
import { getLocalStorageObject, setLocalStorageObject, urlUpdated } from "./localStorage";
import { loginForm } from "./utilsModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { changePasswordFormInputs, deleteAccountFormInputs, resetPasswordFormInputs } from "../../content/guest-pages/login/modal";
import apiClient from "../../../services/FetchClient";


const NavigateOnLogin=(message:string)=>{
  if(message === "Already verification done")
    {
      localStorage.removeItem("dropworksToken");
      window.location.href="/login";
    }
    if(message ==="Link has been expired")
    {
      window.location.href="/sign-up";
    }
}
export const loginApi = async (data: loginForm) => {
  try {
    const response: any = await apiClient.post("/login", data);
    if (response.data.success) {
        localStorage.setItem("dropworksToken", response.data.token);
      return { success: true, message: "" };
    } else {
      return {
        success: false,
        type: response.data?.type || "",
        message: response.data.message,
      };
    }
  } catch (err: any) {
    console.log(err.message, "error in Login Api");
    return null;
  }
};


export const signUpApi = async (data: loginForm) => {
    try {
      const response: any = await apiClient.post(urlUpdated("/register"), data);
      console.log(response,"in api of")
      if (response.data.success) {
       localStorage.setItem("userId",response.data.user_id);
        return { success: true, message: "",email:response?.data?.register?.email };
      }
       else {
        return {
          success: false,
          type: response.data.type,
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err.message, "error in Login Api");
      return null;
    }
  };
  export const recoverbyEmail = async (data: loginForm) => {
    try {
      const response: any = await axios.post(urlUpdated("/password-reset"), data);
      if (response.data.success) {
        return { success: true, message: "" };
      } else {
        return {
          success: false,
          type: response.data?.type || "",
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err.message, "error in Login Api");
      return null;
    }
  };
  export const resetPassword = async ({data,passwordToken}:{data:resetPasswordFormInputs,passwordToken:string}) => {
    try {
      const response: any = await axios.post(urlUpdated(`/set-new-forget-password-by-link/?token=${passwordToken}`), data);
      if (response.data.success) {
        return { success: true, message: "" };
      } else {
        return {
          success: false,
          type: response.data?.type || "",
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err.message, "error in Login Api");
      return null;
    }
  };

  export const registerFirstFormApi = async (data: any) => {
    try {
      let userId=localStorage.getItem('userId');
      const response: any = await axios.put(urlUpdated(`/update-page-1/${userId}`), data);
      console.log(response,"in api of")
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        return {
          success: false,
          type: response.data.type,
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err.message, "error in Login Api");
      return null;
    }
  };
  
  export const registerSecondFormApi = async (data: any) => {
    try {
      let userId=localStorage.getItem('userId');
      const response: any = await axios.put(urlUpdated(`/update-page-2/${userId}`), data);
      console.log(response,"in api of")
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        return {
          success: false,
          type: response.data.type,
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err.message, "error in Login Api");
      return null;
    }
  };

  export const registerFirstPopup = async (data: string[]) => {
    
    let token=localStorage.getItem('dw-register-token');
    try {
      const response: any = await axios.put(urlUpdated(`/update-pop-up-1/?token=${token}`), {
        // token:token,
        role:data
      });
      console.log(response,"our response in first form api=================")
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        NavigateOnLogin(response.data.message)
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err.status, "error in Login Api");
      if(err.status === 401)
      {
        return {
          success: false,
          message: "Your Token has expired",
        }
      }
      return {
        success: false,
        message: err.message,
      }
    }
  };
  export const registerSecondPopup = async (data: string[]) => {
    let token=localStorage.getItem('dw-register-token');
    try {
      const response: any = await axios.put(urlUpdated(`/update-pop-up-2/?token=${token}`), {
        // token:token,
        genres:data
      });
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        NavigateOnLogin(response.data.message)
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err.status, "error in Login Api");
      if(err.status === 401)
      {
        return {
          success: false,
          message: "Your Token has expired",
        }
      }
      return {
        success: false,
        message: err.message,
      }
    }
  };

  export const registerThirdPopup = async (data:any) => {
    let token=localStorage.getItem('dw-register-token');
    try {
      const response: any = await axios.put(urlUpdated(`/update-phone-pop-up-3/?token=${token}`), data);
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        NavigateOnLogin(response.data.message)
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err.status, "error in Login Api");
      if(err.status === 401)
      {
        return {
          success: false,
          message: "Your Token has expired",
        }
      }
      return {
        success: false,
        message: err.message,
      }
    }
  };


  export const registerFourthPopup = async ({phone,otp,countryCode}:{phone:number,otp:number|undefined,countryCode:string}) => {
    let token=localStorage.getItem('dw-register-token');
    try {
      const response: any = await axios.put(urlUpdated(`/update-otp-verify-pop-up-4/?token=${token}`), {
        phone:phone,
        otp:otp,
        countryCode:countryCode
      });
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        NavigateOnLogin(response.data.message)
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const registerFifthPopup = async () => {
    let token=localStorage.getItem('dw-register-token');
    try {
      const response: any = await axios.put(urlUpdated(`/update-pop-up-5/?token=${token}`));
      if (response.data.success) {
        return { success: true, message: "",token:response.data.token };
      }
       else {
        NavigateOnLogin(response.data.message)
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const getUserDetails = async () => {
    let token=localStorage.getItem('dropworksToken');
    if(token)
    {
    try {
      const response: any = await axios.get(urlUpdated('/get-user-by-id'),{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        return { success: true, message: "" };
      }
       else {
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
     
  }
else{
  return null;
}
  };

  export const updateEmail = async (data:any) => {
 
    try {
        const response = await axios.post(urlUpdated('/send-otp-on-current-email'), data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:""
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const updateEmailOtp = async (data:any) => {
     try {
        const response = await axios.post(urlUpdated('/otp-verify-Current-email'), data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:""
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const updateEmailnewEmail= async (data:any) => {
    try {
       const response = await axios.post(urlUpdated('/otp-send-new-email'), data,
         {
           headers: {
             'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
           },
         });
         if(response.data.success)
         {
           return{
             success:true,
             message:""
           }
         }
         else{
           return{
             success:false,
             message:response.data.message
           }
         }
   } catch (err: any) {
     console.log(err, "error in Login Api");
     return {
       success: false,
       message: err?.response?.data?.message || err?.message,
     }
   }
 };

 export const updateEmailnewEmailOTP = async (data:any) => {
  try {
     const response = await axios.post(urlUpdated('/otp-verify-new-email'), data,
       {
         headers: {
           'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
         },
       });
       if(response.data.success)
       {
         return{
           success:true,
           message:""
         }
       }
       else{
         return{
           success:false,
           message:response.data.message
         }
       }
 } catch (err: any) {
   console.log(err, "error in Login Api");
   return {
     success: false,
     message: err?.response?.data?.message || err?.message,
   }
 }
};
export const addPhoneNumber = async (data:any) => {
  try {
     const response = await axios.put(urlUpdated('/add-phone'), data,
       {
         headers: {
           'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
         },
       });
       if(response.data.success)
       {
         return{
           success:true,
           message:""
         }
       }
       else{
         return{
           success:false,
           message:response.data.message
         }
       }
 } catch (err: any) {
   console.log(err, "error in Login Api");
   return {
     success: false,
     message: err?.response?.data?.message || err?.message,
   }
 }
};

export const verifyPhoneNumber = async (data:{phone:string,otp:string,countryCode:string}) => {
  try {
     const response = await axios.put(urlUpdated('/verify-phone-otp'), data,
       {
         headers: {
           'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
         },
       });
       if(response.data.success)
       {
         return{
           success:true,
           message:""
         }
       }
       else{
         return{
           success:false,
           message:response.data.message
         }
       }
 } catch (err: any) {
   console.log(err, "error in Login Api");
   return {
     success: false,
     message: err?.response?.data?.message || err?.message,
   }
 }
};
export const changePhoneNumber = async (data:any) => {
  try {
     const response = await axios.put(urlUpdated('/change-phone'), data,
       {
         headers: {
           'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
         },
       });
       if(response.data.success)
       {
         return{
           success:true,
           message:""
         }
       }
       else{
         return{
           success:false,
           message:response.data.message
         }
       }
 } catch (err: any) {
   console.log(err, "error in Login Api");
   return {
     success: false,
     message: err?.response?.data?.message || err?.message,
   }
 }
};
export const addSocialLinks = async (data:any) => {
  try {
     const response = await axios.put(urlUpdated('/add-social-links'), data,
       {
         headers: {
           'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
         },
       });
       if(response.data.success)
       {
         return{
           success:true,
           message:""
         }
       }
       else{
         return{
           success:false,
           message:response.data.message
         }
       }
 } catch (err: any) {
   console.log(err, "error in Login Api");
   return {
     success: false,
     message: err?.response?.data?.message || err?.message,
   }
 }
};

export const getBlockedUserList = async () => {
  try {
    const response: any = await axios.get('https://dropworksapi.swapinfotech.com/api/block/getblock-item-list',
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",userData:response.data };
  } else {

      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
    console.log(err, "error in Login Api");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};

export const updateUnBlockList = async (data:string[]) => {
  try {
    const response: any = await axios.post('https://dropworksapi.swapinfotech.com/api/block/unblock-item',{blockedUserId:data},
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",userData:response.data };
  } else {

      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
    console.log(err, "error in Login Api");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
};

export const createTrackProject=async(data: any)=>{
  let id="";
  if (data?.id){
id =`?track_id=${data?.id}`;
}
delete data?.id;
  try {
    const response: any = await axios.put(`https://dropworksapi.swapinfotech.com/api/track/create-track${id}`,data,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",data:response.data.data };
  } else {
 
      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
    console.log(err, "error in Login Api");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
}
export const getAllInvitedUserTrackProject=
async(data: any)=>{
  try {
    const response: any = await axios.get(`https://dropworksapi.swapinfotech.com/api/track/get-all-invited-collabotor-by/:${data}`,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",data:response.data.data };
  } else {
 
      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
}

export const inviteTrackProject=async(data: any)=>{

  try {
    const response: any = await axios.post(`https://dropworksapi.swapinfotech.com/api/invite/sent-track-invite-to-user`,data,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",data:response.data.data };
  } else {
 
      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
    console.log(err, "error in Login Api");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
}

export const updateInvitedCollaboratorsTrackProject=async(data: any)=>{

  try {
    const response: any = await axios.put(`https://dropworksapi.swapinfotech.com/api/track/update-invited-collaborator`,data,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
      },
    });
    if (response.data.success) {
      return { success: true, message: "",data:response.data.data };
  } else {
 
      return {
          success: false,
          message: response.data.message,
      };
  }
  } catch (err: any) {
    console.log(err, "error in Login Api");
    return {
      success: false,
      message: err?.response?.data?.message || err?.message,
    }
  }
}

  export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }


  export const updatePhoneNumber = async (data:any) => {
 
    try {
        const response = await axios.put(urlUpdated('/add-phone'), data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:""
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getUserData = async () => {
    try {
      const response: any = await axios.get(urlUpdated(`/get-user-by-id`),
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return { success: true, message: "",userData:response.data };
    } else {

        return {
            success: false,
            message: response.data.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };





  export const updateUser = async (data:any) => {
 
    try {
        const response = await axios.put(urlUpdated('/update-user-profile'), data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          
        console.log('Display name updated successfully:', response.data);
        return(response)
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const changePassword = async ({data,passwordToken}:{data:changePasswordFormInputs,passwordToken:string}) => {
    try {
      const response = await axios.put(urlUpdated('/change-password'), data,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
        if(response.data.success)
        {
          return{
            success:true,
            message:""
          }
        }
        else{
          return{
            success:false,
            message:response.data.message
          }
        }
  } catch (err: any) {
      console.log(err.message, "error in Login Api");
      return null;
    }
  };

  export const deleteAccount = async ({data,passwordToken}:{data:deleteAccountFormInputs,passwordToken:string}) => {
    try {
      const response = await axios.post(urlUpdated('/deactive-account-by-user'), data,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
        if(response.data.success)
        {
          return{
            success:true,
            message:""
          }
        }
        else{
          return{
            success:false,
            type:response.data.type || "username",
            message:response.data.message
          }
        }
  } catch (err: any) {
      console.log(err.message, "error in Login Api");
      return null;
    }
  };

  export const getSecurityQuestions = async () => {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/securityQuestiones/getAllSecurityQuestions");
      if (response.data.success) {
        return { success: true, message: "",questionData:response.data };
    } else {
 
        return {
            success: false,
            message: response.data.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
 
  export const updateSecurityQuestions = async (payload:any) => {
    try {
        const response = await axios.put("https://dropworksapi.swapinfotech.com/api/securityQuestiones/updateSecurityAnswer",{ "answers": payload.answers },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          }
         );
        console.log('Security Questions updated successfully:', response.data);
        return(response)
    } catch (err: any) {
      console.log(err, "error in updateSecurityQuestions Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const getSecurityQuestionsAnswer = async () => {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/securityQuestiones/getSecurityQuestionsByUserId",
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",questionData:response.data };
    } else {
 
        return {
            success: false,
            message: response.data.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const addBeat = async (payload:any) => {
    try {
        const response = await axios.post("https://dropworksapi.swapinfotech.com/api/beats/create-beat",payload,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          }
         );
        console.log('Beat added successfully:', response.data);
        return(response)
    } catch (err: any) {
      console.log(err, "error in addBeat Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const getReceivedInvite = async () => {
    let token=localStorage.getItem('dropworksToken');
    if(token)
    {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/invite/get-received-invitation",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        return { success: true, data:response.data};
      }
       else {
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
     
  }
else{
  return null;
}
  };

  export const getAllBeatsTrackByUserId = async () => {
    try {
      const response: any = await axios.get(urlUpdated(`/get-all-beats-track-by-user-id`),
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",BeatsTrackData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getAllUsers = async (searchName?:string,searchValue?:string) => {
    let searchUrl=`/get-all-user`;
    if(searchValue && searchName)
    {
      searchUrl=`/get-all-user?${searchName}=${searchValue}`
    }  
        try {
      const response: any = await axios.get(urlUpdated(searchUrl),
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",userData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const getAllUserStatus = async () => {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/chat/get-user-group-combined-list",
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",allUserStatus:response.data};
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const getUserGroupList = async () => {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/chat/get-Group-List",
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",groupsStatus:response.data};
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getUnreadMessageList = async () => {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/chat/get-all-unread-message",
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",unreadMessage:response.data};
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const getAllBeats = async () => {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/track/get-all-beat-search",
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",allBeatsData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


  export const getAllTracks = async () => {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/track/get-all-track-search",
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",allTrackData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getSentInvite = async () => {
    let token=localStorage.getItem('dropworksToken');
    if(token)
    {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/invite/get-sent-invitation",{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        return { success: true, data:response.data};
      }
       else {
        return {
          success: false,
          message: response.data.message,
        };
      }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
     
  }
else{
  return null;
}
  };









  export const inviteStatus = async (data:any) => {
 
    try {
        const response = await axios.put(('https://dropworksapi.swapinfotech.com/api/invite/verify-invitation-by-link'), data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:""
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  
  export const getAllProjects = async () => {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/track/get-all-project-search",
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
        return { success: true, message: "",allProjectData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getBeat = async (data:any) => {
 
    try {
        const response = await axios.get((`https://dropworksapi.swapinfotech.com/api/beats/get-beat-by/${data}`),
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:"",
              data:response.data.data
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getLibraryData = async () => {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/track/get-all-track-project-beats-details",
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {

        return { success: true, message: "",allLibraryData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getFeaturedProjectData = async () => {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/track/get-all-project",
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {

        return { success: true, message: "",featuredProjectData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const oneToOneChatHistroy = async (id:string) => {
    try {
      const response: any = await axios.get(`https://dropworksapi.swapinfotech.com/api/chat/chat-history/${id}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {

        return { success: true, message: "",onChatHistroy:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  
  export const getFeaturedBeatsData = async () => {
    try {
      const response: any = await axios.get("https://dropworksapi.swapinfotech.com/api/track//get-all-hot-beats ",
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {

        return { success: true, message: "",featuredBeatsData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };

  export const getUserById = async (data:any) => {
    try {
      const response: any = await axios.get(`
        https://dropworksapi.swapinfotech.com/api/track/get-track-and-beats-deatils-user-by/${data}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
 
        return { success: true, message: "",featuredBeatsData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };


 export const uploadTrackProject=async(data: any)=>{
    let id="";
    if (data?.trackId){
  id =data?.trackId  ;
  }
    try {
      const response: any = await axios.post(`https://dropworksapi.swapinfotech.com/api/track/upload-collaborator-file/${id}`,data,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return { success: true, message: "",data:response.data.data };
    } else {
   
        return {
            success: false,
            message: response.data.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  }

  export const getTrackProjectBeatsDetailsById = async (data:any) => {
    try {
      const response: any = await axios.get(`https://dropworksapi.swapinfotech.com/api/track/get-track-project-beats-details-by-id/${data}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        }
      );
      if (response.data.success) {
 
        return { success: true, message: "",featuredBeatsData:response.data };
    } else {
        return {
            success: false,
            message: response.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };
  export const getFile = async (data:any) => {
 
    try {
        const response = await axios.get((`https://dropworksapi.swapinfotech.com/api/track/get-track-by/${data}`),
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:"",
              data:response.data.data
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };




  export const updateFevourite = async (obj:any) => {
    let id;
    if (obj.hasOwnProperty('beat_id')) {
      id = `post-fevourite?beat_id=${obj.beat_id}`;
  } else if (obj.hasOwnProperty('track_id')) {
      id = `post-fevourite?track_id=${obj.track_id}`;
  }else{
    id = `post-fevourite?project_id=${obj.project_id}`
  }
    try {
        const response = await axios.put(`https://dropworksapi.swapinfotech.com/api/fevourite/${id}`,{},
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          }
         );
        return(response.data)
    } catch (err: any) {
      console.log(err, "error in updateSecurityQuestions Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };









  export const getMissingStep = async (data:any) => {
 
    try {
        const response = await axios.get((`https://dropworksapi.swapinfotech.com/api/track/get-all-missing-steps/${data}`),
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
            },
          });
          if(response.data.success)
          {
            return{
              success:true,
              message:"",
              data:response.data.data
            }
          }
          else{
            return{
              success:false,
              message:response.data.message
            }
          }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  };








  export const deletAuploadFile=async(data: any)=>{
  delete data?.id;
    try {
      const response: any = await axios.put(`https://dropworksapi.swapinfotech.com/api/track/update-collaborator-file-status`,data,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return { success: true, message: "",data:response.data.data };
    } else {
   
        return {
            success: false,
            message: response.data.message,
        };
    }
    } catch (err: any) {
      console.log(err, "error in Login Api");
      return {
        success: false,
        message: err?.response?.data?.message || err?.message,
      }
    }
  }