import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";
import { getLibraryData } from "../../../shared/utils/apiServices";

export const Library=()=>{
    const [libraryTrack, setLibraryTrack] = useState<string[]>([]);
    const [libraryProject, setLibraryProject] = useState<string[]>([]);
    const [libraryBeats, setLibraryBeats] = useState<string[]>([]);
    
    const navigate=useNavigate();

    useEffect(()=>{
        const fetchAlldata = async () => {
            const result = await getLibraryData();
            if (result) {
                setLibraryTrack(result.allLibraryData.data.trackData)
                setLibraryProject(result.allLibraryData.data.projectData)
                setLibraryBeats(result.allLibraryData.data.beatData)
            }
        };

        fetchAlldata()
    },[])

    const [trackarr, setTrackarr] = useState([
        { heading: "", subheading: "" ,image:"",slugName:"" }
      ]);

    const [projectarr, setProjectarr] = useState([
        { heading: "", subheading: "",image:"",slugName:"" }
      ]);

    const [beatsarr, setBeatsarr] = useState([
        { heading: "", subheading: "" ,image:"",beatName:""}
      ]);

      useEffect(()=>{
        if (Array.isArray(libraryTrack)) {
            setTrackarr(libraryTrack.map((item:any)=>(
          {
         heading:item.beat_name,
         subheading:item.description,
         image:item.art_work_image[0]?.src,
         slugName:item.slug_name
        }
      )))}
      },[libraryTrack])


      useEffect(()=>{
        if (Array.isArray(libraryProject)) {
            setProjectarr(libraryProject.map((item:any)=>(
          {
         heading:item.track_name,
         subheading:item.description,
         image:item.art_work_image[0]?.src,
         slugName:item.slug_name
        }
      )))}
      },[libraryProject])


      useEffect(()=>{
        if (Array.isArray(libraryBeats)) {
            setBeatsarr(libraryBeats.map((item:any)=>(
          {
         heading:item.beat_name,
         subheading:item.description,
         image:item.art_work_image[0]?.src,
         beatName:item.beat_slug_name
        }
      )))}
      },[libraryBeats])

      console.log(trackarr,"trackarrtrackarr")
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({navigateTo,trackarr,projectarr,beatsarr}))
}