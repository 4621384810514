import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import './styles.scss';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import { ButtonComponent } from '../../logo-header/view';
import { DropworksRadioCheckbox } from '../../../../../common/custom-field/dropworks-radio-checkbox';
import { FC } from 'react';
import { modal2Type } from '../popup-modal';
import Loader from '../../loader/view';

const view:FC<modal2Type>  = ({genres,UpdateRole,modal2=false,OpenPopup3,loading=false,message=""}) => {
  return (
    <div
    className="modal fade"
    id="Modal2"
    aria-hidden="true"
    aria-labelledby="Modal2"
    tabIndex={-1}
    data-bs-backdrop="static" 
    data-bs-keyboard="false"   
     data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width-s">
      <div className="modal-content ">
    <div className='modal-cross-icon'>
     {/* <CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton> */}
     </div>
        <div className="modal-header  ps-0 pt-0 uni-pr-72">
          <h1 className="modal-title">Starting out</h1>
        </div>
       
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className=" uni-mt-16 uni-mb-16 ctm-txt">Pick your favorite genres </TextfieldLG>
          <TextfieldXSM className='ctm-daws-text uni-mb-56'>(Select at least three)</TextfieldXSM>
          <div className='d-flex uni-mb-24 investor-box-parent uni-mt-8 '>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Pop music")} >
                    <p className='investor-boxs-under-modal2'>Pop music</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Pop music")} /></p>
               </div>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Rock")}>
                    <p className='investor-boxs-under-modal2'>Rock</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Rock")}/></p>
               </div>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Hip hop music")}>

                    <p className='investor-boxs-under-modal2'>Hip hop music</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Hip hop music")}/></p>
               </div>
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent uni-mt-8 '>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Rhythm and blues")}>
                    <p className='investor-boxs-under-modal2'>Rhythm and blues</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Rhythm and blues")}/></p>
               </div>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Country music")}>
                    <p className='investor-boxs-under-modal2'>Country music</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Country music")}/></p>
               </div>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Indie rock")}>
                    <p className='investor-boxs-under-modal2'>Indie rock</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Indie rock")}/></p>
               </div>
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent uni-mt-8 '>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Electronic dance music")}>
                    <p className='investor-boxs-under-modal2'>Electronic dance music</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Electronic dance music")}/></p>
               </div>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Electronic music")}>
                    <p className='investor-boxs-under-modal2'>Electronic music</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Electronic music")}/></p>
               </div>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Pop rock")}>
                    <p className='investor-boxs-under-modal2'>Pop rock</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Pop rock")}/></p>
               </div>
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent'>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Alternative rock")}>
                    <p className='investor-boxs-under-modal2'>Alternative rock</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Alternative rock")}/></p>
               </div>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Popular music")}>
                    <p className='investor-boxs-under-modal2'>Popular music</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Popular music")}/></p>
               </div>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Heavy metal")}>
                    <p className='investor-boxs-under-modal2'>Heavy metal</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Heavy metal")}/></p>
               </div>

               
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent'>
               <div className='investor-boxs  cursor-pointer' onClick={()=>UpdateRole("Music of Latin America")}>
                    <p className='investor-boxs-under-modal2'>Music of Latin America</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Music of Latin America")}/></p>
               </div>
          </div>
          

        </div>  
        
        <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
        {/* <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> */}
        
        <ButtonComponent id="SecureYourAccount" className='d-none'></ButtonComponent>
        {loading?<Loader />:<DropworksSmallButton  disabled={modal2} className={modal2?"btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions":"btn-wid  modal-btns m-0"} onClick={OpenPopup3}>Next</DropworksSmallButton>}

        {/* <DropworksSmallButton  className="btn-wid m-0">Next</DropworksSmallButton> */}
        </div>
        {message && <p className='text-danger w-100 text-center uni-mt-16'>{message}</p>}

      </div>
    </div>
  </div>
  )
}

export default view;

