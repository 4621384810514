import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksLargeButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';

import './styles.scss';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import userIcon from '../../../../../assets/steps/UserIcon.svg';
import blockedUserIcon from "../../../../../assets/steps/blockUserIcon.svg";
import { ButtonComponent, CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useEffect, useState } from 'react';
import { getBlockedUserList } from '../../../utils/apiServices';
const view = () => {

  return (
    <div
    className="modal fade"
    id="BlockedUser2"
    aria-hidden="true"
    aria-labelledby="BlockedUser2"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered  custom-modal-width">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header  ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">Blocked Users</Heading3>
        </div>
        
        <div className="modal-body uni-mb-56   pb-0 ps-0 pt-0 uni-pr-72">
          <TextfieldLG className=" uni-mt-16 uni-mb-56 ctm-txt">Manage blocked users</TextfieldLG>
          <div className='d-flex uni-mb-24 blocked-user-parent align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-3'>
                 <img src={userIcon} alt="" />
                 <TextfieldSM className='block-username'>#USERNAME</TextfieldSM>
              </div>
               <div>
               <DropworksDisableButton className='unblock-button'>Unblock</DropworksDisableButton>
               </div>
          </div>
          <div className='d-flex uni-mb-24 blocked-user-parent align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-3'>
                 <img src={userIcon} alt="" />
                 <TextfieldSM className='block-username'>#USERNAME</TextfieldSM>
              </div>
               <div>
                 <DropworksDisableButton className='unblock-button'>Unblock</DropworksDisableButton>
               </div>
          </div>
          <div className='d-flex uni-mb-24 blocked-user-parent align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-3'>
                 <img src={blockedUserIcon} alt="" />
                 <TextfieldSM className='block-undo'>#USERNAME</TextfieldSM>
              </div>
               <div>
               <ButtonComponent id="BlockedUser1">
                 <DropworksDisableButton className='undo-button'>Undo</DropworksDisableButton>
                 </ButtonComponent>
               </div>
          </div>
        </div>  
        <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
          <CloseModalButton>
        <DropworksSmallButton  className="btn-wid m-0">Save</DropworksSmallButton>
        </CloseModalButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

