import { FC } from "react";
import { view } from "./view";


interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
}

export const UserLable:FC<ButtonProps>=({...props})=>{
    return view({...props });
}