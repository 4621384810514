import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { ButtonComponent } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useForm, SubmitHandler  } from 'react-hook-form';
import Loader from '../../loader/view';
import { useEffect, useRef, useState } from 'react';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';
import { updateEmailnewEmail } from '../../../utils/apiServices';
import { setNewEmail } from '../../../../../redux/reducer/modal';
import { useDispatch } from 'react-redux';

interface FormValues {
  newEmail: string;
}

const view = () => {
  const buttonRef = useRef<HTMLAnchorElement>(null);

  const handleClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click(); // Programmatically trigger the click
    }
  };
  const { register, handleSubmit, formState: { errors ,isSubmitted ,isValid },reset ,setError} = useForm<FormValues>({ mode: "onChange" });
  const [loading, setLoading] = useState<boolean>(false);
  const [message,setMessage]=useState<string>()
  const dispatch =useDispatch();


  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    console.log(data,"");
    setLoading(true);
    const response: any = await updateEmailnewEmail(data);
    if (response.success) {
      setLoading(false);
      dispatch(setNewEmail(data.newEmail))
      handleClick();
    } else {
      setLoading(false);
      setError("newEmail",{type:"custom",message:response.message})
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  };
  useEffect(()=>{
    setTimeout(()=>{
      reset();
    },1000)
  },[])

  return (
    <ModalWrapper modalId='EmailModal2' onBackdropClick={()=>reset()}>

    <div
    className="modal fade"
    id="EmailModal2"
    aria-hidden="true"
    aria-labelledby="EmailModal2"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">New Email Address</Heading3>
        </div>
   
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className=" uni-mt-16 uni-mb-8 ctm-txt">We will send you a verification code to confirm your new email </TextfieldLG>
          <TextfieldXSM className="ctm-txt2 uni-mb-56">Will require a login</TextfieldXSM>
        
          <DropworksInput className=" " placeholder="Email"  {...register("newEmail", {
            required: "Email is required",
            pattern: {
              value: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
              message: "Invalid email address",
            },
          })}/>
          {errors.newEmail && <p className='text-danger'>{errors.newEmail.message}</p>}
        </div>  
        <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72 uni-mt-56">
        {loading?<Loader />: <><ButtonComponent id="EmailModal1">
        <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 social-link-modal-btns uni-mr-16">Back</DropworksSmallButton>
        </ButtonComponent>
       <DropworksSmallButton  disabled={!!errors.newEmail} className={isValid?"btn-wid  modal-btns m-0":"btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions"}  onClick={handleSubmit(onSubmit)}>Next</DropworksSmallButton></>}

       <ButtonComponent ref={buttonRef} id="EmailModal6" className='d-none'></ButtonComponent>
        </div>

      </div>
    </div>
  </div>
        
  </ModalWrapper>
  )
}

export default view;

