import { useNavigate } from "react-router-dom"
import view from "./view";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSecondFormApi } from "../../../shared/utils/apiServices";
import { useEffect, useRef, useState } from "react";
import { NavigationOnLogin } from "../login";
import { useSelector } from "react-redux";
export const Setting_Up_Page=()=>{
  const uploadedImage =useSelector((state:any)=>state.upload.settingUpImg);
  const setUploadImgAPI=useSelector((state:any)=>state.upload.settingUpImgAPI);
  NavigationOnLogin();
  const [loading,setLoading] =useState(false);
  const [showText,setShowText]=useState(false);
    const schema3 = yup
    .object({ 
        username: yup.string().trim().required("Username is required").min(3, "Username should  have atleast 3 characters"),
        display_name: yup.string().trim().required("Display Name is required").min(3, "Display name should  have atleast 3 characters").max(30, "Display name should not be greater than 30 characters"),
        biography: yup.string().trim().max(80, "Biography should not be greater than 80 characters"),
        profile_image: yup.array().required("Profile Image is required"),
    })

    useEffect(()=>{
      if(setUploadImgAPI?.[0]?.src || uploadedImage)
      {setValue("profile_image",setUploadImgAPI || [uploadedImage] ,{shouldValidate:true})}
    },[setUploadImgAPI])

  const {
    register,
    handleSubmit,
    formState: { errors ,isSubmitted},
    setError,
    setValue
  } = useForm<any>({
    resolver: yupResolver(schema3),
    mode: "onSubmit",
  });

  
  const onSubmit = async (data: any) => {
    setLoading(true);
    console.log(data, "data in filldetails");
    let response: any = await registerSecondFormApi(data);
    if (response?.success) {
   setLoading(false);
 navigate("/verification-complete")
    } else {
      setLoading(false);
      setShowText(false);
      setError(response?.type, { type: "custom", message: response?.message });
    }
  };

    const navigate=useNavigate();
        const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({navigateTo, handleSubmit, onSubmit, errors, register,setValue,loading,showText,isSubmitted,uploadedImage}))
}