import { FC, useEffect, useState } from "react";
import { view } from "./view";
import { getSecurityQuestions, getSecurityQuestionsAnswer, updateSecurityQuestions } from "../../../utils/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { setSecurityQuestions } from "../../../../../redux/reducer/settingsSlice";
import { closeModal } from "../../logo-header/view";




export const SecurityQuestion: FC<any> = () => {
    const dispatch = useDispatch()
    const [initialQuetions, setInitialQuetions] = useState<any>([]);
    const [selectedValues,setSelectedValues]=useState<any>([
        {
          "questionId": "",
          "answer": ""
        },
        {
          "questionId": "",
          "answer": ""
        },
        {
          "questionId": "",
          "answer": ""
        }
      ])
    useEffect(() => {
        const fetchSecurityQuestionsData = async () => {
            const result = await getSecurityQuestions(); // Call your API function
            if (result.success && result.questionData) { // Adjust based on your API response
                dispatch(setSecurityQuestions(result.questionData.data));
            }
        };

        fetchSecurityQuestionsData();
    }, []);

    useEffect(() => {
        const fetchSecurityQuestionsAnswer = async () => {
            const result = await getSecurityQuestionsAnswer(); // Call your API function
            if (result.success && result?.questionData) { // Adjust based on your API response
                const formattedData = result.questionData.data.map((item:any) => ({
                    questionId: item.question,
                    answer: item.answer
                  }));
                setSelectedValues(formattedData)
                setInitialQuetions(formattedData)
            }
        };

        fetchSecurityQuestionsAnswer();
    }, []);

    const openModal=()=>{
        // const formattedData = initialQuetions?.map((item:any)=>({
        //     questionId:item?.questionId,
        //     answer: item?.answer
        // }))
        // setSelectedValues(formattedData)
    }

    const securityQuestiones = useSelector((state: any) => state.settings.securityQuestions);
    function transformQuestionIds(inputData:any, referenceData:any) {
        // Create a map from question text to _id (questionId)
        const questionMap = referenceData.reduce((map:any, item:any) => {
            map[item.question] = item._id;
            return map;
        }, {});
    
        // Replace the question text with the correct _id (questionId) from the reference data
        return inputData.map((item:any) => {
            const questionId = questionMap[item.questionId]; // Find the corresponding questionId (mapped _id)
            if (questionId) {
                return {
                    questionId: questionId, // Replace questionId with real _id
                    answer: item.answer
                };
            } else {
                // If no matching questionId found, return the original item or handle as necessary
                return item;
            }
        });
    }
    
    const handleSubmit = async() => {
      
      const sendData=  transformQuestionIds(selectedValues,securityQuestiones);
      let payload = {
        answers: sendData,
    };
        let response: any = await updateSecurityQuestions(payload);
        if (response) {
            closeModal();
            const result = await getSecurityQuestionsAnswer(); // Call your API function
            if (result.success && result?.questionData) { // Adjust based on your API response
                const formattedData = result.questionData.data.map((item:any) => ({
                    questionId: item.question,
                    answer: item.answer
                }));
                setSelectedValues(formattedData)
                setInitialQuetions(formattedData)
            }
        } else {
            console.log("failLLLLLLLLL");
        }
    }


    return view({ securityQuestiones, handleSubmit,selectedValues,setSelectedValues,openModal});
}
export default SecurityQuestion