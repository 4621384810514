import React, { FC } from "react";
import "./styles.scss";
import { IoCloseCircleOutline } from "react-icons/io5";
import searchIcon from "../../../assets/img/searchIcon.svg" 

import searchCrossIcon from "../../../assets/img/searchCrossIcon.svg"
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  clearInput: () => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue: any;
}

export const view: FC<InputProps> = ({
  inputValue,
  handleInputChange,
  clearInput,
  className = "",
  placeholder = "Search",
  ...rest
}) => {



  return (
    <div className={`custom-search  ${className}`} >
     
        
        <img src={searchIcon} className="custom-search-icon " alt="icon"/>
        
   
      <input
        className="custom-search-input"
        {...rest}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        
      />
      <span className="custom-search-closeicon">
        {inputValue.length > 0 && (
          <img src={searchCrossIcon} className="custom-search-cross-icon"  onClick={clearInput} />
        )}
      </span>
    </div>
  );
};
