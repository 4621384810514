import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CheckAuthentication } from "../../utils/localStorage";
import { setAuthentication } from "../../../../redux/reducer/auth";
import { RootState } from "../../../../redux/store";
import { getUserData } from "../../utils/apiServices";
import { setLoginHistory, setUserData } from "../../../../redux/reducer/userSlice";
export const NewStudioheader=()=>{

    const navigate=useNavigate();
    const dispatch =useDispatch();
    const {authentication :isLoggedin} = useSelector((state:RootState)=>state.auth) || false ;
    const logoutUser=()=>{
        localStorage.removeItem("dropworksToken");
        dispatch(setAuthentication(false))
        }
    useEffect(() => {
     let value= CheckAuthentication();
     dispatch(setAuthentication(value))
    },[]);
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }
    useEffect(() => {
        const fetchUserData = async () => {
            const result = await getUserData(); // Call your API function
            if (result.success && result.userData) { // Adjust based on your API response
                dispatch(setUserData(result.userData.data.userdata));
                dispatch(setLoginHistory(result.userData.data.loginDeviceData)) // Dispatch the action to set user data
            }
        };
    
        fetchUserData();
    }, []);

    const userData = useSelector((state: any) => state.user);
    const {displayName} =userData;

    return(view({navigateTo,isLoggedin,logoutUser,displayName,profileImg:userData.profileImg}))
}