import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllBeatsTrackByUserId } from "../../shared/utils/apiServices";
import { viewType } from "./modal";

export const Profile=()=>{

    
    const navigate=useNavigate();
    const dispatch = useDispatch()
    const [beatsData,setBeatsData] = useState<any>()
    const [trackData,setTrackData] = useState<any>()
    const [recentReleasesData,setRecentReleasesData] = useState<any>()
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    useEffect(() => {
        const fetchAllBeatsTrackBy = async () => {
            const result = await getAllBeatsTrackByUserId();
            if (result.success) {
                setBeatsData(result.BeatsTrackData.data.beatsData)
                setTrackData(result.BeatsTrackData.data.trackData)
                setRecentReleasesData(result.BeatsTrackData.data.recentReleasesdata)
            }
        };
    
        fetchAllBeatsTrackBy();
    }, []);

    const firstName = useSelector((state: any) => state.user.firstname)
    const lastName = useSelector((state: any) => state.user.lastname)
    const userName = useSelector((state: any) => state.user.username)
    const role = useSelector((state: any) => state.user.role)
    const bioGraphy = useSelector((state: any) => state.user.bioGraphy)
    const DWPreferences: any = useSelector((state: any) => state.user.DWPreferences)
    
    return(view({navigateTo,firstName,lastName,userName,role,bioGraphy,DWPreferences,beatsData,trackData,recentReleasesData}))
}