import { FC, useEffect, useState } from "react";
import "./styles.scss";
import { FaExternalLinkAlt } from "react-icons/fa";
import fonticon from "../../../../assets/img/dropworkfonticon.png"
import { FaTiktok } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { MdLanguage } from "react-icons/md";
import globeImg from '../../../../assets/steps/global.svg';
import footermobile from '../../../../assets/steps/Dwfooterlogo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from "../../../../redux/reducer/languageSlice";
import { RootState } from "../../../../redux/store";
import { MeatballsMenuDropdown } from "../../../../common/icons";


export const view: FC<any> = ({ hide, allHide }) => {
    const dispatch = useDispatch();
    const currentLanguage = useSelector((state: RootState) => state.language.language);
  
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedLanguage = e.target.value;
      dispatch(setLanguage(selectedLanguage));
    };
    return (
        <div className={allHide ? "d-none" : "footer  px-0"}>
            <div className={hide ? "d-none" : "footer_first px-0 container container-xxl container-xl  px-0"}>
                <div className="row footer_first_columns px-0">
                    <div className="col-lg-4 col-6 col-md-4 fs-6 footer_first_columns_first px-0">
                        <div className="footer_first_columns_first_heading">Company</div>
                        <div className="footer_first_columns_first_subheading footer-options">About</div>
                        <div className="footer_first_columns_first_subheading  footer-thrid-coll footer-options ">Resource</div>
                        <div className="footer_first_columns_third_heading footer_mobile_view">Support</div>
                        <div className="footer_first_columns_third_subheading footer_mobile_view footer-options">Help Center <span className="footer_first_columns_third_subheading_icons"><FaExternalLinkAlt /></span></div>
                    </div>
                    <div className="col-lg-4 col-6 col-md-4 fs-6 footer_first_columns_second px-0">
                        <div className="footer_first_columns_second_heading">Legal</div>
                        <div className="footer_first_columns_second_subheading footer-options">Terms of Use</div>
                        <div className="footer_first_columns_second_subheading footer-options">Privacy Policy</div>
                        <div className="footer_first_columns_second_subheading footer_mobile_view text-nowrap footer-options">Community Guidlines</div>
                        <div className="footer_first_columns_second_subheading  footer-thrid-coll footer-options">Legal Notice</div>
                        <div className="footer_first_columns_second_subheading footer-options">GDPR</div>
                        <div className="footer_first_columns_second_subheading  footer-thrid-coll footer-options">Security Policy</div>
                        <div className="footer_first_columns_second_subheading  footer-thrid-coll footer-options">Cookie Settings</div>
                    </div>
                    <div className="col-lg-4 col-6 col-md-4 fs-6 footer_first_columns_third px-0 footer-thrid-coll">
                        <div className="footer_first_columns_third_heading">Support</div>
                        <div className="footer_first_columns_third_subheading footer-options">Help Center <span className="footer_first_columns_third_subheading_icons"><FaExternalLinkAlt /></span></div>
                        <div className="footer_first_columns_third_subheading footer-options">Status <span className="footer_first_columns_third_subheading_icons"><FaExternalLinkAlt /></span></div>
                    </div>
                </div>
            </div>
            <hr className="full-width-child footer-hr" />
            <div className="footer_second">
                {/* <div className="d-flex footer_second_first container-new container"> */}
                <div className="d-flex footer_second_first w-100 ">
                    <div className="footer_second_first_texts">
                        <img src={fonticon} className="web-footerlogo" />
                        <img src={footermobile} className="mobile-footerlogo" />
                        <span className="footer_second_first_texts_copyright fs-6"><span className="footer_second_first_texts_copyright_icon">&copy;</span> Copyright 2024 DropWorks Inc. All rights reserved</span>
                    </div>
                    <div className="footer_second_first_icons d-flex">
                    <div className="footer_second_first_icons_icon">
                  <img src={globeImg} />
                  {/* <span className="uni-ml-8"> */}
                <select 
                 value={currentLanguage}
                 onChange={handleChange}
                 className="footer-select-language">
                 <option value="en">English</option>
                 <option value="es">Spanish</option>
                 <option value="pt">Portuguese</option>
                </select>
                {/* </span> */}
                </div>
                        {/* <div className="footer_second_first_icons_english">English</div> */}
                        <div className="footer_second_first_icons_icon"><FaTiktok /></div>
                        <div className="footer_second_first_icons_icon"><FaTwitter /></div>
                        <div className="footer_second_first_icons_icon"><FaInstagram /></div>
                        <div className="footer_second_first_icons_icon"><FaYoutube /></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
