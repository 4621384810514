import './styles.scss'
import { viewType } from "./modal";
import { FC, useEffect, useState } from "react";
import { Heading2 } from '../../../../common/custom-field/dropstock-heading';
import { ButtonComponent } from '../../../shared/components/logo-header/view';
const view:FC<viewType> = ({ navigateTo }) => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1000);
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='create container'>
        <Heading2 className='create-heading uni-mb-32'>Create</Heading2>

        {isWideScreen ? <>
          <div className='create-content'>
         
            <div className='create-content-section1 uni-mb-40 cursor-pointer' onClick={navigateTo("/beat-empty")}><Heading2 className='uni-ml-56 uni-mb-24 mobile-create-heading'>Add a <span>BEAT</span> to my library </Heading2></div>
       
            <div className='create-content-section2 uni-mb-40 cursor-pointer' onClick={navigateTo("/track-project-overview")}><Heading2 className='text-end uni-mr-56  uni-mb-24 mobile-create-heading'>Create a new <span>TRACK</span></Heading2></div>
            <div className='create-content-section3 uni-mb-40 cursor-pointer' onClick={navigateTo("/track-project-overview2")}><Heading2 className='uni-ml-56  uni-mb-24 mobile-create-heading'>Add an already <span>RELEASED TRACK</span> to DropWorks</Heading2></div>
        </div>
        </>:<>
        <div className='create-content'>
          <ButtonComponent id="CreateMobile">
            <div className='create-content-section1 uni-mb-40 cursor-pointer'><Heading2 className='uni-ml-56 uni-mb-24 mobile-create-heading'>Add a <span>BEAT</span> to my library </Heading2></div>
          </ButtonComponent>
          <ButtonComponent id="CreateMobile">
            <div className='create-content-section2 uni-mb-40 cursor-pointer' ><Heading2 className='text-end uni-mr-56  uni-mb-24 mobile-create-heading'>Create a new <span>TRACK</span></Heading2></div>
            </ButtonComponent>
            <ButtonComponent id="CreateMobile">
            <div className='create-content-section3 uni-mb-40 cursor-pointer' ><Heading2 className='uni-ml-56  uni-mb-24 mobile-create-heading'>Add an already <span>RELEASED TRACK</span> to DropWorks</Heading2></div>
            </ButtonComponent>
        </div>
        </>}
       
    </div>
  )
}

export default view