import React from "react";
import view from "./view";
interface CustomDivProps extends React.HTMLAttributes<HTMLDivElement> {
  }

  export  const TextfieldLG:React.FC<CustomDivProps> = ({  ...props }) => {
    return (
        view({...props,className:`${props.className} custom-text-lg`})
    );
  };

  export  const TextfieldMD:React.FC<CustomDivProps> = ({  ...props }) => {
    return (
        view({...props,className:`${props.className} custom-text-md`})
    );
  };

  export  const TextfieldSM:React.FC<CustomDivProps> = ({  ...props }) => {
    return (
        view({...props,className:`${props.className} custom-text-sm`})
    );
  };  

  export  const TextfieldXSM:React.FC<CustomDivProps> = ({  ...props }) => {
    return (
        view({...props,className:`${props.className} custom-text-xsm`})
    );
  };

  