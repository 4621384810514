import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksLargeButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { BlueTik } from "../../../../../common/all-icons";
import hankeymen from "../../../../../assets/img/hankeyman.png";
import tree from "../../../../../assets/img/tree.png";
import { CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";

const view = () => {
  return (
    <div
      className="modal fade"
      id="SellDropStocks3"
      aria-hidden="true"
      aria-labelledby="SellDropStocks3"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width selldropstock3-modal ">
        <div className="modal-content ">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-32">
            <Heading3 className="approve-modal-title mx-0 my-0">Confirm?</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <TextfieldSM className="sell-modal-subtitle uni-mb-32 mb-16-mbl">
              You are about to sell your Dropstocks. This action is permanent
              and non-reversible.
            </TextfieldSM>
            <div className="d-flex align-items-center uni-mb-32">
              <img className="img-fluid custom-tree-img uni-mr-16" src={tree} />
              <h4 className="">
                {" "}
                <span className="sellDropstock-modal-text">Hot Summer</span>
                <TextfieldMD className="d-block m-0 sell-dropstocks-subpara">
                  Unlost, Uncovered, Meyna, Maluma
                </TextfieldMD>
              </h4>
            </div>
            <TextfieldSM className="sell-note-3">
              Please note that you will be selling{" "}
              <span className="sell-dropstock-highlight">800 Dropstocks</span>{" "}
              and will receive{" "}
              <span className="sell-dropstock-highlight">$960.00</span> in your
              account balance.
            </TextfieldSM>
          </div>
          <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton className="buttoncomponent-mobile-modle">
              <DropworksSmallButton className="btn-wid social-link-modal-btns btn-bg-gray3-approve my-0 ml-0">
                Cancel
              </DropworksSmallButton>
            </CloseModalButton>
            <CloseModalButton className="buttoncomponent-mobile-modle">
              <DropworksSmallButton className="btn-wid social-link-modal-btns m-0">
                Confirm
              </DropworksSmallButton>
            </CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
