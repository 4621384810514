import React, { FC, useEffect, useState } from "react";
import { view } from "./view";
import ArrowDown from "../../assets/steps/arrow-down.svg";

interface Option {
  value: string;
  label: string;
}

interface DropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: Option[];
  className?: string;
  placeHolderValue?:string;
  maxheight?:number;
  selectedValue?:string;
  onChangeValue?: (value: string | null) => void; 
}


export const SelectArrowDropdown: FC<DropdownProps> = ({ maxheight ,selectedValue="",options, ...props }) => {

const [value,setValue]=useState<any>("")

useEffect(()=>{
setValue(selectedValue|| "");
},[selectedValue]);

const updateValue=(newValue:string)=>{
  setValue(newValue);
}

  return view({value,updateValue, options,...props})};
