import { FC, useState } from "react";
import  view  from "./view";
import { registerFifthPopup } from "../../../utils/apiServices";



export const AccountSecured:FC<any>=()=>{
const [message,setMessage]=useState<string>("")
    const callFinishApi=async()=>{
        setMessage("Loading")
        const response = await registerFifthPopup();
        console.log(response,"in api of finish")
        if(response.success)
        {

    if(response.token)
    {
        localStorage.setItem('dropworksToken',response.token);
        localStorage.removeItem("dw-register-token");
        window.location.href='/'
    }
        }
        else{
            setMessage(response.message);
            setTimeout(()=>{
                setMessage("")
            },3000)
        }
    }
    return view({callFinishApi,message});
}
export default AccountSecured