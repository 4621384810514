import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksTextArea } from '../../../../../common/custom-field/dropworks-textArea';
import king from '../../../../../assets/img/king.png';
import verfiedIcon from '../../../../../assets/steps/verified-badge.svg';
import { FC } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import download from '../../../../../assets/steps/download.svg';
import { closeModal, CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { BlueTik } from '../../../../../common/all-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { inviteStatus } from '../../../utils/apiServices';
interface viewType {
  navigateTo:(path:string)=>()=>void
} 


const view  :FC<viewType> =({ navigateTo }) => {
  const invite = useSelector((state: RootState) => state.invite)
  const submitData = async (data: { inviteId: string; acceept_status: string }) => {
    let response: any = await inviteStatus(data);
    if (response) {
       closeModal()
      } else {
        console.log("fail");
      }
}
  return (
    <div
      className="modal fade"
      id="ActiveInviteSent"
      aria-hidden="true"
      aria-labelledby="ActiveInviteSent"
      tabIndex={-1}
      data-bs-theme="dark" 
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Invite <span className='invite-received'>Sent</span> </Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh">You have invited @kingroland to participate in your project</TextfieldLG>
            <div className="d-flex align-items-center uni-mb-32 invite_firstcolumn">
              <img src={king} className="invite_firstcolumn_imagebox" alt="" />
              <CloseModalButton>
              <div  onClick={navigateTo("/user-profile")} className='invite-modal-pointer'>
                <h2 className="fw-700 text-light uni-mr-8">{invite.sentINvite[invite.selectInde]?.receiver_name}
                <span> <BlueTik/></span> </h2>
                  <TextfieldSM className='text-light'>@kingroland</TextfieldSM>
              </div>
              </CloseModalButton>
             
            </div>

            <TextfieldSM className=' received-label uni-mb-16'>Project</TextfieldSM>
            <DropworksInput placeholder={invite.sentINvite[invite.selectInde]?.track_id.project_name} readOnly className='uni-mb-32 cstm-inp-invite'></DropworksInput>

            <TextfieldSM className='received-label uni-mb-16 '>Role</TextfieldSM>
            <DropworksInput  placeholder={invite.sentINvite[invite.selectInde]?.role} readOnly className='uni-mb-32 cstm-inp-invite'></DropworksInput>

            <TextfieldSM className=' received-label uni-mb-16 ' >Percentage Offered</TextfieldSM>
            <DropworksInput placeholder={invite.sentINvite[invite.selectInde]?.percentage_offered} className='uni-mb-32 cstm-inp-invite'></DropworksInput>
            <TextfieldSM className='received-label uni-mb-16 '>Message</TextfieldSM>

            <DropworksTextArea className="uni-mb-32 cstm-txt-invite uni-p-16"  hideIcon={true} readOnly placeholder={invite.sentINvite[invite.selectInde]?.message}>
            </DropworksTextArea>
            <TextfieldLG className='text-light uni-mb-16'>Preview</TextfieldLG>
            <div className='d-flex justify-content-between uni-p-16 align-items-center uni-mb-32'>
               <TextfieldSM className='file-name'>File name .mp3</TextfieldSM>
               <TextfieldSM className='file-name'> <img src={download} alt="" className="uni-ml-8" /></TextfieldSM>
            </div>
            <div className=' uni-mb-32'>
              <TextfieldSM className='file-name uni-mb-8'>View <span className='collaboration'>Collaboration Agreement</span></TextfieldSM>
              <TextfieldSM className='received-date '>Sent on {invite.sentINvite[invite.selectInde]?.created_at}</TextfieldSM>
            </div>

          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 invite-cancel-btn" onClick={() => submitData({ inviteId: invite.sentINvite[invite.selectInde]?._id,  acceept_status: "cancel" })}>Cancel Invite</DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;
