import { FC } from "react"
import { viewType } from "./modal"
import "./styles.scss";
import {FaXTwitter} from 'react-icons/fa6';
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import { FcGoogle } from "react-icons/fc";
import { DropworksPassword } from "../../../../common/custom-field/dropworks-password";
import { TextfieldSM, TextfieldXSM } from "../../../../common/custom-field/dropstock-text";
import { Heading2, Heading3 } from "../../../../common/custom-field/dropstock-heading";
import Loader from "../../../shared/components/loader/view";

export const view :FC<viewType> = ({ navigateTo,handleSubmit,onSubmit,errors ,register,loading=false,isSubmitted}) => {
  
  return (<div className="login page-content row align-items-pages container container-xl  container-xxl  pb-3 m-auto h-100 px-0 min-h-800">
    <div className="login_content col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
    <Heading3 className="login_heading my-0">Welcome to DropWorks</Heading3>
   
      <TextfieldSM className="login_normal front-pages-second-heading uni-my-32">
        Use one of the options below to login
      </TextfieldSM>
      <form onSubmit={handleSubmit(onSubmit)} className="needs-validation " >
        <div className="mobile-margin-top">
        <DropworksInput  {...register('email')} disabled={loading} className={`form-control login-specing login-input-first  ${isSubmitted && (errors.email ? 'is-invalid b-red-2' : !errors.email ? 'is-valid  b-green-2' : '')}`} placeholder="Email"/></div>
        {errors.email && (
  <p className="invalid-feedback">
    {errors.email.message}
  </p>)}
        <DropworksPassword className="mb-0 relative login-specing " className3={isSubmitted} className2={`uni-mt-16 form-control login-cstm-pass p-0  ${isSubmitted && (errors.password ? 'is-invalid b-red-2' : !errors.password ? 'is-valid   b-green-2' : '')}`}  disabled={loading}  {...register('password')} />
        {errors.password && <p  className="invalid-feedback text-danger-mobile">{errors.password.message}</p>}
       {!loading && <DropworksButton  type="submit" className="mb-0 uni-mt-32 login_register font-weight-600 ">Login</DropworksButton>}
      </form>
      {loading ? <Loader className="uni-mt-40"/> : <>  <TextfieldSM className="login_or uni-my-16 text-center">OR</TextfieldSM>
        <DropworksButton className="login_register_google uni-mb-32 font-weight-600 "> <FcGoogle className="login_icon" /> Sign in with Google</DropworksButton>

        <p className="login_already_registered uni-mb-24 fs-6">Don’t have an account?   <span className="login_logins"  onClick={navigateTo("/sign-up")}>Register</span></p>
        <p className="login_already_registered  fs-6">Forgot your password? <span className="login_logins"  onClick={navigateTo("/recover-account-email")}>Recover</span></p></>}
    </div>
  </div>
  )
}


