import React from "react";
import view from "./view";
interface CustomRadioButtonProps extends React.HTMLAttributes<HTMLSpanElement> {
  checked?: boolean;
  onChange?:(value:any)=>void;
  disabled?:boolean
}

  export  const DropworksSwitch:React.FC<CustomRadioButtonProps> = ({  ...props }) => {
    return (
        view({...props,className:`${props.className} radiobutton`})
    );
  };

  
   



