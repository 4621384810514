import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { ButtonComponent } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
const view = () => {
  return (
    <div
      className="modal fade"
      id="CompositSubmit"
      aria-hidden="true"
      aria-labelledby="CompositSubmit"
      tabIndex={-1}
      data-bs-theme="dark"
    >
     <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className='Dropworks-select-submit-title'>DropWorks Composite <br />Track Submission </Heading3>
          </div>
          <div className="modal-body uni-mb-56 ps-0 pt-0 pb-0 uni-pr-72 "> 
          <TextfieldLG className='uni-mb-56 Dropworks-select-submit-subtitle'>Select the Drop you want to submit to DropWorks Select</TextfieldLG>
            <div className='content-height'>
              <div className='d-flex uni-p-16  select-track-mobile content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select'>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>(Owned: 800)</span> <DropworksRadioButton />
                </div>
              </div>
              <div className='d-flex uni-p-16  select-track-mobile content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select'>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>(Owned: 800)</span> <DropworksRadioButton />
                </div>
              </div>
              <div className='d-flex uni-p-16  select-track-mobile content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select '>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>Under review</span> 
                </div>
              </div>
              <div className='d-flex uni-p-16  select-track-mobile content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select'>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>(Owned: 800)</span> <DropworksRadioButton />
                </div>
              </div>
              <div className='d-flex uni-p-16  select-track-mobile content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select'>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>(Owned: 800)</span> <DropworksRadioButton />
                </div>
              </div> 
              <div className='d-flex uni-p-16  select-track-mobile content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select'>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>(Owned: 800)</span> <DropworksRadioButton />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <ButtonComponent id="TrackReportModal">
            <DropworksDisableButton className="btn-wid m-0  search-invite-upload custom-btn-upload">
              Next
            </DropworksDisableButton>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  )
}
 
export default view;
 
 