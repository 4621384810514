import { io, Socket } from 'socket.io-client';

let socket: Socket | null = null;

interface MessageData {
  chat_type: number;
  message_to?: string;
  group_id?: string; 
  message: string;
}
export const connectSocket = (token: any): Socket => {
  socket = io(`${process.env.REACT_APP_BASE_URL}`, {  
    query: { token },
    transports: ['websocket'],
  });
 
  socket.on('connect', () => console.log(`Connected: ${socket?.id}`));
  socket.on('connect_error', (err: Error) => console.error('Connection Error:', err));
 
  return socket;
};

export const sendMessage = (messageData: MessageData): void => {
  if (socket) {
    socket.emit('message', JSON.stringify(messageData)); 
    console.log('Message sent', messageData); 
  }
};
export const disconnectSocket = (): void => {
  if (socket) {
    socket.disconnect();
  }
};

export const joinGroup = (groupId: string): void => {
  if (socket) {
    socket.emit('joinGroup', groupId);  
    console.log(`Joined group: ${groupId}`);
  }
};

export const leaveGroup = (groupId: string): void => {
  if (socket) {
    socket.emit('leaveGroup', groupId); 
    console.log(`Left group: ${groupId}`);
  }
};

export const onMessageReceived = (callback: (message: any) => void): void => {
  if (socket) {
    socket.on('message', (message: any) => {
      console.log('Message received:', message);  
      callback(message); 
    });
  } else {
    console.log('Socket not connected'); 
  }
};






