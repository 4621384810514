import { Link, Outlet, useLocation } from "react-router-dom";
import { FC, useRef, ReactNode, useState, useEffect, forwardRef } from "react";
import "./styles.scss";
import { viewType } from "./modal";
import logo from "../../../../assets/img/dropworklogo.png";
import { Footer } from "../footer";
import Modal4 from "../pop-ups/modal-4";
import DisplayNameModal from "../pop-ups/display-name";
import BiographyModal from "../pop-ups/biography-modal";
import SecurityQuestion from "../pop-ups/security-question";
import SecurityQuestion2 from "../pop-ups/security-question 2";
import ChangePasswordModal from "../pop-ups/change-password";
import ChangePasswordModal2 from "../pop-ups/change-password2";
import ChangePasswordModal3 from "../pop-ups/change-password3";
import DeleteAccount from "../pop-ups/delete-account";
import DeleteAccount3Modal from "../pop-ups/delete-account3";
import RegionModal from "../pop-ups/region-modal";
import iconimage from "../../../../assets/img/dropworklogo.png";
import DisplayLanguage from "../pop-ups/display-language";
import InviteAcceptRecieved, {
  ActiveInviteReceived,
} from "../pop-ups/active-invite-received";
import ReportModal1 from "../pop-ups/report-modal-1";
import ThankYouModal from "../pop-ups/thank-you-modal";
import InviteModal from "../pop-ups/invite-modal";
import InviteModal2 from "../pop-ups/invite-modal-2";
import AccountSecured from "../pop-ups/acc-secured";
import SecureYourAccount from "../pop-ups/secure-your-account";
import SocialLink8 from "../pop-ups/social-links-8";
import EmailModal1 from "../pop-ups/email-modal-1";
import EmailModal2 from "../pop-ups/email-modal-2";
import EmailModal6 from "../pop-ups/email-modal-6";
import MobilePhoneModal3 from "../pop-ups/mobile-phone-modal-3";
import MobilePhoneModalA from "../pop-ups/mobile-phone-modal-A";
import MobilePhoneModalB from "../pop-ups/mobile-phone-modal-B";
import ProfilePictureModal from "../pop-ups/profile-picture-modal";
import ProfilePictureModal2 from "../pop-ups/profile-picture-modal-2";
import ReportModal2 from "../pop-ups/report-modal-2";
import ReportModal3 from "../pop-ups/report-modal-3";
import ReportModal4 from "../pop-ups/report-modal-4";
import ReportRecentChatModal from "../pop-ups/report-recent-chat";
import SuccessfullyBlocked from "../pop-ups/successfully-blocked";
import TrackReportModal from "../pop-ups/track-report-reason";
import UserReportReasonModal from "../pop-ups/user-report-reason";
import BeatReportModal from "../pop-ups/beat-report-reason";
import ImpersonatingReportModal from "../pop-ups/impersonating-report-modal";
import AcceptedInviteReceived from "../pop-ups/accepted-invite-received";
import DeclinedInviteReceived from "../pop-ups/declined-invite-received";
import ExpiredInviteReceived from "../pop-ups/expired-invite-received";
import AcceptedInviteSent from "../pop-ups/accepted-invite-sent";
import ActiveInviteSent from "../pop-ups/active-invite-sent";
import DeclinedInviteSent from "../pop-ups/declined-invite-sent";
import ExpiredInviteSent from "../pop-ups/expired-invite-sent";
import RolesModal from "../pop-ups/roles-modal";
import DawsModal from "../pop-ups/daws-modal";
import Modal2 from "../pop-ups/modal-2";
import EmailModal from "../pop-ups/email-modal";
import BlockedUser1 from "../pop-ups/blockuser-1";
import BlockedUser2 from "../pop-ups/blockuser-2";
import BlockedModal from "../pop-ups/blocked-modal";
import Modal1 from "../pop-ups/modal-1";
import SelectTrack from "../pop-ups/select-track";
import SelectBeat from "../pop-ups/select-beat";
import SocialLinksNotification from "../pop-ups/social-links-notification";
import LoginHistorySocials from "../pop-ups/login-history";
import UploadBanner from "../pop-ups/upload-banner";
import UploadBanner2 from "../pop-ups/upload-banner-2";
import SocialLinksMain from "../pop-ups/socail-links-main";
import BeatArtWork from "../pop-ups/beat-artwork";
import ReleasedModal from "../pop-ups/release-modal";
import ApproveFile from "../pop-ups/approve-file";
import DeclineFile from "../pop-ups/decline-file";
import UploadModal from "../pop-ups/upload-modal";
import DepositModal from "../pop-ups/deposit-modal";
import WithdrawModal from "../pop-ups/withdraw-modal";
import InviteModal8 from "../pop-ups/invite-modal-8";
import ChangeAlias from "../pop-ups/change-alias";
import RemoveCollaborator from "../pop-ups/remove-collaborator";
import DeleteFile from "../pop-ups/delete-file";
import ApproveFile2 from "../pop-ups/approve-file-2";
import DeleteChat from "../pop-ups/delete-chat";
import Spline from "@splinetool/react-spline";
import DropWorksSelectSubmit from "../pop-ups/dropworks-select-submit";
import SubMissionThankyou from "../pop-ups/submission-thankyou";
import SellDropStocks from "../pop-ups/sell-dropstocks";
import SellDropStocks3 from "../pop-ups/sell-dropstocks-3";
import DropStocksSelectsubmit3 from "../pop-ups/dropworks-select-submit-3";
import DropWorksCompositSubmit from "../pop-ups/dropworks-composit-submit";
import {
  DropworksSecondaryButton,
  DropworksSmallButton,
} from "../../../../common/custom-field/dropworks-button";
import { IoMenu, IoSearchSharp } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";
import BellIcon from "../../../../assets/steps/bell.svg";
import NotificationIcon from "../../../../assets/steps/envelope.svg";
import UserIcon from "../../../../assets/steps/Group 18414.svg";
import SettingUpPopup from "../pop-ups/setting-up-popup";
import CreateMobile from "../pop-ups/create-mobile";

interface PopUpId {
  popupId?: string;
}

const view: FC<viewType> = ({
  dropAnimationRef,
  navigateTo,
  isLoggedin = false,
  logoutUser,
  profileImg = "",
  displayName,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  const handleClickOutside = (event: any) => {
    // Check if click is outside the sidebar
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for outside clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    // Add/remove the no-scroll and blur effect when menu is open
    if (menuOpen) {
      document.body.classList.add("no-scroll");

      // Apply blur to both elements with IDs 'main-content' and 'main-content2'
      document.getElementById("main-content")?.classList.add("blur");
      document.getElementById("main-content2")?.classList.add("blur");
    } else {
      document.body.classList.remove("no-scroll");

      // Remove blur from both elements
      document.getElementById("main-content")?.classList.remove("blur");
      document.getElementById("main-content2")?.classList.remove("blur");
    }

    // Clean up on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
      document.getElementById("main-content")?.classList.remove("blur");
      document.getElementById("main-content2")?.classList.remove("blur");
    };
  }, [menuOpen]);

  useEffect(() => {
    setMenuOpen(false); // Close the sidebar when the route changes
  }, [location]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const setwidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", setwidth);
    return () => {
      window.removeEventListener("resize", setwidth);
    };
  }, []);
  return (
    <>
<CreateMobile/>
      <Modal4 />
      <ApproveFile2 />
      <DeleteFile />
      <DeclineFile />
      <ApproveFile />
      <SocialLinksMain />
      <UploadBanner2 />
      <UploadBanner />
      <LoginHistorySocials />
      <SocialLinksNotification />
      <AccountSecured />
      <SecureYourAccount />
      <SocialLink8 />
      <EmailModal1 />
      <EmailModal2 />
      <EmailModal6 />
      <MobilePhoneModal3 />
      <MobilePhoneModalA />
      <MobilePhoneModalB />
      <ProfilePictureModal />
      <ProfilePictureModal2 />
      <DisplayNameModal />
      <BiographyModal />
      <SecurityQuestion />
      <SecurityQuestion2 />
      <ChangePasswordModal />
      <ChangePasswordModal2 />
      <ChangePasswordModal3 />
      <DeleteAccount />
      <DeleteAccount3Modal />
      <RegionModal />
      <DisplayLanguage />
      <ActiveInviteReceived />
      <AcceptedInviteReceived />
      <DeclinedInviteReceived />
      <ExpiredInviteReceived />
      <AcceptedInviteSent />
      <ActiveInviteSent />
      <DeclinedInviteSent />
      <ExpiredInviteSent />
      <ReportModal1 />
      <ReportModal2 />
      <ReportModal3 />
      <ReportModal4 />
      <ReportRecentChatModal />
      <SuccessfullyBlocked />
      <ThankYouModal />
      <TrackReportModal />
      <UserReportReasonModal />
      <BeatReportModal />
      <ImpersonatingReportModal />
      <RolesModal />
      <DawsModal />
      <InviteModal />
      <InviteModal2 />
      <Modal2 />
      <EmailModal />
      <BlockedUser1 />
      <BlockedUser2 />
      <BlockedModal />
      <Modal1 />
      <SelectTrack />
      <SelectBeat />
      <BeatArtWork />
      <ReleasedModal />
      <UploadModal />
      <DepositModal />
      <WithdrawModal />
      <InviteModal8 />
      <ChangeAlias />
      <RemoveCollaborator />
      <DeleteChat />
      <DropWorksSelectSubmit />
      <SubMissionThankyou />
      <SellDropStocks />
      <SellDropStocks3 />
      <DropStocksSelectsubmit3 />
      <DropWorksCompositSubmit />
      <ReleasedModal />
      <SettingUpPopup/>
      <div className="task container-fluid px-0">
        {/* <div className="newstudioheader_content logo-header-content">
        <div className="newstudioheader_content_nav">      
          <ul className="newstudioheader_content_nav_sidebar"> 
            <div className="newstudioheader_content_nav_sidebar_logo">
              <li>
                <Link to="/">
                  <img src={iconimage} />
                </Link>
              </li>
              <li >
                <Link to="#">X</Link>
              </li>
            </div>
          
            
            <li>
              <Link to="/marketplace-products">Products</Link>
            </li>
            <li>
              <Link to="#">Trade</Link>
            </li>
            <li>
              <Link to="#">Portfolio</Link>
            </li>
            <li className=" newstudioheader_content_nav_sidebar_links">
              <Link to="#">
                Studio <MdKeyboardArrowDown />
              </Link>
              {isDropdownOpen ? (
                <ul>
                  <li>
                    <a href="#">Featured</a>
                  </li>
                  <li>
                    <a href="#">DropStocks</a>
                  </li>
                  <li>
                    <a href="#">DropOffers</a>
                  </li>
                  <li>
                    <a href="#">Collection</a>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
          
          </ul>
          <div className="newstudioheader_content_nav_navbar">
            <div className="newstudioheader_content_nav_navbar_logo ">
              <li>
                <Link to="/">
                  <img src={iconimage} />
                </Link>
              </li>
            </div>
            {window.location.pathname==='/' &&
            <>
            <ul className="newstudioheader_content_nav_navbar_first ">
            <li className="hideOnMobile newmarketplace_content_nav_navbar_first_links dropdown"><Link to="/marketplace-products">Marketplace <MdKeyboardArrowDown className="uni-ml-8" /></Link>
                                <ul className="newmarketplace_content_nav_navbar_first_links_dropdown dropdown_menu uni-mt-16 p-0">
                                    <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu newmarketplace_content_nav_navbar_first_links-2 dropdown p-0 mini-submenus"><Link to="/marketplace-products"><div  className="uni-p-16" >Product  <MdKeyboardArrowDown className="uni-ml-8" /></div></Link>

                                        <ul className="newmarketplace_content_nav_navbar_first_links_dropdown2 dropdown_menu2 sub-dropdown-ul  pt-0">
                                            <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu2 sub-dropdown-li"><Link to="/marketplace-dropworks-composite">DropWorks Composite</Link></li>
                                            <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu2 sub-dropdown-li"><Link to="/marketplace-dropworks-select">DropWorks Select </Link></li>
                                        </ul>


                                    </li>
                                    <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu newmarketplace_content_nav_navbar_first_links-2 dropdown p-0 mini-submenus"><Link to="#"><div  className="uni-p-16" >Trade  <MdKeyboardArrowDown className="uni-ml-8" /></div></Link>

                                        <ul className="newmarketplace_content_nav_navbar_first_links_dropdown2 dropdown_menu2 sub-dropdown-ul pt-0">
                                            <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu2 sub-dropdown-li"><Link to="/dropworks-composite">DropWorks Composite</Link></li>
                                            <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu2 sub-dropdown-li"><Link to="/dropworks-select">DropWorks Select </Link></li>
                                        </ul>
 

                                    </li>
                                    <li className="newmarketplace_content_nav_navbar_first_links_dropdown_submenu p-0 mini-submenus"><Link to="/personal-portfolio"><div  className="uni-p-16" >Portfolio </div> </Link></li>
                                </ul>
                            </li>
              <li className="hideOnMobile newstudioheader_content_nav_navbar_first_links dropdown">
                <Link to="/featured">
                  Studio <MdKeyboardArrowDown className="uni-ml-8" />
                </Link>
                <ul className="newstudioheader_content_nav_navbar_first_links_dropdown dropdown_menu p-0 w-185">
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li">
                    <Link className=" dropdowns-li" to="/featured">Featured</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li">
                    <Link to="/library" className=" dropdowns-li">Library</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li">
                  <Link to="/invite " className=" dropdowns-li">Invites</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li">
                    <Link to="/create" className=" dropdowns-li">Create</Link>
                  </li>
                </ul>
              </li>
            
              <li className="hideOnMobile">
                <Link to="/personal-portfolio">Bookings </Link>
              </li>
           
              <li className="hideOnMobile" onClick={navigateTo("/search")}>
                <IoSearchSharp className="cursor-pointer" />
              </li>
            </ul>

            <ul className="newstudioheader_content_nav_navbar_second">
              <li className="mar-mr-12">
                <Link to="/notification">
           
                </Link>
              </li>
              <li className="me-0">
                <Link to="/message-chat">
           
                </Link>
              </li>
              <div className="task_header_logoheaderbutton">
         <DropworksSmallButton className="mb-0 task_header_logoheaderbutton_login" onClick={navigateTo("/login")} >Login</DropworksSmallButton>
         <DropworksSecondaryButton className="mb-0 task_header_logoheaderbutton_signup" onClick={navigateTo("/sign-up")}>Signup</DropworksSecondaryButton>
         </div>
              <li className="imgg  newstudioheader_content_nav_navbar_second_links">
                <Link to="#">
                </Link>
              </li>
              <li className="menu-button">
                <Link to="#">
                  <IoMenu />
                </Link>
              </li>
            </ul>
            </>
}
          </div>
        </div>
         </div> */}

        <div className="home-main-nav" id="main-content">
          {window.location.pathname === "/" && (
            <div
              onClick={() => setMenuOpen(true)}
              className="home-nav-sidevar-menu"
            >
              <AiOutlineMenu />
            </div>
          )}
          <span className="home-nav-image">
            <Link to="/">
              <img src={iconimage} />
            </Link>
          </span>
          {window.location.pathname === "/" && (
            <div
              className="home-nav-sidevar-search"
              onClick={navigateTo("/search")}
            >
              <IoSearchSharp className="cursor-pointer" />
            </div>
          )}
          {window.location.pathname === "/" && (
            <>
              <nav className="home-nav">
                <ul className="home-nav-ul">
                  <li>
                    <Link to="/marketplace-products">
                      Marketplace{" "}
                      <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                    </Link>
                    <ul className="home-nav-dropdown">
                      <li>
                        <Link to="#">
                          {" "}
                          <div className="uni-p-18">
                            Product{" "}
                            <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                          </div>
                        </Link>
                        <ul className="home-nav-dropdown2">
                          <li>
                            <Link to="/marketplace-dropworks-composite">
                              DropWorks Composite{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/marketplace-dropworks-select">
                              DropWorks Select{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="#">
                          <div className="uni-p-18">
                            Trade{" "}
                            <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                          </div>
                        </Link>
                        <ul className="home-nav-dropdown2">
                          <li>
                            <Link to="/dropworks-composite">
                              DropWorks Composite{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/dropworks-select">
                              DropWorks Select{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/personal-portfolio">
                          <div className="uni-p-18">Portfolio</div>{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/featured">
                      Studio{" "}
                      <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                    </Link>
                    <ul className="home-nav-dropdown home-nav-hover-effect">
                      <li>
                        <Link to="/featured">
                          {" "}
                          <div className="uni-p-18">Featured </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/library">
                          {" "}
                          <div className="uni-p-18">Library </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/invite">
                          {" "}
                          <div className="uni-p-18">Invites </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/create">
                          {" "}
                          <div className="uni-p-18">Create </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="">Bookings</Link>
                  </li>

                  <li>
                    <Link to="search">
                      <IoSearchSharp className="cursor-pointer" />
                    </Link>
                  </li>
                </ul>
              </nav>

              {isLoggedin ? (
                <div className="nav-right">
                  <ul>
                    <li className="mar-mr-12">
                      <Link to="/notification">
                        <img src={BellIcon} />
                      </Link>
                    </li>
                    <li>
                      <Link to="/message-chat">
                        <img src={NotificationIcon} />
                      </Link>
                    </li>

                    <li className="uni-mx-24 truncate-text ">
                      <Link to="/profile">
                        {displayName || "#USERNAME"}
                      </Link>

                      <ul className="home-nav-dropdown-profile home-nav-hover-effect-profile text-center ml-50">
                        <li>
                          <Link to="/wallet">
                            {" "}
                            <div className="uni-p-18">Wallet </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/profile">
                            {" "}
                            <div className="uni-p-18">Profile </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/profile-setting">
                            {" "}
                            <div className="uni-p-18">Settings </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/favorites">
                            {" "}
                            <div className="uni-p-18">Favorites </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            {" "}
                            <div className="uni-p-18">Demo Account </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="/" onClick={logoutUser}>
                            {" "}
                            <div className="uni-p-18">Logout </div>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/profile">
                        <img
                          className="profile-img"
                          src={profileImg || UserIcon}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="home-nav-buttons">
                  <DropworksSmallButton
                    className="mb-0 home-nav-buttons-login"
                    onClick={navigateTo("/login")}
                  >
                    Login
                  </DropworksSmallButton>
                  <DropworksSecondaryButton
                    className="mb-0 home-nav-buttons-signup"
                    onClick={navigateTo("/sign-up")}
                  >
                    Signup
                  </DropworksSecondaryButton>
                </div>
              )}
            </>
          )}
        </div>
        <div
          className={`home-nav-sidevar ${menuOpen ? "open" : ""}`}
          ref={sidebarRef}
        >
          {isLoggedin ? (
            <div className="home-nav-sidevar-profile">
              <ul>
                <span>
                  <li>
                    <Link to="/profile">
                      <img src={UserIcon} className="user-img-sidevar" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile">
                      {displayName || "#USERNAME"}
                    </Link>
                  </li>
                </span>
                <span>
                  <li>
                    <Link to="/notification">
                      <img src={BellIcon} />
                    </Link>
                  </li>
                  <li>
                    <Link to="/message-chat">
                      <img src={NotificationIcon} />
                    </Link>
                  </li>
                </span>
              </ul>
            </div>
          ) : (
            <div className="home-nav-sidevar-buttons">
              <DropworksSmallButton
                className=" home-nav-sidevar-buttons-login uni-mb-16"
                onClick={navigateTo("/login")}
              >
                Login
              </DropworksSmallButton>
              <DropworksSecondaryButton
                className="mb-0 home-nav-sidevar-buttons-signup"
                onClick={navigateTo("/sign-up")}
              >
                Signup
              </DropworksSecondaryButton>
            </div>
          )}
          <div className="home-nav-sidevar-menu1">
            <ul className="home-nav-sidevar-ul">
              <li>
                <Link to="/marketplace-products" className="font-wh-sidevar">
                  Marketplace
                </Link>
              </li>
              <li>
                <span tabIndex={0}>
                  Products{" "}
                  <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                </span>
                <ul className="home-nav-sidevar-dropdown">
                  <li>
                    <Link to="/marketplace-dropworks-composite">
                      DropWorks Composite
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketplace-dropworks-select">
                      DropWorks Select
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span tabIndex={0}>
                  Trade{" "}
                  <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                </span>
                <ul className="home-nav-sidevar-dropdown">
                  <li>
                    <Link to="/dropworks-composite">DropWorks Composite</Link>
                  </li>
                  <li>
                    <Link to="/dropworks-select">DropWorks Select</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/personal-portfolio">Portfolio </Link>
              </li>
              <li>
                <Link to="/featured" className="font-wh-sidevar">
                  Studio{" "}
                </Link>
              </li>
              <li>
                <Link to="/featured">Featured </Link>
              </li>
              <li>
                <Link to="/library">Library </Link>
              </li>
              <li>
                <Link to="/invite">Invites </Link>
              </li>
              <li>
                <Link to="/create">Create </Link>
              </li>
              <hr className="hr-sidevar" />
              <li className="pt-0">
                <Link to="">Booking </Link>
              </li>
              {isLoggedin ? (
                <li>
                  <Link to="/" onClick={logoutUser}>
                    Logout
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>
        </div>

        <div className="task_outlet d-flex " id="main-content2">
          {windowWidth >= 1000 &&
            (window.location.pathname === "/login" ||
              window.location.pathname === "/sign-up" ||
              window.location.pathname === "/recover-account-email" ||
              window.location.pathname === "/recover-account" ||
              window.location.pathname === "/fill-details") && (
              <Spline scene="https://prod.spline.design/Q6HQEVFAEh4wccrI/scene.splinecode" />
            )}
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default view;

interface ButtonComponentProps {
  id: string;
  name?: string;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
}
interface ButtonComponentPropsClose {
  name?: string;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
}

export const ButtonComponent = forwardRef<HTMLAnchorElement, ButtonComponentProps>(
  ({ id, name, children, className, disabled = false }, ref) => {
    const localRef = useRef<HTMLAnchorElement>(null); // Local ref to handle click if no ref is provided
    const buttonRef = ref || localRef;

  return (
    <a
      ref={buttonRef}
      className={`open-modal m-0  ${className}`}
      data-bs-toggle={disabled ? "" : "modal"}
      href={disabled ? "#" : `#${id}`}
      role="button"
    >
      {children || name}
    </a>
  );
});

export const CloseModalButton = forwardRef<HTMLAnchorElement, ButtonComponentPropsClose>(
  ({ name, children, className }, ref) => {
    const buttonRef = useRef<HTMLAnchorElement>(null);
    const combinedRef = ref || buttonRef; // Use the provided ref or local ref

    return (
      <a
        ref={combinedRef}
        className={`open-modal m-0 ${className}`}
        data-bs-dismiss="modal" // Bootstrap attribute to dismiss the modal
        role="button"
      >
        {children || name} 
      </a>
    );
  }
);

export function closeModal() {
  // Find the open modal element
  const openModal = document.querySelector('.modal.show');
  
  if (openModal) {
    // Find the close button inside the open modal
    const closeButton = openModal.querySelector('[data-bs-dismiss="modal"]');
    
    if (closeButton) {
      // Trigger a click on the close button to close the modal
      (closeButton as HTMLElement).click();
    }
  }
}

export const openPopup=(popUpId:string)=>{
  closeModal();

  const modalElement = document.getElementById(popUpId);
  
  if (modalElement) {
    // Create or retrieve an existing modal instance and open it
    let modalInstance = (window as any).bootstrap.Modal.getInstance(modalElement);
    if (!modalInstance) {
      modalInstance = new (window as any).bootstrap.Modal(modalElement);
    }
    modalInstance.show();
  }
}