import { FC, useEffect, useState } from "react";
import view from "./view";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, updateUser } from "../../../utils/apiServices";
import { setUserData } from "../../../../../redux/reducer/userSlice";



export const DisplayNameModal: FC<any> = () => {
    const dispatch = useDispatch()
    const [userDisplayName , setUserDisplayName] = useState<string>()
    
    const useData = useSelector((state: any) => state.user);
    const {displayName} =useData;
    useEffect(()=>{
        setUserDisplayName(displayName)
    },[displayName])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserDisplayName(e.target.value)
    };

    const openModal=()=>{
        setUserDisplayName(displayName)
    }
    const submitData = async(data: { display_name: string })=>{
        let response: any = await updateUser(data);
        if (response) {
            const result = await getUserData(); // Call your API function
            if (result.success && result.userData) { // Adjust based on your API response
                dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
            }
          } else {
            console.log("failLLLLLLLLL");
          }
    }

   
    return view(
        { userDisplayName , onChange: handleChange ,submitData ,openModal }

    );
}
export default DisplayNameModal


