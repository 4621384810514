import { FC, useEffect, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import { GoPlus } from "react-icons/go";
import {
  DropworksButton,
  DropworksDisableButton,
  DropworksNormalButton,
} from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../common/custom-field/dropstock-text";
import Tabs from "../../../common/tabs";
import { DropworksSelect } from "../../../common/custom-field/dropworks-select";
import { DropworksSwitch } from "../../../common/custom-field/dropworks-switch";
import kingRoland from "../../../assets/img/roland.png";
import { BlueTik, YelloTik } from "../../../common/all-icons";
import { MeatballsMenuDropdown } from "../../../common/icons";
import king from "../../../assets/img/king.png";
import downloadIcon from "../../../assets/steps/download.svg";
import Producer from "../../../assets/img/Producer.png";
import { UserLable } from "../../../common/user-labels";
import treeImage from "../../../assets/img/tree.png";
import rightArrow from "../../../assets/steps/arrow-small-right.svg";
import exclamationTriangle from "../../../assets/steps/exclamation-triangle.svg";
import { ButtonComponent } from "../../shared/components/logo-header/view";
import TabsComponent from "../../../common/tabs-component/view";
import { Heading4, Heading5 } from "../../../common/custom-field/dropstock-heading";
import { SelectArrowDropdown } from "../../../common/selection-dropdown";
import CustomTooltip from "../../../common/custom-tooltip/view";
const view: FC<viewType> = ({ navigateTo }) => {
  const checkTabState = (value: number) => {
    setTabValue(value);
  };

  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState([
    "Change Alias"
  ]);
  const [MeatballsMenuOptions2, setMeatballsMenuOptions2] = useState([
    "Change Alias",
    "Message",
    "Remove"
  ]);

  const [MeatballsMenuOptions3, setMeatballsMenuOptions3] = useState([
    "Delete",
    "Approve",
    "Decline"
  ]);
  const [tabsValue, setTabValue] = useState(0);
  const [showComponent, setShowComponent] = useState(tabsValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(tabsValue);
    }, 300); // Matches the CSS transition duration

    return () => clearTimeout(timeout);
  }, [tabsValue]);

  const options = [
    { value: 'Primary Artist', label: 'Primary Artist' },
    { value: 'Featured Artist', label: 'Featured Artist' },
    { value: 'Hidden', label: 'Hidden' },
  ]

 
  const LanguageOption =[
    { value: 'Afrikaans', label: 'Afrikaans'},
    { value: 'Amharic', label: 'Amharic'},
    { value: 'Bulgarian', label: 'Bulgarian'},
    { value: 'Catalan', label: 'Catalan'},
    { value: 'Chinese (Hong Kong)1', label: 'Chinese (Hong Kong)1'},
    { value: 'Chinese (PRC)', label: 'Chinese (PRC)'},
    { value: 'Chinese (Taiwan)', label: 'Chinese (Taiwan)'},
    { value: 'Czech', label: 'Czech'},
    { value: 'Danish', label: 'Danish'},
    { value: 'Dutch', label: 'Dutch'},
    { value: 'English (UK)', label: 'English (UK)'},
    { value: 'English (US)', label: 'English (US)'},
    { value: 'Estonian', label: 'Estonian'},
    { value: 'Filipino', label: 'Filipino'},
    { value: 'Finnish', label: 'Finnish'},
    { value: 'French (Canada)', label: 'French (Canada)'},
    { value: 'French (France)', label: 'French (France)'},
    { value: 'German', label: 'German'},
    { value: 'Greek', label: 'Greek'},
    { value: 'Hebrew', label: 'Hebrew'},
    { value: 'Hindi', label: 'Hindi'},
    { value: 'Hungarian', label: 'Hungarian'},
    { value: 'Icelandic', label: 'Icelandic'},
    { value: 'Indonesian', label: 'Indonesian'},
    { value: 'Italian', label: 'Italian'},
    { value: 'Japanese', label: 'Japanese'},
    { value: 'Korean', label: 'Korean'},
    { value: 'Latvian', label: 'Latvian'},
    { value: 'Lithuanian', label: 'Lithuanian'},
    { value: 'Malay', label: 'Malay'},
    { value: 'Polish', label: 'Polish'},
    { value: 'Norwegian', label: 'Norwegian'},
    { value: 'Portuguese (Brazil)', label: 'Portuguese (Brazil)'},
    { value: 'Portuguese (Portugal)', label: 'Portuguese (Portugal)'},
    { value: 'Romanian', label: 'Romanian'},
    { value: 'Serbian', label: 'Serbian'},
    { value: 'Slovak', label: 'Slovak'},
    { value: 'Slovenian', label: 'Slovenian'},
    { value: 'Spanish (Latin America)', label: 'Spanish (Latin America)'},
    { value: 'Spanish (Spain)', label: 'Spanish (Spain)'},
    { value: 'Swahili', label: 'Swahili'},
    { value: 'Swedish', label: 'Swedish'},
    { value: 'Thai', label: 'Thai'},
    { value: 'Turkish', label: 'Turkish'},
    { value: 'Ukrainian', label: 'Ukrainian'},
    { value: 'Vietnamese', label: 'Vietnamese'},
    { value: 'Zulu', label: 'Zulu'},

  
  ]

  return (
    <div className="container container-xxl min-height-cover">
      <h1 className="uni-mb-24">Project Name</h1>
      <Tabs
        id="15"
        tabsTitle={["Overview", "Collaborators", "File Center", "Track Details", "Release"]}
        className="uni-mb-40"
        checkTabState={checkTabState}
      />
      <TabsComponent
        tabsValue={tabsValue}
        components={[
          <>
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 p-0 track-overview-col">
                <Heading4 className="uni-mb-16">Overview</Heading4>
                <TextfieldLG className="mt-0">
                  Fill out the general information about this track.{" "}
                </TextfieldLG>
                <TextfieldSM className="fg-color uni-mb-40 mt-0">
                  Note that you can navigate freely between sections at any
                  given time.
                </TextfieldSM>

                <TextfieldLG className="uni-mb-16 mt-0 content-sunheading">
                  Track Name
                </TextfieldLG>
                <DropworksInput placeholder="Name" className="uni-mb-32" />
                <TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Description
                </TextfieldLG>
                <DropworksTextArea
                  className="uni-mb-32 track-textarea"
                  placeholder="Description (Optional)"
                  rows={2}
                  hideIcon={true}

                />
                <TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Genre
                </TextfieldLG>
                <DropworksDisableButton className="custom-select-dropdown track-overview-dropdown uni-mb-56">
              <SelectArrowDropdown
                options={LanguageOption}
                placeHolderValue="Select"
              ></SelectArrowDropdown>
            </DropworksDisableButton>
                <TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Language
                </TextfieldLG>
                <DropworksDisableButton className="custom-select-dropdown track-overview-dropdown uni-mb-56">
              <SelectArrowDropdown
                options={LanguageOption}
                placeHolderValue="Select"
              ></SelectArrowDropdown>
            </DropworksDisableButton>

                <div className="d-flex justify-content-between uni-py-16 uni-mb-40">
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">
                    Contains Explicit Language
                  </TextfieldSM>
                  <DropworksSwitch />
                </div>
                {/* <div className="d-flex justify-content-between uni-py-16 uni-mb-40">
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">
                    Make this project public
                  </TextfieldSM>
                  <DropworksSwitch />
                </div> */}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 d-flex justify-content-end p-0">
                <div>
                  <TextfieldLG className="me-0 ml-auto beat-empty-artwork ">Artwork</TextfieldLG>

                  <div className="beatEmpty_uploadbox2 flex-column mb-3">
                    <ButtonComponent id="trackartwork">
                      <img src={iconImg} alt="Upload Icon" />
                    </ButtonComponent>
                    <label className="mt-4"> Track Artwork</label>
                  </div>
                </div>
              </div>
            </div>
          </>,
          <>
            <div className="">
              <Heading4 className="uni-mb-16">Collaborators</Heading4>
              <TextfieldLG>Manage your team of collaborators here.</TextfieldLG>
              <TextfieldSM className="fg-color uni-mb-40">
                Note that you can navigate freely between sections at any given
                time.
              </TextfieldSM>

              <div className="d-flex justify-content-between align-items-center collaborators-content uni-mb-32">
                <div className="collaborators-content-under pe-0 uni-mb-32">
                  <h2>
                    Producer <YelloTik />
                  </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img src={king} className="img-king uni-mr-16" alt="" />
                    <div className="uni-mr-16">
                      <Heading5 className="king-roland-text">
                        King Roland <BlueTik position="top"/>
                      </Heading5>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                      <SelectArrowDropdown options={options} placeHolderValue="Credit" ></SelectArrowDropdown></DropworksDisableButton>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions}
                      className="custom-metaballs"
                    ></MeatballsMenuDropdown>
                  </div>
                </div>

                <div className="collaborators-content-under pe-0 uni-mb-32">
                  <h2>
                    Vocalist <YelloTik />
                  </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <Heading5 className="king-roland-text">
                        King Roland <BlueTik position="top"/>
                      </Heading5>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                      <SelectArrowDropdown options={options} placeHolderValue="Credit" ></SelectArrowDropdown></DropworksDisableButton>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                      className="custom-metaballs"
                    ></MeatballsMenuDropdown>
                  </div>
                </div>
              </div>

              <div className=" d-flex justify-content-between align-items-center collaborators-content uni-mb-32">
                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <h2 className="king-roland-text">
                    Beat Maker <YelloTik />
                  </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <Heading5>
                        King Roland <BlueTik position="top"/>
                      </Heading5>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                      <SelectArrowDropdown options={options} placeHolderValue="Credit" ></SelectArrowDropdown></DropworksDisableButton>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                      className="custom-metaballs"
                    ></MeatballsMenuDropdown>
                  </div>
                </div>

                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <h2 className="king-roland-text">Lyricist </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <Heading5>
                        King Roland <BlueTik position="top"/>
                      </Heading5>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                      <SelectArrowDropdown options={options} placeHolderValue="Credit" ></SelectArrowDropdown></DropworksDisableButton>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                      className="custom-metaballs"
                    ></MeatballsMenuDropdown>
                  </div>
                </div>
              </div>

              <div className=" d-flex justify-content-between align-items-center collaborators-content uni-mb-32">
                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <h2>Artwork Designer </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <Heading5 className="king-roland-text">
                        King Roland <BlueTik position="top"/>
                      </Heading5>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                      <SelectArrowDropdown options={options} placeHolderValue="Credit" ></SelectArrowDropdown></DropworksDisableButton>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                      className="custom-metaballs"
                    ></MeatballsMenuDropdown>
                  </div>
                </div>

                <div className="collaborators-content-under pe-0 uni-mb-32">
                  <h2>Curator </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <h4 className="king-roland-text">
                        King Roland <BlueTik position="top"/>
                      </h4>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksDisableButton className="custom-selectarrow uni-mr-16">
                      <SelectArrowDropdown options={options} placeHolderValue="Credit" ></SelectArrowDropdown></DropworksDisableButton>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                      className="custom-metaballs"

                    ></MeatballsMenuDropdown>
                  </div>
                </div>
              </div>

              <div className=" d-flex justify-content-between">
                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <ButtonComponent id="SearchInvite">
                    <div className="track-plus-icon">
                      <span className="under-plus-icon">+</span>
                    </div>
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </>,
          <>
            <Heading4 className="uni-mb-16">File Center</Heading4>
            <div className="d-flex">
              <div className="uni-mr-56">
                <TextfieldLG className="mt-0">
                  Manage all files uploaded here.
                </TextfieldLG>
                <TextfieldSM className="fg-color uni-mb-40">
                  Note that you can navigate freely between sections at any
                  given time.
                </TextfieldSM>
              </div>
              <ButtonComponent id="UploadModal">
                <DropworksNormalButton>Upload</DropworksNormalButton>
              </ButtonComponent>
            </div>
            <div className="d-flex justify-content-between file-center-main align-items-center uni-mb-32">
              <div className="producer-main">
                <h2 className="uni-mb-16">
                  Producer <YelloTik />{" "}
                </h2>

                <div className="d-flex align-items-center uni-mb-16 invite_firstcolumn">
                  <img src={Producer} className="img-king" alt="" />
                  <div>
                  <Heading5 className="fw-700 d-flex align-items-center">
                      King Roland <BlueTik className="uni-ml-8"/>{" "}
                    </Heading5>
                  </div>
                </div>
                <div className="producer-border">
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown className="custom-metaballs2"
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown className="custom-metaballs2"
                          arr={MeatballsMenuOptions3} 
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown className="custom-metaballs2"
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown className="custom-metaballs2"
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown className="custom-metaballs2"
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3 m-0">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown className="custom-metaballs2"
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </>,
          <>
          
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 p-0">
                <Heading4 className="uni-mb-16">Track Details</Heading4>
                <TextfieldLG className="mx-0 my-0 fs-color-g3">Fill out the specific information about this track.  </TextfieldLG>
                <TextfieldSM className=" mx-0  uni-mb-32 fg-color">Note that you can navigate freely between sections at any given time.</TextfieldSM>
                <TextfieldMD className="mt-0 uni-mb-40">Fill out the specific information about this track.  </TextfieldMD>

                <TextfieldLG className="mt-0 uni-mb-16">UPC Code</TextfieldLG>
                <DropworksInput placeholder="UPC Code" className="uni-mb-32" />

                <TextfieldLG className="mt-0 uni-mb-16">ISRC Code</TextfieldLG>
                <DropworksInput placeholder="ISRC Code" className="uni-mb-32" />

                <TextfieldLG className="mx-0 my-0">Royalty income records</TextfieldLG>
                <hr />

                <div className="d-flex justify-content-between">
                  <TextfieldSM className="mx-0 my-0">August 2024</TextfieldSM>
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">File name .pdf <span className="uni-ml-16 ">x</span></TextfieldSM>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <TextfieldSM className="mx-0 my-0">July 2024</TextfieldSM>
                  <DropworksNormalButton className="upload-btn my-0">Upload</DropworksNormalButton>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <TextfieldSM className="mx-0 my-0">July 2024</TextfieldSM>
                  <DropworksNormalButton className="upload-btn my-0">Upload</DropworksNormalButton>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <TextfieldSM className="mx-0 my-0">July 2024</TextfieldSM>
                  <DropworksNormalButton className="upload-btn my-0">Upload</DropworksNormalButton>
                </div>
                <hr />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 track-links">
                <TextfieldLG className="uni-mb-16 mt-0 track-links-align">Track Links
                  <CustomTooltip title="Please add the direct links to all platforms where this track has been released." position="right">
                    <RiQuestionFill className="uni-ml-16 track-tooltip" />
                  </CustomTooltip>
                </TextfieldLG>
                <DropworksInput placeholder="Track Links" className="uni-mb-16" />
                <div className="plus-icon2"><GoPlus /></div>
              </div>
            </div>
          </>,
          <>
           <Heading4 className="uni-mb-16">Release</Heading4>
            <TextfieldLG>
              Remember to review everything before releasing the track.{" "}
            </TextfieldLG>
            <TextfieldSM className="fg-color uni-mb-40">
              {" "}
              Note that you can navigate freely between sections at any given
              time.
            </TextfieldSM>

            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <img src={treeImage} className="uni-mr-24" />
                <div>
                  <h1 className="fs-56">Hot Summer </h1>
                  <TextfieldMD className="fc-gray4">
                    Unlost, Uncovered, Meyna, Maluma
                  </TextfieldMD>
                  <UserLable>EDM</UserLable>
                </div>
              </div>
              <ButtonComponent id="ReleaseModal">
                <DropworksNormalButton className="release-btn">
                  Release
                </DropworksNormalButton>
              </ButtonComponent>
            </div>
            <hr className="uni-my-40" />
            <div className="uni-p-40">
              <h2 className="missing-text uni-mb-24">
                <img src={exclamationTriangle} className="uni-mr-16" />
                Missing Steps
              </h2>
              <TextfieldLG className="fc-gray4 uni-mr-8 uni-mb-16 mt-0">
                Credit all collaborators <img src={rightArrow} />
              </TextfieldLG>
              <TextfieldLG className="fc-gray4 uni-mr-8 uni-mb-16 mt-0">
                Upload the final track file <img src={rightArrow} />
              </TextfieldLG>
              <TextfieldLG className="fc-gray4 uni-mr-8 mt-0 mb-0">
                Fill all the base information of the track{" "}
                <img src={rightArrow} />
              </TextfieldLG>
            </div>
          </>
        ]}
      />
    </div>
  );
};
export default view;
