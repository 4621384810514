import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import './styles.scss';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import { CloseModalButton } from '../../logo-header/view';
import { DropworksRadioCheckbox } from '../../../../../common/custom-field/dropworks-radio-checkbox';
import { useState } from 'react';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';

const view = ({DWPreferences,handleSelectChange,selectedItems,submitData}:any) => {
  

  return (
    <div
    className="modal fade"
    id="DawsModal"
    aria-hidden="true"
    aria-labelledby="DawsModal"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content modal-content-boxes-modal ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header  ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">DAWs</Heading3>
        </div>
     
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className=" uni-mt-16 uni-mb-16 ctm-txt">Select the DAWs that you are familiar with </TextfieldLG>
          <TextfieldXSM className='ctm-daws-text'>Choose up to 2</TextfieldXSM>
          <TextfieldXSM className='ctm-daws-text daws-content-mobile-space uni-mb-56'>(Don’t select any if you do not want this do be displayed on your profile)</TextfieldXSM>
          <div className='investor-box-wrapper'>
          <div className='d-flex uni-mb-24 investor-box-parent'>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under'>FL Studio</p>
                    <p className='investor-boxs-radiobutton'>
                    <DropworksRadioCheckbox
                                             checked={selectedItems.includes("FL Studio")}
                                             onChange={(e) => handleSelectChange(e, "FL Studio")}
                                             disabled={selectedItems.length === 2 && !selectedItems.includes("FL Studio")}
                                        />
                      </p>
               </label>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under'>Ableton Live</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox
                                             checked={selectedItems.includes("Ableton Live")}
                                             onChange={(e) => handleSelectChange(e, "Ableton Live")}
                                             disabled={selectedItems.length === 2 && !selectedItems.includes("Ableton Live")}
                                        /></p>
               </label>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under'>Logic Pro </p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox
                                             checked={selectedItems.includes("Logic Pro")}
                                             onChange={(e) => handleSelectChange(e, "Logic Pro")}
                                             disabled={selectedItems.length === 2 && !selectedItems.includes("Logic Pro")}
                                        /></p>
               </label>
               <label className='investor-boxs cursor-pointer investor-mobile-ui'>
                    <p className='investor-boxs-under'>Pro Tools</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox
                                             checked={selectedItems.includes("Pro Tools")}
                                             onChange={(e) => handleSelectChange(e, "Pro Tools")}
                                             disabled={selectedItems.length === 2 && !selectedItems.includes("Pro Tools")}
                                        /></p>
               </label>
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent'>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under'>Pro Tools</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox
                                             checked={selectedItems.includes("Pro Tools")}
                                             onChange={(e) => handleSelectChange(e, "Pro Tools")}
                                             disabled={selectedItems.length === 2 && !selectedItems.includes("Pro Tools")}
                                        /></p>
               </label>
               <label className='investor-boxs cursor-pointer'>
                    <p className='investor-boxs-under'>Cubase</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox
                                             checked={selectedItems.includes("Cubase")}
                                             onChange={(e) => handleSelectChange(e, "Cubase")}
                                             disabled={selectedItems.length === 2 && !selectedItems.includes("Cubase")}
                                        /></p>
               </label>
               <label className='investor-boxs cursor-pointer investor-mobile-ui'>
                    <p className='investor-boxs-under'>Pro Tools</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox
                                             checked={selectedItems.includes("Pro Tools")}
                                             onChange={(e) => handleSelectChange(e, "Pro Tools")}
                                             disabled={selectedItems.length === 2 && !selectedItems.includes("Pro Tools")}
                                        /></p>
               </label>
               <label className='investor-boxs cursor-pointer investor-mobile-ui'>
                    <p className='investor-boxs-under'>Cubase</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox
                                             checked={selectedItems.includes("Cubase")}
                                             onChange={(e) => handleSelectChange(e, "Cubase")}
                                             disabled={selectedItems.length === 2 && !selectedItems.includes("Cubase")}
                                        /></p>
               </label>
               
          </div>
          </div>
          

        </div>  
        
        <div className="modal-footer mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
        {/* <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> */}
        
        <CloseModalButton>
        <DropworksSmallButton  className="btn-wid modal-btns m-0" onClick={()=>submitData({ DW_preferences: selectedItems })}>Save</DropworksSmallButton></CloseModalButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

