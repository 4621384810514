import { FC } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

export const view: FC<any> = ({
    navigateTo,
    image,
    arr,
    className,
    className2,page
}) => {
    const navigate=useNavigate()
    const submitData = async (data:any) => {
      if(page=="library"){
        navigate(`/track-project-overview/${data}`)
      }
      else{
        navigate(`/project-page/${data}`)
      }
        
  }
    return (
        <div className={`row ${className}`}>
            {arr.length>0?<>{arr.map((data:any, index:any) => (
                <div className={`col-6 col-sm-6 col-md-3 col-lg-2 project-icon ${className2}`} onClick={()=>submitData(data.slugName)}>
                    <div className="project-icon-box">
                        {data.image?<img className="project-icon-box" src={`${process.env.REACT_APP_IMGURL}/${data.image}`}/>:""}
                    </div>
                    <div className="project-icon-heading">{data?.heading?.length > 12 ? data.heading.substring(0, 12) + "..." : data.heading}</div>
                    <div className="project-icon-subheading">{data.subheading}</div>
                </div>
            ))}</>:"No Projects Data"}
             
        </div>
    );
};
