import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useEffect } from "react";
import { getReceivedInvite, getSentInvite } from "../../shared/utils/apiServices";
import { useDispatch } from "react-redux";
import { setInvite, setSentInvite } from "../../../redux/reducer/inviteSlice";

export const Invite=()=>{

    
    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }
    const dispatch=useDispatch()


    useEffect(() => {
        const fetchReceviedInvite = async () => {
            const result = await getReceivedInvite(); // Call your API function
            if (result && result) { // Adjust based on your API response
               dispatch(setInvite(result.data.data))
            }
        };
    
        fetchReceviedInvite();
    }, []);

    useEffect(() => {
        const fetchSentInvite = async () => {
            const result = await getSentInvite(); // Call your API function
            if (result && result) { // Adjust based on your API response
               dispatch(setSentInvite(result.data.data))
            }
        };
    
        fetchSentInvite();
    }, []);

    return(view({navigateTo}))
}