import { FC } from "react";
import { view } from "./view";
import ArrowDown from "../../assets/steps/arrow-down.svg"
import ArrowDownGray4 from "../../assets/steps/arrow-down-gray-4.svg"
interface DropdownProps extends React.InputHTMLAttributes<HTMLInputElement> {
    arr: string[];
    icon?:any;
    className?:string;
  height?:string;
  pagename?:string;
}

export const MoreMobile:FC<DropdownProps>=({ arr,pagename,...props})=>{
    return view({ arr,pagename,icon:ArrowDown,...props});
}
