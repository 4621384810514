import { FC, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Link, Outlet, useLocation } from "react-router-dom";
import iconimage from "../../../../assets/img/dropworklogo.png";
import { IoSearchSharp } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoMenu } from "react-icons/io5";
import { Footer } from "../footer";
import BellIcon from "../../../../assets/steps/bell.svg";
import NotificationIcon from "../../../../assets/steps/envelope.svg";
import UserIcon from "../../../../assets/steps/Group 18414.svg";
import beatsImage from "../../../../assets/img/temporary-images/beat-image.svg";
import trackImage from "../../../../assets/img/temporary-images/track-image.svg";
import projectImage from "../../../../assets/img/temporary-images/project-image.svg";
import BeatArtWork from "../pop-ups/beat-artwork";
import InviteModal8 from "../pop-ups/invite-modal-8";
import InviteModal2 from "../pop-ups/invite-modal-2";
import UploadModal from "../pop-ups/upload-modal";
import ReleasedModal from "../pop-ups/release-modal";
import ActiveInviteReceived from "../pop-ups/active-invite-received";
import DeclinedInviteReceived from "../pop-ups/declined-invite-received";
import AcceptedInviteReceived from "../pop-ups/accepted-invite-received";
import ExpiredInviteReceived from "../pop-ups/expired-invite-received";
import AcceptedInviteSent from "../pop-ups/accepted-invite-sent";
import ActiveInviteSent from "../pop-ups/active-invite-sent";
import DeclinedInviteSent from "../pop-ups/declined-invite-sent";
import ExpiredInviteSent from "../pop-ups/expired-invite-sent";
import InviteModal from "../pop-ups/invite-modal";
import BlockedModal from "../pop-ups/blocked-modal";
import SuccessfullyBlocked from "../pop-ups/successfully-blocked";
import ReportModal1 from "../pop-ups/report-modal-1";
import ReportModal2 from "../pop-ups/report-modal-2";
import ReportModal3 from "../pop-ups/report-modal-3";
import ReportModal4 from "../pop-ups/report-modal-4";
import ReportRecentChatModal from "../pop-ups/report-recent-chat";
import ThankYouModal from "../pop-ups/thank-you-modal";
import TrackReportModal from "../pop-ups/track-report-reason";
import UserReportReasonModal from "../pop-ups/user-report-reason";
import BeatReportModal from "../pop-ups/beat-report-reason";
import ImpersonatingReportModal from "../pop-ups/impersonating-report-modal";
import SelectTrack from "../pop-ups/select-track";
import SelectBeat from "../pop-ups/select-beat";
import { AudioPlayer } from "../audio-player";
import RolesModal from "../pop-ups/roles-modal";
import DawsModal from "../pop-ups/daws-modal";
import Modal2 from "../pop-ups/modal-2";
import EmailModal from "../pop-ups/email-modal";
import BlockedUser1 from "../pop-ups/blockuser-1";
import BlockedUser2 from "../pop-ups/blockuser-2";
import Modal1 from "../pop-ups/modal-1";
import DepositModal from "../pop-ups/deposit-modal";
import WithdrawModal from "../pop-ups/withdraw-modal";
import ChangeAlias from "../pop-ups/change-alias";
import RemoveCollaborator from "../pop-ups/remove-collaborator";
import DeleteChat from "../pop-ups/delete-chat";
import DropWorksSelectSubmit from "../pop-ups/dropworks-select-submit";
import SubMissionThankyou from "../pop-ups/submission-thankyou";
import SellDropStocks from "../pop-ups/sell-dropstocks";
import SellDropStocks3 from "../pop-ups/sell-dropstocks-3";
import DropStocksSelectsubmit3 from "../pop-ups/dropworks-select-submit-3";
import DropWorksCompositSubmit from "../pop-ups/dropworks-composit-submit";
import ApproveFile2 from "../pop-ups/approve-file-2";
import DeleteFile from "../pop-ups/delete-file";
import DeclineFile from "../pop-ups/decline-file";
import ApproveFile from "../pop-ups/approve-file";
import SocialLinksMain from "../pop-ups/socail-links-main";
import UploadBanner2 from "../pop-ups/upload-banner-2";
import UploadBanner from "../pop-ups/upload-banner";
import LoginHistorySocials from "../pop-ups/login-history";
import SocialLinksNotification from "../pop-ups/social-links-notification";
import AccountSecured from "../pop-ups/acc-secured";
import SecureYourAccount from "../pop-ups/secure-your-account";
import SocialLink8 from "../pop-ups/social-links-8";
import EmailModal1 from "../pop-ups/email-modal-1";
import EmailModal2 from "../pop-ups/email-modal-2";
import EmailModal6 from "../pop-ups/email-modal-6";
import MobilePhoneModal3 from "../pop-ups/mobile-phone-modal-3";
import MobilePhoneModalA from "../pop-ups/mobile-phone-modal-A";
import MobilePhoneModalB from "../pop-ups/mobile-phone-modal-B";
import ProfilePictureModal from "../pop-ups/profile-picture-modal";
import ProfilePictureModal2 from "../pop-ups/profile-picture-modal-2";
import DisplayNameModal from "../pop-ups/display-name";
import BiographyModal from "../pop-ups/biography-modal";
import SecurityQuestion from "../pop-ups/security-question";
import DisplayLanguage from "../pop-ups/display-language";
import RegionModal from "../pop-ups/region-modal";
import DeleteAccount from "../pop-ups/delete-account";
import DeleteAccount3Modal from "../pop-ups/delete-account3";
import ChangePasswordModal3 from "../pop-ups/change-password3";
import ChangePasswordModal2 from "../pop-ups/change-password2";
import ChangePasswordModal from "../pop-ups/change-password";
import SecurityQuestion2 from "../pop-ups/security-question 2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { setDropworksSelectoptin } from "../../../../redux/reducer/dropworksSelect";
import backgrounduserimg from "../../../../assets/img/background-user-profileimg.png";
import { AiOutlineMenu } from "react-icons/ai";
import { DropworksSecondaryButton, DropworksSmallButton } from "../../../../common/custom-field/dropworks-button";
import CreateMobile from "../pop-ups/create-mobile";
import userBannerImg from "../../../../assets/img/background-user-profileimg.png" ;
import ProjectName from "../pop-ups/project-name";
import BeatArtWorkFirst from "../pop-ups/beat-artwork-first";

export const view: FC<any> = ({ navigateTo ,isLoggedin=false,logoutUser ,displayName,profileImg=""}) => {
  const music = useSelector((state: RootState) => state.music);
  const userData=useSelector((state: RootState)=>state.user)
  const location = useLocation();
  console.log(music, "wdqwdfqwdcwqa");

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  function showSidebar() {
    const sidebar = document.querySelector(
      ".newstudioheader_content_nav_sidebar"
    );
    if (sidebar) {
      sidebar.setAttribute("style", "display: flex;");
    }
  }

  function hideSidebar() {
    const sidebar = document.querySelector(
      ".newstudioheader_content_nav_sidebar"
    );
    if (sidebar) {
      sidebar.setAttribute("style", "display: none;");
    }
  }

  const openSubmenu = () => {
    const button = document.getElementById("neww");
    button &&
      button.addEventListener("click", function () {
        this.classList.toggle("active");
      });
  };
  const dispatch = useDispatch();


  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = (event: any) => {
    // Check if click is outside the sidebar
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for outside clicks
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up event listener on unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    // Add/remove the no-scroll and blur effect when menu is open
    if (menuOpen) {
      document.body.classList.add('no-scroll');
  
      // Apply blur to both elements with IDs 'main-content' and 'main-content2'
      document.getElementById('main-content')?.classList.add('blur');
      document.getElementById('main-content2')?.classList.add('blur');
    } else {
      document.body.classList.remove('no-scroll');
  
      // Remove blur from both elements
      document.getElementById('main-content')?.classList.remove('blur');
      document.getElementById('main-content2')?.classList.remove('blur');
    }
  
    // Clean up on component unmount
    return () => {
      document.body.classList.remove('no-scroll');
      document.getElementById('main-content')?.classList.remove('blur');
      document.getElementById('main-content2')?.classList.remove('blur');
    };
  }, [menuOpen]);

  useEffect(() => {
    setMenuOpen(false); // Close the sidebar when the route changes
  }, [location]);

  return (
    <div className="newstudioheader">
      <CreateMobile/>
      <ApproveFile2 />
      <DeleteFile />
      <DeclineFile />
      <ApproveFile />
      <SocialLinksMain />
      <UploadBanner2 />
      <UploadBanner />
      <LoginHistorySocials />
      <SocialLinksNotification />
      <AccountSecured />
      <SecureYourAccount />
      <SocialLink8 />
      <EmailModal1 />
      <ProjectName/>
      <EmailModal2 />
      <EmailModal6 />
      <MobilePhoneModal3 />
      <MobilePhoneModalA />
      <MobilePhoneModalB />
      <ProfilePictureModal />
      <ProfilePictureModal2 />
      <DisplayNameModal />
      <BeatArtWorkFirst/>
      <BiographyModal />
      <SecurityQuestion />
      <SecurityQuestion2 />
      <ChangePasswordModal />
      <ChangePasswordModal2 />
      <ChangePasswordModal3 />
      <DeleteAccount />
      <DeleteAccount3Modal />
      <RegionModal />
      <DisplayLanguage />
      <ActiveInviteReceived />
      <AcceptedInviteReceived />
      <DeclinedInviteReceived />
      <ExpiredInviteReceived />
      <AcceptedInviteSent />
      <ActiveInviteSent />
      <DeclinedInviteSent />
      <ExpiredInviteSent />
      <ReportModal1 />
      <ReportModal2 />
      <ReportModal3 />
      <ReportModal4 />
      <ReportRecentChatModal />
      <SuccessfullyBlocked />
      <ThankYouModal />
      <TrackReportModal />
      <UserReportReasonModal />
      <BeatReportModal />
      <ImpersonatingReportModal />
      <RolesModal />
      <DawsModal />
      <InviteModal />
      <InviteModal2 />
      <Modal2 />
      <EmailModal />
      <BlockedUser1 />
      <BlockedUser2 />
      <BlockedModal />
      <Modal1 />
      <SelectTrack />
      <SelectBeat />
      <BeatArtWork />
      <ReleasedModal />
      <UploadModal />
      <DepositModal />
      <WithdrawModal />
      <InviteModal8 />
      <ChangeAlias />
      <RemoveCollaborator />
      <DeleteChat />
      <DropWorksSelectSubmit />
      <SubMissionThankyou />
      <SellDropStocks />
      <SellDropStocks3 />
      <DropStocksSelectsubmit3 />
      <DropWorksCompositSubmit />
      <ReleasedModal />
      {window.location.pathname === "/profile"  && (
        <div className="newstudioheader-dynamic-image studio-header newstudioheader-backgrounduserimg"   style={{
          backgroundImage: `url(${
            userData?.bannerImg || userBannerImg
          })`
        }}
        ></div>
      )}
        {(window.location.pathname?.includes("/user-profile"))  && (
        <div className="newstudioheader-dynamic-image studio-header newstudioheader-backgrounduserimg"   style={{
          backgroundImage: `url(${
       userBannerImg
          })`
        }}
        ></div>
      )}
      {/* <div className="newstudioheader_content d-none">
        <div className="newstudioheader_content_nav">
          <ul className="newstudioheader_content_nav_sidebar">
            <div className="newstudioheader_content_nav_sidebar_logo">
              <li>
                <Link to="/home">
                  <img src={iconimage} />
                </Link>
              </li>
              <li onClick={hideSidebar}>
                <Link to="#">X</Link>
              </li>
            </div>
            <li>
              <Link to="/marketplace-products">Products</Link>
            </li>
            <li>
              <Link to="#">Trade</Link>
            </li>
            <li>
              <Link to="#">Portfolio</Link>
            </li>
            <li className=" newstudioheader_content_nav_sidebar_links">
              <Link to="#" onClick={toggleDropdown}>
                Studio <MdKeyboardArrowDown />
              </Link>
              {isDropdownOpen ? (
                <ul>
                  <li>
                    <a href="#">Featured</a>
                  </li>
                  <li>
                    <a href="#">DropStocks</a>
                  </li>
                  <li>
                    <a href="#">DropOffers</a>
                  </li>
                  <li>
                    <a href="#">Collection</a>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
          </ul>
          <div className="newstudioheader_content_nav_navbar">
            <div className="newstudioheader_content_nav_navbar_logo ">
              <li>
                <Link to="/home">
                  <img src={iconimage} />
                </Link>
              </li>
            </div>
            <ul className="newstudioheader_content_nav_navbar_first ">
              <li className="hideOnMobile newstudioheader_content_nav_navbar_first_links_products dropdown">
                <Link to="/marketplace-products">
                  Products <MdKeyboardArrowDown className="uni-ml-8" />
                  <ul className="newstudioheader_content_nav_navbar_first_links_dropdown dropdown_menu p-0  newstudioheader_products">
                    <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile dropdowns-li ">
                      <Link
                        to="/marketplace-dropworks-composite"
                        className=" dropdowns-li  uni-mb-8"
                      >
                        Dropworks Composite
                      </Link>
                    </li>
                    <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li">
                      <Link
                        to="/marketplace-dropworks-select"
                        className=" dropdowns-li"
                      >
                        Dropworks Select
                      </Link>
                    </li>
                  </ul>
                </Link>
              </li>
              <li
                className="hideOnMobile  newstudioheader_content_nav_navbar_first_links_trade dropdown "
                tabIndex={0}
              >
                <Link to="#">
                  Trade <MdKeyboardArrowDown className="uni-ml-8" />
                  <ul className="newstudioheader_content_nav_navbar_first_links_dropdown dropdown_menu p-0   ">
                    <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile dropdowns-li ">
                      <Link
                        to="/dropworks-composite "
                        className=" dropdowns-li uni-mb-8"
                      >
                        DropWorks Composite
                      </Link>
                    </li>
                    <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li">
                      <Link to="/dropworks-select " className=" dropdowns-li">
                        Dropworks Select
                      </Link>
                    </li>
                  </ul>
                </Link>
              </li>
              <li className="hideOnMobile">
                <Link to="/personal-portfolio">Portfolio </Link>
              </li>
              <li className="hideOnMobile newstudioheader_content_nav_navbar_first_links dropdown">
                <Link to="/featured">
                  Studio <MdKeyboardArrowDown className="uni-ml-8" />
                </Link>
                <ul className="newstudioheader_content_nav_navbar_first_links_dropdown dropdown_menu p-0 w-185">
                  <li
                    className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li"
                    onClick={navigateTo("/featured")}
                  >
                    <Link className=" dropdowns-li" to="/featured">
                      Featured
                    </Link>
                  </li>
                  <li
                    className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li"
                    onClick={navigateTo("/library")}
                  >
                    <Link to="/library" className=" dropdowns-li">
                      Library
                    </Link>
                  </li>
                  <li
                    className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li"
                    onClick={navigateTo("/invite")}
                  >
                    <Link to="/invite " className=" dropdowns-li">
                      Invites
                    </Link>
                  </li>
                  <li
                    className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  dropdowns-li"
                    onClick={navigateTo("/create")}
                  >
                    <Link to="/create" className=" dropdowns-li">
                      Create
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="hideOnMobile" onClick={navigateTo("/search")}>
                <IoSearchSharp className="cursor-pointer" />
              </li>
            </ul>

            <ul className="newstudioheader_content_nav_navbar_second">
              <li className="mar-mr-12 a">
                <Link to="/notification">
                  <img src={BellIcon} />
                </Link>
              </li>
              <li className="me-0 a">
                <Link to="/message-chat">
                  <img src={NotificationIcon} />
                </Link>
              </li>
              <li className="mar-mx-24  newstudioheader_content_nav_navbar_second_links">
                <Link to="/user-profile" className="text-white">
                  #USERNAME
                </Link>
                <ul className="newstudioheader_content_nav_navbar_second_links_dropdown dropdown p-0 w-wallet">
                  <li
                    className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile  p-wallet"
                    onClick={navigateTo("/wallet")}
                  >
                    <Link to="/wallet">Wallet</Link>
                  </li>
                  <li
                    className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile  p-wallet"
                    onClick={navigateTo("/user-profile")}
                  >
                    <Link to="/user-profile">Profile</Link>
                  </li>
                  <li
                    className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile   p-wallet"
                    onClick={navigateTo("/profile-setting")}
                  >
                    <Link to="/profile-setting">Settings</Link>
                  </li>
                  <li
                    className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile   p-wallet"
                    onClick={navigateTo("/favorites")}
                  >
                    <Link to="/favorites">Favorites</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile   p-wallet">
                    <a href="#">Demo Account</a>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile  p-wallet ">
                    <a href="#">Logout</a>
                  </li>
                </ul>
              </li>
              <li className="imgg  newstudioheader_content_nav_navbar_second_links">
                <Link to="#">
                  <img src={UserIcon} />
                </Link>
              </li>
              <li className="menu-button" onClick={showSidebar}>
                <Link to="#">
                  <IoMenu />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}




      <div className="home-main-nav" id="main-content">
        <div onClick={() => setMenuOpen(true)} className="home-nav-sidevar-menu">
          <AiOutlineMenu />
        </div>
        <span className="studio-nav-image">
          <Link to="/">
            <img src={iconimage} />
          </Link>
        </span>
        <div className="home-nav-sidevar-search" onClick={navigateTo("/search")}>
          <IoSearchSharp className="cursor-pointer" />
        </div>
        <nav className="home-nav">
          <ul className="home-nav-ul">
            <li><Link to="/marketplace-products">Products <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" /></Link>
              <ul className="home-nav-dropdown home-nav-hover-effect">
                <li><Link to="/marketplace-dropworks-composite"> <div className="uni-p-18 text-nowrap">DropWorks Composite </div></Link></li>
                <li><Link to="/marketplace-dropworks-select"> <div className="uni-p-18 text-nowrap">DropWorks Select  </div></Link></li>
              </ul>
            </li>
            <li><Link to="#">Trade <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" /></Link>
              <ul className="home-nav-dropdown home-nav-hover-effect">
                <li><Link to="/dropworks-composite"> <div className="uni-p-18 text-nowrap">DropWorks Composite </div></Link></li>
                <li><Link to="/dropworks-select"> <div className="uni-p-18 text-nowrap">DropWorks Select  </div></Link></li>
              </ul>
            </li>
            <li><Link to="/personal-portfolio">Portfolio </Link></li>
            <li><Link to="/featured">Studio  <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" /></Link>
              <ul className="home-nav-dropdown home-nav-hover-effect">
                <li><Link to="/featured"> <div className="uni-p-18">Featured </div></Link></li>
                <li><Link to="/library"> <div className="uni-p-18">Library  </div></Link></li>
                <li><Link to="/invite"> <div className="uni-p-18">Invites  </div></Link></li>
                <li><Link to="/create"> <div className="uni-p-18">Create   </div></Link></li>
              </ul>
            </li>
            <li><Link to="/search"><IoSearchSharp className="cursor-pointer" /></Link></li>
          </ul>
        </nav>
        {isLoggedin ?
          <div className="nav-right">
                    <ul>
                        <li className="mar-mr-12">
                            <Link to="/notification">
                                <img src={BellIcon} />
                            </Link>
                        </li>
                        <li >
                            <Link to="/message-chat">
                                <img src={NotificationIcon} />
                            </Link>
                        </li>

                        <li className="uni-mx-24 truncate-text">
                            <Link to="/profile">

                                {displayName || '#USERNAME'}
                            </Link>

                            <ul className="home-nav-dropdown-profile home-nav-hover-effect-profile text-center ml-50">
                                <li><Link to="/wallet"> <div className="uni-p-18">Wallet </div></Link></li>
                                <li><Link to="/profile"> <div className="uni-p-18">Profile  </div></Link></li>
                                <li><Link to="/profile-setting"> <div className="uni-p-18">Settings  </div></Link></li>
                                <li><Link to="/favorites"> <div className="uni-p-18">Favorites   </div></Link></li>
                                <li><Link to="#"> <div className="uni-p-18">Demo Account   </div></Link></li>
                                <li><Link  to="/" onClick={logoutUser}> <div className="uni-p-18">Logout  </div></Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/profile">
                                <img src={profileImg || UserIcon} className="profile-img" />
                            </Link>
                        </li>
                    </ul>
                </div>:<div className="home-nav-buttons">
            <DropworksSmallButton className="mb-0 home-nav-buttons-login" onClick={navigateTo("/login")} >Login</DropworksSmallButton>
            <DropworksSecondaryButton className="mb-0 home-nav-buttons-signup" onClick={navigateTo("/sign-up")}>Signup</DropworksSecondaryButton>
          </div>}
      </div>
      <div className={`home-nav-sidevar ${menuOpen ? "open" : ""}`} ref={sidebarRef}>
      {isLoggedin? <div className="home-nav-sidevar-profile">
          <ul>
            <span>
              <li >
                <Link to="/profile">
                  <img  src={profileImg || UserIcon}  className="user-img-sidevar profile-img"/>
                </Link>
              </li>
              <li >
                <Link to="/profile">
                  {displayName  || '#USERNAME'}

                </Link>
              </li>
            </span>
            <span>
              <li >
                <Link to="/notification">
                  <img src={BellIcon} />
                </Link>
              </li>
              <li >
                <Link to="/message-chat">
                  <img src={NotificationIcon} />
                </Link>
              </li>
            </span>

          </ul>
        </div>:<div className="home-nav-sidevar-buttons">
            <DropworksSmallButton className=" home-nav-sidevar-buttons-login uni-mb-16" onClick={navigateTo("/login")} >Login</DropworksSmallButton>
            <DropworksSecondaryButton className="mb-0 home-nav-sidevar-buttons-signup" onClick={navigateTo("/sign-up")}>Signup</DropworksSecondaryButton>
          </div>}
        <div className="home-nav-sidevar-menu1">
          <ul className="home-nav-sidevar-ul">
            <li><Link to="/marketplace-products" className="font-wh-sidevar">Marketplace</Link></li>
            <li><span tabIndex={0}>Products <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" /></span>
            <ul className="home-nav-sidevar-dropdown">
                  <li><Link to="/marketplace-dropworks-composite">DropWorks Composite</Link></li>
                  <li><Link to="/marketplace-dropworks-select">DropWorks Select</Link></li>
                </ul>
            </li>
            <li><span tabIndex={0}>Trade <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" /></span>
            <ul className="home-nav-sidevar-dropdown">
                  <li><Link to="/dropworks-composite">DropWorks Composite</Link></li>
                  <li><Link to="/dropworks-select">DropWorks Select</Link></li>
                </ul>
                </li>
            <li><Link to="/personal-portfolio">Portfolio </Link></li>
            <li><Link to="/featured" className="font-wh-sidevar">Studio </Link></li>
            <li><Link to="/featured">Featured </Link></li>
            <li><Link to="/library">Library </Link></li>
            <li><Link to="/invite">Invites </Link></li>
            <li><Link to="/create">Create </Link></li>
            <hr className="hr-sidevar" />
            <li className="pt-0"><Link to="/wallet">Wallet </Link></li>
            <li ><Link to="/profile">Profile </Link></li>
            <li ><Link to="/profile-setting">Settings </Link></li>
            <li ><Link to="/favorites">Favorites </Link></li>
            <li ><Link to="#">Demo Account </Link></li>
            <li ><Link to="/" onClick={logoutUser}>Logout </Link></li>
          </ul>
        </div>
      </div>

      <div
        className={`newstudioheader_outlet ${(window.location.pathname === "/user-profile" || window.location.pathname === "/profile")
          ? "newstudioheader-profile-outlet"
          : ""
          }`} id="main-content2"
      >
        <Outlet />
        <Footer />
      </div>
      {music.Data && (
        <>
          <div
            className="d-flex  w-100 "
            style={{ background: "rgb(0 0 0)", height: "97px" }}
          ></div>
          <AudioPlayer />
        </>
      )}
    </div>
  );
};
