import { useNavigate } from "react-router-dom";
import { view } from "./view";
import { useEffect, useState } from "react";
import { getFeaturedBeatsData, getFeaturedProjectData } from "../../shared/utils/apiServices";

const MarketplaceFeatured =()=>{
    const navigate=useNavigate();
    const [featuredProject, setFeaturedProject] = useState<string[]>([]);
    const [featuredBeats, setFeaturedBeats] = useState<string[]>([]);

    const [projectarr, setProjectarr] = useState<any>([
        { heading: "", subheading: "",image:"",slugName:"" }
      ]);

    const [beatsarr, setBeatsarr] = useState<any>([
        { heading: "", subheading: "" ,image:"",beatName:""}
      ]);


    useEffect(()=>{
        const fetchProjectData = async () => {
            const result = await getFeaturedProjectData();
            if (result) {
                setFeaturedProject(result.featuredProjectData.data)
            }
        };
        const fetchBeatData = async () => {
            const result = await getFeaturedBeatsData();
            if (result) {
                console.log(result.featuredBeatsData.data,"this-=-=-=-")
                setFeaturedBeats(result.featuredBeatsData.data)
            }
        };

        fetchBeatData()
        fetchProjectData()
    },[])

    useEffect(()=>{
        if (Array.isArray(featuredProject)) {
            setProjectarr(featuredProject.map((item:any)=>(
          {
         heading:item.beat_name,
         subheading:item.description,
         image:item.art_work_image[0].src,
         slugName:item.slug_name
        }
      )))}
    },[featuredProject])

    useEffect(()=>{
        if (Array.isArray(featuredBeats)) {
            setBeatsarr(featuredBeats.map((item:any)=>(
          {
         heading:item.beat_name,
         subheading:item.description,
         image:item.art_work_image[0].src,
         beatName:item.beat_slug_name
        }
      )))}
    },[featuredBeats])

    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({navigateTo,projectarr,beatsarr}))
}

export default MarketplaceFeatured;