import { useNavigate } from "react-router-dom"
import { view } from "./view"
interface CustomDivProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onChangeCountry?:(data:any)=>void
  }
export const DropworksDropdownCountry:React.FC<CustomDivProps> =({onChangeCountry,...props})=>{


    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({onChangeCountry,...props}))
}