const englishdict = {
    ETH: 'ETH',
    TRANSACTIONS: 'Unleash Music’s Potential',
    STREAMING_LINKS: 'Streaming Links',
    APPROVE: 'Approve',
    REJECT: 'Reject',
    DISABLED: 'Disabled',

};

export default englishdict;
