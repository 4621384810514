import React from "react";
import view from "./view";
interface CustomSpanProps extends React.HTMLAttributes<HTMLSpanElement> {}

  export  const Heading0:React.FC<CustomSpanProps> = ({  ...props }) => {
    return (
        view({...props,className:`${props.className} heading0`,headingtype:0})
    );
  };

  export  const Heading1:React.FC<CustomSpanProps> = ({  ...props }) => {
    return (
        view({...props,headingtype:1})
    );
  };

  export  const Heading2:React.FC<CustomSpanProps> = ({  ...props }) => {
    return (
        view({...props,headingtype:2})
    );
  };  

  export  const Heading3:React.FC<CustomSpanProps> = ({  ...props }) => {
    return (
        view({...props,headingtype:3})
    );
  };

   export  const Heading4:React.FC<CustomSpanProps> = ({  ...props }) => {
    return (
        view({...props,headingtype:4})
    );
  };   
   export  const Heading5:React.FC<CustomSpanProps> = ({  ...props }) => {
    return (
        view({...props,headingtype:5})
    );
  };
   
  export  const Heading6:React.FC<CustomSpanProps> = ({  ...props }) => {
    return (
        view({...props,headingtype:6})
    );
  };


