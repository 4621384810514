import {
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksLargeButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from "../../logo-header/view";
import "./styles.scss";
import tree from "../../../../../assets/img/tree.png";
import { DropworksInputMarketplace } from "../../../../../common/custom-field/dropworks-input-marketplace";
import { ButtonComponent } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";

const view = () => {
  return (
    <div
      className="modal fade"
      id="SellDropStocks"
      aria-hidden="true"
      aria-labelledby="SellDropStocks"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width sell-dropstock-modal">
        <div className="modal-content ">
          <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header sell-dropstocks-header ps-0 pt-0 uni-pr-72 uni-mb-32">
            <Heading3 className="approve-modal-title mx-0 my-0">Sell Dropstocks</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 ">
            <TextfieldSM className="sell-stocks uni-mb-32 mb-16-mbl">
              Congratulation, your Dropstocks qualify to be sold. Please check
              the offer made by our team after thorough analysis. You may sell
              your Dropstocks at this time or comeback later.
            </TextfieldSM>
            <div className="d-flex align-items-center uni-mb-32">
              <img className="img-fluid custom-tree-img uni-mr-16" src={tree} />
              <h4 className="">
                {" "}
                <span className="sellDropstock-modal-text">Hot Summer</span>
                <TextfieldMD className="d-block m-0 sell-dropstocks-subpara">
                  Unlost, Uncovered, Meyna, Maluma
                </TextfieldMD>
              </h4>
            </div>
              <DropworksInputMarketplace
                placeholder="1.2"
                className="price-input-field"
              />
              <div className="uni-mt-8 uni-mb-16">
              <DropworksInputMarketplace
                placeholder="Quantity"
                unit="DropStocks"
                className="quantity-inupt-placeholder"
              />
         </div>
            <div className="uni-mb-16">
            <DropworksInputMarketplace
              placeholder="Total"
              unit="USD"
              className=" quantity-inupt-placeholder"
            />
            </div>
            <div className="d-flex justify-content-between align-items-center uni-mb-16">
              <TextfieldXSM className="m-0 sell-dropstocks-available">
                Available
              </TextfieldXSM>
              <TextfieldXSM className="m-0 available-stocks">
                800 Dropstocks
              </TextfieldXSM>
            </div>
            <ButtonComponent id="SellDropStocks3">
              <DropworksLargeButton className="sell-dropstock-sellbtn">
                Sell
              </DropworksLargeButton>
            </ButtonComponent>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72 uni-mt-16"></div>
        </div>
      </div>
    </div>
  );
};

export default view;
