import { FC } from "react";
import  view  from "./view";
import { useNavigate } from "react-router-dom";



export const AcceptedInviteReceived:FC<any>=()=>{
    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        console.log(value,"all value")
        navigate(value)
    }

    return(view({navigateTo}))
}
export default AcceptedInviteReceived