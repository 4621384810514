import React, { FC } from "react";
import "./styles.scss";
import playmusic from "../../assets/img/play-music-icon.png"
import { getBeat } from "../../app/shared/utils/apiServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBeat } from "../../redux/reducer/beatSlice";
export const view: FC<any> = ({
  navigateTo,
  BeatesIcon,
  arr,
  className,
  className2
}) => {
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const submitData = async (data:any) => {
    // let response: any = await getBeat(data);

    // if (response) {
    //   dispatch(setBeat(response.data))
    //   console.log(response.data[0].beat_slug_name,"reapose=========================")
    //   const slugName = response.data[0].beat_slug_name
      navigate(`/beats-page/${data}`)
      // } else {
      //   console.log("fail");
      // }
}
  return (
    <div className={`row ${className}`}>
      {arr.length>0?<>{arr.map((data: any, index: any) => (
        <div className={`col-6 col-sm-6 col-md-3 col-lg-2 BeatesIcon-icon ${className2}`}>
          <div className="BeatesIcon-icon-box" onClick={()=>submitData(data.beatName)}>
            {data.image?<img className="BeatesIcon-icon-box" src={`${process.env.REACT_APP_IMGURL}/${data.image}`}/>:""}
            <img className="BeatesIcon-icon-box-icon" src={playmusic} />
          </div>
          <div className="BeatesIcon-icon-heading">{data?.heading?.length > 12 ? data.heading.substring(0, 12) + "..." : data.heading}</div>
          <div className="BeatesIcon-icon-subheading">{data.subheading}</div>
        </div>
      ))}</>:"No Beats Data"}
    </div>
  );
};
