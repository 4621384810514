import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BeatSlice {
   Data:any,
  }

const initialState : BeatSlice = {
  Data:"", 

};

const beatSlice = createSlice({
  name: "invite",
  initialState,
  reducers: {
    setBeat: (state, action: PayloadAction<any>) => {
      state.Data = action.payload; 
    }
  },
});


export const { setBeat} = beatSlice.actions;


export const beatReducer = beatSlice.reducer;
