import { FC, useEffect, useState } from "react";
import "./styles.scss";
import { UserIcon } from "../../../common/user-icon";
import { TrackIcon } from "../../../common/track-icon";
import { BeatesIcon } from "../../../common/beats-icon";
import { DropworksDropdown } from "../../../common/custom-field/dropworks-dropdown";
import { ProjectIcon } from "../../../common/project-icon";
import userImage from "../../../assets/img/Profile-pics/kingroland-image.png";
import blueTik from "../../../assets/img/Profile-pics/Subtract.svg";
import backBtn from "../../../assets/img/icons/backBtn.svg";
import { viewType } from "./modal";
import { UserLable } from "../../../common/user-labels";
import {
  DropworksButton,
  DropworksDisableButton,
  DropworksSmallButton,
  GradientActiveBtn,
} from "../../../common/custom-field/dropworks-button";
import { ArrowDropdown, ArrowDropdownGray4, MeatballsMenuDropdown } from "../../../common/icons";
import { SelectArrowDropdown } from "../../../common/selection-dropdown";
import { ButtonComponent } from "../../shared/components/logo-header/view";
import { Heading3 } from "../../../common/custom-field/dropstock-heading";
import CustomTooltip from "../../../common/custom-tooltip/view";
import { BlueTik } from "../../../common/all-icons";
import { TextfieldXSM } from "../../../common/custom-field/dropstock-text";
import { MoreMobile } from "../../../common/moreMobile";
import { useSelector } from "react-redux";


export const view: FC<viewType> = ({ navigateTo,userData,beatsarr,recentReleasesarr,trackarr}) => {
  const [isFollowing, setIsFollowing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const publicProfile = useSelector((state: any) => state.user.profileImg);
  const handleClick = () => {
    setIsFollowing((prev) => !prev);
  };

  const handleMouseEnter = () => {
    if (isFollowing) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [userarr, setUserarr] = useState([
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
  ]);

  const [linksArray, setLinkAyyay] = useState<any>([
    // {
    //   name: "",
    //   link: "",
    // },
  ]);
  useEffect(()=>{
    if(userData?.social_links?.length>0){
      setLinkAyyay(userData?.social_links.map((data:any)=>(
        data.name
      //   {
      //   name:data.name,
      //   link:data.link,
      // }
    )))
    }
  },[userData])
  
  console.log(linksArray,"linkArray")

  const [dropdownarrRoles, setDropdownarrRoles] = useState({
    heading: "Roles",
    option: [
      "Investor",
      "Producer",
      "Vocalist",
      "Lyricist",
      "Beat Maker",
      "Curator",
      "Artwork Designer",
    ],
  });
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState(
    [
      "Block",
      "Report",
    ]
  );

  const [dropdownarrTracks, setDropdownarrTracks] = useState({
    heading: "Genre",
    option: ["Genre", "Genre", "Genre", "Genre", "Genre", "Genre", "Genre"],
  });

  const [projectarr, setProjectarr] = useState([
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
  ]);

  const [button, setButton] = useState("user-profile");

  const handleButtonClick = (type: string) => {
    setButton(type);
  };
  const options = [
    { value: 'Spotify', label: 'Spotify' },
    { value: 'Apple Music', label: 'Apple Music' },
    { value: 'Deezer', label: 'Deezer' },
    { value: 'Tidal', label: 'Tidal' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Youtube', label: 'Youtube' },
  ]


  return (
    <div className="user-profile container container-xxl min-height-cover">
      <div className={`user-profile-body ${button === "tracks" || button === "beats" ? "user-profile-body-h100" : ""}`}>
        <div>
          <img
            src={`${process.env.REACT_APP_IMGURL}/${userData?.profile_image[0]?.src}`||userImage}
            className="user-profile-image"
            alt="user-image"
          />
        </div>
        <div className="user-profile-content">
          <div className="w-100">
            <div className="user-profile-details">
              <Heading3 className="uni-mr-8 mb-0">{userData?.firstname}{" "}{userData?.lastname}</Heading3>
                <BlueTik />
            </div>
            {userData?.username && <div className="user-profile-id">@{userData?.username}</div>}
          </div>
          { button === "user-profile" ? (
            <>
              <div className="d-flex flex-wrap gap-3 mt-2 all-lables">
                {userData?.role?.map((role:any)=>(
                <UserLable className="user-label-style user-label-profile">{role}</UserLable>
                ))}
                {
                  userData?.DW_preferences?.length > 0 ? userData?.DW_preferences.map((DWPreferences:any)=>(
                    <UserLable className="user-label-style user-label-profile">{DWPreferences}</UserLable>
                  )) : ""
                }
                {/* <UserLable className="user-label-style user-label-profile">Investor</UserLable>
                <UserLable className="user-label-profile">Producer</UserLable>
                <UserLable className="user-label-profile">Vocalist</UserLable>
                <UserLable className="user-label-profile">Lyricist</UserLable>
                <UserLable className="user-label-profile">Beat Maker</UserLable>
                <UserLable className="user-label-profile">Curator</UserLable>
                <UserLable className="user-label-profile">Artwork Designer</UserLable>
                <UserLable className="user-label-profile">FL Studio</UserLable> */}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      { button === "user-profile" ? (
        <>
          <div className="d-flex gap-3  uni-mt-32 user-profile-bottons mobile-mt-0">
            <DropworksDisableButton
              className={`follow-button user-profiles-follow-buttons ${isFollowing ? "following" : "follow"} ${isHovered && isFollowing ? "hover" : ""
                }`}
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >  {isFollowing ? (isHovered ? "Unfollow" : "Following") : "Follow"}</DropworksDisableButton>
            {/* please add dropdown */}
            <DropworksSmallButton className="user-profiles-select-buttons uni-bg-disable"    data-bs-toggle="dropdown">

              <TextfieldXSM className="m-0">Socials</TextfieldXSM>
              <ArrowDropdownGray4 pagename="user-profile"
                className="px-0 "
                 arr={linksArray}
              />


            </DropworksSmallButton>
            <DropworksSmallButton className="user-profiles-Message-buttons uni-bg-disable" onClick={navigateTo("/message-chat2")}>Message</DropworksSmallButton >
            <div className="d-flex align-items-center justify-content-center mobile-arrow-track position-relative">
              
              <span className="uni-mr-8">  More</span>
              <MoreMobile 
                                  className="px-0 mobile-p-0"
                                  arr={["Block","Report"]}
                                />
              </div>
            <ButtonComponent id="InviteModal"  className="invite-disabled-btn-mbl">  <DropworksSmallButton className="invite-btn-w user-profiles-invite-buttons  invite-btn-disable-mbl">Invite</DropworksSmallButton></ButtonComponent>
            <span className="convert-more d-flex align-items-center">
            <MeatballsMenuDropdown arr={MeatballsMenuOptions}
              height={"9px"}></MeatballsMenuDropdown>
              </span>
          </div>
        </>
      ) : (
        ""
      )}
      <div className="user-profile-button d-flex flex-wrap mb-4">
        {button === "tracks" || button === "beats" ? (
          <>
            <button
              className={`user-profile-button-backBtn`}
              onClick={() => handleButtonClick("user-profile")}
            >
              <img
                className="user-profile-button-image"
                src={backBtn}
                alt="backBtn"
              />
            </button>
            <button
              className={`user-profile-button-user-profile ${button === "tracks" ? "active" : ""
                }`}
              onClick={() => handleButtonClick("tracks")}
            >
              Tracks
            </button>
            <button
              className={`user-profile-button-user-profile ${button === "beats" ? "active" : ""
                }`}
              onClick={() => handleButtonClick("beats")}
            >
              Beats
            </button>
          </>
        ) : (
          ""
        )}
      </div>
      {userData?.biography && button === "user-profile" ? (
        <>
          <div className="user-profile-subheading uni-mt-56 mobile-mt-16 ">Biography</div>
          <div className="user-profile-para uni-mb-56 mobile-mb-16">
            {userData?.biography}{" "}
          </div>
        </>
      ) : null}

      {button === "user-profile" || button === "tracks" ? (
        <>
          {button === "tracks" ? (
            <div className="user-profile-subheading">Tracks</div>
          ) : (
            <div
              className="user-profile-subheading-link"
              onClick={() => handleButtonClick("tracks")}
            >
              Top Tracks
            </div>
          )}

          <div className="user-profile-usericon">
            <TrackIcon arr={trackarr} className='all-icon-mobile uni-pl-24 full-width-child' className2='track-icon-mobile-view' />
          </div>
        </>
      ) : null}
      {button === "user-profile" || button === "tracks" ? (
        <>
          {button === "tracks" ? (
            ""
          ) : (
            <div
              className="user-profile-subheading-link"
              onClick={() => handleButtonClick("tracks")}
            >
              Recent Releases
            </div>
          )}

          <div className="user-profile-usericon">
            <TrackIcon arr={recentReleasesarr} className='all-icon-mobile uni-pl-24 full-width-child' className2='track-icon-mobile-view' />
          </div>
        </>
      ) : null}

      {button === "user-profile" || button === "beats" ? (
        <>
          {button === "beats" ? (
            <div className="user-profile-subheading-link">Beats</div>

          ) : (
            <div
              className="user-profile-subheading-link"
              onClick={() => handleButtonClick("beats")}
            >
              Beats
            </div>
          )}
          <div className="user-profile-usericon">
            <BeatesIcon arr={beatsarr} className='all-icon-mobile uni-pl-24 full-width-child' className2='beats-icon-mobile-view' />
          </div>
        </>
      ) : null}
    </div>
  );
};
