import { FC, useState } from "react";
import "./styles.scss";
import { TextfieldLG, TextfieldSM } from "../../../common/custom-field/dropstock-text";
import { Heading4, Heading6 } from "../../../common/custom-field/dropstock-heading";
import { viewType } from "./modal";
import { BackArrowIcon } from "../../../common/all-icons";
import { setDropworksSelectoptin } from "../../../redux/reducer/dropworksSelect";
import { useDispatch } from "react-redux";
export const view: FC<viewType> = ({ navigateTo }) => {
  const dispatch = useDispatch()
  return (
    <div className="new-wallet container container-xxl min-height-cover">
      <div>
        <div className="overview">

          <div className="dropstocks-select-portfolio">
            <div className="table-view">

              <div className="table-bg-parent-select">
                <div className="d-flex mobile-p-lr">
                  <span className="cursor-pointer uni-mt-8" onClick={() => {
                    navigateTo("/dropworks-select");

                  }}>  <BackArrowIcon className="uni-mr-16" />
                  </span>

                  <Heading4 className="dropstocks-select-portfolio-head uni-mb-32">
                    DropWorks Select portfolio <TextfieldSM className="dropstocks-select-portfolio-subhead  mt-8">DWS</TextfieldSM>
                  </Heading4>
                </div>
                <div className="table-wrapper-select-portfolio">
                  <table className="table custom-table-select-portfolio">
                    <thead className="text-dark align-items-center justify-content-between">
                      <tr className="table-row-dropstock uni-mb-16">
                        <th scope="col" className="track-column">
                          Track
                        </th>
                        <th scope="col">Added</th>
                        <th scope="col" className="quantity-table-head">Quantity</th>
                        <th scope="col" className="anual-return">Est. Annual return</th>
                      </tr>
                    </thead>
                    <tbody>{Array.from({ length: 200 }).map((ele) => {
                      return (<tr className="text-light d-flex justify-content-between align-items-center uni-mt-16">
                        <td className="date-time track-column">
                          <div className="d-flex gap-3 align-items-center">
                            <span className="select-box"></span>
                            <div className="d-flex">
                              <Heading6 className="my-0 p-0 cursor-pointer select-box-text" onClick={() => navigateTo("/track-page")}>
                                Track Name 1
                              </Heading6>
                            </div>
                          </div>
                        </td>
                        <td>08/23/24</td>
                        <td className="last-table-data">800<span className="sub-quantity-price">/5000</span></td>
                        <td className="last-table-data">4.1%</td>
                      </tr>)
                    })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};
