import { useNavigate, useParams } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react"
import { getTrackProjectBeatsDetailsById } from "../../shared/utils/apiServices"

export const TrackPage=()=>{
    const slugName = useParams()
    const trackSlugName = slugName.slugName;
    const [trackData , setTrackData] = useState<any>()
    
    const navigate=useNavigate();


    useEffect(()=>{
        const fetchUserData = async () => {
            const result = await getTrackProjectBeatsDetailsById(trackSlugName); 
            if (result.success) {
                console.log(result.featuredBeatsData.data.track)
                setTrackData(result.featuredBeatsData.data.track)
            }
        };
    
        fetchUserData();
    },[trackSlugName])
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({navigateTo,trackData}))
}