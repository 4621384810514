import { ChangeEvent, FC, useEffect, useState } from "react";
import "./styles.scss";
import Tabs from "../../../common/tabs";
import { DropworksSearchInput } from "../../../common/custom-field/dropworks-search-input";
import OffNotification from "../../../assets/steps/muted icon.svg";
import { HiDotsHorizontal } from "react-icons/hi";
import { DropworksMessageInput } from "../../../common/custom-field/dropworks-input-messagebox";
import { ArrowDropdown, MeatballsMenuDropdown } from "../../../common/icons";
import TabsComponent from "../../../common/tabs-component/view";
import { Heading2 } from "../../../common/custom-field/dropstock-heading";
import { Link } from "react-router-dom";
import PdfImage from"../../../assets/img/pdf-image.png"
import DocImage from"../../../assets/img/doc-image.png"
import { BackArrowIcon } from "../../../common/all-icons";
import { connectSocket, disconnectSocket, onMessageReceived,sendMessage } from "../../shared/utils/socketServices";
import { string } from "yup";
interface MessageData {
  chat_type: number;
  message_to?: any;
  message: string;
  group_id?: string;
}
interface ReceivedMessage {
  message: string;
  from: string;
  to: string;
  group_id: string;
}
export const view: FC<any> = ({allUserData,allGroupData,userchatHistroy,selectedUserId,setSelectedUserId,selectedGroupId,setSelectedGroupId,allUnread}) => {
  let token =localStorage.getItem("dropworksToken");
  const [message, setMessage] = useState<string>('');
  const [messages, setMessages] = useState<ReceivedMessage[]>([]);
  
  useEffect(() => {
    connectSocket(token);
  
    onMessageReceived((msg: ReceivedMessage) => {

      console.log("Received message:", msg);
      console.log("selectedUserId:", selectedUserId, "selectedGroupId:", selectedGroupId);
     
      const isOneToOneMessage = msg.from && msg.to && selectedUserId && (msg.from === selectedUserId || msg.to === selectedUserId);
      const isGroupMessage = selectedGroupId && msg.from === selectedGroupId;
      console.log(isGroupMessage,'is this groupmessage');
      console.log(isOneToOneMessage,'is this groupmessage');

      if (isOneToOneMessage) {
        // Handle one-to-one messages
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            actionType: 1,
            message: msg.message,
            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            id: selectedUserId,
          }
        ]);
        setMessages((prevMessages) => [...prevMessages, msg]);
        console.log("Received one-to-one message:", msg);
      } else if (isGroupMessage) {
        // Handle group messages
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            actionType: 1,
            message: msg.message,
            time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            id: selectedGroupId,
          }
        ]);
        setMessages((prevMessages) => [...prevMessages, msg]);
        console.log("Received group message:", msg);
      } else {
        console.log("Message does not match any active chat:", msg);
      }
    });
  
    return () => disconnectSocket();
  }, [token, selectedUserId, selectedGroupId]);
  
  const [allChat,setAllChat] = useState<any>([]);
  const [unreadMessage,setUnreadMessage] = useState<any>([]);  
  const[groupChat, setGroupChat] = useState<any>([])

  useEffect(() => {
    if (allUserData.length > 0) {
      const updatedArray = allUserData.map((data: any, index: number) => ({
        imgUrl: data?.profile_image?.[0]?.src,   
        name: data.username,
        project: data.beat_name,
        seen: false,
        date: "8/14/2024",
        time: "15:46",
        type: data.type,
        id: data._id,
        text: "Message example that artists may send to each other",
        allowNotification: false,
        chatStatus: data.chatStatus,
        lastChatTime: new Date(data.lastChatTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit', 
        }),
        
      }));
      setAllChat(updatedArray);
    }
  }, [allUserData]);

  useEffect(() => {
    if (allUnread.length > 0) {
      const updatedArray = allUnread.map((data: any, index: number) => ({
        imgUrl: data?.profile_image?.[0]?.src,   
        name: data.username,
        project: data.beat_name,
        seen: false,
        date: "8/14/2024",
        time: "15:46",
        type: data.type,
        id: data._id,
        text: "Message example that artists may send to each other",
        allowNotification: false,
        chatStatus: data.chatStatus,
        lastChatTime: new Date(data.lastChatTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit', 
        }),
      }));
      setUnreadMessage(updatedArray);
    }
  }, [allUnread]);
  // console.log(unreadMessage,'This is unread message list');
  useEffect(() => {
    if (allGroupData.length > 0) {
      const updatedArray = allGroupData.map((data: any, index: number) => ({
        imgUrl: data?.profile_image?.[0]?.src,   
        name: data.project_name,
        seen: false,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        id: data._id,
        text: "Message example that artists may send to each other",
        allowNotification: false,
      }));
      setGroupChat(updatedArray);
     
    }
  }, [allGroupData]);
  console.log(groupChat,'This is groupchat data');
 
  const [chatHistory, setChatHistory] = useState([
    {
      actionType: 1,
      message: "We have Recieved Message",
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      id: selectedUserId ,
      imgUrl: ""
    },
    { actionType: 0, message: "We have Sent Message", time: "15:46",id:selectedUserId},
  ]);

  const [groupChatHistory, setGroupChatHistory] = useState([
    {
      actionType: 1,
      message: "We have Recieved Message",
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      id: selectedUserId ,
      imgUrl: ""
    },
    {
      actionType: 0,
      message: "We have Recieved Message",
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      id: selectedUserId ,
      imgUrl: ""
    },
    {
      actionType: 1,
      message: "We have Recieved Message",
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      id: selectedUserId ,
      imgUrl: ""
    },
    {
      actionType: 0,
      message: "We have Recieved Message",
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      id: selectedUserId ,
      imgUrl: ""
    },
    // { actionType: 0, message: "We have Sent Message", time: "15:46",id:selectedUserId},
  ]);
  console.log(groupChatHistory,'--------------------------------------------------------------');


  useEffect(() => {
    if ( userchatHistroy&&userchatHistroy.length > 0) {
      const formattedChatHistory = userchatHistroy.map((messageData: any) => ({
        actionType: messageData.actionType === 0 ? 1 : 0, 
        message: messageData.message,
        time: new Date(messageData.chat_datetime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }),
        id: selectedUserId,
        imgUrl: "" 
      }));
      setChatHistory(prevChatHistory => [...prevChatHistory, ...formattedChatHistory]);
      setSelectedUserId(selectedUserId);
    }
  }, [userchatHistroy, selectedUserId]);
  // console.log(chatHistory,'userChatHistroy in virw');
  const getFilteredChatHistory = () => {
    return chatHistory.filter((item) => item.id === selectedUserId);
  };
  const [show, setShow] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);

  const handleSendMessage = (message: string) => {
    if (message.trim()) {
      let messageData: MessageData;
  
      if (selectedUserId === '' && selectedGroupId) {  // Group chat condition
        messageData = {
          chat_type: 1,              // Group chat
          group_id: selectedGroupId,  // Set group ID
          message
        };
      } else {  // One-to-one chat condition
        messageData = {
          chat_type: 0,              // One-to-one chat
          message_to: selectedUserId, // Set recipient user ID
          message
        };
      }
  
      // Send the message using the socket
      sendMessage(messageData);
  
      // Update chat history with the sent message
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { 
          actionType: 0, 
          message: messageData.message, 
          time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), 
          id: selectedUserId 
        }
      ]);
  
      // Clear input message
      setMessage('');
    }
  };
  console.log('this is GroupId ',selectedGroupId);  
   
useEffect(() => {
  setTimeout(()=>{
    
  })
}, []);
  const click = () => {
    setShow(!show);
  };
  const [dropdown, setdropDown] = useState(false);

  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
    setActiveIndex1(null)
    setActiveIndex2(null)
    setActiveIndex3(null)
  }; 
  const [activeIndex1, setActiveIndex1] = useState<number | null>(null);
  const [activeIndex2, setActiveIndex2] = useState<number | null>(null);
  const [activeIndex3, setActiveIndex3] = useState<number | null>(null);
  const ErrorImg =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxqCQMRexiN9Iu6yVJQt3py5bPb13DRIqCXA&s";

  const [chattingTab, setChattingTab] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [fileType, setFileType] = useState<string>('');

const getClassNames = (ele:any) => {
  return `newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${
    activeIndex1 === ele.id ? "active-chat" : ""
  }`;
};
const getProjectName = (ele:any) => {
  return `newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${
    activeIndex3 === ele.id ? "active-chat" : ""
  }`;
};

// console.log(selectedUserId,'This is selectedUserId');

const getChattingTabDetails = () => {
  const currentChat = allChat.flat().find((ele: any) => ele.id === selectedUserId); 
  return currentChat ? { name: currentChat.name, imgUrl: currentChat.imgUrl } : { name: "Name Not Found", imgUrl: "" };
};
const { name, imgUrl } = getChattingTabDetails();

const getGroupChattingTabDetails = () => {
  const currentChat = allChat.flat().find((ele: any) => ele.id === selectedGroupId); 
  return currentChat ? { groupname: currentChat.name, groupimgUrl: currentChat.imgUrl } : { name: "Name Not Found", imgUrl: "" };
};
const{groupname, groupimgUrl} = getGroupChattingTabDetails();
const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
  const uploadedFile = event.target.files?.[0];
  if (uploadedFile) {
    setFile(uploadedFile);
    setFileType(uploadedFile.type);
  }
};

const handleIconClick = () => {
  document.getElementById('file-input')?.click();
};
const renderPreview = () => {
  if (!file) return null;

  const fileURL = URL.createObjectURL(file);
  if (fileType.startsWith('image/')) {
    return <img src={fileURL} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />;
  } else if (fileType === 'application/pdf') {
    return (
      <div style={filePreviewStyle}>
        <img src={PdfImage} alt="PDF" style={iconStyle} />
        <div>
          <p>{file.name}</p>
          <p>PDF File</p>
        </div>
      </div>
    );
  } else if (fileType.startsWith('video/')) {
    return <video controls style={{ maxWidth: '100%', maxHeight: '200px' }}><source src={fileURL} type={fileType} /></video>;
  } else if (fileType.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
    return (
      <div style={filePreviewStyle}>
        <img src={DocImage} alt="Word" style={iconStyle} />
        <div>
          <p>{file.name}</p>
          <p>Word Document</p>
        </div>
      </div>
    );
  } else {
    return <p>Unsupported file type: {file.name}</p>;
  }
};

const filePreviewStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #ccc',
  padding: '10px',
  marginTop: '10px',
};

const iconStyle: React.CSSProperties = {
  width: '40px',
  height: '40px',
  marginRight: '10px',
};
const handleDropdownToggle = (index: number) => {
  setActiveDropdown(activeDropdown === index ? null : index);
};
  return (
    <div className="newmessage pb-3 px-0 mt-0-mbl">
  <div>
     </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 w-100">
        <div className="d-flex">
          <div className={`newmessage-left ${dropdown ? "mobile-d-none" :""} `}>
            <Heading2 className="newmessage-left-heading uni-mb-24 uni-ml-16">Messages</Heading2>
            <Tabs
              id="10"
              tabsTitle={["All", "Unread", "Projects"]}
              className="uni-mb-24 uni-ml-16 message-tabs"
              checkTabState={checkTabState}
            />
            <DropworksSearchInput className="uni-mb-24 uni-ml-16 search-input-message" />
            <div className="newmessage-left-chatsection">
            <TabsComponent tabsValue={tabsValue} components={
                [<>{allChat.map((ele:any,index:number) => {
                  return (
                    <div 
                    key={index}
                    className={`newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${getClassNames(ele)} ${
                      activeIndex1 === index ? "active-chat" : ""
                    }`}
                    onClick={() => {
                      setChattingTab(ele.type);
                      setSelectedUserId(ele.id);
                      setActiveIndex1(index);
                      setdropDown(true);
                    }}
                  >
                      <img
                        src={`${process.env.REACT_APP_IMGURL}/${ele.imgUrl}`}
                        className={
                          ele.type === "Artist"
                            ? "img-profilepic"
                            : "img-profilepic-project"
                        }
                        onError={(e) => (e.currentTarget.src = ErrorImg)}
                        alt="image"
                      />
                      <div className=" align-items-center uni-ml-16 mobile-pr-16 m-w-100">
                        <p className="newmessage-left-chatsection-chat-heading">
                        {ele.type==='user'? ele.name : ele.project}
                          <p>
                            {!ele.seen ? (
                              <p className="newmessage-left-chatsection-chat-time">
                                {ele.lastChatTime}
                              </p>
                            ) : (
                              <p className="newmessage-left-chatsection-chat-date">
                                {ele.date}
                              </p>
                            )}
                          </p>
                        </p>
                        <p className="newmessage-left-chatsection-chat-text">
                          <p className="newmessage-left-chatsection-chat-text-long-text">
                            {ele.text}
                          </p>
                          <p className="d-flex align-items-center">
                            {!ele.allowNotification && (
                              <img
                                className="img-offNotification uni-mx-8"
                                src={OffNotification}
                              />
                            )}
                            {!ele.seen && (
                              <p
                                className={
                                  ele.allowNotification
                                    ? "notify-yellow-dot uni-ml-40"
                                    : "notify-yellow-dot "
                                }
                              ></p>
                            )}
                            {ele.seen && !ele.allowNotification && ele.name === "Project Name" && (
                              <ArrowDropdown pagename="message"
                                className="px-0 mobile-p-0"
                                arr={["mute"]}
                                isOpen={activeDropdown === index}
                                onToggle={() => handleDropdownToggle(index)}
                              />
                            )}
                            {ele.seen && !ele.allowNotification && ele.name != "Project Name" && (
                              <ArrowDropdown pagename="message "
                                className="px-0 mobile-p-0"
                                arr={["Unmute", "Block", "Report", "Delete"]}
                                isOpen={activeDropdown === index}
                                onToggle={() => handleDropdownToggle(index)}
                              />
                            )}
                          </p>
                        </p>
                      </div>
                    </div>
                  );
                })}
                </>,
                <>{unreadMessage.map((ele:any,index:any) => {
                  return (
                    <div
                      className={`newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${
                        activeIndex2 === index ? "active-chat" : ""
                      }`}
                        onClick={() => {setChattingTab(ele.type),setSelectedUserId(ele.id), setActiveIndex2(index),setdropDown(true)}}
                    >
                      <img
                        src={ele.imgUrl}
                        className={
                          ele.type === "Artist"
                            ? "img-profilepic"
                            : "img-profilepic-project"
                        }
                        onError={(e) => (e.currentTarget.src = ErrorImg)}
                        alt="image"
                      />
                      <div className=" align-items-center uni-ml-16 mobile-pr-16 m-w-100">
                        <p className="newmessage-left-chatsection-chat-heading ">
                         {/* {ele.type === 'user' ? ele.name : ele.project } */}
                         {ele.name}
                          <p>
                            {!ele.seen ? (
                              <p className="newmessage-left-chatsection-chat-time">
                                {ele.time}
                              </p>
                            ) : (
                              <p className="newmessage-left-chatsection-chat-date">
                                {ele.date}
                              </p>
                            )}
                          </p>
                        </p>
                        <p className="newmessage-left-chatsection-chat-text">
                          <div className="newmessage-left-chatsection-chat-text-long-text">
                            {ele.text}
                          </div>
                          <p className="d-flex align-items-center">
                            {!ele.allowNotification && (
                              <img
                                className="img-offNotification uni-mx-8"
                                src={OffNotification}
                              />
                            )}
                            {!ele.seen && (
                              <p
                                className={
                                  ele.allowNotification
                                    ? "notify-yellow-dot uni-ml-40"
                                    : "notify-yellow-dot "
                                }
                              ></p>
                            )}
                            {ele.seen && !ele.allowNotification && (
                              <ArrowDropdown pagename="message"
                                className="px-0 mobile-p-0"
                                arr={["Unmute", "Block", "Report", "Delete"]}
                                id={`${index}`}
                                isOpen={activeDropdown === index} 
                                onToggle={() => handleDropdownToggle(index)} 
                              />
                            )}
                          </p>
                        </p>
                      </div>
                    </div>
                  );
                })}
                </>
                  ,
                <>{groupChat.map((ele:any,index:any) => {  
                  return (
                    <div
                    className={`newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${getProjectName(ele)} ${
                      activeIndex1 === index ? "active-chat" : ""
                    }`}
                      // className={`newmessage-left-chatsection-chat uni-mb-16 cursor-pointer ${
                      //   activeIndex3 === index ? "active-chat" : ""
                      // }`}
                        onClick={() => {setChattingTab(ele.type), setSelectedGroupId(ele.id), setActiveIndex3(index),setdropDown(true)}}
                    >
                      <img
                        src={ele.imgUrl}
                        className={
                          ele.type === "Project"
                            ? "img-profilepic"
                            : "img-profilepic-project"
                        }
                        onError={(e) => (e.currentTarget.src = ErrorImg)}
                        alt="image"
                      />
                      <div className=" align-items-center uni-ml-16 mobile-pr-16 m-w-100">
                        <p className="newmessage-left-chatsection-chat-heading " >
                          {ele.name}{" "}
                          <p>
                            {!ele.seen ? (
                              <p className="newmessage-left-chatsection-chat-time">
                                {ele.time}
                              </p>
                            ) : (
                              <p className="newmessage-left-chatsection-chat-date">
                                {ele.date}
                              </p>
                            )}
                          </p>
                        </p>
                        <p className="newmessage-left-chatsection-chat-text">
                          <div className="newmessage-left-chatsection-chat-text-long-text">
                            {ele.text}
                          </div>
                          <p className="d-flex align-items-center">
                            {!ele.allowNotification && (
                              <img
                                className="img-offNotification uni-mx-8"
                                src={OffNotification}
                              />
                            )}
                            {!ele.seen && (
                              <p
                                className={
                                  ele.allowNotification
                                    ? "notify-yellow-dot uni-ml-40"
                                    : "notify-yellow-dot "
                                }
                              ></p>
                            )}
                            {ele.seen && !ele.allowNotification && (
                              <ArrowDropdown pagename="message"
                                className="px-0 mobile-p-0"
                                arr={["mute"]}
                                isOpen={activeDropdown === index} 
                                onToggle={() => handleDropdownToggle(index)} 
                              />
                            )}
                          </p>
                        </p>
                      </div>
                    </div>
                  );
                })}
                </>
                ]
              } />
            </div>
          </div>
          {chattingTab && (
            <div className={`full-width-child newmessage-right uni-px-24 ${dropdown ? "" :"mobile-d-none"} `}>
              <div className="newmessage-right-heading-content d-flex align-items-center">
                <span onClick={()=>setdropDown(false)}>
              <BackArrowIcon className="uni-mr-16 hide-on-web"/></span>
                {" "}
                <img
              src={
             chattingTab === "Project"
      ? "https://i0.wp.com/picjumbo.com/wp-content/uploads/pure-nature-landscape-single-tree-in-green-field-free-photo.jpg?w=600&quality=80"
      : `${process.env.REACT_APP_IMGURL}/${imgUrl}`
  }
  className={
    chattingTab === "Artist"
      ? "img-profilepic"
      : "img-profilepic-project"
  }
  onError={(e) => (e.currentTarget.src = ErrorImg)}
  alt="image"
/>
                <p className="newmessage-right-heading-content-heading uni-mr-16"><Link to={ chattingTab === "Artist" ? "/user-profile":"#"} className="uni-pl-16">
                {name}
                </Link></p>
                { chattingTab === "Project"?  
                 <MeatballsMenuDropdown pagename="message"
                  height="6px"
                  arr={["mute"]}
                ></MeatballsMenuDropdown>
                :   <MeatballsMenuDropdown pagename="message"
                  height="6px"
                  arr={["Unmute", "Block", "Report", "Delete"]}
                ></MeatballsMenuDropdown>}
              </div>
              <div className="newmessage-right-chatting justify-content-end uni-py-32">
                <div className="newmessage-chat-history flex-column">
                {getFilteredChatHistory().map((item, index) => (
                    <>
                      {item.actionType === 1 ? (<>
                        <div className="newmessage-receiver">
                          {chattingTab === "Project" && (
                            <img
                            src={item.imgUrl || ""}
                              className={
                                chattingTab === "Project"
                                  ? "img-profilepic uni-mr-8 "
                                  : "img-profilepic-project"
                              }
                              onError={(e) => (e.currentTarget.src = ErrorImg)}
                              alt="image"
                            />
                          )}
                          <p className="newmessage-receiver-chat-triangle"></p>
                          <div className="newmessage-receiver-message">
                            <span className="newmessage-receiver-message-received-message">{item.message}</span>
                            <span className="fs-gray3 uni-fw-400 newmessage-receiver-message-time">
                              {item.time}
                            </span>
                          </div>
                        </div>
                      </>
                      ) : (
                        ""
                      )}
                      {item.actionType  === 0 ? (
                          <div className="newmessage-sender justify-content-end">
                            <div className="newmessage-sender-message ">
                              <span className="newmessage-sender-message-sender-message">{item.message}</span>
                              <span className="uni-fw-400 newmessage-sender-message-time">{item.time}</span>
                            </div>
                            <p className="newmessage-sender-chat-triangle"></p>
                          </div>      
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </div>
                <div className="newmessage-send-message">
                  <DropworksMessageInput
                    placeholder="Type Search" 
                    message={handleSendMessage}
                                    />
                </div>
              </div>
            </div>
          )}  
        </div>
      </div>
    </div>
  );
};