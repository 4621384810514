import { useNavigate, useParams } from "react-router-dom"
import { view } from "./view"
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUserById } from "../../shared/utils/apiServices";

export const UserProfile=()=>{
    const [userData ,setUserData] = useState()
    const [trackData ,setTrackData] = useState<string[]>([])
    const [recentRelaseData ,setRecentRelaseData] = useState<string[]>([])
    const [beatsData ,setBeatsData] = useState<string[]>([])

    const [beatsarr, setBeatsarr] = useState<any>([
        { heading: "", subheading: "" ,image:"",beatName:""}
      ]);

    const [recentReleasesarr, setRecentReleasesarr] = useState([
        { heading: "",subheading: "",image:"",slugName:"" }
    ]);

    const [trackarr, setTrackarr] = useState([
        { heading: "", subheading: "" ,image:"",slugName:"" }
      ]);

    const slugName = useParams()

    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    useEffect(()=>{
        const userId = slugName.slugName;
        const fetchUserData = async () => {
            const result = await getUserById(userId); 
            if (result.success) {
                console.log(result.featuredBeatsData.data.userData)
                setUserData(result.featuredBeatsData.data.userData)
                setTrackData(result.featuredBeatsData.data.trackData)
                setRecentRelaseData(result.featuredBeatsData.data.recentrelasedata)
                setBeatsData(result.featuredBeatsData.data.beatsData)
            }
        };
    
        fetchUserData();
    },[slugName])
    
      useEffect(()=>{
        if (Array.isArray(beatsData)) {
        setBeatsarr(beatsData?.map((item:any)=>(
          {
         heading:item.beat_name,
         subheading:item.beat_slug_name,
         image:item.art_work_image[0]?.src,
         beatName:item.beat_slug_name
        }
      )))}
      },[beatsData])
    
      useEffect(()=>{
        if (Array.isArray(recentRelaseData)) {
            setRecentReleasesarr(recentRelaseData?.map((item:any)=>(
          {
         heading:item.beat_name,
         subheading:item.short_description,
         image:item.art_work_image[0]?.src,
         slugName:item.slug_name
        }
      )))}
      },[recentRelaseData])

      useEffect(()=>{
        if (Array.isArray(trackData)) {
            setTrackarr(trackData.map((item:any)=>(
          {
         heading:item.beat_name,
         subheading:item.description,
         image:item.art_work_image[0].src,
         slugName:item.slug_name
        }
      )))}
      },[trackData])
    
    return(view({navigateTo,userData,beatsarr,recentReleasesarr,trackarr}))
}