import { FC, useEffect, useState } from "react";
import "./styles.scss";
import { AiOutlineDown } from "react-icons/ai";
import axios from "axios";
import { DropworksDisableButton } from "../dropworks-button";
import { SelectArrowDropdown } from "../../selection-dropdown";
 import Countries from "../../../i18n/country";
// Sample country data
interface Country{
  name: { common: string };
  idd: { root: string; suffixes: string[] };
  flags: { svg: string };
}
 
const countryData = Countries;

 
interface CustomDivProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onChangeCountry?:(data:any)=>void
}
 
export const view: FC<CustomDivProps> = ({onChangeCountry,disabled=false,...props }) => {
  // const [countryData, setCountryData] = useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = useState( { name: "Afghanistan", phonePrefix: "+93", minPhoneLength: 9, maxPhoneLength: 9, flag: "🇦🇫", flagUrl: "https://flagpedia.net/data/flags/h80/af.png"});
  
  const handleCountrySelect = (country: any) => {
    onChangeCountry && onChangeCountry(country)
    setSelectedCountry(country);
  };

  useEffect(()=>{
if(props.value)
{
  let data= countryData.filter((ele:any)=>ele.phonePrefix == props.value);
  setSelectedCountry(data[0]);
  }
  },[props.value])
 
  return (
    <div className={`${props.className} dropdown cursor-pointer`} >
      <div
        className="country-selector dropdown-toggle"
        id="dropdownMenuButton"
        data-bs-toggle={!disabled?"dropdown":""}
        aria-expanded="false"
      >
        {selectedCountry && (
          <>
            <img src={selectedCountry.flagUrl} alt={`${selectedCountry.name} Flag`} className="flag-img" />
            <span className="code">
              {selectedCountry.phonePrefix}
              {/* {selectedCountry.name} */}
            </span>
            <AiOutlineDown className="dropdown-icon" />
          </>
        )}
      </div>
      <ul className="dropdown-menu dropdown-ul" aria-labelledby="dropdownMenuButton">
        {countryData.map((country) => (
          <li
            key={country.name}
            className="dropdown-li"
            onClick={() => !disabled &&  handleCountrySelect(country)}
          >
            {country.name}
          </li>
        ))}
      </ul>
   
    </div>
  );
};