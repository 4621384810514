import { FC, InputHTMLAttributes } from "react";
import { view } from "./view";

interface SelectProps extends InputHTMLAttributes<HTMLInputElement> {}

export const DropworksRadioCheckbox: FC<SelectProps> = (props) => {
  return view({
    ...props,
  });
};
