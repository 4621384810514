import React, { useEffect, useState } from 'react'

const TabsComponent = ({tabsValue,components}:{tabsValue:number,components:JSX.Element[]}) => {
    const [showComponent, setShowComponent] = useState(tabsValue);
    
    useEffect(() => {
        const timeout = setTimeout(() => {
          setShowComponent(tabsValue);
        }, 100); // Matches the CSS transition duration
    
        return () => clearTimeout(timeout);
      }, [tabsValue]);
  return (
    <div className={`fade ${tabsValue === showComponent ? 'fade-in' : 'fade-out'}`}>{components[showComponent]}</div>
  )
}

export default TabsComponent;