import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

const initialState = {
  language: localStorage.getItem('lng') || 'en', 
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      
      if (i18next.language !== action.payload) {
        i18next.changeLanguage(action.payload)
          .catch((err) => {
            console.error('Error changing language:', err);
          });
      }

      localStorage.setItem('lng', action.payload);
      console.log(action.payload,'thisis isisi fios');
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
