import './App.css';
import { BrowserRouter} from 'react-router-dom';
import MainRoutes from './app/routing/MainRoutes';
import "../src/styles/variablesstyles.scss"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    AOS.init({ duration: 1250 });
  }, []);


  return (
    <BrowserRouter>
    <MainRoutes/>
    </BrowserRouter>
  );
}

export default App;
