import { FC, useEffect, useState } from "react";
import view from "./view";
import { useDispatch, useSelector } from "react-redux";
import { registerThirdPopup } from "../../../utils/apiServices";
import { RootState } from "../../../../../redux/store";
import { setMobile, setMobileCountryCode, setModal3, setStartTimer } from "../../../../../redux/reducer/modal";

export const SecureYourAccount: FC<any> = () => {
  const [phNumber, setPhNumber] = useState<number>();
  const [message,setMessage]=useState<string>()
  const updateNumber = (value: number) => {
    setPhNumber(value);
};
const { modal3 } = useSelector((state: RootState) => state.modal);

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const OpenPopup3 = async () => {
    setLoading(true);
const data={phone:phNumber,countryCode:numberValidation.phonePrefix}
    const response: any = await registerThirdPopup(data);
    if (response.success) {
      phNumber && dispatch(setMobile(phNumber));
      dispatch(setMobileCountryCode(numberValidation.phonePrefix));
      setLoading(false);
      const modalTrigger1 = document.querySelector(
        'a[data-bs-toggle="modal"][href="#Modal4"]'
      ) as HTMLAnchorElement;
      if (modalTrigger1) {
        dispatch(setStartTimer(true));
        modalTrigger1.click();
      }
    } else {
      setLoading(false);
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  };

 const [numberValidation,setNumberValidation]=useState({ name: "Afghanistan", phonePrefix: "+93", minPhoneLength: 9, maxPhoneLength: 9, flag: "🇦🇫", flagUrl: "https://flagpedia.net/data/flags/h80/af.png"})
  const changeCountry =(data:any)=>{
  setNumberValidation(data);
  }
  return view({ phNumber,modal3, updateNumber,loading,OpenPopup3,message,numberValidation,changeCountry });
};
export default SecureYourAccount;
