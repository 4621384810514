import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { ButtonComponent, closeModal, CloseModalButton } from '../../logo-header/view';
import "./styles.scss";
import { FC, useEffect, useState } from "react";

import { DropworksTextArea } from '../../../../../common/custom-field/dropworks-textArea';
import king from '../../../../../assets/img/king.png';
import verfiedIcon from '../../../../../assets/steps/verified-badge.svg';
import { MdOutlineFileDownload } from "react-icons/md";
import download from '../../../../../assets/steps/download.svg';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { BlueTik } from '../../../../../common/all-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { inviteStatus } from '../../../utils/apiServices';

interface viewType {
  navigateTo:(path:string)=>()=>void
} 

const view :FC<viewType> =({ navigateTo }) => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1000);
  const invite = useSelector((state: RootState) => state.invite)
  
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1000);
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const submitData = async (data: { inviteId: string; trackId: string; acceept_status: string }) => {
    let response: any = await inviteStatus(data);
    if (response) {
      closeModal()
      } else {
        console.log("fail");
      }
}
  return (
    <div
      className="modal fade"
      id="ActiveInviteReceived"
      aria-hidden="true"
      aria-labelledby="ActiveInviteReceived"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Invite <span className='invite-received'>Received</span> </Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh">You have been invited by @kingroland to participate in his project</TextfieldLG>
            <div className="d-flex align-items-center uni-mb-32 invite_firstcolumn">
              <img src={king} className="invite_firstcolumn_imagebox" alt="" />
              <CloseModalButton>
              <div onClick={navigateTo("/user-profile")} className='invite-modal-pointer'>
                <h2 className="fw-700 text-light username-invite-modal uni-mr-8">{invite.Data[invite.selectInde]?.sent_user_id.firstname+" "+ invite.Data[invite.selectInde]?.sent_user_id.lastname}
                <span> <BlueTik/></span> </h2>
                  <TextfieldSM className='text-light'>@kingroland</TextfieldSM>
              </div></CloseModalButton>
             
            </div>
           

            <TextfieldLG className=' received-label uni-mb-16'>Project</TextfieldLG>
            <DropworksInput placeholder={invite.Data[invite.selectInde]?.track_id?.project_name||"no"} className='uni-mb-32 cstm-inp-invite' readOnly></DropworksInput>

            <TextfieldLG className='received-label uni-mb-16 '>Role</TextfieldLG>
            <DropworksInput placeholder={invite.Data[invite.selectInde]?.role} className='uni-mb-32 cstm-inp-invite' readOnly></DropworksInput>

            <TextfieldLG className=' received-label uni-mb-16 ' >Percentage Offered</TextfieldLG>
            <DropworksInput placeholder={invite.Data[invite.selectInde]?.percentage_offered} className='uni-mb-32  cstm-inp-invite' readOnly></DropworksInput>

            <TextfieldLG className=' received-label uni-mb-16'>Message</TextfieldLG>
            <DropworksTextArea className="uni-mb-32 cstm-txt-invite uni-p-16 " readOnly  hideIcon={true} placeholder= {invite.Data[invite.selectInde]?.message}>
           
      
            </DropworksTextArea>
            <TextfieldLG className='text-light uni-mb-16'>Preview</TextfieldLG>
            <div className='d-flex justify-content-between uni-p-16 align-items-center uni-mb-32'>
               <TextfieldSM className='file-name'>File name .mp3</TextfieldSM>
               <TextfieldSM className='file-name'> <img src={download} alt="" className="uni-ml-8" /></TextfieldSM>
            </div>
            <div className=' uni-mb-32'>
              <TextfieldSM className='file-name uni-mb-8'>View <span className='collaboration'>Collaboration Agreement</span></TextfieldSM>
              <TextfieldSM className='received-date '>Received on {invite.Data[invite.selectInde]?.created_at}</TextfieldSM>
            </div>
          </div>
          <div className="modal-footer invite-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <DropworksSmallButton className="btn-wid invite-modal-btn btn-bg-gray3 my-0 ml-0  uni-mr-16" onClick={() => submitData({ inviteId: invite.Data[invite.selectInde]?._id, trackId: invite.Data[invite.selectInde]?.track_id._id, acceept_status: "rejected" })}>Decline</DropworksSmallButton>
            {isWideScreen ?   <DropworksSmallButton className="btn-wid invite-modal-btn m-0" onClick={() => submitData({ inviteId: invite.Data[invite.selectInde]?._id, trackId: invite.Data[invite.selectInde]?.track_id._id, acceept_status: "accepted" })}>Accept</DropworksSmallButton>
            :<>
              <ButtonComponent id="CreateMobile" className='buttoncomponent-mobile-modle'>
              <DropworksSmallButton className="btn-wid invite-modal-btn m-0" onClick={() => submitData({ inviteId: invite.Data[invite.selectInde]?._id, trackId: invite.Data[invite.selectInde]?.track_id._id, acceept_status: "accepted" } )}>Accept</DropworksSmallButton>
              </ButtonComponent>
                </>}
          
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;


