import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { stat } from 'fs';
// import { User } from 'oidc-client-ts';

export interface AuthState {
  authentication: boolean | undefined
  isFirstTime:boolean
  userdata: any|null
}

const initialState: AuthState = {
  authentication: false,
  isFirstTime:false,
  userdata:null
};


export const getUserDetails = createAsyncThunk('auth/getUserDetails', async () => {
  const response = await fetch('/api/userdetails');
  const data = await response.json();
  return data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthentication: (state, action: PayloadAction<boolean>) => {
      state.authentication = action.payload;
      if(action.payload && !state.userdata)
      {
        getUserDetails()
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.userdata = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        console.error('Failed to fetch user details:', action.error);
        state.userdata= null;
      });
  },
});

export const {setAuthentication} = authSlice.actions;

export default authSlice.reducer;


