import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MusicSlice {
   Data:boolean
  }

const initialState : MusicSlice = {
  Data: false, 
};

const musicSlice = createSlice({
  name: "music",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<boolean>) => {
      state.Data = action.payload; 
    }
  },
});


export const { setData } = musicSlice.actions;


export const musicReducer = musicSlice.reducer;
