import { FC } from "react";
import view from "./view";
import { ImagesProps } from "./model";
import blueTikImage from "../../assets/img/icons/blueTik.svg"
import yellowTikImage from "../../assets/img/icons/yellow-tik.svg"
import trackPlayBackBtn from "../../assets/img/play-btn/track-Playback-Btn.svg"
import beatPlayBackBtn from "../../assets/img/play-btn/Beat-Playback-Btn.png"
import playerBtn from "../../assets/img/play-btn//track-player-pause.svg"
import rightNextBtn from "../../assets/img/play-btn/next-right-btn.svg"
import leftNextBtn from "../../assets/img/play-btn/next-left-btn.svg"
import volumeUpIcon from "../../assets/img/play-btn/volume-up-icon.svg"
import crossCircle from "../../assets/img/icons/cross-circle.svg"
import crossIcon from "../../assets/img/icons/x-mark w.svg"
import homeCheckIcon from "../../assets/steps/Check.svg"
import backArrow from "../../assets/steps/backArrow.svg"
import downArrow from "../../assets/steps/arrow-down.svg"
import pause from "../../assets/steps/pause.svg"

import
muteIcon
from
'../../assets/img/play-btn/mute-icon.svg';


// export const BlueTik:FC<ImagesProps>=({className})=>{
//     return view({icon:blueTikImage,className, iconkey:"bluetick"});
// }

export const BlueTik: FC<ImagesProps> = ({ className, position = 'right' }) => {
    return view({ icon: blueTikImage, className, iconkey: "bluetick", position });
  };

  export const MuteIcon:FC<ImagesProps>=({className})=>{
    return view({icon:muteIcon,className});
}

export const YelloTik:FC<ImagesProps>=({className})=>{
    return view({icon:yellowTikImage,className,iconkey:"yellowtick"});
}
export const BeatPlayBackBtn:FC<ImagesProps>=({className})=>{
    return view({icon:beatPlayBackBtn,className});
}
export const TrackPlayBackBtn:FC<ImagesProps>=({className})=>{
    return view({icon:trackPlayBackBtn,className});
}
export const PlayerPauseBtn:FC<ImagesProps>=({className})=>{
    return view({icon:playerBtn,className});
}
export const LeftNextBtn:FC<ImagesProps>=({className})=>{
    return view({icon:leftNextBtn,className});
}
export const RightNextBtn:FC<ImagesProps>=({className})=>{
    return view({icon:rightNextBtn,className});
}
export const VolumeUpIcon:FC<ImagesProps>=({className})=>{
    return view({icon:volumeUpIcon,className});
}
export const CrossCircle:FC<ImagesProps>=({className})=>{
    return view({icon:crossCircle,className});
}
export const CrossIcon:FC<ImagesProps>=({className})=>{
    return view({icon:crossIcon,className});
}

export const HomeCheckIcon:FC<ImagesProps>=({className})=>{
    return view({icon:homeCheckIcon,className});
}
export const BackArrowIcon:FC<ImagesProps>=({className})=>{
    return view({icon:backArrow,className});
}
export const DownArrowIcon:FC<ImagesProps>=({className})=>{
    return view({icon:downArrow,className});
}

export const AudioPauseBtn:FC<ImagesProps>=({className})=>{
    return view({icon:pause,className});
}