import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { DropworksSelect } from "../../../../../common/custom-field/dropworks-select";
import { DropworksTextArea } from "../../../../../common/custom-field/dropworks-textArea";
import iconImg from "../../../../../assets/steps/uploadicon.svg";
import { SelectArrowDropdown } from "../../../../../common/selection-dropdown";
import { ButtonComponent, closeModal, CloseModalButton } from "../../logo-header/view";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { inviteTrackProject } from "../../../utils/apiServices";
import { getALlInvitedUserbyTrackProject } from "../../../../../redux/reducer/uploadSlice";
import { AppDispatch } from "../../../../../redux-toolkit/store";
import Loader from "../../loader/view";
const view = () => {
  const { inviteModalUserId }=useSelector((state:RootState)=>state.upload);
  const [uploadedAudioFile, setUploadedAudioFile] = useState<any>(null);
  const [audioFileAPI, setAudioFileAPI] = useState<any>(null);
  const [audioImage, setAudioImage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [percentageShare,setPercentageShare]=useState("");
  const navigate = useNavigate(); 
  const dispatch =useDispatch<AppDispatch>();
  const {trackId}=useSelector((state:RootState)=>state.upload)
  const navigateTo = (value: string) => () => {
      navigate(value);
    };
  const schema6 = yup.object({
    role: yup.string().required("Role is  required"),
    percentageOffered: yup.string().required("Percentage is required"),
    message: yup.string(),
     
    });
    const onChange=(data:any)=>{
      setValue('role',data,{shouldValidate:true})
    }

    const {register,handleSubmit,formState: { errors ,isSubmitted,isValid},setError,setValue} = useForm<any>({resolver: yupResolver(schema6),mode: "onSubmit"});
  const onSubmit = async (data: any) => {
    

        data={...data,upload_audio:audioFileAPI,receiverUserId:inviteModalUserId,trackId:trackId};
      const response: any = await inviteTrackProject(data);

      if (response.success) {
        dispatch(getALlInvitedUserbyTrackProject(trackId));
        // dispatch(setInvitedUserTrackProject())
        closeModal();
      } else {
        setError(response.type || "role" , { type: "custom", message: response.message });
      }
    };

    const handleRemoveAudio = () => {  
      setUploadedAudioFile(null);
      setAudioFileAPI(null);
  };
    const handleAudioUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      setLoading(true);
      if (file) {
          setUploadedAudioFile(file);
          const formData = new FormData();
          formData.append('uploadingFile', file);

          try {
              const uploadResponse = await axios.post(`${process.env.REACT_APP_URL}/upload-file`, formData);
              if (uploadResponse.data.success) {
                setLoading(false);
                  setAudioFileAPI(uploadResponse?.data.file_data)
              }
              else {
                setLoading(false);
                  console.error("Else Error in response of uploading image", uploadResponse.data.message);
              }
          }
          catch (error) {
              console.error("Catch Error in response of uploading image", error);
          }
      }
  };

  const options = [
    { value: "Producer", label: "Producer" },
    { value: "Vocalist", label: "Vocalist" },
    { value: "Lyricist", label: "Lyricist" },
    { value: "Beat Maker", label: "Beat Maker" },
    { value: "Curator", label: "Curator" },
    { value: "Artwork Designer", label: "Artwork Designer" },
  ];
  return (
    <div 
      className="modal fade"
      id="InviteModal2"
      aria-hidden="true"
      aria-labelledby="InviteModal2"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Invitation</Heading3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="uni-mb-32 uni-mt-16 ctm-txt">
              Please define your invitation parameters
            </TextfieldLG>

            <TextfieldLG className="text-content mt-0 uni-mb-16">
              Role
            </TextfieldLG>

            <DropworksDisableButton className="custom-select-dropdown ">
              <SelectArrowDropdown
                options={options}
                placeHolderValue="Select"
                onChangeValue={onChange}
              ></SelectArrowDropdown>
            </DropworksDisableButton>
              {errors.role && typeof errors.role.message === "string" && <p className="invalid-feedback error-red">{errors.role.message}</p>}
            <TextfieldLG className="text-content uni-mt-16 ">
              Percentage Offered
            </TextfieldLG>
            <DropworksInput placeholder="Percentage %" className=""     maxLength={2}  onInput={(e: any) => {
                    let check = e.target.value.replace(/[^0-9]/g, "");
                    if (check.charAt(0) === "0") {
                      check = check.slice(1); // Remove the first character if it's '0'
                    }
                    setValue('percentageOffered',check,{shouldValidate:true})
                  }}/>
            {errors.percentageOffered && typeof errors.percentageOffered.message === "string" && <p className="invalid-feedback error-red">{errors.percentageOffered.message}</p>}

            <TextfieldLG className="text-content uni-mb-16 uni-mt-32" >
              Message
            </TextfieldLG>
            <DropworksTextArea
              className="invite-textarea "
              placeholder="Message (Optional)"
              onChange={(e:any)=>setValue('message',e.target.value,{shouldValidate:true})}
            />

            <TextfieldLG className="text-content uni-mb-16 uni-mt-32">
              Preview
            </TextfieldLG>
            <TextfieldXSM className="uni-mb-16 mt-0 preview-text">
              You may optionally attach a audio file in this invitation
            </TextfieldXSM>
  <div className="audio-upload-section">
          {audioFileAPI ? (
            <div className="audio-file-info d-flex align-items-center justify-content-between">
              <TextfieldSM className="file-name mx-0 my-0">
                {(() => {
                  const fileName = uploadedAudioFile.name;
                  const extension = fileName.slice(fileName.lastIndexOf('.'));
                  const nameWithoutExtension = fileName.slice(0, fileName.lastIndexOf('.'));

                  return nameWithoutExtension.length > 10
                    ? `${nameWithoutExtension.slice(0, 10)}...${extension}`
                    : fileName;
                })()}
              </TextfieldSM>

              <span className="remove-file-icon ml-2" onClick={handleRemoveAudio}>X</span>
            </div>
          ) : loading ? <Loader className="uni-mt-24"/>: (
            <label htmlFor="audio-uploadss" className="upload-label">
              <div className="beatEmpty_uploadbox flex-column">
                <img src={iconImg} className="audio-img" />
                <label className="uni-pt-16 uni-pb-16 beat-text">Upload</label>
              </div>
            </label>
          )}
          <input
            id="audio-uploadss"
            type="file"
            accept=".mp3, .wav, .m4a"
            onChange={handleAudioUpload}
            className="d-none"
          />
        </div>
          </div>
          <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72 uni-mt-32">
          <ButtonComponent id="InviteModal8">
            <DropworksSmallButton className="btn-wid social-link-modal-btns btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
              Back
            </DropworksSmallButton>
            </ButtonComponent>
             <DropworksSmallButton className={isValid && !loading ?"btn-wid social-link-modal-btns m-0":"btn-wid m-0  social-link-modal-btns custom-inactive-btn text-secondary disable-interactions"} type="submit">
              Invite
            </DropworksSmallButton>
          </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default view;
