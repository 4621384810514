import axios from "axios"; // Importing axios for making HTTP requests

/*
 * Name: AxiosService.ts
 * Description: Configured Axios instance for API requests with interceptors for handling tokens and responses.
 * Version: 1.0.0
 * Author: SWAPAC INFOTECH
 */

const Api = axios.create({
  baseURL: process.env.REACT_APP_URL, // Setting the base URL from environment variables
});

// Add a request interceptor
Api.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("dropworksToken"); // Retrieving the token from localStorage

    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // Adding the token to the request headers if it exists
    }
    return config; // Returning the modified config
  },
  (error: any) => {
    return Promise.reject(error); // Rejecting the promise if there's an error
  }
);

// Add a response interceptor
Api.interceptors.response.use(
  (response: any) => {
    return response; // Returning the response as is if no errors
  },
  function (error: any) {
    console.log(error,"all error------------------------------------------------->")
    const originalRequest = error.config; 
    if (
      error.response.status === 401 &&
      originalRequest.url === `${process.env.REACT_APP_URL}/login`
    ) {
      window.location.href = "/login";
      localStorage.removeItem("dropworksToken") // Redirecting to login if authentication fails
      return Promise.reject(error);
    }

    // Handling retry logic for 401 Unauthorized errors
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Marking the request as retried

      // Removing the token and redirecting to login
      if (localStorage.getItem("dropworksToken")) {
        localStorage.removeItem("dropworksToken");
        window.location.href = "/login";
      } else {
        window.location.href = "/login"; // Redirecting to login if token is missing
        localStorage.removeItem("dropworksToken");
      }
      return axios(originalRequest).then((res: any) => {}); // Retrying the original request
    }
  }
);

export default Api; // Exporting the configured Axios instance
