import React, { useEffect, ReactNode } from 'react';

interface ModalWrapper {
  modalId: string;
  onBackdropClick ?: () => void;
  children: ReactNode;
}

const ModalWrapper: React.FC<ModalWrapper> = ({
  modalId,
  onBackdropClick,
  children,
}) => {
  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    const handleHideModal = () => {
        setTimeout(()=>{
            onBackdropClick &&  onBackdropClick();
        },500)
    };

    if (modalElement) {
      modalElement.addEventListener('hide.bs.modal', handleHideModal);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hide.bs.modal', handleHideModal);
      }
    };
  }, [modalId, onBackdropClick]);

  return <>{children}</>;
};

export default ModalWrapper;
