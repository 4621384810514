import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";
import { getAllBeats, getAllProjects, getAllTracks, getAllUsers } from "../../shared/utils/apiServices";

export const Search=()=>{
    const [allUserData,setAllUserData] = useState<string[]>([])
    const [allBeatsData,setAllBeatsData] = useState<string[]>([])
    const [allTrackData,setAllTrackData] = useState<string[]>([])
    const [allProjectData,setAllProjectData] = useState<string[]>([])
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

    const [userarr, setUserarr] = useState([
        { heading: "", subheading: "" , image: "" , slugName:"" },
    
      ]);
    const [filteredUsers, setFilteredUsers] = useState([
        { heading: "", subheading: "" , image: "" , slugName:""},
    
      ]);

    const [beatsarr, setBeatsarr] = useState<any>([
        { heading: "", subheading: "", image:"" ,beastName:""},
      ]);

    const navigate=useNavigate();

    useEffect(() => {
        const fetchAllUsers = async () => {
            const result = await getAllUsers();
            if (result) {
                setAllUserData(result.userData.data)
            }
        };

        const fetchAllBeats = async () => {
            const result = await getAllBeats();
            if (result) { 
                setAllBeatsData(result.allBeatsData.data.beatData)
            }
        };
        const fetchAllTracks = async () => {
            const result = await getAllTracks(); 
            if (result.allTrackData.success) { 
                setAllTrackData(result.allTrackData.data.trackData)
            }
        };
        const fetchAllProjects = async () => {
            const result = await getAllProjects();
            if (result.allProjectData.success) {
                setAllProjectData(result.allProjectData.data.projectData)
            }
        };
    
        fetchAllUsers()
        fetchAllBeats()
        fetchAllTracks()
        fetchAllProjects()
    }, []);

    useEffect(()=>{
        if (Array.isArray(allUserData)) {
          setUserarr(allUserData.map((item:any)=>(
          {
         heading:item.display_name,
         subheading:item.username,
         image:item.profile_image[0]?.src,
         slugName:item.slug_name
        })))
    
    }
      },[allUserData])
    
      useEffect(()=>{
        if (Array.isArray(allBeatsData)) {
        setBeatsarr(allBeatsData.map((item:any)=>(
          {
         heading:item.beat_name,
         subheading:item.beat_slug_name,
         image:item.art_work_image[0].src,
         beatName:item.beat_slug_name
        }
      )))
    }
      },[allBeatsData])

      const [projectarr, setProjectarr] = useState<any>([
        {
          heading: "",
          subheading: "",
          image:"",
          slugName:""
        }
      ]);
      useEffect(()=>{
        if (Array.isArray(allProjectData)) {
          setProjectarr(allProjectData.map((item:any)=>(
          {
         heading:item.project_name,
         subheading:item.short_description,
         image:item.art_work_image[0]?.src,
         slugName:item.slug_name
        }
      )))}
      },[allProjectData])


    const handleRoleChange = (role: string) => {
        setSelectedRoles((prev) =>prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]);
      };


    useEffect(()=>{
        if(selectedRoles.length>0){
          const filteredUsers = allUserData.filter((user:any) => 
            user.role.some((role:any) => selectedRoles.includes(role))
        );
        setFilteredUsers(filteredUsers.map((item:any)=>(
          {
         heading:item.display_name,
         subheading:item.username,
         image:item.profile_image[0]?.src,
         slugName:item.slug_name
        })))
        }
        else{
          setFilteredUsers(allUserData.map((item:any)=>(
            {
           heading:item.display_name,
           subheading:item.username,
           image:item.profile_image[0]?.src,
           slugName:item.slug_name
          })))
        }
      },[selectedRoles])

      const [trackarr, setTrackarr] = useState([
        {
          heading: "",
          subheading: "",
          slugName:"",  
          image:""
        }
      ]);
    
      useEffect(()=>{
        if (Array.isArray(allTrackData)) {
          setTrackarr(allTrackData.map((item:any)=>(
          {
         heading:item.beat_name,
         subheading:item.short_description,
         image:item.art_work_image[0].src,
         slugName:item.slug_name
        }
      )))}
      },[allTrackData])
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({navigateTo,allUserData,allBeatsData,allTrackData,allProjectData,userarr,filteredUsers,selectedRoles,beatsarr,projectarr,trackarr,handleRoleChange}))
}