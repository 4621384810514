import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { AiOutlineRight } from "react-icons/ai";
import { CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import notice from "../../../../../assets/steps/exclamation-triangle.svg"
const view = () => {
  return (
    <div
      className="modal fade"
      id="CreateMobile"
      aria-hidden="true"
      aria-labelledby="CreateMobile"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
          <div className="modal-cross-icon">
            <CloseModalButton>
              <img src={crossIcon} className="fs-1 uni-mr-16" />
            </CloseModalButton>
          </div>
          <div className="modal-header ps-0 pt-0">
          <img src={notice} className="uni-mr-16"/> 
            <Heading3 className="modal-title-fs-32 featured-not-available">
            Feature not available on mobile
            </Heading3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
