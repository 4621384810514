import { FC, useEffect, useState } from "react";
import view from "./view";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { setModal1 } from "../../../../../redux/reducer/modal";
import { registerFirstPopup } from "../../../utils/apiServices";

export const Modal1: FC<any> = () => {
  const [role, setRole] = useState<string[]>([]);
  const [loading,setLoading]=useState<boolean>(false);
  const [message,setMessage] =useState<string>("")
  const dispatch =useDispatch();
  useEffect(()=>{
if(role.length){
    dispatch(setModal1(false));
  }
else{
    dispatch(setModal1(true))
}
  },[role])

const OpenPopup2= async()=>{
    setLoading(true)
const response:any = await registerFirstPopup(role);
if(response.success)
    {
        setLoading(false)
    const modalTrigger1 = document.querySelector('a[data-bs-toggle="modal"][href="#Modal2"]') as HTMLAnchorElement;
    if (!modal1 && modalTrigger1) {
      modalTrigger1.click();
    }
}
else{
setLoading(false);
setMessage(response.message);
setTimeout(()=>{
 
setMessage("");
},3000)
}
}
  const UpdateRole = (data: string) => {
    if (role.includes(data)) {
     !loading && setRole((pre) => pre.filter((ele) => ele !== data));
    } else {
      !loading &&   setRole([...role, data]);
    }
  };
  const {modal1} = useSelector((state: RootState) => state.modal);
  return view({ role, UpdateRole,modal1 ,OpenPopup2,loading,message});
};
export default Modal1;
