const Countries = [
  {
    name: "Afghanistan",
    phonePrefix: "+93",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇦🇫",
    flagUrl: "https://flagpedia.net/data/flags/h80/af.png"
  },
  {
    name: "Albania",
    phonePrefix: "+355",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇦🇱",
    flagUrl: "https://flagpedia.net/data/flags/h80/al.png"
  },
  {
    name: "Algeria",
    phonePrefix: "+213",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇩🇿",
    flagUrl: "https://flagpedia.net/data/flags/h80/dz.png"
  },
  {
    name: "Andorra",
    phonePrefix: "+376",
    minPhoneLength: 6,
    maxPhoneLength: 6,
    flag: "🇦🇩",
    flagUrl: "https://flagpedia.net/data/flags/h80/ad.png"
  },
  {
    name: "Angola",
    phonePrefix: "+244",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇦🇴",
    flagUrl: "https://flagpedia.net/data/flags/h80/ao.png"
  },
  {
    name: "Antigua and Barbuda",
    phonePrefix: "+1-268",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇦🇬",
    flagUrl: "https://flagpedia.net/data/flags/h80/ag.png"
  },
  {
    name: "Argentina",
    phonePrefix: "+54",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇦🇷",
    flagUrl: "https://flagpedia.net/data/flags/h80/ar.png"
  },
  {
    name: "Armenia",
    phonePrefix: "+374",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇦🇲",
    flagUrl: "https://flagpedia.net/data/flags/h80/am.png"
  },
  {
    name: "Australia",
    phonePrefix: "+61",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇦🇺",
    flagUrl: "https://flagpedia.net/data/flags/h80/au.png"
  },
  {
    name: "Austria",
    phonePrefix: "+43",
    minPhoneLength: 10,
    maxPhoneLength: 13,
    flag: "🇦🇹",
    flagUrl: "https://flagpedia.net/data/flags/h80/at.png"
  },
  {
    name: "Azerbaijan",
    phonePrefix: "+994",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇦🇿",
    flagUrl: "https://flagpedia.net/data/flags/h80/az.png"
  },
  {
    name: "Bahamas",
    phonePrefix: "+1-242",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇧🇸",
    flagUrl: "https://flagpedia.net/data/flags/h80/bs.png"
  },
  {
    name: "Bahrain",
    phonePrefix: "+973",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇧🇭",
    flagUrl: "https://flagpedia.net/data/flags/h80/bh.png"
  },
  {
    name: "Bangladesh",
    phonePrefix: "+880",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇧🇩",
    flagUrl: "https://flagpedia.net/data/flags/h80/bd.png"
  },
  {
    name: "Barbados",
    phonePrefix: "+1-246",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇧🇧",
    flagUrl: "https://flagpedia.net/data/flags/h80/bb.png"
  },
  {
    name: "Belarus",
    phonePrefix: "+375",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇧🇾",
    flagUrl: "https://flagpedia.net/data/flags/h80/by.png"
  },
  {
    name: "Belgium",
    phonePrefix: "+32",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇧🇪",
    flagUrl: "https://flagpedia.net/data/flags/h80/be.png"
  },
  {
    name: "Belize",
    phonePrefix: "+501",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇧🇿",
    flagUrl: "https://flagpedia.net/data/flags/h80/bz.png"
  },
  {
    name: "Benin",
    phonePrefix: "+229",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇧🇯",
    flagUrl: "https://flagpedia.net/data/flags/h80/bj.png"
  },
  {
    name: "Bhutan",
    phonePrefix: "+975",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇧🇹",
    flagUrl: "https://flagpedia.net/data/flags/h80/bt.png"
  },
  {
    name: "Bolivia",
    phonePrefix: "+591",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇧🇴",
    flagUrl: "https://flagpedia.net/data/flags/h80/bo.png"
  },
  {
    name: "Bosnia and Herzegovina",
    phonePrefix: "+387",
    minPhoneLength: 8,
    maxPhoneLength: 9,
    flag: "🇧🇦",
    flagUrl: "https://flagpedia.net/data/flags/h80/ba.png"
  },
  {
    name: "Botswana",
    phonePrefix: "+267",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇧🇼",
    flagUrl: "https://flagpedia.net/data/flags/h80/bw.png"
  },
  {
    name: "Brazil",
    phonePrefix: "+55",
    minPhoneLength: 10,
    maxPhoneLength: 11,
    flag: "🇧🇷",
    flagUrl: "https://flagpedia.net/data/flags/h80/br.png"
  },
  {
    name: "Brunei",
    phonePrefix: "+673",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇧🇳",
    flagUrl: "https://flagpedia.net/data/flags/h80/bn.png"
  },
  {
    name: "Bulgaria",
    phonePrefix: "+359",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇧🇬",
    flagUrl: "https://flagpedia.net/data/flags/h80/bg.png"
  },
  {
    name: "Burkina Faso",
    phonePrefix: "+226",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇧🇫",
    flagUrl: "https://flagpedia.net/data/flags/h80/bf.png"
  },
  {
    name: "Burundi",
    phonePrefix: "+257",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇧🇮",
    flagUrl: "https://flagpedia.net/data/flags/h80/bi.png"
  },
  {
    name: "Cambodia",
    phonePrefix: "+855",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇰🇭",
    flagUrl: "https://flagpedia.net/data/flags/h80/kh.png"
  },
  {
    name: "Cameroon",
    phonePrefix: "+237",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇨🇲",
    flagUrl: "https://flagpedia.net/data/flags/h80/cm.png"
  },
  {
    name: "Canada",
    phonePrefix: "+1",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇨🇦",
    flagUrl: "https://flagpedia.net/data/flags/h80/ca.png"
  },
  {
    name: "Cape Verde",
    phonePrefix: "+238",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇨🇻",
    flagUrl: "https://flagpedia.net/data/flags/h80/cv.png"
  },
  {
    name: "Central African Republic",
    phonePrefix: "+236",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇨🇫",
    flagUrl: "https://flagpedia.net/data/flags/h80/cf.png"
  },
  {
    name: "Chad",
    phonePrefix: "+235",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇹🇩",
    flagUrl: "https://flagpedia.net/data/flags/h80/td.png"
  },
  {
    name: "Chile",
    phonePrefix: "+56",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇨🇱",
    flagUrl: "https://flagpedia.net/data/flags/h80/cl.png"
  },
  {
    name: "China",
    phonePrefix: "+86",
    minPhoneLength: 11,
    maxPhoneLength: 11,
    flag: "🇨🇳",
    flagUrl: "https://flagpedia.net/data/flags/h80/cn.png"
  },
  {
    name: "Colombia",
    phonePrefix: "+57",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇨🇴",
    flagUrl: "https://flagpedia.net/data/flags/h80/co.png"
  },
  {
    name: "Comoros",
    phonePrefix: "+269",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇰🇲",
    flagUrl: "https://flagpedia.net/data/flags/h80/km.png"
  },
  {
  name: "Congo",
  phonePrefix: "+242",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇨🇬",
  flagUrl: "https://flagpedia.net/data/flags/h80/cg.png"
},
{
  name: "Costa Rica",
  phonePrefix: "+506",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇨🇷",
  flagUrl: "https://flagpedia.net/data/flags/h80/cr.png"
},
{
  name: "Croatia",
  phonePrefix: "+385",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇭🇷",
  flagUrl: "https://flagpedia.net/data/flags/h80/hr.png"
},
{
  name: "Cuba",
  phonePrefix: "+53",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇨🇺",
  flagUrl: "https://flagpedia.net/data/flags/h80/cu.png"
},
{
  name: "Cyprus",
  phonePrefix: "+357",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇨🇾",
  flagUrl: "https://flagpedia.net/data/flags/h80/cy.png"
},
{
  name: "Czech Republic",
  phonePrefix: "+420",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇨🇿",
  flagUrl: "https://flagpedia.net/data/flags/h80/cz.png"
},
{
  name: "Denmark",
  phonePrefix: "+45",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇩🇰",
  flagUrl: "https://flagpedia.net/data/flags/h80/dk.png"
},
{
  name: "Djibouti",
  phonePrefix: "+253",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇩🇯",
  flagUrl: "https://flagpedia.net/data/flags/h80/dj.png"
},
{
  name: "Dominica",
  phonePrefix: "+1-767",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇩🇲",
  flagUrl: "https://flagpedia.net/data/flags/h80/dm.png"
},
{
  name: "Dominican Republic",
  phonePrefix: "+1-809",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇩🇴",
  flagUrl: "https://flagpedia.net/data/flags/h80/do.png"
},
{
  name: "Ecuador",
  phonePrefix: "+593",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇪🇨",
  flagUrl: "https://flagpedia.net/data/flags/h80/ec.png"
},
{
  name: "Egypt",
  phonePrefix: "+20",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇪🇬",
  flagUrl: "https://flagpedia.net/data/flags/h80/eg.png"
},
{
  name: "El Salvador",
  phonePrefix: "+503",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇸🇻",
  flagUrl: "https://flagpedia.net/data/flags/h80/sv.png"
},
{
  name: "Equatorial Guinea",
  phonePrefix: "+240",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇬🇶",
  flagUrl: "https://flagpedia.net/data/flags/h80/gq.png"
},
{
  name: "Eritrea",
  phonePrefix: "+291",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇪🇷",
  flagUrl: "https://flagpedia.net/data/flags/h80/er.png"
},
{
  name: "Estonia",
  phonePrefix: "+372",
  minPhoneLength: 7,
  maxPhoneLength: 8,
  flag: "🇪🇪",
  flagUrl: "https://flagpedia.net/data/flags/h80/ee.png"
},
{
  name: "Eswatini",
  phonePrefix: "+268",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇸🇿",
  flagUrl: "https://flagpedia.net/data/flags/h80/sz.png"
},
{
  name: "Ethiopia",
  phonePrefix: "+251",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇪🇹",
  flagUrl: "https://flagpedia.net/data/flags/h80/et.png"
},
{
  name: "Fiji",
  phonePrefix: "+679",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇫🇯",
  flagUrl: "https://flagpedia.net/data/flags/h80/fj.png"
},
{
  name: "Finland",
  phonePrefix: "+358",
  minPhoneLength: 7,
  maxPhoneLength: 12,
  flag: "🇫🇮",
  flagUrl: "https://flagpedia.net/data/flags/h80/fi.png"
},
{
  name: "France",
  phonePrefix: "+33",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇫🇷",
  flagUrl: "https://flagpedia.net/data/flags/h80/fr.png"
},
{
  name: "Gabon",
  phonePrefix: "+241",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇬🇦",
  flagUrl: "https://flagpedia.net/data/flags/h80/ga.png"
},
{
  name: "Gambia",
  phonePrefix: "+220",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇬🇲",
  flagUrl: "https://flagpedia.net/data/flags/h80/gm.png"
},
{
  name: "Georgia",
  phonePrefix: "+995",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇬🇪",
  flagUrl: "https://flagpedia.net/data/flags/h80/ge.png"
},
{
  name: "Germany",
  phonePrefix: "+49",
  minPhoneLength: 10,
  maxPhoneLength: 11,
  flag: "🇩🇪",
  flagUrl: "https://flagpedia.net/data/flags/h80/de.png"
},
{
  name: "Ghana",
  phonePrefix: "+233",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇬🇭",
  flagUrl: "https://flagpedia.net/data/flags/h80/gh.png"
},
{
  name: "Greece",
  phonePrefix: "+30",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇬🇷",
  flagUrl: "https://flagpedia.net/data/flags/h80/gr.png"
},
{
  name: "Grenada",
  phonePrefix: "+1-473",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇬🇩",
  flagUrl: "https://flagpedia.net/data/flags/h80/gd.png"
},
{
  name: "Guatemala",
  phonePrefix: "+502",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇬🇹",
  flagUrl: "https://flagpedia.net/data/flags/h80/gt.png"
},
{
  name: "Guinea",
  phonePrefix: "+224",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇬🇳",
  flagUrl: "https://flagpedia.net/data/flags/h80/gn.png"
},
{
  name: "Guinea-Bissau",
  phonePrefix: "+245",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇬🇼",
  flagUrl: "https://flagpedia.net/data/flags/h80/gw.png"
},
{
  name: "Guyana",
  phonePrefix: "+592",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇬🇾",
  flagUrl: "https://flagpedia.net/data/flags/h80/gy.png"
},
{
  name: "Haiti",
  phonePrefix: "+509",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇭🇹",
  flagUrl: "https://flagpedia.net/data/flags/h80/ht.png"
},
{
  name: "Honduras",
  phonePrefix: "+504",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇭🇳",
  flagUrl: "https://flagpedia.net/data/flags/h80/hn.png"
},
{
  name: "Hungary",
  phonePrefix: "+36",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇭🇺",
  flagUrl: "https://flagpedia.net/data/flags/h80/hu.png"
},
{
  name: "Iceland",
  phonePrefix: "+354",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇮🇸",
  flagUrl: "https://flagpedia.net/data/flags/h80/is.png"
},
{
  name: "India",
  phonePrefix: "+91",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇮🇳",
  flagUrl: "https://flagpedia.net/data/flags/h80/in.png"
},
{
  name: "Indonesia",
  phonePrefix: "+62",
  minPhoneLength: 10,
  maxPhoneLength: 13,
  flag: "🇮🇩",
  flagUrl: "https://flagpedia.net/data/flags/h80/id.png"
},
{
  name: "Iran",
  phonePrefix: "+98",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇮🇷",
  flagUrl: "https://flagpedia.net/data/flags/h80/ir.png"
},
{
  name: "Iraq",
  phonePrefix: "+964",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇮🇶",
  flagUrl: "https://flagpedia.net/data/flags/h80/iq.png"
},
{
  name: "Ireland",
  phonePrefix: "+353",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇮🇪",
  flagUrl: "https://flagpedia.net/data/flags/h80/ie.png"
},
{
  name: "Israel",
  phonePrefix: "+972",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇮🇱",
  flagUrl: "https://flagpedia.net/data/flags/h80/il.png"
},
{
  name: "Italy",
  phonePrefix: "+39",
  minPhoneLength: 9,
  maxPhoneLength: 10,
  flag: "🇮🇹",
  flagUrl: "https://flagpedia.net/data/flags/h80/it.png"
},{
  name: "Jamaica",
  phonePrefix: "+1-876",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇯🇲",
  flagUrl: "https://flagpedia.net/data/flags/h80/jm.png"
},
{
  name: "Japan",
  phonePrefix: "+81",
  minPhoneLength: 10,
  maxPhoneLength: 11,
  flag: "🇯🇵",
  flagUrl: "https://flagpedia.net/data/flags/h80/jp.png"
},
{
  name: "Jordan",
  phonePrefix: "+962",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇯🇴",
  flagUrl: "https://flagpedia.net/data/flags/h80/jo.png"
},
{
  name: "Kazakhstan",
  phonePrefix: "+7",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇰🇿",
  flagUrl: "https://flagpedia.net/data/flags/h80/kz.png"
},
{
  name: "Kenya",
  phonePrefix: "+254",
  minPhoneLength: 9,
  maxPhoneLength: 10,
  flag: "🇰🇪",
  flagUrl: "https://flagpedia.net/data/flags/h80/ke.png"
},
{
  name: "Kiribati",
  phonePrefix: "+686",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇰🇮",
  flagUrl: "https://flagpedia.net/data/flags/h80/ki.png"
},
{
  name: "Kuwait",
  phonePrefix: "+965",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇰🇼",
  flagUrl: "https://flagpedia.net/data/flags/h80/kw.png"
},
{
  name: "Kyrgyzstan",
  phonePrefix: "+996",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇰🇬",
  flagUrl: "https://flagpedia.net/data/flags/h80/kg.png"
},
{
  name: "Laos",
  phonePrefix: "+856",
  minPhoneLength: 9,
  maxPhoneLength: 10,
  flag: "🇱🇦",
  flagUrl: "https://flagpedia.net/data/flags/h80/la.png"
},
{
  name: "Latvia",
  phonePrefix: "+371",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇱🇻",
  flagUrl: "https://flagpedia.net/data/flags/h80/lv.png"
},
{
  name: "Lebanon",
  phonePrefix: "+961",
  minPhoneLength: 7,
  maxPhoneLength: 8,
  flag: "🇱🇧",
  flagUrl: "https://flagpedia.net/data/flags/h80/lb.png"
},
{
  name: "Lesotho",
  phonePrefix: "+266",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇱🇸",
  flagUrl: "https://flagpedia.net/data/flags/h80/ls.png"
},
{
  name: "Liberia",
  phonePrefix: "+231",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇱🇷",
  flagUrl: "https://flagpedia.net/data/flags/h80/lr.png"
},
{
  name: "Libya",
  phonePrefix: "+218",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇱🇾",
  flagUrl: "https://flagpedia.net/data/flags/h80/ly.png"
},
{
  name: "Liechtenstein",
  phonePrefix: "+423",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇱🇮",
  flagUrl: "https://flagpedia.net/data/flags/h80/li.png"
},
{
  name: "Lithuania",
  phonePrefix: "+370",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇱🇹",
  flagUrl: "https://flagpedia.net/data/flags/h80/lt.png"
},
{
  name: "Luxembourg",
  phonePrefix: "+352",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇱🇺",
  flagUrl: "https://flagpedia.net/data/flags/h80/lu.png"
},
{
  name: "Madagascar",
  phonePrefix: "+261",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇲🇬",
  flagUrl: "https://flagpedia.net/data/flags/h80/mg.png"
},
{
  name: "Malawi",
  phonePrefix: "+265",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇲🇼",
  flagUrl: "https://flagpedia.net/data/flags/h80/mw.png"
},
{
  name: "Malaysia",
  phonePrefix: "+60",
  minPhoneLength: 9,
  maxPhoneLength: 10,
  flag: "🇲🇾",
  flagUrl: "https://flagpedia.net/data/flags/h80/my.png"
},
{
  name: "Maldives",
  phonePrefix: "+960",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇲🇻",
  flagUrl: "https://flagpedia.net/data/flags/h80/mv.png"
},
{
  name: "Mali",
  phonePrefix: "+223",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇲🇱",
  flagUrl: "https://flagpedia.net/data/flags/h80/ml.png"
},
{
  name: "Malta",
  phonePrefix: "+356",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇲🇹",
  flagUrl: "https://flagpedia.net/data/flags/h80/mt.png"
},
{
  name: "Marshall Islands",
  phonePrefix: "+692",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇲🇭",
  flagUrl: "https://flagpedia.net/data/flags/h80/mh.png"
},
{
  name: "Mauritania",
  phonePrefix: "+222",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇲🇷",
  flagUrl: "https://flagpedia.net/data/flags/h80/mr.png"
},
{
  name: "Mauritius",
  phonePrefix: "+230",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇲🇺",
  flagUrl: "https://flagpedia.net/data/flags/h80/mu.png"
},
{
  name: "Mexico",
  phonePrefix: "+52",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇲🇽",
  flagUrl: "https://flagpedia.net/data/flags/h80/mx.png"
},
{
  name: "Micronesia",
  phonePrefix: "+691",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇫🇲",
  flagUrl: "https://flagpedia.net/data/flags/h80/fm.png"
},
{
  name: "Moldova",
  phonePrefix: "+373",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇲🇩",
  flagUrl: "https://flagpedia.net/data/flags/h80/md.png"
},
{
  name: "Monaco",
  phonePrefix: "+377",
  minPhoneLength: 8,
  maxPhoneLength: 9,
  flag: "🇲🇨",
  flagUrl: "https://flagpedia.net/data/flags/h80/mc.png"
},
{
  name: "Mongolia",
  phonePrefix: "+976",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇲🇳",
  flagUrl: "https://flagpedia.net/data/flags/h80/mn.png"
},
{
  name: "Montenegro",
  phonePrefix: "+382",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇲🇪",
  flagUrl: "https://flagpedia.net/data/flags/h80/me.png"
},
{
  name: "Morocco",
  phonePrefix: "+212",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇲🇦",
  flagUrl: "https://flagpedia.net/data/flags/h80/ma.png"
},
{
  name: "Mozambique",
  phonePrefix: "+258",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇲🇿",
  flagUrl: "https://flagpedia.net/data/flags/h80/mz.png"
},
{
  name: "Myanmar",
  phonePrefix: "+95",
  minPhoneLength: 7,
  maxPhoneLength: 9,
  flag: "🇲🇲",
  flagUrl: "https://flagpedia.net/data/flags/h80/mm.png"
},
{
  name: "Namibia",
  phonePrefix: "+264",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇳🇦",
  flagUrl: "https://flagpedia.net/data/flags/h80/na.png"
},
{
  name: "Nauru",
  phonePrefix: "+674",
  minPhoneLength: 7,
  maxPhoneLength: 7,
  flag: "🇳🇷",
  flagUrl: "https://flagpedia.net/data/flags/h80/nr.png"
},
{
  name: "Nepal",
  phonePrefix: "+977",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇳🇵",
  flagUrl: "https://flagpedia.net/data/flags/h80/np.png"
},
{
  name: "Netherlands",
  phonePrefix: "+31",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇳🇱",
  flagUrl: "https://flagpedia.net/data/flags/h80/nl.png"
},
{
  name: "New Zealand",
  phonePrefix: "+64",
  minPhoneLength: 9,
  maxPhoneLength: 9,
  flag: "🇳🇿",
  flagUrl: "https://flagpedia.net/data/flags/h80/nz.png"
},
{
  name: "Nicaragua",
  phonePrefix: "+505",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇳🇮",
  flagUrl: "https://flagpedia.net/data/flags/h80/ni.png"
},
{
  name: "Niger",
  phonePrefix: "+227",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇳🇪",
  flagUrl: "https://flagpedia.net/data/flags/h80/ne.png"
},
{
  name: "Nigeria",
  phonePrefix: "+234",
  minPhoneLength: 10,
  maxPhoneLength: 10,
  flag: "🇳🇬",
  flagUrl: "https://flagpedia.net/data/flags/h80/ng.png"
},
{
  name: "North Macedonia",
  phonePrefix: "+389",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇲🇰",
  flagUrl: "https://flagpedia.net/data/flags/h80/mk.png"
},
{
  name: "Norway",
  phonePrefix: "+47",
  minPhoneLength: 8,
  maxPhoneLength: 8,
  flag: "🇳🇴",
  flagUrl: "https://flagpedia.net/data/flags/h80/no.png"
},
{
    name: "Oman",
    phonePrefix: "+968",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇴🇲",
    flagUrl: "https://flagpedia.net/data/flags/h80/om.png"
  },
  {
    name: "Pakistan",
    phonePrefix: "+92",
    minPhoneLength: 10,
    maxPhoneLength: 11,
    flag: "🇵🇰",
    flagUrl: "https://flagpedia.net/data/flags/h80/pk.png"
  },
  {
    name: "Palau",
    phonePrefix: "+680",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇵🇼",
    flagUrl: "https://flagpedia.net/data/flags/h80/pw.png"
  },
  {
    name: "Panama",
    phonePrefix: "+507",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇵🇦",
    flagUrl: "https://flagpedia.net/data/flags/h80/pa.png"
  },
  {
    name: "Papua New Guinea",
    phonePrefix: "+675",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇵🇬",
    flagUrl: "https://flagpedia.net/data/flags/h80/pg.png"
  },
  {
    name: "Paraguay",
    phonePrefix: "+595",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇵🇾",
    flagUrl: "https://flagpedia.net/data/flags/h80/py.png"
  },
  {
    name: "Peru",
    phonePrefix: "+51",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇵🇪",
    flagUrl: "https://flagpedia.net/data/flags/h80/pe.png"
  },
  {
    name: "Philippines",
    phonePrefix: "+63",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇵🇭",
    flagUrl: "https://flagpedia.net/data/flags/h80/ph.png"
  },
  {
    name: "Poland",
    phonePrefix: "+48",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇵🇱",
    flagUrl: "https://flagpedia.net/data/flags/h80/pl.png"
  },
  {
    name: "Portugal",
    phonePrefix: "+351",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇵🇹",
    flagUrl: "https://flagpedia.net/data/flags/h80/pt.png"
  },
  {
    name: "Qatar",
    phonePrefix: "+974",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇶🇦",
    flagUrl: "https://flagpedia.net/data/flags/h80/qa.png"
  },
  {
    name: "Romania",
    phonePrefix: "+40",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇷🇴",
    flagUrl: "https://flagpedia.net/data/flags/h80/ro.png"
  },
  {
    name: "Russia",
    phonePrefix: "+7",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇷🇺",
    flagUrl: "https://flagpedia.net/data/flags/h80/ru.png"
  },
  {
    name: "Rwanda",
    phonePrefix: "+250",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇷🇼",
    flagUrl: "https://flagpedia.net/data/flags/h80/rw.png"
  },
  {
    name: "Saint Kitts and Nevis",
    phonePrefix: "+1-869",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇰🇳",
    flagUrl: "https://flagpedia.net/data/flags/h80/kn.png"
  },
  {
    name: "Saint Lucia",
    phonePrefix: "+1-758",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇱🇨",
    flagUrl: "https://flagpedia.net/data/flags/h80/lc.png"
  },
  {
    name: "Saint Vincent and the Grenadines",
    phonePrefix: "+1-784",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇻🇨",
    flagUrl: "https://flagpedia.net/data/flags/h80/vc.png"
  },
  {
    name: "Samoa",
    phonePrefix: "+685",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇼🇸",
    flagUrl: "https://flagpedia.net/data/flags/h80/ws.png"
  },
  {
    name: "San Marino",
    phonePrefix: "+378",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇸🇲",
    flagUrl: "https://flagpedia.net/data/flags/h80/sm.png"
  },
  {
    name: "Sao Tome and Principe",
    phonePrefix: "+239",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇸🇹",
    flagUrl: "https://flagpedia.net/data/flags/h80/st.png"
  },
  {
    name: "Saudi Arabia",
    phonePrefix: "+966",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇸🇦",
    flagUrl: "https://flagpedia.net/data/flags/h80/sa.png"
  },
  {
    name: "Senegal",
    phonePrefix: "+221",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇸🇳",
    flagUrl: "https://flagpedia.net/data/flags/h80/sn.png"
  },
  {
    name: "Serbia",
    phonePrefix: "+381",
    minPhoneLength: 8,
    maxPhoneLength: 9,
    flag: "🇷🇸",
    flagUrl: "https://flagpedia.net/data/flags/h80/rs.png"
  },
  {
    name: "Seychelles",
    phonePrefix: "+248",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇸🇨",
    flagUrl: "https://flagpedia.net/data/flags/h80/sc.png"
  },
  {
    name: "Sierra Leone",
    phonePrefix: "+232",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇸🇱",
    flagUrl: "https://flagpedia.net/data/flags/h80/sl.png"
  },
  {
    name: "Singapore",
    phonePrefix: "+65",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇸🇬",
    flagUrl: "https://flagpedia.net/data/flags/h80/sg.png"
  },
  {
    name: "Slovakia",
    phonePrefix: "+421",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇸🇰",
    flagUrl: "https://flagpedia.net/data/flags/h80/sk.png"
  },
  {
    name: "Slovenia",
    phonePrefix: "+386",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇸🇮",
    flagUrl: "https://flagpedia.net/data/flags/h80/si.png"
  },
  {
    name: "Solomon Islands",
    phonePrefix: "+677",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇸🇧",
    flagUrl: "https://flagpedia.net/data/flags/h80/sb.png"
  },
  {
    name: "Somalia",
    phonePrefix: "+252",
    minPhoneLength: 7,
    maxPhoneLength: 9,
    flag: "🇸🇴",
    flagUrl: "https://flagpedia.net/data/flags/h80/so.png"
  },
  {
    name: "South Africa",
    phonePrefix: "+27",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇿🇦",
    flagUrl: "https://flagpedia.net/data/flags/h80/za.png"
  },
  {
    name: "South Sudan",
    phonePrefix: "+211",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇸🇸",
    flagUrl: "https://flagpedia.net/data/flags/h80/ss.png"
  },
  {
    name: "Spain",
    phonePrefix: "+34",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇪🇸",
    flagUrl: "https://flagpedia.net/data/flags/h80/es.png"
  },
  {
    name: "Sri Lanka",
    phonePrefix: "+94",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇱🇰",
    flagUrl: "https://flagpedia.net/data/flags/h80/lk.png"
  },
  {
    name: "Sudan",
    phonePrefix: "+249",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇸🇩",
    flagUrl: "https://flagpedia.net/data/flags/h80/sd.png"
  },
  {
    name: "Suriname",
    phonePrefix: "+597",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇸🇷",
    flagUrl: "https://flagpedia.net/data/flags/h80/sr.png"
  },
  {
    name: "Sweden",
    phonePrefix: "+46",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇸🇪",
    flagUrl: "https://flagpedia.net/data/flags/h80/se.png"
  },
  { 
    name: "Switzerland",
    phonePrefix: "+41",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇨🇭",
    flagUrl: "https://flagpedia.net/data/flags/h80/ch.png"
  },
  {
    name: "Syria",
    phonePrefix: "+963",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇸🇾",
    flagUrl: "https://flagpedia.net/data/flags/h80/sy.png"
  },
  {
    name: "Taiwan",
    phonePrefix: "+886",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇹🇼",
    flagUrl: "https://flagpedia.net/data/flags/h80/tw.png"
  },
  {
    name: "Tajikistan",
    phonePrefix: "+992",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇹🇯",
    flagUrl: "https://flagpedia.net/data/flags/h80/tj.png"
  },
  {
    name: "Tanzania",
    phonePrefix: "+255",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇹🇿",
    flagUrl: "https://flagpedia.net/data/flags/h80/tz.png"
  },
  {
    name: "Thailand",
    phonePrefix: "+66",
    minPhoneLength: 9,
    maxPhoneLength: 10,
    flag: "🇹🇭",
    flagUrl: "https://flagpedia.net/data/flags/h80/th.png"
  },
  {
    name: "Togo",
    phonePrefix: "+228",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇹🇬",
    flagUrl: "https://flagpedia.net/data/flags/h80/tg.png"
  },
  {
    name: "Tonga",
    phonePrefix: "+676",
    minPhoneLength: 5,
    maxPhoneLength: 5,
    flag: "🇹🇴",
    flagUrl: "https://flagpedia.net/data/flags/h80/to.png"
  },
  {
    name: "Trinidad and Tobago",
    phonePrefix: "+1-868",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇹🇹",
    flagUrl: "https://flagpedia.net/data/flags/h80/tt.png"
  },
  {
    name: "Tunisia",
    phonePrefix: "+216",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇹🇳",
    flagUrl: "https://flagpedia.net/data/flags/h80/tn.png"
  },
  {
    name: "Turkey",
    phonePrefix: "+90",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇹🇷",
    flagUrl: "https://flagpedia.net/data/flags/h80/tr.png"
  },
  {
    name: "Turkmenistan",
    phonePrefix: "+993",
    minPhoneLength: 8,
    maxPhoneLength: 8,
    flag: "🇹🇲",
    flagUrl: "https://flagpedia.net/data/flags/h80/tm.png"
  },
  {
    name: "Tuvalu",
    phonePrefix: "+688",
    minPhoneLength: 5,
    maxPhoneLength: 5,
    flag: "🇹🇻",
    flagUrl: "https://flagpedia.net/data/flags/h80/tv.png"
  },
  {
    name: "Uganda",
    phonePrefix: "+256",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇺🇬",
    flagUrl: "https://flagpedia.net/data/flags/h80/ug.png"
  },
  {
    name: "Ukraine",
    phonePrefix: "+380",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇺🇦",
    flagUrl: "https://flagpedia.net/data/flags/h80/ua.png"
  },
  {
    name: "United Arab Emirates",
    phonePrefix: "+971",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇦🇪",
    flagUrl: "https://flagpedia.net/data/flags/h80/ae.png"
  },
  {
    name: "United Kingdom",
    phonePrefix: "+44",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇬🇧",
    flagUrl: "https://flagpedia.net/data/flags/h80/gb.png"
  },
  {
    name: "United States",
    phonePrefix: "+1",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇺🇸",
    flagUrl: "https://flagpedia.net/data/flags/h80/us.png"
  },
  {
    name: "Uruguay",
    phonePrefix: "+598",
    minPhoneLength: 8,
    maxPhoneLength: 9,
    flag: "🇺🇾",
    flagUrl: "https://flagpedia.net/data/flags/h80/uy.png"
  },
  {
    name: "Uzbekistan",
    phonePrefix: "+998",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇺🇿",
    flagUrl: "https://flagpedia.net/data/flags/h80/uz.png"
  },
  {
    name: "Vanuatu",
    phonePrefix: "+678",
    minPhoneLength: 7,
    maxPhoneLength: 7,
    flag: "🇻🇺",
    flagUrl: "https://flagpedia.net/data/flags/h80/vu.png"
  },
  {
    name: "Vatican City",
    phonePrefix: "+379",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇻🇦",
    flagUrl: "https://flagpedia.net/data/flags/h80/va.png"
  },
  {
    name: "Venezuela",
    phonePrefix: "+58",
    minPhoneLength: 10,
    maxPhoneLength: 10,
    flag: "🇻🇪",
    flagUrl: "https://flagpedia.net/data/flags/h80/ve.png"
  },
  {
    name: "Vietnam",
    phonePrefix: "+84",
    minPhoneLength: 9,
    maxPhoneLength: 10,
    flag: "🇻🇳",
    flagUrl: "https://flagpedia.net/data/flags/h80/vn.png"
  },
  {
    name: "Western Sahara",
    phonePrefix: "+212",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇪🇭",
    flagUrl: "https://flagpedia.net/data/flags/h80/eh.png"
  },
  {
    name: "Yemen",
    phonePrefix: "+967",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇾🇪",
    flagUrl: "https://flagpedia.net/data/flags/h80/ye.png"
  },
  {
    name: "Zambia",
    phonePrefix: "+260",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇿🇲",
    flagUrl: "https://flagpedia.net/data/flags/h80/zm.png"
  },
  {
    name: "Zimbabwe",
    phonePrefix: "+263",
    minPhoneLength: 9,
    maxPhoneLength: 9,
    flag: "🇿🇼",
    flagUrl: "https://flagpedia.net/data/flags/h80/zw.png"
  }
];
  

  export default Countries;