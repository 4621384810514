import React from "react";
import "./styles.scss";
import DropwworksImg from "../../../assets/img/newDropworkslogo.png";
import { FaInstagram } from "react-icons/fa";
import CountdownTimer from "./targetDate";

const view = () => {
  const targetDate = new Date("2024-12-01T00:00:00");
  return (
    <div className="dw-maintenance">
      <video className="dw-maintenance-video" autoPlay muted loop>
        <source src="/dropworks.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="dw-maintenance-child">
        <div className="dw-maintenance-child-header">
          <img src="/Dropworks_logo.png" />
        </div>
        <div className="dw-maintenance-child-main">
          <div className="dw-maintenance-child-main-content">
            <div className="dw-maintenance-child-main-content-heading">
              Coming Soon
            </div>
          </div>
          <div className="dw-maintenance-child-main-content2"></div>

          <CountdownTimer targetDate={targetDate} />
        </div>
      </div>
    </div>
  );
};

export default view;
