import React, { forwardRef ,FC } from "react";
import "./styles.scss";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const view: FC<InputProps & { ref?: React.Ref<HTMLInputElement> }> = ({ className = "", ref, ...rest }) => {
  if(rest.type==="checkbox")
  {
    return <input ref={ref} className={`custom_inp_checkbox ${className}`} {...rest} />
  }
  else{
    return <input  ref={ref}  className={`custom_inp ${className}`} {...rest} />;
  }
};