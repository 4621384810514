import {configureStore} from '@reduxjs/toolkit';

import { authSlice } from './reducer/auth';
import { musicReducer } from './reducer/musicSlice';
import { dropWorksSelectReducer } from './reducer/dropworksSelect';
import { uploadReducer } from './reducer/uploadSlice';
import { modalReducer } from './reducer/modal';
import { userReducer } from './reducer/userSlice';
import languageReducer from './reducer/languageSlice';
import { authSlice1 } from '../redux-toolkit/features/authSlice';
import { settingsReducer } from './reducer/settingsSlice';
import { mailReducer } from './reducer/mailSlice';
import { inviteReducer } from './reducer/inviteSlice';
import { beatReducer } from './reducer/beatSlice';
import { projectReducer } from './reducer/projectnameSlice';

// import {uiSlice} from '@app/store/reducers/ui';
// import {createLogger} from 'redux-logger';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    auth2:authSlice1.reducer,
    music: musicReducer,
    dropWorksSelectOption:dropWorksSelectReducer,
    upload:uploadReducer,
    modal:modalReducer,
    user:userReducer,
    language: languageReducer,
    settings: settingsReducer,
    mail:mailReducer,
    invite:inviteReducer,
    beat:beatReducer,
    projectName:projectReducer

    // ui: uiSlice.reducer
  },
//   middleware: (getDefaultMiddleware) => [
//     ...getDefaultMiddleware().concat(createLogger())
//   ]
});
export type RootState = ReturnType<typeof store.getState>;
export default store;