import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton, openPopup } from '../../logo-header/view';
import { DropworksDropdownCountry } from '../../../../../common/custom-field/dropworks-dropdown-country';
import { ButtonComponent } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';
import { useState } from 'react';
import Loader from '../../loader/view';
// import { openPopup } from '../../../utils/localStorage';

const view = ({ 
  shownext, 
  submitDataNext, 
  openModal, 
  message,
  loading,
  changeCountry,
  phone ,handleChange,phoneNumber,countryCode,numberValidation
}: { 
  shownext: boolean; 
  submitDataNext: () => void; 
  openModal: () => void; 
  phone: string;
  message:string;
  loading:boolean;
  changeCountry:(data:any)=>void;
  handleChange:(num:string)=>void;
  phoneNumber:string,
  countryCode:string,
  numberValidation:any
}) => {



  return (
    <ModalWrapper modalId="MobilePhoneModalA" onBackdropClick={openModal}>
      <div className="modal fade" id="MobilePhoneModalA" aria-hidden="true" aria-labelledby="MobilePhoneModalA" tabIndex={-1} data-bs-theme="dark">
        <div className="modal-dialog modal-dialog-centered custom-modal-width">
          <div className="modal-content">
            <div className='modal-cross-icon'>
              <CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0 uni-pr-72">
              <Heading3 className="modal-title">Secure your account</Heading3>
            </div>
            <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
              <TextfieldLG className="uni-mt-16 uni-mb-72 ctm-txt">Add a valid phone number</TextfieldLG>
              <p className='d-flex'>
                <DropworksDropdownCountry className="uni-mr-16 secureaccount-country uni-mb-8" onChangeCountry={changeCountry} value={countryCode} disabled={shownext} />
                <DropworksInput className="mb-0 mt-0 phone-number-input" placeholder="Phone number" value={phoneNumber}   maxLength={Number(numberValidation.maxPhoneLength)}
                disabled={shownext}
                  onInput={(e: any) => {
                    let check = e.target.value.replace(/[^0-9]/g, "");
                    if (check.charAt(0) === "0") {
                      check = check.slice(1); // Remove the first character if it's '0'
                    }
                    handleChange(check)
                  }}
                  />
              </p> 
                {message && <p className='text-danger'>{message}</p>}
                {phoneNumber && 
  (phoneNumber.length < Number(numberValidation.minPhoneLength) || 
   phoneNumber.length > Number(numberValidation.maxPhoneLength)) && (
    <p className="text-danger">
      Phone number should have a minimum length of  {numberValidation.minPhoneLength} {" "}
      {/* {Number(numberValidation.maxPhoneLength) > Number(numberValidation.minPhoneLength)
        ? `  */}
        and a maximum length of 
        {/* $ */}
        {" "} {numberValidation.maxPhoneLength}.
        {/*  ` : ""
       */}
    </p>
)}
            </div>  
            <div className="uni-mt-40 modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
      
                {shownext ? (
                  <DropworksSmallButton className="btn-wid social-link-modal-btns m-0" onClick={()=>openPopup('MobilePhoneModalB')} >Change</DropworksSmallButton>
                ) : (
                loading ? <Loader /> : <DropworksSmallButton disabled={!phoneNumber || phoneNumber?.length<numberValidation.minPhoneLength || phoneNumber?.length>numberValidation.maxPhoneLength} className={!phoneNumber ||  phoneNumber?.length<numberValidation.minPhoneLength || phoneNumber?.length>numberValidation.maxPhoneLength?"btn-wid social-link-modal-btns m-0 custom-inactive-btn text-secondary disable-interactions":"btn-wid social-link-modal-btns m-0"}   onClick={submitDataNext}>Next</DropworksSmallButton>
                )}
           
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default view;
