import { FC, useState } from "react";
import "./styles.scss";
import trackImage from "../../../assets/img/temporary-images/track-image.svg";
import userImage from "../../../assets/img/Profile-pics/kingroland-image.png";
import { UserLable } from "../../../common/user-labels";
import { ArrowDropdown, MeatballsMenuDropdown } from "../../../common/icons";
import {
  BlueTik,
  
  TrackPlayBackBtn,
  YelloTik,
} from "../../../common/all-icons";
import { viewType } from "./modal";
import { setData } from "../../../redux/reducer/musicSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Heading1 } from "../../../common/custom-field/dropstock-heading";
import { TextfieldMD } from "../../../common/custom-field/dropstock-text";
import { HeartIcon } from "../../../common/heart-icon";
import { MoreMobile } from "../../../common/moreMobile";


export const view: FC<viewType> = ({ navigateTo ,trackData}) => {
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState([
    "Report",
  ]);
  const [songTeam, setSongTeam] = useState([
    {
      img: `${userImage}`,
      fullName: "King Roland",
      title: "Producer",
      goldTik: true,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "Maba",
      title: "Vocalist",
      goldTik: true,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "Kahino",
      title: "Beat Maker",
      goldTik: true,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "Lala Baby",
      title: "Lyricist ",
      goldTik: false,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "King Roland",
      title: "Artwork Designer",
      goldTik: false,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "King Roland",
      title: "Curator",
      goldTik: false,
      blueTik: true,
    },
  ]);
  console.log(songTeam);


  const music = useSelector((state: RootState) => state.music);

  const dispatch = useDispatch();

  const trackImg = trackData?.art_work_image[0]?.src


  return (<>
  
    <div className="track-page container container-xxl min-height-cover">
      <div className="track-page-body uni-mb-56">
        <div className="image-track">
          <img src={`${process.env.REACT_APP_IMGURL}/${trackImg}`||trackImage} className="track-page-image" alt="user-image" />
        </div>
        <div className="track-page-content">
          <div className="w-100 d-flex align-items-center uni-mb-24 track-page-head-content">
            <div className="track-page-details">
              <Heading1 className="mb-0 head">{trackData?.beat_name}</Heading1>
              <TextfieldMD className="track-page-song-title mb-0 mt-0 m-0">
              King Roland, Mabapupi Ocachino , Kahino, Lala Baby
            </TextfieldMD>
            
            </div>
            <div className="track-page-playback-Btn" onClick={() => dispatch(setData(true))}>
                <TrackPlayBackBtn  className="play-btn-track uni-ml-24" />
              </div>
          </div>
          <div className="d-flex gap-3 mt-2 w-100 mobile-viewtrackbutton">
            <UserLable>Track</UserLable>
            <UserLable className={`user-lables-gradient cursor-pointer`} onClick={navigateTo("/dropworks-composite")}>
              DropWorks Composite
            </UserLable>
            <UserLable className={`user-lables-gradient2 cursor-pointer`} onClick={navigateTo("/dropworks-select")}>
              DropWorks Select
            </UserLable>
            <div className="uni-py-8 uni-px-16 heart-icon-track">
            <HeartIcon/></div>
            <div className="d-flex align-items-center justify-content-center mobile-arrow-track track-more-btn uni-mt-8">
              
            <span className="uni-mr-8">  More</span>
            <MoreMobile
                                className="px-0 mobile-p-0"
                                arr={["Block","Report"]}
                              />
            </div>
            <span className="convert-more d-flex align-items-center">
         <MeatballsMenuDropdown pagename="track"
          arr={MeatballsMenuOptions}
          height={"6px"}
        ></MeatballsMenuDropdown> 
        </span>
          </div> 
        </div>
      
      </div>

      <div className="d-flex justify-content-between flex-wrap track-page-main">
        {songTeam.map((item: any, index: number) => (
          <div key={index} className="col-xxl-5 col-xl-5 col-md-5  col-12  uni-mb-16 track-page-main-sub">
            <div className="track-page-col">
              <div>
                <h4 className="track-page-subheading d-flex">
                  {item.title}
                  {item.goldTik ? (
                    <div className="uni-ml-8">
                  
                  
       
                    </div>
                  ) : (
                    ""
                  )}
                </h4>
              </div>
              <div className="track-page-sub-content mb-4 cursor-pointer" onClick={navigateTo("/user-profile")}>
                <img
                  src={item.img}
                  className="track-page-sub-content-img"
                  alt=""
                />
                <div className="track-page-sub-details">
                  <h5 className="track-page-sub-song-name">{item.fullName}</h5>

                  {item.blueTik ? (
                    <div className="uni-ml-8">
                      
                      <BlueTik position="top"/>
                    
                    </div>
                  ) : (
                    ""
                  )}
                  <span className="drop-track">
                  <MeatballsMenuDropdown
                    arr={MeatballsMenuOptions}
                    height={"5px"}
                    className="track-metaballsmenudropdown"
                  /></span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </>
  );
};
