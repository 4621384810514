import { ChangeEvent, FC, useState } from "react";
import { view } from "./view";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  message?:(value:string)=>void
}

export const DropworksMessageInput:FC<InputProps>=({ message, ...props })=>{
    const [inputValue, setInputValue] = useState("");
    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setInputValue(e.target.value);
      const lines = e.target.value.split('\n').length;

    // Optional: Log or perform actions when the line changes
    // console.log(`Line count: ${lines}`);

    };
    
    const clearInput = () => {
      setInputValue("");
    };

    // const sendMessage = () => {
    //   props.message && props.message(inputValue);
    //   setInputValue("");
    // };

    const handleSendMessage = () => {
      if (message) {
        message(inputValue);
      }
      setInputValue("");
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        if (message) {
          message(inputValue);
        }
        e.preventDefault(); // Prevent the default action (e.g., form submission)
        setInputValue("");
      }
    };
  
      
    // const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    //   if (e.key === 'Enter') {
    //     props.message && props.message(inputValue)
    //     e.preventDefault(); // Prevent the default action (e.g., form submission)
    //     setInputValue("");
    //   }
    // };

    return view({handleInputChange,handleKeyDown,inputValue,clearInput,sendMessage: handleSendMessage,...props});
}