import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import heartIcon from '../../assets/steps/newheartimg.svg';
import fillheartIcon from '../../assets/steps/newheartfill.svg';
import { updateFevourite } from "../../app/shared/utils/apiServices";

export const view: FC<any> = ({className,id,liked}) => {

    const [isFilled, setIsFilled] = useState(false);

    console.log(liked,"liked-liked")
    useEffect(()=>{
        if(liked?.user_id){
            setIsFilled(true)
        }else{
            setIsFilled(false)
        }
    },[liked])

    const handleClick = async (id:any) => {
        setIsFilled(prev => !prev);

        const response = await updateFevourite(id);
        if(response.success){
            console.log(response.message)
        }

    };
    return (
        
        <img src={isFilled ? fillheartIcon : heartIcon} alt="Heart Icon" onClick={() => handleClick(id)}   className={`cursor-pointer custom-heart-icon ${isFilled ? "is-filled" : ""}`}/>
    );
};
