import { useNavigate } from "react-router-dom"
import { view } from "./view"

export const DemoModal=()=>{

    
    const navigate=useNavigate();
    
    

    return(view())
}