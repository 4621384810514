import { useNavigate, useParams } from "react-router-dom"
import { view } from "./view"
import { getTrackProjectBeatsDetailsById } from "../../shared/utils/apiServices";
import { useEffect, useState } from "react";

export const ProjectPage=()=>{
    const slugName = useParams();
    const projectSlugName = slugName.slugName;
    const [projectData , setProjectData] = useState<any>()
    
    const navigate=useNavigate();

    useEffect(()=>{
        const fetchUserData = async () => {
            const result = await getTrackProjectBeatsDetailsById(projectSlugName); 
            if (result.success) {
                console.log(result.featuredBeatsData.data.track,"this is goood")
                setProjectData(result.featuredBeatsData.data.track)
            }
        };
    
        fetchUserData();
    },[projectSlugName])

    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({navigateTo,projectData}))
}