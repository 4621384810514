import { FC, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../../common/custom-field/dropworks-textArea";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import { ButtonComponent } from "../../../shared/components/logo-header/view";
import {
  Heading2,
  Heading3,
} from "../../../../common/custom-field/dropstock-heading";
import { LoadingInterface, settingUpviewType } from "../login/modal";
import Loader from "../../../shared/components/loader/view";
import { CropperTool } from "../../../../common/cropper-tool/view";
const view: FC<settingUpviewType & LoadingInterface> = ({
  navigateTo,
  handleSubmit,
  onSubmit,
  errors,
  register,
  setValue,
  loading = false,
  showText = false,
  isSubmitted,
  uploadedImage = "",
}) => {
  return (
    <div className="settingUp row align-items-pages container pb-3 m-auto h-100  justify-content-between px-0  min-h-800">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 px-0 settingUp_form">
        <Heading3 className="settingUp_heading my-0">
          Setting up your profile
        </Heading3>
        <div className="signUp_normal uni-my-32  custom-text-sm front-pages-second-heading mb-16-mbl">
          Please fill out all the fields
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DropworksInputIcon
            placeholder="Username"
            disabled={loading}
            maxLength={15}
            {...register("username")}
            tooltipTitle="This will be your unique username that will be permanently tied to your account."
            className={`form-control mb-0 ${
              isSubmitted &&
              (errors.username
                ? "is-invalid b-red-2 is-invalid-2"
                : !errors.username
                ? "is-valid  is-invalid-2 b-green-2"
                : "")
            }`}
          />
          {errors.username && (
            <p className="invalid-feedback">{errors.username.message}</p>
          )}
          <DropworksInputIcon
            placeholder="Display Name"
            disabled={loading}
            maxLength={30}
            className2="uni-mt-24 mobile-m-8-settingup"
            {...register("display_name")}
            tooltipTitle="This is the name that people will know you for. Can be changed in the settings."
            className={`form-control mb-0 ${
              isSubmitted &&
              (errors.display_name
                ? "is-invalid b-red-2 is-invalid-2"
                : !errors.display_name
                ? "is-valid  is-invalid-2 b-green-2"
                : "")
            }`}
          />
          {errors.display_name && (
            <p className="invalid-feedback">{errors.display_name.message}</p>
          )}

          <DropworksTextArea
            className="mb-0"
            maxLength={80}
            disabled={loading}
            className2="uni-mt-24 mobile-m-8-settingup "
            onChange={(e) =>
              setValue("biography", e.target.value, { shouldValidate: true })
            }
            placeholder="Biography (optional)"
            rows={4}
          />
          {errors.biography && (
            <p className="invalid-feedback">{errors.biography.message}</p>
          )}
          {/* <ButtonComponent id="Modal1"> */}
          {loading ? (
            <Loader className="uni-mt-32"/>
          ) : (
            <DropworksButton
              type="submit"
              className="finish-settingup-web uni-mt-32"
            >
              Finish
            </DropworksButton>
          )}
          {/* </ButtonComponent> */}
        </form>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 mt-5 mt-sm-0 px-0 margin-0-settingup">
        <div className="settingUp_uploadbox flex-column  ml-auto overflow-hidden">
          {uploadedImage ? (
            <ButtonComponent id="settinguppopup">
            <img src={uploadedImage} className="upload-img-2 w-100 h-100" />
            </ButtonComponent>
          ) : (
            <>
              <ButtonComponent id="settinguppopup">
                <img src={iconImg} />
              </ButtonComponent>
              <label className="mt-4"> Profile Picture</label>
            </>
          )}
        </div>
        {errors.profile_image && (
          <p className="invalid-feedback w-476  ml-auto">
            {errors.profile_image.message}
          </p>
        )}
        <div className="w-476  ml-auto uni-mt-40">
          {/* <DropworksButton disabled={loading} className="settingUp_register_finish">
              Randomize
            </DropworksButton> */}
        </div>
        {loading ? (
          <Loader className="finish-settingup-mobile uni-mt-16" />
        ) : (
          <DropworksButton type="submit" className="finish-settingup-mobile">
            Finish
          </DropworksButton>
        )}
      </div>
    </div>
  );
};
export default view;
