import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";

import "./styles.scss";
import { DropworksSwitch } from "../../../../../common/custom-field/dropworks-switch";
import iconImg from "../../../../../assets/img/upload-icon.png";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import {
  setUploadSettingImg,
  setUploadSettingImgAPI,
  setUploadSettingImgBanner,
  setUploadSettingImgBannerAPI,
} from "../../../../../redux/reducer/uploadSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { CropperTool } from "../../../../../common/cropper-tool/view";
import { getUserData, updateUser } from "../../../utils/apiServices";
import { CropperToolBanner } from "../../../../../common/cropper-tool-banner/view";
import { setUserData } from "../../../../../redux/reducer/userSlice";
import uploadbannerImage from "../../../../../assets/img/background-user-profileimg.png";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
const view = () => {
  const [onUpload, setOnupload] = useState(false);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [isCropperOpen, setCropperOpen] = useState(false);
  const userData = useSelector((state: any) => state.user);
  
  const dispatch = useDispatch();

  const handleImageCrop = (croppedImage: any) => {
    setCroppedImage(croppedImage);
  };

  const submitData = async (data: { banner_image: any }) => {
    let response: any = await updateUser(data);
    if (response) {
      const result = await getUserData(); // Call your API function
      if (result.success && result.userData) {
        // Adjust based on your API response
        dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
      }
    } else {
      console.log("failLLLLLLLLL");
    }
  };
  const saveCall = async () => {
    dispatch(setUploadSettingImgBanner(croppedImage));
    try {
      const response = await fetch(croppedImage);
      const blob = await response.blob();

      const fileName = `${Date.now()}.jpg`;

      const file = new File([blob], fileName, { type: blob.type });

      const formData = new FormData();

      formData.append("uploadingFile", file);

      try {
        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_URL}/upload-file`,
          formData
        );
        if (uploadResponse.data.success) {
          submitData({ banner_image: uploadResponse.data.file_data });
          dispatch(setUploadSettingImgBannerAPI(uploadResponse.data.file_data));
        } else {
          console.error(
            "Else Error in response of uploading image",
            uploadResponse.data.message
          );
        }
      } catch (error) {
        console.error("Catch Error in response of uploading image", error);
      }
    } catch (error) {
      console.error("Error processing the cropped image:", error);
    }
    setCroppedImage(null);
    setOnupload(false);
  };
  const openModal=()=>{
    setCroppedImage(null), setOnupload(false);
  }
  return (
    <ModalWrapper modalId="UploadBanner2" onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="UploadBanner2"
      aria-hidden="true"
      aria-labelledby="UploadBanner2"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog   mx-auto   modal-dialog-centered custom-modal-width ">
        <div className="modal-content modal-width-upload-banner">
          <div className="modal-cross-icon">
            <CloseModalButton>
              <img src={crossIcon} className="fs-1 uni-mr-16" />
            </CloseModalButton>
          </div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Upload a Banner</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh uni-mb-16 modal-second-head-font">
              Try to use a 16:9 high resolution banner
            </TextfieldLG>
            <TextfieldXSM className="ctm-txt2 uni-mb-56 modal-second-head-font">
              (Don’t upload anything if you do not want this do be displayed on
              your profile)
            </TextfieldXSM>

            <div className="uploadBanner_uploadbox-banner upload-banner-space mx-0 my-0 position-relative overflow-hidden">
              {onUpload ? (
                <>
                  {croppedImage == null ? (
                    <CropperToolBanner
                      onClose={() => setCropperOpen(false)}
                      onImageCrop={handleImageCrop}
                      iconImg={iconImg}
                      uploadText="Upload"
                      id="settingupPopup"
                    />
                  ) : (
                    <img
                      src={croppedImage}
                      className=" cursor-pointer w-100 h-100"
                    />
                  )}
                </>
              ) : userData?.bannerImg ? (
                <img
                  src={userData?.bannerImg}
                  className=" w-100 h-100"
                />
              ) : (<>
                {croppedImage == null ? (
                  <CropperToolBanner
                    onClose={() => setCropperOpen(false)}
                    onImageCrop={handleImageCrop}
                    iconImg={iconImg}
                    uploadText="Upload"
                    id="settingupPopup"
                  />
                ) : (
                  <img
                    src={croppedImage}
                    className=" cursor-pointer w-100 h-100"
                  />
                )}
             </> )}
            </div>
          </div>
          <div className="modal-footer  mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            {!onUpload ? (
              userData?.bannerImg ?
              <>
                <DropworksSmallButton
                  className="btn-wid m-0 modal-btns"
                  onClick={() => setOnupload(true)}
                >
                  Upload
                </DropworksSmallButton>
              </>:  <>
                <CloseModalButton>
                  <DropworksSmallButton
                    className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 social-link-modal-btns"
                    onClick={() => {
                      setCroppedImage(null), setOnupload(false);
                    }}
                  >
                    Cancel
                  </DropworksSmallButton>
                </CloseModalButton>
                {croppedImage == null ? (
                  <DropworksSmallButton
                    className="btn-wid m-0 disabled-btn social-link-modal-btns"
                    disabled
                  >
                    Save
                  </DropworksSmallButton>
                ) : (
                  <>
                    <CloseModalButton>
                      <DropworksSmallButton
                        className="btn-wid m-0"
                        onClick={saveCall}
                      >
                        Save
                      </DropworksSmallButton>
                    </CloseModalButton>
                  </>
                )}
              </>
            ) : (
              <>
                <CloseModalButton>
                  <DropworksSmallButton
                    className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 social-link-modal-btns"
                    onClick={() => {
                      setCroppedImage(null), setOnupload(false);
                    }}
                  >
                    Cancel
                  </DropworksSmallButton>
                </CloseModalButton>
                {croppedImage == null ? (
                  <DropworksSmallButton
                    className="btn-wid m-0 disabled-btn social-link-modal-btns"
                    disabled
                  >
                    Save
                  </DropworksSmallButton>
                ) : (
                  <>
                    <CloseModalButton>
                      <DropworksSmallButton
                        className="btn-wid m-0 social-link-modal-btns"
                        onClick={saveCall}
                      >
                        Save
                      </DropworksSmallButton>
                    </CloseModalButton>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    </ModalWrapper>
  );
};

export default view;
