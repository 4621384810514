import React, { FC } from "react";
import "./styles.scss";
import { TextfieldLG, TextfieldXSM } from "../custom-field/dropstock-text";

export const view: FC<any> = ({
    navigateTo,
    image,
    arr,
    className2,
     className
}) => {
    return (
        <div className={`row  ${className}`}>
            {arr.length>0?<>{arr.map((data:any, index:any) => (
                <div key={index} className={`col-6 col-sm-6 col-md-3 col-lg-2 user-icon  ${className2} `} onClick={navigateTo(`/user-profile/${data.slugName}`)}>
                    <div className="user-icon-box">
                        <img className="user-icon-box" src={`${process.env.REACT_APP_IMGURL}/${data.image}`}/>
                    </div>
                    <TextfieldLG className="user-icon-heading mt-0">{data?.heading?.length > 12 ? data.heading.substring(0, 12) + "..." : data.heading}</TextfieldLG>
                    <TextfieldXSM className="user-icon-subheading m-0">{data?.subheading?.length > 12 ? data.subheading.substring(0, 12) + "..." : data.subheading}</TextfieldXSM>
                </div>
            ))}</>:"No User Data"}
        </div>
    );
};
