import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import './styles.scss';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import { ButtonComponent } from '../../logo-header/view';
import { DropworksRadioCheckbox } from '../../../../../common/custom-field/dropworks-radio-checkbox';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { FC } from 'react';
import { modal1Type } from '../popup-modal';
import Loader from '../../loader/view';

const view:FC<modal1Type> = ({role,UpdateRole,modal1=false,OpenPopup2,loading=false,message=""}) => {
  return (
    <div
    className="modal fade"
    id="Modal1"
    aria-hidden="true"
    aria-labelledby="Modal1"
    tabIndex={-1}
    data-bs-backdrop="static" 
   data-bs-keyboard="false"   
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width-s">
    <ButtonComponent id="Modal2" className='d-none'></ButtonComponent>
      <div className="modal-content  modal-content-boxes-modal">
    <div className='modal-cross-icon'>
     {/* <CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton> */}
        </div>
        <div className="modal-header  ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">Starting out</Heading3>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
          <TextfieldLG className=" uni-mt-16 ctm-txt">Pick your roles </TextfieldLG>
          <TextfieldXSM className='uni-mb-56 select-atleast'>(Select at least one)</TextfieldXSM>
          <div className='d-flex uni-mb-24 investor-box-parent' >
               <div className='investor-boxs cursor-pointer' onClick={()=>UpdateRole("Investor")}>
                    <p className='investor-boxs-under'>Investor</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={role.includes("Investor")} /></p>
               </div>
               <div className='investor-boxs cursor-pointer'  onClick={()=>UpdateRole("Producer")}>
                    <p className='investor-boxs-under'>Producer</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox  checked={role.includes("Producer")} /></p>
               </div>
               <div className='investor-boxs cursor-pointer'  onClick={()=>UpdateRole("Vocalist")}>
                    <p className='investor-boxs-under'>Vocalist</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox  checked={role.includes("Vocalist")} /></p>
               </div>
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent'>
          <div className='investor-boxs cursor-pointer'  onClick={()=>UpdateRole("Lyricist")}>
                    <p className='investor-boxs-under'>Lyricist</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox  checked={role.includes("Lyricist")} /></p>
               </div>
               <div className='investor-boxs cursor-pointer'  onClick={()=>UpdateRole("Beat Maker")}>
                    <p className='investor-boxs-under'>Beat Maker</p>
                    <p className='investor-boxs-radiobutton' ><DropworksRadioCheckbox  checked={role.includes("Beat Maker")} /></p>
               </div>
               <div className='investor-boxs cursor-pointer'  onClick={()=>UpdateRole("Curator")}>
                    <p className='investor-boxs-under'>Curator</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox  checked={role.includes("Curator")} /></p>
               </div>
          </div>
          <div className='d-flex  investor-box-parent'>
          <div className='investor-boxs cursor-pointer'  onClick={()=>UpdateRole("Cover Designer")}>
                    <p className='investor-boxs-under'>Cover Designer</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox  checked={role.includes("Cover Designer")} /></p>
               </div>
          </div>

        </div>    
        
        <div className="modal-footer mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
        {/* <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> */}
        
        {/* <ButtonComponent id="Modal2" className={modal1?' disable-interactions':''}> */}
        {loading?<Loader />:<DropworksSmallButton  disabled={modal1} className={modal1?"btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions":"btn-wid  modal-btns m-0"} onClick={OpenPopup2}>Next</DropworksSmallButton>}
        {/* </ButtonComponent> */}
        </div>
        {message && <p className='text-danger w-100 text-center uni-mt-16'>{message}</p>}
      </div>
    </div>
  </div>
  )
}

export default view;

