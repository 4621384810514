import { FC } from "react";
import { view } from "./view";
import { useNavigate } from "react-router-dom";

// interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const BeatesIcon:FC<any>=({...props})=>{
    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }
    return view({...props,navigateTo});
}