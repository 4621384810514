import { FC, useState } from "react";
import "./styles.scss";
import { TrackIcon } from "../../../../common/track-icon";
import { ProjectIcon } from "../../../../common/project-icon";
import { DropworkGhostButton } from "../../../../common/custom-field/dropworks-button";
import { BeatesIcon } from "../../../../common/beats-icon";
import { Heading2 } from "../../../../common/custom-field/dropstock-heading";
import Tabs from "../../../../common/tabs";
import TabsComponent from "../../../../common/tabs-component/view";

export const view: FC = ({trackarr,projectarr,beatsarr}:any) => {
  const [dropdownarrTracks, setDropdownarrTracks] = useState({
    heading: "Genre",
    option: ["Genre", "Genre", "Genre", "Genre", "Genre", "Genre", "Genre"],
  });

  const [button, setButton] = useState("all");

  const handleButtonClick = (type: string) => {
    setButton(type);
  };
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value)
  }

  return (
    <div className="library container container-xl container-xxl min-height-cover">
      <Heading2 className="library-heading">Library</Heading2>
      <Tabs className="uni-mb-56" tabsTitle={["All", "Tracks", "Projects", "Beats"]}   id="7"  checkTabState={checkTabState}/>
      <TabsComponent
      tabsValue={tabsValue}
      components={[
        <>
        <div className="library-subheading">Your Tracks</div>
        <div className="library-usericon">
            <TrackIcon arr={trackarr.slice(0,6)}  className='all-icon-mobile uni-pl-24 full-width-child' className2='track-icon-mobile-view'/>
          </div>
        <div className="library-subheading">Your Projects</div>
        <div className="library-usericon">
            <ProjectIcon arr={projectarr.slice(0,6)} page="library" className='all-icon-mobile uni-pl-24 full-width-child' className2='project-icon-mobile-view' />
          </div>
          <div className="library-subheading">Your Beats</div>
          <div className="library-usericon">
            <BeatesIcon arr={beatsarr.slice(0,6)}  className='all-icon-mobile uni-pl-24 full-width-child' className2='beats-icon-mobile-view' />
          </div>
        </>,
        <>
         <div className="library-usericon">
            <TrackIcon arr={trackarr} />
          </div>
        </>,
        <>
         <div className="d-flex library-btn-parent gap-4 mb-4">
            <DropworkGhostButton className="library-btns active" >Active</DropworkGhostButton>
            <DropworkGhostButton className="library-btns">Released</DropworkGhostButton>
            </div>
           <div className="library-usericon">
            <ProjectIcon arr={projectarr} page="library" />
          </div>
        </>,
        <>
          <div className="library-usericon">
            <BeatesIcon arr={beatsarr} />
          </div>
        </>
      ]}/>
    </div>
  );
};
