import React, { FC } from "react";
import "./styles.scss";
import { RiQuestionFill } from "react-icons/ri";
import CustomTooltip from "../../custom-tooltip/view";

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    hideIcon?: boolean;
    className2?:string | undefined,
  }
export const view: FC<TextareaProps> = ({ className = "",  hideIcon = false,className2, ...rest }) => {
  return (

    <div className={`custom_inp_icon_container  ${className2}`}>
    <textarea
      className={` custom_textarea_inp ${className}`}
      {...rest}
    />
      {!hideIcon && ( 
     <span className="question-icon">
     <CustomTooltip title="Small biography that will be displayed on your profile. In case you do not want to display it then just leave it blank. Can be changed in the settings." position="right">
        <RiQuestionFill className="hover-icon-color" />
        </CustomTooltip>
    </span>
      )}
  </div>
    // <div>
    // <textarea className={`custom_textarea_inp ${" "} ${className}`} {...rest} />
    // <span className="question-icon">
    //       <RiQuestionFill />
    //   </span>

    // </div>
  );
};
