import { FC } from "react";
import "./styles.scss";
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColor?: string;
}


export const view: FC<ButtonProps> = ({
  className = "",
  children,
  ...rest
}) => {
  return (
    <button className={`user-lables ${className}`} {...rest}>
      {children}
    </button>
  );
}
