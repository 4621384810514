import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { useDispatch, useSelector } from "react-redux";
import { addPhoneNumber, getUserData, updatePhoneNumber, updateUser } from "../../../utils/apiServices";
import { setUserData } from "../../../../../redux/reducer/userSlice";
import { useNavigate } from "react-router-dom";
import { openPopup } from "../../logo-header/view";
import { setPhoneNumberAdded, setPhoneNumberAddedCountryCode } from "../../../../../redux/reducer/modal";



export const MobilePhoneModalA:FC<any>=()=>{
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [shownext, setShowNext] = useState<boolean>(false);      
    const userData = useSelector((state: any) => state.user);
    const [loading,setLoading] =useState<boolean>(false)
    const [message,setMessage] =useState<string>("")
    const { phone,countryCode } = userData;
    const navigate = useNavigate();
    
    useEffect(() => {
        if(phone)
        {
            setPhoneNumber(phone);
            setShowNext(true);
        }
    }, [phone]);

    const handleChange = (e:string) => {
        setPhoneNumber(e);
    };

    const openModal = () => {
        setPhoneNumber(phoneNumber);  
    };
    const [numberValidation,setNumberValidation]=useState({ name: "Afghanistan", phonePrefix: "+93", minPhoneLength: 9, maxPhoneLength: 9, flag: "🇦🇫", flagUrl: "https://flagpedia.net/data/flags/h80/af.png"})
    const changeCountry =(data:any)=>{
    setNumberValidation(data);
    }
    const submitData = async (data: { phone: string }) => {
        setLoading(true)
            const response = await addPhoneNumber({phone:phoneNumber,countryCode:numberValidation.phonePrefix});
            if(response.success)
            {
                dispatch(setPhoneNumberAdded(phoneNumber));
                dispatch(setPhoneNumberAddedCountryCode(numberValidation.phonePrefix))
                setLoading(false)
                openPopup('MobilePhoneModal3')
            }
            else{
            setMessage(response.message);
            setLoading(false)
            setTimeout(()=>{
              setMessage("")
            },2000)
            }
    
        // const response: any = await updatePhoneNumber(data);
        // if (response) {
        //     const result = await getUserData(); 
        //     if (result.success && result.userData) { 
        //         dispatch(setUserData(result.userData.data.userdata));
        //         const modalTrigger = document.querySelector('a[data-bs-toggle="modal"][href="#MobilePhoneModal3"]') as HTMLAnchorElement;
        //         if (modalTrigger) {
        //             modalTrigger.click();
        //         }
        //     }
        // } else {
        //     console.error("Failed to update phone number.");
        // }
    };

    return view({ 
        shownext, 
        submitDataNext: () => submitData({ phone: phoneNumber }), 
        countryCode,
        changeCountry,
        openModal,
        phone ,
        message,
        phoneNumber,
        numberValidation,
        handleChange,
        loading
    });
}
export default MobilePhoneModalA;