import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { changePhoneNumber, getUserData, updatePhoneNumber } from "../../../utils/apiServices";
import { setUserData } from "../../../../../redux/reducer/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openPopup } from "../../logo-header/view";
import { setPhoneNumberAdded ,setPhoneNumberAddedCountryCode} from "../../../../../redux/reducer/modal";
// import { openPopup } from "../../../utils/localStorage";
// import { closeModal } from "../../logo-header/view";



export const MobilePhoneModalB:FC<any>=()=>{

    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [shownext, setShowNext] = useState<boolean>(false);      
    const userData = useSelector((state: any) => state.user);
    const [loading,setLoading]=useState<boolean>(false)
    const { PhoneNubmer } = userData;
    const navigate = useNavigate();
    const [message,setMessage]=useState("")
    useEffect(() => {
        setPhoneNumber(PhoneNubmer);
        setShowNext(true);
    }, [PhoneNubmer]);

    const handleChange = (e:string) => {
        setPhoneNumber(e);
    };

    const [numberValidation,setNumberValidation]=useState({ name: "Afghanistan", phonePrefix: "+93", minPhoneLength: 9, maxPhoneLength: 9, flag: "🇦🇫", flagUrl: "https://flagpedia.net/data/flags/h80/af.png"})
    const changeCountry =(data:any)=>{
    setNumberValidation(data);
    }

    const submitData = async () => {
        setLoading(true);
        const response: any = await changePhoneNumber({phone:phoneNumber,countryCode:numberValidation.phonePrefix});
        if (response.success) {
            setLoading(false)
            openPopup('MobilePhoneModal3')
       dispatch(setPhoneNumberAdded(phoneNumber));
       dispatch(setPhoneNumberAddedCountryCode(numberValidation.phonePrefix));
            const result = await getUserData(); 
            if (result.success && result.userData) { 
                dispatch(setUserData(result.userData.data.userdata));
            }
        } else {
            setLoading(false)
            setMessage(response.message);
            setTimeout(()=>{
                setMessage("")
            },2000)
            console.error("Failed to update phone number.");
        }
    };
    return view({ 
        shownext, 
        message,
        numberValidation,
        submitData, 
        phoneNumber ,
        handleChange,
        loading,
        changeCountry
    });
    

   
}
export default MobilePhoneModalB;