import { FC, useState } from "react";
import  view  from "./view";

import { useNavigate } from "react-router-dom";


export const InviteModal2:FC<any>=()=>{
   
    return view();
}
export default InviteModal2