import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import { CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const view = () => {

  const userData = useSelector((state: any) => state.user);
 const [log,setLog]=useState([])
 useEffect(() => {
  
  setLog(userData.loginHistory);
}, [userData]);

  

  return (
    <div
    className="modal fade"
    id="LoginHistorySocials"
    aria-hidden="true"
    aria-labelledby="LoginHistorySocials"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width ">
      <div className="modal-content mx-auto modal-width-login-history">
      <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">Login History</Heading3>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
          <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh uni-mb-32 mb-16-mbl mt-8-mbl">Check for suspicious activity</TextfieldLG>

          <p className='uni-p-16 border-bottom popup-space-mobile'>
          <TextfieldMD className='mx-0 my-0 text-field-login-history-first'>Last Logins</TextfieldMD>
        
          </p>
          {log ? log?.map((data:any,index:any)=>{
            return (
              <p className='uni-p-16 border-bottom popup-space-mobile'>
              <TextfieldSM className='mx-0 my-0 text-field-login-history'>{data.time} {data.browserName} {data.ip}</TextfieldSM>
              </p>
            )
          }) :   <TextfieldMD className="uni-mb-8 uni-my-16 ctm-txt ctm-md-lh  mb-16-mbl mt-8-mbl text-center">No Login History</TextfieldMD>}
          {/* <p className='uni-p-16 border-bottom popup-space-mobile'>
          <TextfieldSM className='mx-0 my-0 text-field-login-history'>12-12-2023 20:02:33, chrome 76.0.3239.84</TextfieldSM>
          </p>
          <p className='uni-p-16 border-bottom popup-space-mobile' >
          <TextfieldSM className='mx-0 my-0 text-field-login-history'>12-12-2023 20:02:33, chrome 76.0.3239.84</TextfieldSM>
          </p>
          <p className='uni-p-16 border-bottom popup-space-mobile'>
          <TextfieldSM className='mx-0 my-0 text-field-login-history'>12-12-2023 20:02:33, chrome 76.0.3239.84</TextfieldSM>
          </p>
          <p className='uni-p-16 border-bottom popup-space-mobile'>
          <TextfieldSM className='mx-0 my-0 text-field-login-history'>12-12-2023 20:02:33, chrome 76.0.3239.84</TextfieldSM>
          </p> */}
        </div>  
        <div className="modal-footer ps-0 pt-0 pb-0 social-mobile-modal-footer  uni-pr-72">
          <CloseModalButton>
          <DropworksSmallButton  className="btn-wid m-0 social-link-modal-btns">Confirm</DropworksSmallButton>
          </CloseModalButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

